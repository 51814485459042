import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-outbound-routes',
  templateUrl: './outbound-routes.component.html',
  styleUrls: ['./outbound-routes.component.css']
})
export class OutboundRoutesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
