import { EmployeeService } from "./../../../../service/employee.service";
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Language, LanguageInterface } from "../../../../service/language";
import { TranslateService } from "@ngx-translate/core";
import { _ } from "@biesbjerg/ngx-translate-extract/dist/utils/utils";
import { LoginService } from "../../../../service/login.service";
import { ThemeColorInterface } from "../../../../service/Utils/Interfaces.class";
import { ThemeColor } from "../../../../service/constant";
import { Router } from "@angular/router";
import * as toastr from "toastr";
import { API, StorageKey } from "../../../../service/constant";
import { DashboardService } from "../../../../service/dashboard.service";
import { PermissionsUsersService } from "../../../../service/permissions-users.service";
import { NotificationEmailService } from "../../../../service/notification-email.service";
import { AddCompanyService } from "../../../../service/add-company.service";
import { TrunkSettingService } from "../../../../service/trunk-setting.service";
import { HttpClient } from "@angular/common/http";
import { logoutDelCookie, readCookie } from "../../../provisioner/services";
import { MoboSnackBar } from "../../../provisioner/notification";
import { MatDialog } from "@angular/material/dialog";
import { API_BASE } from "../../../provisioner/env/provisioner.env";


@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class AppHeaderComponent implements OnInit {
  languages: LanguageInterface[];
  themeColor: ThemeColorInterface[];
  userId = localStorage.getItem("userId");
  themeClass = localStorage.getItem("theme");
  tenantKey = localStorage.getItem("tk");
  themeData = {};
  companyData = {};
  companyList: any;
  hostname: any;
  password: string;
  hasMultipleCompanies: boolean = false;
  searchModel: string;
  searchValue: string;
  accountData: any = [];
  searchResults: any = [];
  panelStatusAccount: boolean = true;
  renames: object = {
    extName: "Extension Name",
    extNum: "Extension Number",
    macAddr: "MAC Address",
    name: "Name",
    group: "Group",
    model: "Model",
    created_at: "Latest Config",
    vpbx: "VPBX",
    deploy_date: "Deploy Date",
  };
  public data: any;
  timeRemaining: string = "";
  sessionInterval: any = null;
  sessionWarning: boolean = true;

  

  @ViewChild("supportsearch") input;
  @ViewChild("supportsearchmodel") input_type;

  constructor(
    private translate: TranslateService,
    private loginService: LoginService,
    private employeeService: EmployeeService,
    public dashboardservice: DashboardService,
    public permissionsUsersService: PermissionsUsersService,
    public notificationEmailService: NotificationEmailService,
    public addCompanyService: AddCompanyService,
    public trunkSettingService: TrunkSettingService,
    private http: HttpClient,
    private router: Router,
    private snackBar: MoboSnackBar,
    private dialogRef: MatDialog
  ) {
    this.languages = Language.languages;
    this.themeColor = ThemeColor.themeColor;
    translate.setDefaultLang("en");

  }

  searchType: string = "all";

  ngOnInit() {
    // this.password = this.loginService.getPass()
    // this.getCompanies(this.tenantKey);
    this.changeTheme("red-light");
    this.sessionInterval = setInterval(() => {
      this.timeRemaining = this.getSessionRemaining();
    }, 500);
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    clearInterval(this.sessionInterval);
  }

  search(value) {}

  GoToclient() {
    this.dashboardservice.messageSource.next(1);
    this.router.navigateByUrl("admin/client");
  }

  GoToUsers() {
    this.permissionsUsersService.messageSource.next(1);
    this.router.navigateByUrl("admin/settings/permissions_users");
  }

  GoToLink(link) {
    if (link == "admin/client") {
      this.dashboardservice.messageSource.next(1);
    } else if (link == "admin/settings/permissions_users") {
      this.permissionsUsersService.messageSource.next(1);
    } else if (link == "admin/settings/email_settings") {
      this.notificationEmailService.messageSource.next(0);
    } else if (link == "admin/provisioner") {
      this.addCompanyService.messageSource.next(1);
    } else if (link == "admin/connectivity/trunk_settings") {
      this.trunkSettingService.messageSource.next(1);
    }

    this.router.navigateByUrl(link);
  }

  clickLink(link) {
    this.router.navigateByUrl(link);
  }

  getCompanies(tenantKey) {
    this.loginService.getCompanies(tenantKey).subscribe((res: any) => {
      if (res.info.code == 200) {
        if (res.data.length > 1) {
          this.hasMultipleCompanies = true;
        } else {
          this.hasMultipleCompanies = false;
        }
        this.companyList = res.data;
      }
    });
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  changeTheme(theme) {
    $("#themeDiv").removeClass().addClass(theme);
    this.themeData = { theme: theme };
    if (theme !== this.themeClass) {
      // this.loginService.themeUpdate(this.themeData).subscribe((res: any) => {
      //   localStorage.setItem("theme", theme);
      //   this.themeClass = theme;
      // });
    }
  }

  changeCompany(companyId) {
    this.hostname = window.location.hostname;
    toastr.options.positionClass = "toast-bottom-right";
    this.loginService
      .companyDetails(companyId, this.tenantKey)
      .subscribe((res: any) => {
        if (res.info.code == 200) {
          this.employeeService
            .detailsById(localStorage.getItem("userId"))
            .subscribe((result: any) => {
              if (result.info.code == 200) {
                if (this.hostname == "localhost") {
                  window.open(
                    "http://localhost:4000/login/?email=" +
                      result.data[0].email +
                      "&password=" +
                      this.loginService.getPass() +
                      "&domain=localhost&ck=" +
                      res.data.companyDetails.companyKey,
                    "_blank"
                  );
                } else {
                  window.open(
                    "https://" +
                      res.data.companyDetails.domain +
                      "/login/?email=" +
                      result.data[0].email +
                      "&password=" +
                      this.password +
                      "&domain=" +
                      res.data.companyDetails.domain +
                      "&ck=" +
                      res.data.companyDetails.companyKey,
                    "_blank"
                  );
                }
              }
            });
        } else {
          toastr.error("Something went wrong.");
        }
      });
  }

  logout(r = false) {
    this.dialogRef.closeAll();
    localStorage.clear();
    this.deleteAllCookies();
    logoutDelCookie();
    var extra = {};
    if (r) {
      extra = {
        queryParams: { return: location.href },
      };
    }
    this.router.navigate(["/login"], extra);
    // this.loginService.logout().subscribe((res) => {
    //   localStorage.clear();
    //   // window.location.reload();
    //   this.router.navigate(['/login']);
    // });
  }

  pad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }

  getSessionRemaining() {
    if (readCookie("sessionTime")) {
      var startDate = new Date();
      var endDate = new Date(readCookie("sessionTime"));
      var seconds = parseInt(
        ((endDate.getTime() - startDate.getTime()) / 1000).toString()
      );

      if (
        parseInt((seconds / 60).toString()) == 0 &&
        parseInt((seconds % 60).toString()) == 0
      ) {
        this.snackBar.warn({
          msg: "Your session has expired.",
        });
        this.logout(true);
      }
      if (this.sessionWarning && parseInt((seconds / 60).toString()) == 5) {
        this.sessionWarning = false;
        this.snackBar.warn({
          msg: "Your session is about to expire!! 5 minutes remaining.",
        });
      }
      return `${this.pad(parseInt((seconds / 60).toString()))}:${this.pad(
        parseInt((seconds % 60).toString())
      )}`;
    } else {
      return "";
    }
  }

  deleteAllCookies() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }

  searchApi(type, value) {
    return this.http.get(`${API_BASE}clients/search/${type}?search=${value}`);
  }

  globalSearch() {
    this.panelStatusAccount = true;
    this.searchModel = this.input_type.nativeElement.value;
    this.searchValue = this.input.nativeElement.value;
    if (this.searchValue.length > 2) {
      this.searchApi(this.searchModel, this.searchValue).subscribe(
        (val: Array<{}>) => {
          this.searchResults = [...val];
        }
      );
    } else {
      this.searchResults = [];
    }
  }

  togglePanel(flag) {
    if (flag === "panelStatusAccount") {
      this.panelStatusAccount = !this.panelStatusAccount;
    }
  }

  OnClickNavigate(searchData) {
    this.searchResults = [];
    switch (searchData.type) {
      case "company_setting":
        this.router.navigate([
          `/admin/provisioner/company-settings/${searchData.id}/${searchData.model_id}`,
        ]);
        break;
      case "devices":
        this.router.navigate([
          `/admin/provisioner/device-settings/${searchData.client}/${searchData.model_id}/${searchData.id}/${searchData.group_setting_id}`,
        ]);
        break;
      case "clients":
        switch (searchData.from) {
          case "client":
            this.router.navigate([
              `/admin/provisioner/details/${searchData.id}`,
            ]);
            break;
          case "global_settings":
            this.router.navigate([
              `/admin/provisioner/edit-global-settings/${searchData.client}/${searchData.company_setting_id}/${searchData.model_id}/${searchData.id}`,
            ]);
            break;
          case "groups":
            this.router.navigate([
              `/admin/provisioner/group-settings/${searchData.client}/${searchData.id}`,
            ]);
            break;
          case "group_settings":
            this.router.navigate([
              `/admin/provisioner/group-settings-edit/${searchData.client}/${searchData.group_id}/${searchData.model_id}/${searchData.id}`,
            ]);
            break;

          case "devices":
            this.router.navigate([
              `/admin/provisioner/device-settings/${searchData.client}/${searchData.model_id}/${searchData.id}/${searchData.group_setting_id}`,
            ]);
            break;

          default:
            break;
        }

      default:
        break;
    }
  }
}
