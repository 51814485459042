import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatCardModule, MatButtonModule, MatFormFieldModule, MatDialogModule, MatTableModule, MatSortModule, MatPaginatorModule, MatInputModule, MatSliderModule } from '@angular/material';
import { Select2Module } from "ng-select2-component";
import { MdePopoverModule } from '@material-extended/mde';
import { ContactsComponent } from '../contacts/contacts.component'
import { ContactsRoutingModule } from './contacts-routing.module'
@NgModule({
    imports: [  
      CommonModule,
      BrowserModule,
      MatButtonModule,
      MatCardModule,
      MatDialogModule,
      MatFormFieldModule,
      MatTableModule,
      MatSortModule,
      MatPaginatorModule,
      MatInputModule,
      MatSliderModule,
      FormsModule,
      ReactiveFormsModule,
      Select2Module,
      MdePopoverModule,
      ContactsRoutingModule,
    ],
    declarations :[
      ContactsComponent
    ],
    entryComponents: [],
})

export class ContactsModule { }