import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Select2Option, Select2Data } from 'ng-select2-component';
import * as toastr from "toastr";

@Component({
  selector: 'app-nevermisscall',
  templateUrl: './nevermisscall.component.html',
  styleUrls: ['./nevermisscall.component.scss']
})
export class NevermisscallComponent implements OnInit {
  nevermisscallForm: FormGroup;
  submitted = false;

  public recording

  public data2 : Select2Data = [
    { value: '401', label: '401',},
    { value: '402', label: '402' },
    { value: '403', label: '403' },
    { value: '404', label: '404' },
  ];

  public data3 : Select2Data = [
    { value: '5 Seconds', label: '5 Seconds',},
    { value: '10 Seconds', label: '10 Seconds' },
    { value: '15 Seconds', label: '15 Seconds' },
    { value: '20 Seconds', label: '20 Seconds' },
  ];

  public data4 : Select2Data = [
    { value: '(214)468-897', label: '(214)468-897',},
    { value: '(124)486-879', label: '(124)486-879',},
    { value: '(142)864-987', label: '(142)864-987',},
    { value: '(412)684-978', label: '(412)684-978',},
    { value: '(421)846-789', label: '(421)846-789',},
    { value: '(356)191-320', label: '(356)191-320',},
    { value: '(653)390-211', label: '(653)390-211',},
  ];

  data: Select2Option[] = [];
  firstNext: Select2Option[] = [];
  voicemail: Select2Option[] = [];

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.nevermisscallForm = this.formBuilder.group({
      ring: [''],
      firstFor: [''],
      thenRing: [''],
      nextFor: [''],
      voicemail: [''],
    });
  }

  get f() { return this.nevermisscallForm.controls; }

  onSubmit() {
      this.submitted = true;

      if (this.nevermisscallForm.invalid) {
        toastr.error("Invalid Details !");
        return;
      }
      console.log(this.nevermisscallForm.value);

      toastr.success("Form Submitted Successfully !");
      this.nevermisscallForm.reset();
  }

  update(value: string) {
    this.recording = value;
  }
  open() {
    this.data = JSON.parse(JSON.stringify(this.data2));
    this.firstNext = JSON.parse(JSON.stringify(this.data3));
    this.voicemail = JSON.parse(JSON.stringify(this.data4));
  }

  search(text: string) {
    this.data = text
      ? (JSON.parse(JSON.stringify(this.data2)) as Select2Option[])
        .filter(option => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1)
      : JSON.parse(JSON.stringify(this.data2));
    this.firstNext = text
      ? (JSON.parse(JSON.stringify(this.data3)) as Select2Option[])
        .filter(option => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1)
      : JSON.parse(JSON.stringify(this.data3));
      this.voicemail = text
      ? (JSON.parse(JSON.stringify(this.data4)) as Select2Option[])
        .filter(option => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1)
      : JSON.parse(JSON.stringify(this.data4));
  }

}
