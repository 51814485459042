import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { AddClientComponent } from "./add-client/add-client.component";
import { Store } from "@ngrx/store";
import { State } from "../../../reducers";
import { ClientsLoad, CLientDelete } from "../store/actions";
import { selectAllClients, selectDeleteState } from "../store";
import { Clients, DbResponse } from "../models";
import { Observable } from "rxjs";
import { MoboAlert } from "../notification";
import Swal from "sweetalert2";
import { ClientService, readCookie } from "../services";
import { Router } from "@angular/router";
import { MatSort } from "@angular/material/sort";
import { Common } from "../../../service/constant";

@Component({
  selector: "clients",
  templateUrl: "./clients.component.html",
  styleUrls: ["./clients.component.scss"],
})
export class ClientsComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    private store: Store<State>,
    private alert: MoboAlert,
    private service: ClientService,
    private router: Router
  ) {}

  // columns in table
  displayedColumns: string[] = [
    "name",
    "ext_num",
    "ext_start",
    "blfs",
    "vpbx",
    "actions",
  ];
  dataSource: any;

  @ViewChild(MatPaginator, {}) paginator: MatPaginator;
  @ViewChild(MatSort, {}) sort: MatSort;

  clients$: Observable<Clients[]>;
  clientDelete$: Observable<boolean | DbResponse>;

  ngOnInit() {
    if (!readCookie("authenticate")) {
      return this.router.navigate(["/login"]);
    }
    // loading all clients
    this.loadClients();
    // client deleted state
    this.clientDelete$ = this.store.select(selectDeleteState);
  }

  // load client function
  loadClients() {
    this.store.dispatch(new ClientsLoad());
    this.clients$ = this.store.select(selectAllClients);
    this.clients$.subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    });
  }

  // opening add client dialog
  openAddClientDialog() {
    var config = Common.matDialogConfig;
    config.panelClass = ["animated", "slideInRight", "full-screen-modal"];
    const addClientDialog = this.dialog.open(AddClientComponent, {
      disableClose: true,
      ...config,
    });
    addClientDialog.afterClosed().subscribe((data) => {
      if (data) {
        if (data.added) {
          Swal.fire("Added", "CLient has been added.", "success");
          this.loadClients();
        }
      }
    });
  }

  // delete client function
  deleteClient(id: string, rev: string) {
    this.alert
      .confirm({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
      })
      .then((result) => {
        if (result.value) {
          this.store.dispatch(new CLientDelete({ _id: id, _rev: rev }));
          this.clientDelete$.subscribe((data: DbResponse) => {
            if (data) {
              if (data.ok) {
                Swal.fire("Deleted!", "CLient has been deleted.", "success");
                this.loadClients();
              } else {
                Swal.fire("Oopsss...", "Something went worng", "error");
              }
            }
          });
        }
      });
  }

  // table filter
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  // edit client name
  editClientName(id: string, name: string) {
    Swal.fire({
      title: "Edit client name",
      input: "text",
      inputValue: name,
      showCancelButton: true,
      confirmButtonText: "SAVE",
      cancelButtonText: "CANCEL",
      cancelButtonColor: "#EC4849",
      reverseButtons: true,
      confirmButtonColor: "#45CE30",
      allowOutsideClick: false,
      focusCancel: true,
    }).then((result: any) => {
      if (result.value) {
        this.service.editClientName(id, result.value).subscribe((data: any) => {
          if (data) {
            if (data.ok) {
              Swal.fire("Edited!", "CLient name has been edited.", "success");
              this.loadClients();
            } else {
              Swal.fire("Oopsss...", "Something went worng", "error");
            }
          }
        });
      }
    });
  }

  // edit VPBX
  editVpbx(id: string, vpbx: string) {
    Swal.fire({
      title: "Edit VPBX domain name",
      input: "text",
      inputValue: vpbx,
      cancelButtonColor: "#EC4849",
      reverseButtons: true,
      confirmButtonColor: "#45CE30",
      showCancelButton: true,
      confirmButtonText: "SAVE",
      cancelButtonText: "CANCEL",
      allowOutsideClick: false,
      focusCancel: true,
    }).then((result: any) => {
      if (result.value) {
        this.service.editVpbx(id, result.value).subscribe((data: any) => {
          if (data) {
            if (data.ok) {
              Swal.fire(
                "Edited!",
                "CLient VPBX domain name has been edited.",
                "success"
              );
              this.loadClients();
            } else {
              Swal.fire("Oopsss...", "Something went worng", "error");
            }
          }
        });
      }
    });
  }
}

export class DialogContentExampleDialog {}
