import { Component, OnInit } from '@angular/core';
import { Select2Option, Select2Data } from 'ng-select2-component';
import { FormBuilder, FormGroup, Validators, FormControl  } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';

export interface Calls {
  day: String;
  time: String;
  call_status: String;
  number: String;
  duration: String;
}

const ELEMENT_DATA: Calls[] = [
  {day:'Tuesday',time:'12:14 PM',call_status:'Answered',number:'(469) 879-2040',duration:'22 sec'},
  {day:'Tuesday',time:'12:14 PM',call_status:'Answered',number:'(469) 879-2040',duration:'22 sec'},
  {day:'Tuesday',time:'12:14 PM',call_status:'Answered',number:'(469) 879-2040',duration:'22 sec'},
  {day:'Tuesday',time:'12:14 PM',call_status:'Answered',number:'(469) 879-2040',duration:'22 sec'},
  {day:'Tuesday',time:'12:14 PM',call_status:'Answered',number:'(469) 879-2040',duration:'22 sec'},
];

@Component({
  selector: 'app-calls',
  templateUrl: './calls.component.html',
  styleUrls: ['./calls.component.scss']
})
export class CallsComponent implements OnInit {

  displayedColumns: string[] = ['day','time','call_status','number','duration'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  public recording

  public data2 : Select2Data = [
    { value: '1234567890', label: '1234567890' },
    { value: '2345678901', label: '2345678901',},
    { value: '3456789012', label: '3456789012' },
    { value: '4567890123', label: '4567890123' },
    { value: '5678901234', label: '5678901234' },
    { value: '6789012345', label: '6789012345' },
    { value: '7890123456', label: '7890123456' },
    { value: '8901234567', label: '8901234567' },
    { value: '9012345678', label: '9012345678' },
    { value: '1122334455', label: '1122334455' },
    { value: '6677889900', label: '6677889900' },
    { value: '1526374890', label: '1526374890' },
  ]
  data: Select2Option[] = [];

  allCallsDiv: boolean = true;
  incomingCallDiv: boolean = false;
  outgoingCallsDiv: boolean = false;
  missedCallsDiv: boolean = false;

  constructor(private formBuilder: FormBuilder) { }


  callTabType: number;
  public incomingCalls: [];
  public outgoingCalls: [];
  public missedCalss: [];
  public callsList:[];
  public allCallsList = [
    {
      date: 'Tuesday',
      list:[
        { name:'', callNumber:'(469) 879-2040', company:'VIVANT CORP', missed:false, callTime:'22 Sec', callType:0, time: '12:03 PM' },
        { name:'', callNumber:'(972) 331-1065', company:'ADVANCED VIRTUA', missed:true, callTime:'22 Sec', callType:0, time: '11:04 AM' },
        { name:'Restricted', callNumber:'(187) 656-2244', company:'Anonymous', missed:false, callTime:'22 Sec', callType:1, time: '08:21 AM' },
      ],
    },
    {
      date: 'Monday',
      list:[
        { name:'', callNumber:'(123) 456-7890', company:'VIVANT CORP', missed:false, callTime:'22 Sec', callType:0, time: '12:03 PM' },
        { name:'', callNumber:'(323) 101-6789', company:'ADVANCED VIRTUA', missed:true, callTime:'22 Sec', callType:0, time: '11:04 AM' },
        { name:'Hal Jorden', callNumber:'(233) 001-5678', company:'Anonymous', missed:false, callTime:'22 Sec', callType:1, time: '08:21 AM' },
        { name:'', callNumber:'(123) 456-7890', company:'VIVANT CORP', missed:true, callTime:'22 Sec', callType:0, time: '8:14 PM' },
        { name:'', callNumber:'(323) 101-6789', company:'ADVANCED VIRTUA', missed:true, callTime:'22 Sec', callType:0, time: '8:04 AM' },
        { name:'Barry Allen', callNumber:'(233) 001-5678', company:'Anonymous', missed:false, callTime:'22 Sec', callType:1, time: '7:21 AM' },
      ],
    }
  ];





  ngOnInit() {
    this.callTabType = 1;
  }

  clickAllDiv(event) {
    this.allCallsDiv = true;
    this.incomingCallDiv = false;
    this.outgoingCallsDiv = false;
    this.missedCallsDiv = false;

  }
  clickIncomingDiv(event) {
    this.allCallsDiv = false;
    this.incomingCallDiv = true;
    this.outgoingCallsDiv = false;
    this.missedCallsDiv = false;
  }
  clickOutgoingDiv(event) {
    this.allCallsDiv = false;
    this.incomingCallDiv = false;
    this.outgoingCallsDiv = true;
    this.missedCallsDiv = false;
  }
  clickMissedDiv(event) {
    this.allCallsDiv = false;
    this.incomingCallDiv = false;
    this.outgoingCallsDiv = false;
    this.missedCallsDiv = true;
  }
  

  applyFilter(filterValue: any) {
    this.allCallsList.filter = filterValue.trim().toLowerCase();
  }

  update(value: string) {
    this.recording = value;
  }

  open() {
    this.data = JSON.parse(JSON.stringify(this.data2));
  }

  search(text: string) {
    this.data = text
      ? (JSON.parse(JSON.stringify(this.data2)) as Select2Option[])
        .filter(option => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1)
      : JSON.parse(JSON.stringify(this.data2));
  }
}
