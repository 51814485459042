import { Component, OnInit, Inject, ChangeDetectionStrategy} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { FormControl } from '@angular/forms';
// import { AngularKeypad } from 'angular-keypad';
// export interface DialogData {
//   animal: string;
// }

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.scss'],
})
export class UserDashboardComponent implements OnInit {

  // animal: string;

  constructor(public dialog: MatDialog) { }

  ngOnInit() {

  }

  openDialog(): void {
    const dialogRef = this.dialog.open(Dialerpad, {
      width: '370px',
      panelClass: 'dialpad_dialog'
      // data: {animal: this.animal}
    });

    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    //   // this.animal = result;
    // });
  }
}

@Component({
  selector: 'dialerpad',
  templateUrl: './dialerpad.html',
  styleUrls: ['./dialerpad.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Dialerpad {
  userlist = [
    {initial:'K',name:'Kerisa Knezha',phone:'+1 860-226-5623'},
    {initial:'N',name:'Nicholas Inman',phone:'+1 720-369-2733'},
    {initial:'K',name:'Kerisa Knezha',phone:'+1 860-226-5623'},
    {initial:'K',name:'Kerisa Knezha',phone:'+1 860-226-5623'},
    {initial:'K',name:'Kerisa Knezha',phone:'+1 860-226-5623'},
    {initial:'K',name:'Kerisa Knezha',phone:'+1 860-226-5623'},
  ];
  constructor(
    public dialogRef: MatDialogRef<Dialerpad>
    ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}
