import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatCardModule, MatButtonModule, MatFormFieldModule, MatDialogModule, MatTableModule, MatSortModule, MatPaginatorModule, MatInputModule } from '@angular/material';
import { Select2Module } from "ng-select2-component";
import { CallsComponent } from './calls.component';
import { CallsRoutingModule } from './calls-routing.module';
import { MdePopoverModule } from '@material-extended/mde';
@NgModule({
    imports: [  


  CommonModule,
      BrowserModule,
      MatButtonModule,
      MatCardModule,
      MatDialogModule,
      MatFormFieldModule,
      MatTableModule,
      MatSortModule,
      MatPaginatorModule,
      MatInputModule,
      FormsModule,
      ReactiveFormsModule,
      Select2Module,
      CallsRoutingModule,
      MdePopoverModule
    ],
    declarations :[
      CallsComponent
    ],
    entryComponents: [],
})

export class CallsModule { }