import { ClientsEffects } from "./clients.effects";
import { CompanySettingEffects } from "./companySettings.effects";
import { DeviceEffects } from "./devices.effects";

export const effects = [
    ClientsEffects,
    CompanySettingEffects,
    DeviceEffects
];

export * from './clients.effects';
export * from './companySettings.effects';
export * from './devices.effects';