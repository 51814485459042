import { ClientsAction, ClientsActionType } from "../actions";
import { Clients, DbResponse } from "../../models";

export interface ClientsState {
    loading: boolean;
    loaded: boolean;
    data: Clients[] | null;
    deleting: boolean | null;
    deleted: boolean | null | DbResponse;
    adding: boolean | null;
    added: boolean | null | DbResponse;
}

export const clientsInitialState: ClientsState = {
    loading: false,
    loaded: false,
    data: null,
    deleting: null,
    deleted: null,
    adding: null,
    added: null
}

export function clientsReducer(
    state = clientsInitialState,
    action: ClientsAction
): ClientsState {
    switch (action.type) {
        case ClientsActionType.CLIENTS_LOAD:
            {
                return {
                    ...state,
                    loading: true,
                    loaded: false
                };
            }

        case ClientsActionType.CLIENTS_LOAD_SUCCESS:
                {
                    return {
                        ...state,
                        data: action.payload,
                        loading: false,
                        loaded: true
                    };
                }

        case ClientsActionType.CLIENTS_LOAD_FAIL:
                {
                    return {
                        ...state,
                        loading: false,
                        loaded: false
                    };
                }

        case ClientsActionType.CLIENT_DELETE:
            {
                return {
                    ...state,
                    deleting: true,
                    deleted: false
                };
            }

        case ClientsActionType.CLIENT_DELETE_SUCCESS:
        {
            return {
                ...state,
                deleting: false,
                deleted: action.payload
            };
        }

        case ClientsActionType.CLIENT_DELETE:
        {
            return {
                ...state,
                deleting: false,
                deleted: false
            };
        }

        case ClientsActionType.CLIENT_ADD:
        {
            return {
                ...state,
                adding: true,
                added: false
            };
        }

        case ClientsActionType.CLIENT_ADD_SUCCESS:
        {
            return {
                ...state,
                adding: false,
                added: action.payload
            };
        }

        case ClientsActionType.CLIENT_ADD_FAIL:
        {
            return {
                ...state,
                adding: false,
                added: false
            };
        }
    }
    return state;
}