import { EmployeeService } from './../../../../service/employee.service';
import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Language, LanguageInterface} from '../../../../service/language';
import {TranslateService} from '@ngx-translate/core';
import {_} from '@biesbjerg/ngx-translate-extract/dist/utils/utils';
import {LoginService} from '../../../../service/login.service';
import {ThemeColorInterface} from '../../../../service/Utils/Interfaces.class';
import {ThemeColor} from '../../../../service/constant';
import {Router} from '@angular/router';
import * as toastr from 'toastr';
import { API, StorageKey } from '../../../../service/constant';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})

export class AppHeaderComponent implements OnInit {
  languages: LanguageInterface[];
  themeColor: ThemeColorInterface[];
  userId = localStorage.getItem('userId');
  themeClass = localStorage.getItem('theme');
  tenantKey = localStorage.getItem('tk');
  themeData = {};
  companyData = {};
  companyList: any;
  hostname: any;
  password: string;
  hasMultipleCompanies: boolean = false;
  public data: any;
  constructor(private translate: TranslateService, private loginService: LoginService, private employeeService: EmployeeService, private router: Router) {
    this.languages = Language.languages;
    this.themeColor = ThemeColor.themeColor;
    translate.setDefaultLang('en');
  }

  ngOnInit() {
    this.password = this.loginService.getPass()
    this.changeTheme(this.themeClass ? this.themeClass : 'red-light');
    this.getCompanies(this.tenantKey);
  }

  clickLink(link) {
    this.router.navigateByUrl(link);
  }

  getCompanies(tenantKey){
    this.loginService.getCompanies(tenantKey).subscribe((res: any) => {
      if (res.info.code == 200) {
        if (res.data.length > 1) {
          this.hasMultipleCompanies = true;
        } else {
          this.hasMultipleCompanies = false;
        }
        this.companyList = res.data;
      }
    });
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  changeTheme(theme) {
    $('#themeDiv').removeClass().addClass(theme);
    this.themeData = {'theme': theme};
    if (theme !== this.themeClass) {
      this.loginService.themeUpdate(this.themeData).subscribe((res: any) => {
        localStorage.setItem('theme', theme);
        this.themeClass = theme;
      });
    }
  }

  changeCompany(companyId) {
    this.hostname = window.location.hostname;
    toastr.options.positionClass = "toast-bottom-right";
    this.loginService.companyDetails(companyId, this.tenantKey).subscribe((res: any) => {
      if (res.info.code == 200) {
        this.employeeService.detailsById(localStorage.getItem("userId")).subscribe((result:any) => {
          if (result.info.code == 200) {            
            if (this.hostname == 'localhost') {
              window.open("http://localhost:4000/login/?email=" + result.data[0].email + "&password=" + this.loginService.getPass() + "&domain=localhost&ck=" + res.data.companyDetails.companyKey, "_blank");
            } else {
              window.open("https://" + res.data.companyDetails.domain + "/login/?email=" + result.data[0].email + "&password=" + this.password + "&domain=" + res.data.companyDetails.domain + "&ck=" + res.data.companyDetails.companyKey, "_blank");
            }
          }
        });
      } else {
        toastr.error("Something went wrong.");
      }
    });
  }

  logout(){
    this.loginService.logout().subscribe((res) => {
      localStorage.clear();
      window.location.reload();
    });
  }
}
