import { Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-scroll-top-button',
  templateUrl: './scroll-top-button.component.html',
  styleUrls: ['./scroll-top-button.component.css']
})
export class ScrollTopButtonComponent implements OnInit {

  isScrollTopButtonShow = false;
  @Input('scrollBody') scrollBody: any;
  @Input() type: string;

  constructor() { }

  ngOnInit() {
    if (this.scrollBody) {
      if (this.type === 'detail') {
        this.scrollBody.onscroll = function (event) {
          this.isScrollTopButtonShow = event.target.scrollTop > 100;
        }.bind(this);
      } else {
        this.scrollBody.parentElement.onscroll = function (event) {
          this.isScrollTopButtonShow = event.target.scrollTop > 100;
        }.bind(this);
      }
    }
  }

  moveToTop() {
    if (this.scrollBody) {
      this.scrollBody.scrollTop = 0
    }
  }

}
