import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import { Select2Option, Select2Data } from 'ng-select2-component';
import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import * as toastr from 'toastr';

export interface Food {
  value: string;
  viewValue: string;
 }

@Component({
  selector: 'app-addextension',
  templateUrl: './addextension.component.html',
  styleUrls: ['./addextension.component.scss']
})
export class AddextensionComponent implements OnInit {

  generalFormGroup: FormGroup;
  voicemailFormGroup : FormGroup;
  findfollowmeFormGroup: FormGroup;
  extensionoptionsFormGroup: FormGroup;
  destLocation: boolean = false;
  notReachable: boolean = false;
  busyDestination: boolean = false;

  foods: Food[] = [
    {value: 'steak-0', viewValue: 'Steak'},
    {value: 'pizza-1', viewValue: 'Pizza'},
    {value: 'tacos-2', viewValue: 'Tacos'}
  ];

  public recording

  public data2 : Select2Data = [
    {value:' 5 Seconds',label:' 5 Seconds'},
    {value:' 10 Seconds',label:' 10 Seconds'},
    {value:' 15 Seconds',label:' 15 Seconds'},
    {value:' 20 Seconds',label:' 20 Seconds'},
  ]

  public data3 : Select2Data = [
    { value: 'Ringall', label: 'Ringall' },
    { value: 'Hunt', label: 'Hunt',},
    { value: 'Memoryhunt', label: 'Memoryhunt' },
    { value: 'Firstunavailable', label: 'Firstunavailable' },
    { value: 'Firstnotonphone', label: 'Firstnotonphone' },
    { value: 'Random', label: 'Random' },
  ];

  public data4 : Select2Data = [
    { value: '1000', label: '1000' },
    { value: '2001', label: '2001',},
    { value: '2200', label: '2200' },
    { value: '2321', label: '2321' },
    { value: '4003', label: '4003' },
    { value: '5002', label: '5002' },
  ];

  public data5 : Select2Data = [
    { value: 'None', label: 'None' },
    { value: 'Announcement 1', label: 'Announcement 1',},
    { value: 'Announcement 2', label: 'Announcement 2' },
    { value: 'Announcement 3', label: 'Announcement 3' },
  ];

  public data6 : Select2Data = [
    {value:'None',label:'None'},
    {value:'Music 1',label:'Music 1'},
    {value:'Music 2',label:'Music 2'},
    {value:'Music 3',label:'Music 3'}
  ];

  public data7 : Select2Data = [
    {value:'Default',label:'Default'},
    {value:' 1',label:' 1'},
    {value:' 2',label:' 2'},
    {value:' 3',label:' 3'},
    {value:' 4',label:' 4'},
    {value:' 5',label:' 5'},
    {value:' 6',label:' 6'},
    {value:' 7',label:' 7'},
    {value:' 8',label:' 8'},
    {value:' 9',label:' 9'},
    {value:' 10',label:' 10'}
  ];

  public data8 : Select2Data = [
    {value:'Default',label:'Default'},
    {value:'Fixed CID Value',label:'Fixed CID Value'},
    {value:'Outside Calls Fixed CID Value',label:'Outside Calls Fixed CID Value'},
    {value:'Use Dialed Number',label:'Use Dialed Number'},
    {value:'Force Dialed Number',label:'Force Dialed Number'},
  ];

  public data9 : Select2Data = [
    { value: '100', label: '100' },
    { value: '201', label: '201',},
    { value: '220', label: '220' },
    { value: '231', label: '231' },
    { value: '400', label: '400' },
    { value: '502', label: '502' },
  ];

  public data10 : Select2Data = [
    {value:'Default',label:'Default'},
    {value:'10',label:'10'},
    {value:'20',label:'20'},
    {value:'50',label:'50'},
    {value:'70',label:'70'},
    {value:'100',label:'100'},
    {value:'500',label:'500'},
    {value:'700',label:'700'},
    {value:'1000',label:'1000'},
    {value:'2000',label:'2000'},
    {value:'5000',label:'5000'}
  ];

  public data11 : Select2Data = [
    {value:'Disabled',label:'Disabled'},
    {value:'Screening 1',label:'Screening 1'},
    {value:'Screening 2',label:'Screening 2'},
    {value:'Screening 3',label:'Screening 3'}
  ];

  public data12 : Select2Data = [
    {value:'Use State',label:'Use State'},
    {value:'Ignore State',label:'Ignore State'},
  ];

  public data13 : Select2Data = [
    {value:'None',label:'None'},
    {value:'IVR',label:'IVR'},
    {value:'Extension',label:'Extension'},
    {value:'Ring Group',label:'Ring Group'}
  ];

  public data14 : Select2Data = [
    {value:'None',label:'None'},
    {value:'911',label:'911'},
    {value:'440',label:'440'},
    {value:'111',label:'111'}
  ];

  public data15 : Select2Data = [
    {value:'None',label:'None'},
    {value:'davis-DALLAS',label:'davis-DALLAS'},
    {value:'Knezha-09',label:'Knezha-09'},
    {value:'wireshark-Province',label:'wireshark-Province'}
  ];

  ringTimeData = [
    {id:1, name:'5 Seconds'},
    {id:2, name:'10 Seconds'},
    {id:3, name:'15 Seconds'},
    {id:4, name:'20 Seconds'},
  ];
  outboundLimitData = [
    {id:1, name:'5'},
    {id:2, name:'10'},
    {id:3, name:'20'},
    {id:4, name:'30'},
    {id:1, name:'40'},
    {id:2, name:'50'},
    {id:3, name:'60'},
    {id:4, name:'70'},
    {id:1, name:'80'},
    {id:2, name:'90'},
    {id:2, name:'100'},
  ];
  optDestData1 = [
    {id:1, name:'None'},
    {id:2, name:'IVR'},
    {id:3, name:'Extension'},
    {id:4, name:'Ring Group'},
  ];
  optDestData2 = [
    {id:1, name:'None'},
    {id:2, name:'davis-DALLAS'},
    {id:3, name:'Knezha-09'},
    {id:4, name:'wireshark-Province'},
  ];


  data: Select2Option[] = [];
  ringStrategy: Select2Option[] = [];
  followMe: Select2Option[] = [];
  announcement: Select2Option[] = [];
  moh: Select2Option[] = [];
  ringerVolume: Select2Option[] = [];
  mode: Select2Option[] = [];
  normalExt: Select2Option[] = [];
  outboundLimit: Select2Option[] = [];
  screening: Select2Option[] = [];
  queueState: Select2Option[] = [];
  optDestination: Select2Option[] = [];
  emergencyCid: Select2Option[] = [];
  optDest2: Select2Option[] = [];

  public phoneNumber : NgOption = []
  public phoneNumberlist: NgOption = [
    { value: '100', label: '100(Vitual Assistance)' },
    { value: '101', label: '101(Hamed Cell Phone)',},
    { value: '102', label: '102(Hamed AVS)' },
    { value: '103', label: '103(Support)' },
    { value: '104', label: '100(Vitual Assistance)' },
    { value: '105', label: '101(Hamed Cell Phone)',},
    { value: '106', label: '102(Hamed AVS)' },
    { value: '107', label: '103(Support)' },
    { value: '100', label: '100(Vitual Assistance)' },
    { value: '108', label: '101(Hamed Cell Phone)',},
    { value: '109', label: '102(Hamed AVS)' },
    { value: '110', label: '103(Support)' },
    { value: '111', label: '100(Vitual Assistance)' },
    { value: '112', label: '101(Hamed Cell Phone)',},
    { value: '113', label: '102(Hamed AVS)' },
    { value: '114', label: '103(Support)' },
  ]

  public activeemail : boolean = false
  public password : boolean = false

  public selectedPeople :NgOption = []
  constructor(private _formBuilder: FormBuilder) { }

  ngOnInit() {
    this.destLocation = false;
    this.notReachable = false;
    this.busyDestination = false;

    this.generalFormGroup = this._formBuilder.group({
      userextension: ['', Validators.required],
      displayname: ['',Validators.required],
      outboundcid: ['',Validators.required],
      secreat: ['',Validators.required],
      hardwareMacId: ['',Validators.required],
      userdirctory : ['',Validators.required],
      defaultuser : ['',Validators.required],
      username : ['',Validators.required],
      newuserpassword : ['',Validators.required],
      groups : ['',Validators.required]
    });
    this.voicemailFormGroup = this._formBuilder.group({
      voicemailpassword: ['', Validators.required],
      emailaddress: ['', Validators.email],
      press0_one: ['', Validators.required],
      press0_two: ['', Validators.required],

    });
    this.findfollowmeFormGroup = this._formBuilder.group({
      initialringtime :[''],
      ringstrategy :[''],
      ringtime : [''],
      quickselect : [''],
      announcement : [''],
      playmusic : [''],
      followmelist :[''],
      cidnameprefix :[''],
      alertinfo :[''],
      ringervolume:[''],
      remoteannounce: [''],
      toolateannounce: [''],
      mode:[''],
      followme : [''],
      normalextension : [''],


    });

    this.extensionoptionsFormGroup = this._formBuilder.group({
      ringTime: [''],
      cidprefix :[''],
      callForwardTime: [''],
      outboundLimit: [''],
      phonenumberlist: [],
      optionalDestination1:[''],
      optionalDestination2:[''],
      notReachable: [''],
      notReachable2: [''],
      busyField: [''],
      busyField2: [''],
    })

    this.generalFormGroup.reset();
    this.extensionoptionsFormGroup.reset();

    this.voicemailFormGroup.controls['voicemailpassword'].disable()
    this.voicemailFormGroup.controls['emailaddress'].disable()
  }

  get f() { return this.generalFormGroup.controls; }

  form1(){
    console.log(this.generalFormGroup.value);
  }
  form2(){
    console.log(this.voicemailFormGroup.value);
  }
  form3(){
    console.log(this.findfollowmeFormGroup.value);
  }
  form4(){
    console.log(this.extensionoptionsFormGroup.value);
  }
  update(value: string) {
    this.recording = value;
  }
  updateDestination(value: string) {
    this.destLocation = true;
  }
  updateNotReachable(value: string) {
    this.notReachable = true;
  }
  updateBusy(value: string) {
    this.busyDestination = true;
  }
  resetDestinations() {
    this.extensionoptionsFormGroup.reset();
    this.destLocation = false;
    this.notReachable = false;
    this.busyDestination = false;
    
  }
  open() {
    this.data = JSON.parse(JSON.stringify(this.data2));
    this.ringStrategy = JSON.parse(JSON.stringify(this.data3));
    this.followMe = JSON.parse(JSON.stringify(this.data4));
    this.announcement = JSON.parse(JSON.stringify(this.data5));
    this.moh = JSON.parse(JSON.stringify(this.data6));
    this.ringerVolume = JSON.parse(JSON.stringify(this.data7));
    this.mode = JSON.parse(JSON.stringify(this.data8));
    this.normalExt = JSON.parse(JSON.stringify(this.data9));
    this.outboundLimit = JSON.parse(JSON.stringify(this.data10));
    this.screening = JSON.parse(JSON.stringify(this.data11));
    this.queueState = JSON.parse(JSON.stringify(this.data12));
    this.optDestination = JSON.parse(JSON.stringify(this.data13));
    this.emergencyCid = JSON.parse(JSON.stringify(this.data14));
    this.optDest2 = JSON.parse(JSON.stringify(this.data15));
  }

  search(text: string) {
    this.data = text
      ? (JSON.parse(JSON.stringify(this.data2)) as Select2Option[])
        .filter(option => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1)
      : JSON.parse(JSON.stringify(this.data2));
    this.ringStrategy = text
      ? (JSON.parse(JSON.stringify(this.data3)) as Select2Option[])
        .filter(option => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1)
      : JSON.parse(JSON.stringify(this.data3));
    this.followMe = text
      ? (JSON.parse(JSON.stringify(this.data4)) as Select2Option[])
        .filter(option => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1)
      : JSON.parse(JSON.stringify(this.data4));
    this.announcement = text
      ? (JSON.parse(JSON.stringify(this.data5)) as Select2Option[])
        .filter(option => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1)
      : JSON.parse(JSON.stringify(this.data5));
    this.moh = text
      ? (JSON.parse(JSON.stringify(this.data6)) as Select2Option[])
        .filter(option => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1)
      : JSON.parse(JSON.stringify(this.data6));
    this.ringerVolume = text
      ? (JSON.parse(JSON.stringify(this.data7)) as Select2Option[])
        .filter(option => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1)
      : JSON.parse(JSON.stringify(this.data7));
    this.mode = text
      ? (JSON.parse(JSON.stringify(this.data8)) as Select2Option[])
        .filter(option => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1)
      : JSON.parse(JSON.stringify(this.data8));
    this.normalExt = text
      ? (JSON.parse(JSON.stringify(this.data9)) as Select2Option[])
        .filter(option => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1)
      : JSON.parse(JSON.stringify(this.data9));
    this.outboundLimit = text
      ? (JSON.parse(JSON.stringify(this.data10)) as Select2Option[])
        .filter(option => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1)
      : JSON.parse(JSON.stringify(this.data10));
    this.screening = text
      ? (JSON.parse(JSON.stringify(this.data11)) as Select2Option[])
        .filter(option => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1)
      : JSON.parse(JSON.stringify(this.data11));
    this.queueState = text
      ? (JSON.parse(JSON.stringify(this.data12)) as Select2Option[])
        .filter(option => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1)
      : JSON.parse(JSON.stringify(this.data12));
    this.optDestination = text
      ? (JSON.parse(JSON.stringify(this.data13)) as Select2Option[])
        .filter(option => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1)
      : JSON.parse(JSON.stringify(this.data13));
    this.emergencyCid = text
      ? (JSON.parse(JSON.stringify(this.data14)) as Select2Option[])
        .filter(option => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1)
      : JSON.parse(JSON.stringify(this.data14));
    this.optDest2 = text
      ? (JSON.parse(JSON.stringify(this.data15)) as Select2Option[])
        .filter(option => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1)
      : JSON.parse(JSON.stringify(this.data15));
  }

  changeTemplate(template){
    if(this.selectedPeople.includes(template)){
      toastr.options.positionClass = "toast-bottom-right";
      toastr.error("Phone number is already present in list")
      
    }
    else {
    this.selectedPeople.push(template)
    this.selectedPeople = this.getUnique(this.selectedPeople)
    this.extensionoptionsFormGroup.patchValue({
      phonenumberlist : this.selectedPeople
    })

  }
  
   
  }
  edit(event?){
    console.log(event)
    this.selectedPeople = event
  }

  getUnique(array){
    var uniqueArray = [];
    
    // Loop through array values
    for(var i=0; i < array.length; i++){
        if(uniqueArray.indexOf(array[i]) === -1) {
            uniqueArray.push(array[i]);
        }
    }
    return uniqueArray;
}
handleChange(field,event){
  if(field=='password' && event== true) {
    this.voicemailFormGroup.controls['voicemailpassword'].enable()
  } 
  else{
    this.voicemailFormGroup.controls['voicemailpassword'].disable()
  }
  if(field == 'email' && event== true){
    this.voicemailFormGroup.controls['emailaddress'].enable()
  }
  else{
    this.voicemailFormGroup.controls['emailaddress'].disable()
  }
}
}
