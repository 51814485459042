import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatSort} from '@angular/material';

export interface CallQueues {
  name: String;
  phone: String;
  order: String;
  enabled: String;
  description: String;
}

const ELEMENT_DATA: CallQueues[] = [
  {name:'Test Client Name',phone:'(713) 955-3757',order:'10',enabled:'True', description:'This is demo Inbound Routes description here'}
];

@Component({
  selector: 'app-user-inbound-routes',
  templateUrl: './user-inbound-routes.component.html',
  styleUrls: ['./user-inbound-routes.component.css', '../user-dashboard.component.scss']
})
export class UserInboundRoutesComponent implements OnInit {
  displayedColumns: string[] = ['name','phone','order','enabled','description','action'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  constructor() { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

}
