import { Action } from "@ngrx/store";
import * as proModel from '../../models'
import { HttpErrorResponse } from "@angular/common/http";
import { type } from "os";
import { Clients, DbResponse } from "../../models";

export enum ClientsActionType {
    CLIENTS_LOAD = '[Clients] Clients Load',
    CLIENTS_LOAD_SUCCESS = '[Clients] Clients Load Success',
    CLIENTS_LOAD_FAIL = '[Clients] Clients Load Fail',
    
    CLIENT_DELETE = '[Clients] Clients DELETE',
    CLIENT_DELETE_SUCCESS = '[Clients] Clients DELETE Success',
    CLIENT_DELETE_FAIL = '[Clients] Clients DELETE Fail',
   
    CLIENT_ADD = '[Clients] Clients ADD',
    CLIENT_ADD_SUCCESS = '[Clients] Clients ADD Success',
    CLIENT_ADD_FAIL = '[Clients] Clients ADD Fail',

}

export class ClientsLoad implements Action {
    readonly type = ClientsActionType.CLIENTS_LOAD;
}

export class ClientsLoadSuccess implements Action {
    readonly type = ClientsActionType.CLIENTS_LOAD_SUCCESS;
    constructor(public payload: proModel.Clients[]) {}
}

export class ClientsLoadFail implements Action {
    readonly type = ClientsActionType.CLIENTS_LOAD_FAIL;
    constructor(public payload: HttpErrorResponse) {}
}

export class CLientDelete implements Action {
    readonly type = ClientsActionType.CLIENT_DELETE;
    constructor(public payload: {_id: string, _rev:string}) {}
}

export class CLientDeleteSuccess implements Action {
    readonly type = ClientsActionType.CLIENT_DELETE_SUCCESS;
    constructor(public payload: any) {}
}

export class CLientDeleteFail implements Action {
    readonly type = ClientsActionType.CLIENT_DELETE_FAIL;
    constructor(public payload: HttpErrorResponse) {}
}

export class CLientAdd implements Action {
    readonly type = ClientsActionType.CLIENT_ADD;
    constructor(public payload: {data: Clients}) {}
}

export class CLientAddSuccess implements Action {
    readonly type = ClientsActionType.CLIENT_ADD_SUCCESS;
    constructor(public payload: DbResponse) {}
}

export class CLientAddFail implements Action {
    readonly type = ClientsActionType.CLIENT_ADD_FAIL;
    constructor(public payload: HttpErrorResponse) {}
}

export type ClientsAction = 
| ClientsLoad
| ClientsLoadSuccess
| ClientsLoadFail
| CLientDelete
| CLientDeleteSuccess
| CLientDeleteFail
| CLientAdd
| CLientAddSuccess
| CLientAddFail
;

