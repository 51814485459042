import { NgModule} from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import {TranslateModule} from '@ngx-translate/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule, MatInputModule, MatButtonModule, MatAutocompleteModule, MatIconModule,MatTabsModule,MatTableModule, MatFormFieldModule, MatSelectModule, MatSlideToggleModule, MatCardModule, MatPaginatorModule,MatSortModule,MatCheckboxModule } from '@angular/material';
import { PagingparkingComponent } from "./pagingparking.component";
import { PagingparkinglistComponent } from './pagingparkinglist/pagingparkinglist.component';
import { NewpagegroupComponent } from './newpagegroup/newpagegroup.component';
import { NewparkinglotComponent } from './newparkinglot/newparkinglot.component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { Select2Module } from "ng-select2-component";
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonComponentModule } from "../../../common/commonComponent.module";

@NgModule({
    declarations: [
        PagingparkingComponent,
        PagingparkinglistComponent,
        NewpagegroupComponent,
        NewparkinglotComponent
],
    imports: [
    BrowserModule,
        TranslateModule.forRoot(),
        FormsModule,
        MatStepperModule,
        MatInputModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatIconModule,
        MatTabsModule,
        MatTableModule,
        MatFormFieldModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatCardModule,
        MatPaginatorModule,
        MatSortModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        UiSwitchModule,
        Select2Module,
        NgSelectModule,
        CommonComponentModule
    ],
    providers: [],
    bootstrap: [PagingparkingComponent],
    exports:[],
})

export class PagingparkingModule { }