import { CompanySettings } from "../../models";
import { CompanySettingAction, CompanySettingActionType } from "../actions";

export interface CompanySettingState {
  loading: boolean;
  loaded: boolean;
  data: CompanySettings[] | null;
}

export const companySettingInitialState: CompanySettingState = {
  loading: false,
  loaded: false,
  data: null
};

export function companySettingReducer(
  state = companySettingInitialState,
  action: CompanySettingAction
): CompanySettingState {
  switch (action.type) {
    case CompanySettingActionType.COMPANY_SETTING_LOAD: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case CompanySettingActionType.COMPANY_SETTING_LOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload
      };
    }

    case CompanySettingActionType.COMPANY_SETTING_LOAD_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null
      };
    }
  }
  return state;
}
