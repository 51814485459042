import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl  } from '@angular/forms';
import { Select2Option, Select2Data } from 'ng-select2-component';
import * as toastr from "toastr";


@Component({
  selector: 'app-addtimecondition',
  templateUrl: './addtimecondition.component.html',
  styleUrls: ['./addtimecondition.component.scss']
})
export class AddtimeconditionComponent implements OnInit {

  timeConditionForm: FormGroup;
  submitted = false;

  changeOverrideData = [
    {id:1, name:'Unchanged'},
    {id:2, name:'Reset Override'},
    {id:3, name:'Temporary Matched'},
    {id:4, name:'Temporary Unmatched'},
    {id:5, name:'Permanent Matched'},
    {id:6, name:'Permanent Unmatched'},
  ];

  destinationData = [
    {id:1, name:'Default'},
    {id:2, name:'Destination 1'},
    {id:3, name:'Destination 2'},
    {id:4, name:'Destination 3'},
  ]

  timeZoneData = [
    {id:1, name:'System Timezone'},
    {id:2, name:'TimeZone 1'},
    {id:3, name:'TimeZone 2'},
    {id:4, name:'TimeZone 3'},
    {id:5, name:'TimeZone 4'},
    {id:6, name:'TimeZone 5'},
  ];

  timeGroupData = [
    {id:1, name:'None'},
    {id:2, name:'Group 1'},
    {id:3, name:'Group 2'},
    {id:4, name:'Group 3'},
    {id:5, name:'Group 4'},
    {id:6, name:'Group 5'},
  ];

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.timeConditionForm = this.formBuilder.group({
      conditionName: ['', [Validators.required, Validators.maxLength(30)]],
      // overRidePin: ['', [Validators.required, Validators.max(3000)]],
      // changeOverride: ['', Validators.required],
      // timeZone: ['', Validators.required],
      // timeGroup: ['', Validators.required],
      // destination1: ['', Validators.required],
      // destination2: ['', Validators.required],
      // destination3: ['', Validators.required],
      // destination4: ['', Validators.required],
    })
  }

  get f() { return this.timeConditionForm.controls; }

  onSubmit() {
      this.submitted = true;

      if (this.timeConditionForm.invalid) {
        toastr.error("Invalid Details !");
        return;
      }
      console.log(this.timeConditionForm.value);

      toastr.success("Form Submitted Successfully !")
  }

}
