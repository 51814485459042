import { Component, OnInit, Inject, Optional } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatTableDataSource,
  MatPaginator
} from "@angular/material";
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { ClientService } from "../../../services";

@Component({
  selector: "add-group",
  templateUrl: "./add-group.component.html",
  styleUrls: ["./add-group.component.scss"]
})
export class AddGroupComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<AddGroupComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data,
    private _formBuilder: FormBuilder,
    private _toastr: ToastrService,
    private clientService: ClientService

  ) {}

  addGroupForm: FormGroup;

  ngOnInit() {
    this.addGroupForm = this._formBuilder.group({
      name: ["", Validators.required]
    });
  }

  addGroup() {
    if (this.addGroupForm.valid) {
      this.clientService.add_group(this.data.client_id, this.addGroupForm.value.name).subscribe((data: any) => {
        if(data.ok){
          this._toastr.success("Group is added", "", {
            positionClass: "toast-top-center"
          });
          this.dialogRef.close();
        }else{
          this._toastr.error("Opps! Try again", "", {
            positionClass: "toast-top-center"
          });
        }
      })
    } else {
      this._toastr.error("Group name is required", "", {
        positionClass: "toast-top-center"
      });
    }
  }
}
