import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from '../admin.component';
import { ConnectivityComponent } from './connectivity.component';
import { OutboundRoutesComponent } from './outbound-routes/outbound-routes.component';
import { TrunkSettingComponent } from './trunk-setting/trunk-setting.component';


const routes: Routes = [];

@NgModule({
  imports: [RouterModule.forChild([
    {
        path: 'admin',
        component: AdminComponent,
        data: {
          title: 'Connectivity',
          status: false
        },
   
        children: [
        {
            path: 'connectivity',
            component:TrunkSettingComponent,
            children: [
              {
                path: 'trunk_settings',
                component: TrunkSettingComponent
              }
            ]
        }
      ]
    }
  ]),
],
  exports: [RouterModule]
})
export class connectivityRoutingModule { }