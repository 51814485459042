import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {API, StorageKey} from './constant';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {RequestOptions} from "@angular/http";
import { catchError, map } from 'rxjs/operators';


export interface IBugDetails {
  bugId: number;
  moduleId: number;
  type: string;
}

@Injectable({
  providedIn: 'root'
})
export class BugsService {

  public updateBugEventListener = new BehaviorSubject<IBugDetails>({
    bugId: 0,
    moduleId: 0,
    type: ""
  });

  constructor(private http: HttpClient) { }

    createBug(data): Observable<any>{
      return this.http.post(API.ADD_BUG, data);
  }


    updateBug(bugId, data) {
      return this.http.post(API.UPDATE_BUG + bugId, data);
    }

  bugDetails(data) {
      return this.http.get(API.BUG_DETAILS + data.bugId + '?moduleId=' + data.moduleId + '&type=' + data.type, data);
    }

  getBugList(filter, moduleName, moduleId){
    filter = JSON.stringify(filter);
    let params = new HttpParams();
    if (moduleName) {
      if (moduleName === "client") {
        params = params.append("moduleName", moduleName);
        params = params.append("moduleId", moduleId);
      }
    }
    params = params.append("filter", filter);
    return this.http.get(API.BUG_LIST, {params : params});
  }

  updateBugStatus(bugId,data){
    return this.http.post(API.UPDATE_BUG_STATUS + bugId, data)
  }

  deleteBug(bugId,data){
    return this.http.post(API.DELETE_BUG + bugId ,data)

  }

  bugFileAttchments(data){
    return this.http.get(API.ATTACHMENTS_LIST + '?moduleId=' + data.moduleId + '&type=' + data.type, data );

  }

  addAttachments(attachmentsData){
    return this.http.post(API.ADD_BUG_ATTACHMENTS, attachmentsData);
  }

  deleteAttachments(attachmentId){
    return this.http.post(API.DELETE_BUG_ATTACHMENTS + attachmentId,attachmentId);
  }



}
