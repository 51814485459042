import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-addivr',
  templateUrl: './addivr.component.html',
  styleUrls: ['./addivr.component.css']
})
export class AddivrComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
