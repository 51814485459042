import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Select2Module } from "ng-select2-component";
import { MatTabsModule, MatSelectModule, MatButtonModule, MatTableModule,MatInputModule, MatFormFieldModule, MatPaginatorModule, MatCardModule} from "@angular/material";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { OutboundRoutesComponent } from './outbound-routes/outbound-routes.component';
import { InboundRoutesComponent } from './inbound-routes/inbound-routes.component';
import {ClientConnectivityRoutingModule} from './client-connectivity-routing.module';
import { InboundRoutesListComponent } from './inbound-routes/inbound-routes-list/inbound-routes-list.component';
import { AddRouteComponent } from './inbound-routes/add-route/add-route.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OutboundRoutesListComponent } from './outbound-routes/outbound-routes-list/outbound-routes-list.component';
import { AddOutboundRouteComponent } from './outbound-routes/add-outbound-route/add-outbound-route.component';
import { connectivityModule } from '../../admin/connectivity/connectivity.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonComponentModule } from "../../common/commonComponent.module";

@NgModule({
    imports: [
      CommonModule,
      ClientConnectivityRoutingModule,
      MatTableModule,
      MatTabsModule,
      MatButtonModule,
      MatInputModule,
      MatFormFieldModule,
      MatPaginatorModule,
      MatCardModule,
      HttpClientModule,
      BrowserAnimationsModule,
      MatSelectModule,
      connectivityModule,
      FormsModule,
      ReactiveFormsModule,
      Select2Module,
      NgSelectModule,
      CommonComponentModule
      ],
    declarations: [
      OutboundRoutesComponent,
      InboundRoutesComponent,
      InboundRoutesListComponent,
      AddRouteComponent,
      OutboundRoutesListComponent,
      AddOutboundRouteComponent,
    ]
  })

  export class ClientConnectivityModule { }