import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator, MatTableDataSource} from '@angular/material';


export interface Systemreordings {
  name : string;
  description : string;
  supported_language : string;
}

const ELEMENT_DATA: Systemreordings[] = [
  {name :  'Default', description : 'File',supported_language:'' },
  {name : 'Nstar', description : 'File', supported_language : '' }
  
];
@Component({
  selector: 'app-systemrecordinglist',
  templateUrl: './systemrecordinglist.component.html',
  styleUrls: ['./systemrecordinglist.component.scss']
})
export class SystemrecordinglistComponent implements OnInit {

  displayedColumns: string[] = [ 'display_name','description','supported_language','action'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  constructor() { }

  ngOnInit() {
  }

}
