import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { BreadcrumbService } from "./services/breadcrumb.service";
import { MatDialog } from "@angular/material";
import Swal from "sweetalert2";
import { ClientService, readCookie } from "./services";
import { Subscription } from "rxjs";
import { AddCompanyService } from "../../service/add-company.service";


@Component({
  selector: "app-provisioner",
  templateUrl: "./provisioner.component.html",
  styleUrls: ["./provisioner.component.css"],
})
export class ProvisionerComponent implements OnInit {
  breadCrumb: any = [];
  subscript: Subscription;
  selectTabindex: number = 0;

  authenticate: boolean;


  constructor(
    public addCompanyService: AddCompanyService,
    private route: Router,
    private dialog: MatDialog,
    private service: ClientService,
    private breadcrumb: BreadcrumbService
  ) {
    this.route.events
      .filter((e) => e instanceof NavigationEnd)
      .subscribe(() => {
        this.authenticate = readCookie("authenticate") ? true : false;
      });
    this.subscript = this.addCompanyService.messageSource.subscribe((val) => {
      if (val != 0) {
        this.selectTabindex = val;
      } else {
        this.selectTabindex = 0;
      }
    });
  }

  ngOnInit() {
    if (!readCookie("authenticate")) {
      this.route.navigate(["/login"], {
        queryParams: { return: location.href },
      });
      this.authenticate = false;
      return false;
    }
    this.authenticate = true;
  }

  editPath() {
    this.service.getConfigPath().subscribe((data: any) => {
      Swal.fire({
        title: "Edit config path",
        input: "text",
        inputValue: data.config_path,
        showCancelButton: true,
        cancelButtonColor: "#EC4849",
        reverseButtons: true,
        confirmButtonColor: "#45CE30",
        confirmButtonText: "SAVE",
        cancelButtonText: "CANCEL",
        allowOutsideClick: false,
        focusCancel: true,
      }).then((result: any) => {
        if (result.value) {
          this.service.editConfigPath(result.value).subscribe((data: any) => {
            console.log(data);
            if (data) {
              if (data.ok) {
                Swal.fire("Edited!", "Config path has been edited.", "success");
              } else {
                Swal.fire("Oopsss...", "Something went worng", "error");
              }
            }
          });
        }
      });
    });
  }

  ngOnDestroy() {
    this.subscript.unsubscribe();
    this.addCompanyService.messageSource.next(0);
  }
}
