import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {API} from "./constant";

@Injectable({
  providedIn: 'root'
})
export class AgreementService {

  constructor(private http: HttpClient) { }

  agreementEmailTemplate() {
    return this.http.get(API.CLIENT_AGREEMENT_EMAIL_TEMPLATE);
  }
  clientNewAgreement(action, clientId, id){
    if (action && id) {
      return this.http.get(API.CLIENT_NEW_AGREEMENT + '/' + clientId + '?action=' + action + '&id=' + id);
    } else if (id) {
      return this.http.get(API.CLIENT_NEW_AGREEMENT + '/' + clientId + '?id=' + id);
    } else if (action) {
      return this.http.get(API.CLIENT_NEW_AGREEMENT + '/' + clientId + '?action=' + action);
    }
  }
  deleteEmailTemplate(id) {
    return this.http.delete(API.EMAIL_TEMPLATE_DELETE + '/' + id);
  }

  addEmailTemplate(data) {
    return this.http.post(API.EMAIL_TEMPLATE_ADD , data);
  }
}
