import { Component, OnInit, Inject, Optional } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ClientService } from "../../services";
import Swal from "sweetalert2";
import { Store } from "@ngrx/store";
import { State } from "../../../../reducers";
import { Observable } from "rxjs";
import { Device } from "../../models";
import { DeviceLoad, selectAllDevices } from "../../store";

@Component({
  selector: "add-company-setting",
  templateUrl: "./add-company-setting.component.html",
  styleUrls: ["./add-company-setting.component.scss"]
})
export class AddCompanySettingComponent implements OnInit {
  devices$: Observable<Device[]>;
  addCompanySettingForm: FormGroup;
  deviceModels:Array<any> = [];
  constructor(
    private dialogRef: MatDialogRef<AddCompanySettingComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data,
    private _formBuilder: FormBuilder,
    private service: ClientService,
    private store: Store<State>
  ) {}

  ngOnInit() {
    this.addCompanySettingForm = this._formBuilder.group({
      name: ["", Validators.required],
      device_model: [null, Validators.required]
    });

    // this.service.devices().subscribe((data: any) => {
    //   this.devices = data;
    // });
    this.store.dispatch(new DeviceLoad());
    this.store.select(selectAllDevices).subscribe((res:any)=>{
      if(res){
        var models = []
        for(var i in res){
          models.push({
            id: res[i]._id,
            name: `${res[i].device_manuf} ${res[i].device_model}`
          })
        }
        this.deviceModels = [...models]
      }
    })

  }

  addCompanySetting() {
    if (this.addCompanySettingForm.valid) {
      this.service
        .addComapnySetting(
          this.addCompanySettingForm.value.name,
          this.addCompanySettingForm.value.device_model
        )
        .subscribe((data: any) => {
          if (data) {
            if (data.ok) {
              this.dialogRef.close({
                added: true
              });
            } else {
              Swal.fire("Oopsss...", "Something went worng", "error");
            }
          }
        });
    }
  }
}
