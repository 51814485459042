import { Component, OnInit, Optional, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ClientService } from "../../services";
import Swal from "sweetalert2";

@Component({
  selector: "add-device-model",
  templateUrl: "./add-device-model.component.html",
  styleUrls: ["./add-device-model.component.scss"]
})
// @ts-ignore
export class AddDeviceModelComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<AddDeviceModelComponent>,
    // @ts-ignore 
    @Optional() @Inject(MAT_DIALOG_DATA) private data,
    private _formBuilder: FormBuilder,
    private service: ClientService
  ) {}

  addDeviceModelForm: FormGroup;
  file: File;
  settings: any = [];
  errors = [];

  ngOnInit() {
    this.addDeviceModelForm = this._formBuilder.group({
      device_manuf: [null, Validators.required],
      device_model: [null, Validators.required]
    });
  }

  convertFile(event: any) {
    this.file = event.target.files[0];
    var Pvalues = [];
    const reader = new FileReader();
    reader.readAsText(this.file);
    reader.onload = () => {
      let csv: string = reader.result.toString();
      var jsonData = this.csvJSON(csv);
      delete jsonData[0];
      var categories = [];
      var margedSettings = {};
      for (var i in jsonData) {
        var item = jsonData[i];

        if (!categories.includes(item[0])) {
          categories.push(item[0]);
          margedSettings[item[0]] = [item];
        } else {
          margedSettings[item[0]].push(item);
        }
      }
      for (var name in margedSettings) {
        var tempSetting = {};
        tempSetting["name"] = name;
        tempSetting["values"] = [];
        for (var val in margedSettings[name]) {
          if(!Pvalues.includes(margedSettings[name][val][3]))
          {
            Pvalues.push(margedSettings[name][val][3]);
            var temp = {};
            temp["setting_name"] = margedSettings[name][val][1];
            temp["setting_description"] = margedSettings[name][val][2];
            temp["setting_variable_name"] = margedSettings[name][val][3];
            temp["setting_variable_type"] = margedSettings[name][val][4];
            if (margedSettings[name][val].length > 5) {
              temp["setting_options"] = [];
              for (
                var count = 5;
                count < margedSettings[name][val].length;
                count++
              ) {
                if (
                  margedSettings[name][val][count] &&
                  margedSettings[name][val][count + 1] &&
                  margedSettings[name][val][count] !== "\\N" &&
                  margedSettings[name][val][count + 1] !== "\\N"
                ) {
                  temp["setting_options"].push({
                    value: margedSettings[name][val][count],
                    name: margedSettings[name][val][++count]
                  });
                }
              }
              if (temp["setting_options"].length == 0) {
                delete temp["setting_options"];
              }
            }
            tempSetting["values"].push(temp);
          } else {
            if(!this.errors.includes(`${margedSettings[name][val][3]} already exists`)){
              this.errors.push(`${margedSettings[name][val][3]} already exists`)
            }
            
          }
        }
        this.settings.push(tempSetting);
      }
    };
  }

  csvJSON(csv) {
    let allTextLines = csv.split(/\r|\n|\r/);
    let headers = allTextLines[0].split(",");
    console.log(headers)
    let lines = [];

    for (let i = 0; i < allTextLines.length; i++) {
      // split content based on comma
      let data = allTextLines[i].split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
      if (data.length === headers.length) {
        let tarr = [];
        for (let j = 0; j < headers.length; j++) {
          tarr.push(data[j].replace(/(^")|("$)/g, ''));
        }
        lines.push(tarr);
      }
    }
    // all rows in the csv file
    // console.log(lines);
    return lines;
  }

  addDeviceModel() {
    console.log(this.settings)
    if (this.addDeviceModelForm.valid && this.settings.length > 0) {
      var data = {
        device_manuf: this.addDeviceModelForm.value.device_manuf,
        device_model: this.addDeviceModelForm.value.device_model,
        defaults: this.settings
      };
      this.service.addDeviceModel(data).subscribe((data:any)=>{
        if(data){
          if(data.ok){
            this.dialogRef.close({
              added: true
            })
          }else{
            Swal.fire("Oopsss...", "Something went worng", "error");
          }
        }
      })
    }
  }
}
