import { Component, OnInit } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatSort} from '@angular/material';

export interface CallQueues {
  direction: String;
  to: String;
  from: String;
  sourceDevice: String;
  destinationDevice: String;
  sourceUserId: string;
  destinationUserId: string;
  customTag: string;
}

const ELEMENT_DATA: CallQueues[] = [
  {direction:'Inbound', to:'400',from:'14698792040',sourceDevice:'',destinationDevice:'Mobile Device',sourceUserId:'',destinationUserId:'Cknezha536',customTag:''},
  {direction:'Inbound', to:'400',from:'14698792040',sourceDevice:'',destinationDevice:'Mobile Device',sourceUserId:'',destinationUserId:'Cknezha536',customTag:''},
  {direction:'Inbound', to:'400',from:'Restricted',sourceDevice:'',destinationDevice:'Mobile Device',sourceUserId:'',destinationUserId:'Cknezha536',customTag:''},
  {direction:'Inbound', to:'400',from:'14698792040',sourceDevice:'',destinationDevice:'Mobile Device',sourceUserId:'',destinationUserId:'Cknezha536',customTag:''},
  {direction:'Inbound', to:'402',from:'14698792040',sourceDevice:'',destinationDevice:'Mobile Device',sourceUserId:'Cknezha536',destinationUserId:'NInman937',customTag:''},
  {direction:'Inbound', to:'400',from:'Restricted',sourceDevice:'',destinationDevice:'Mobile Device',sourceUserId:'',destinationUserId:'Cknezha536',customTag:''},
  {direction:'Inbound', to:'402',from:'14698792040',sourceDevice:'',destinationDevice:'Mobile Device',sourceUserId:'Cknezha536',destinationUserId:'NInman937',customTag:''},
  {direction:'Inbound', to:'400',from:'Restricted',sourceDevice:'',destinationDevice:'Mobile Device',sourceUserId:'',destinationUserId:'Cknezha536',customTag:''},
];

@Component({
  selector: 'app-reports-list',
  templateUrl: './reports-list.component.html',
  styleUrls: ['./reports-list.component.scss']
})
export class ReportsListComponent implements OnInit {
  displayedColumns: string[] = ['action','direction','to','from','sourceDevice','destinationDevice','sourceUserId','destinationUserId','customTag'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  constructor() { }

  ngOnInit() {
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
