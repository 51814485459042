import { Component, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuService } from '../../../../service/menu.service';
import {IResponse, NavItem} from '../../../../service/Utils/Interfaces.class';

export interface PeriodicElement {
  icon: string;
  label : string;
  link  :string;
  items : items[];
  menu_id :number;
  parent : number
}
export interface items {
  icon: string;
  label : string;
  link  :string;
  items : [];
  menu_id :number;
  parent : number
}
const APPLICATION_DATA: items[]=[
  {icon: "fa fa-angle-right" , label: "extension & Users" ,link: "/client/applications/extensionandusers",items:[] ,menu_id: 3,parent: 2},
  {icon: "fa fa-angle-right" , label: "ring groups" ,link: "/client/applications/ringgroups",items:[] ,menu_id: 4,parent: 2},
  {icon: "fa fa-angle-right" , label: "ivr" ,link: "/client/applications/ivr",items:[] ,menu_id:5,parent: 2},
  {icon: "fa fa-angle-right" , label: "parking" ,link: "/client/applications/pagingparking",items:[] ,menu_id: 6,parent: 2},
  // {icon: "fa fa-angle-right" , label: "call queues" ,link: "/client/applications/callqueues",items:[] ,menu_id: 7,parent: 2},
  {icon: "fa fa-angle-right" , label: "time groups" ,link: "/client/applications/timegroups",items:[] ,menu_id: 8,parent: 2},
  {icon: "fa fa-angle-right" , label: "time condition" ,link: "/client/applications/timeconditions",items:[] ,menu_id:9 ,parent: 2},
  {icon: "fa fa-angle-right" , label: "misc destination" ,link: "/client/applications/miscdestination",items:[] ,menu_id: 10,parent: 2},
  {icon: "fa fa-angle-right" , label: "night mode cotrol" ,link: "/client/applications/nightmodecontrol",items:[] ,menu_id: 11,parent: 2},
  {icon: "fa fa-angle-right" , label: "announcement" ,link: "/client/applications/allannouncements",items:[] ,menu_id: 12,parent: 2},
  // {icon: "fa fa-angle-right" , label: "call flow overview" ,link: "callflowoverview",items:[] ,menu_id: 13,parent: 2},
  // {icon: "fa fa-angle-right" , label: "conference bridge" ,link: "/client/applications/conferencebridges",items:[] ,menu_id: 14,parent: 2},
  {icon: "fa fa-angle-right" , label: "music on hold" ,link: "/client/applications/musiconhold",items:[] ,menu_id: 15,parent: 2},
  {icon: "fa fa-angle-right" , label: "system recordings" ,link: "/client/applications/systemrecordings",items:[] ,menu_id: 31,parent: 2},]

const CONNECTIVITY : items[] =[
  {icon: "fa fa-angle-right" , label: "inbound routes" ,link: "/client/connectivity/inbound-routes",items:[] ,menu_id: 17,parent: 16},
  {icon: "fa fa-angle-right" , label: "outbound routes" ,link: "/client/connectivity/outbound-routes",items:[] ,menu_id: 18,parent: 16}
]
const REPORTS : items[] =[
  {icon: "fa fa-angle-right" , label: "client report" ,link: "/client/reports/clientreport",items:[] ,menu_id: 20,parent: 19},
  {icon: "fa fa-angle-right" , label: "call details record" ,link: "/client/reports/calldetails",items:[] ,menu_id: 21,parent: 19},
  // {icon: "fa fa-angle-right" , label: "voicemail management" ,link: "/client/reports/voicemail",items:[] ,menu_id: 22,parent: 19},
  {icon: "fa fa-angle-right" , label: "extension settings" ,link: "/client/reports/extensioninfoandsetting",items:[] ,menu_id: 23,parent: 19},
  // {icon: "fa fa-angle-right" , label: "conference" ,link: "/client/reports/conference",items:[] ,menu_id: 24,parent: 19},
  // {icon: "fa fa-angle-right" , label: "queue reports" ,link: "/client/reports/queuereport",items:[] ,menu_id: 25,parent: 19},
  // {icon: "fa fa-angle-right" , label: "live call monitoring" ,link: "/client/reports/livecallmonitoring",items:[] ,menu_id: 26,parent: 19},
]
const SETTINGS : items[]=[
  {icon: "fa fa-angle-right" , label: "Permissions & Users" ,link: "/client/settings/permissions-users",items:[] ,menu_id: 28,parent: 27},
  // {icon: "fa fa-angle-right" , label: "Intrusion Detections" ,link: "/client/settings/intrusion-detection",items:[] ,menu_id: 29,parent: 27},
  {icon: "fa fa-angle-right" , label: "Provisioner" ,link: "/client/settings/provisioner",items:[] ,menu_id: 30,parent: 27}

]
const ELEMENT_DATA: PeriodicElement[] = [
  {icon: "fa fa-tachometer-alt" , label: "dashboard" ,link: "/client/dashboard",items:[] ,menu_id: 1,parent: 0},
  {icon: "fa fa-pencil-square-o" , label: "applications" ,link:"applications", items:APPLICATION_DATA ,menu_id: 2,parent: 0},
  {icon: "fa fa fa-link" , label: "connectivity" ,link:"connectivity", items:CONNECTIVITY ,menu_id: 16,parent: 0},
  {icon: "fa fa-bar-chart" , label: "reports" ,link:"reports", items:REPORTS ,menu_id: 19,parent: 0},
  {icon: "fa fa-cog" , label: "settings" ,link:"settings", items:SETTINGS ,menu_id: 27,parent: 0},
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: []
})
export class AppSidebarComponent implements OnDestroy {
  myData: any;
  displayList = false;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  navItems: NavItem[];
  config = {
    paddingAtStart: true,
    listBackgroundColor: `#3A3F51`,
    backgroundColor: `#445137`,
    interfaceWithRoute: true,
    highlightOnSelect: true,
    selectedListFontColor: `#ffffff`,
    fontColor: `rgb(8, 54, 71)`,
    collapseOnSelect: true,
    classname: 'my-custom-class',
    rtlLayout: false
  };

  constructor(
    private menuService: MenuService,
    private router: Router,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
  ) 
  {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    var setupDone = localStorage.getItem('setup');
    if (setupDone == "1") {
      this.navItems = ELEMENT_DATA
    }  
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  selectedItem($event) {
    // console.log($event);
  }

  selectedLabel($event) {
    // console.log($event);
  }

  redirect(link) {
    this.router.navigate([link]);
    setTimeout(() => {
      this.displayList = true;
    }, 100);
  }
}
