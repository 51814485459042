import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AdminRoutingModule } from "./admin-routing.module";
import { AdminComponent } from "./admin.component";
import { BrowserModule } from "@angular/platform-browser";
import { MaterialModule } from "../material-module";
import { FullComponent } from "./layouts/full/full.component";
import { AppSidebarComponent } from "./layouts/full/sidebar/sidebar.component";
import { AppHeaderComponent } from "./layouts/full/header/header.component";
import { NgMaterialMultilevelMenuModule } from "ng-material-multilevel-menu";
import { FooterComponent } from "./layouts/full/footer/footer.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MenuListItemComponent } from "./layouts/full/menu-list-item/menu-list-item.component";
import { SharedModule } from "./shared/shared.module";
import {DashboardRoutingModule} from "./dashboard/dashboard-routing.module";
import { SettingsModule } from "./settings/settings.module";
import { ClientModule } from "./client/client.module";
import {connectivityModule} from "./connectivity/connectivity.module";
import { applicationsModule } from "./applications/applications.module";
import { ivrModule } from "./applications/ivr/ivr.module";
import { ReportsModule} from './reports/reports.module';
import { BillingModule } from './billing/billing.module';
import { ProvisionerModule } from './provisioner/provisioner.module' 
import { DashboardModule } from './dashboard/dashboard.module';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
@NgModule({
  imports: [
  CommonModule,
    BrowserModule,
    AdminRoutingModule,
    MaterialModule,
    NgMaterialMultilevelMenuModule,
    FlexLayoutModule,
    DashboardRoutingModule,
    SettingsModule,
    FormsModule,
    ReactiveFormsModule,
    ClientModule,
    connectivityModule,
    applicationsModule,
    ivrModule,
    ReportsModule,
    BillingModule,
    ProvisionerModule,
    DashboardModule,
    SharedModule,
  ],
  declarations: [
    AdminComponent,
    FullComponent,
    AppSidebarComponent,
    AppHeaderComponent,
    FooterComponent,
    MenuListItemComponent,
  ],
  exports : [
    SharedModule
  ]
})
export class AdminModule {}
