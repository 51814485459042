import { NgModule, Component } from "@angular/core";
import { RouterModule, Router, Routes } from "@angular/router";
import {ClientComponent} from "../client.component";
import { OutboundRoutesComponent } from "./outbound-routes/outbound-routes.component";
import { InboundRoutesComponent } from "./inbound-routes/inbound-routes.component";

const routes: Routes = [
    {
        path: 'client',
        component: ClientComponent,
        children: [
            {
                path: 'connectivity',
                children: [
                    {
                        path: 'inbound-routes',
                        component: InboundRoutesComponent,
                    },
                    {
                        path: 'outbound-routes',
                        component: OutboundRoutesComponent,
                    },
                ]
            }
        ]
    }
]
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ClientConnectivityRoutingModule {}