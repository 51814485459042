import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { ClientDashboardComponent } from './client-dashboard.component';
import {ClientDashboardRoutingModule} from './client-dashboard-routing.module';
import {MatCardModule, MatButtonModule, MatTableModule, MatSortModule, MatPaginatorModule} from '@angular/material';
import { ChartsModule } from 'ng2-charts';
import { CallStatusComponent } from './call-status/call-status.component';
import { CallStatisticsComponent } from './call-statistics/call-statistics.component';
import { VoicemailComponent } from './voicemail/voicemail.component';
import { ExtensionandusersModule } from '../applications/extensionandusers/extensionandusers.module';
import { InboundRoutesComponent } from './inbound-routes/inbound-routes.component'
import { RingGroupsModule } from "../applications/ringgroups/ringgroups.module";
import { TimeconditionsModule } from "../applications/timeconditions/timeconditions.module";
import { IvrModule } from "../applications/ivr/ivr.module";
import { CommonComponentModule } from "../../common/commonComponent.module";

@NgModule({
    imports: [
      CommonModule,
      BrowserModule,
      ClientDashboardRoutingModule,
      MatButtonModule,
      MatCardModule,
      ChartsModule,
      MatTableModule,
      MatSortModule,
      ExtensionandusersModule,
      MatPaginatorModule,
      RingGroupsModule,
      TimeconditionsModule,
      IvrModule,
      CommonComponentModule

    ],
    declarations :[
      ClientDashboardComponent,
      CallStatusComponent,
      CallStatisticsComponent,
      VoicemailComponent,
      InboundRoutesComponent,

    ],
})

export class clientDashboardModule { }