import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserComponent } from '../user.component'
import { ReportsComponent } from './reports.component';

const routes: Routes = [
  {
    path: 'user',
    component: UserComponent,
    children: [
      {
          path: 'reports',
          component: ReportsComponent,
      },
    ]
  }
];

// @NgModule({
//   imports: [RouterModule.forChild([
//     {
//         path: 'user',
//         component: UserComponent,
//         children: [
//           {
//               path: 'reports',
//               component: ReportsComponent,
//           },
//         ]
//     }
//   ]),
// ],
//   exports: [RouterModule]
// })


@NgModule({
  imports: [RouterModule.forChild(routes)],

exports: [RouterModule]
})
export class ReportsRoutingModule { }
