import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {

  allDiv: boolean = true;
  companyDiv: boolean = false;
  myContactsDiv: boolean = false;
  
  public usersList = [
    {
      initial:'C',
      list: [
        { initials:'CK', name:'Carisaa Knezha', companyName:'inman Littlefield... x403', type:'Extension' },
      ]
    },
    {
      initial:'L',
      list: [
        { initials:'LL', name:'Linda Littlefield', companyName:'inman Littlefield... x404', type:'Extension' },
      ]
    },
    {
      initial:'N',
      list: [
        { initials:'NI', name:'Nicholas Inman', companyName:'inman Littlefield... x402', type:'Extension' },
      ]
    },
    {
      initial:'S',
      list: [
        { initials:'SS', name:'Service Serice', companyName:'inman Littlefield... x405', type:'Extension' },
      ]
    },

  ];

  constructor() { }
  
  ngOnInit() {
  }

  applyFilter(filterValue: any) {
    this.usersList.filter = filterValue.trim().toLowerCase();
  }
  
  clickAllDiv(event) {
    this.allDiv = true;
    this.companyDiv = false;
    this.myContactsDiv = false;
  }
  clickCompanyDiv(event) {
    this.allDiv = false;
    this.companyDiv = true;
    this.myContactsDiv = false;
  }
  clickContactsDiv(event) {
    this.allDiv = false;
    this.companyDiv = false;
    this.myContactsDiv = true;
  }

} 
