import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { MaterialModule } from "../material-module";
import { FullComponent } from "./layouts/full/full.component";
import { AppSidebarComponent } from "./layouts/full/sidebar/sidebar.component";
import { AppHeaderComponent } from "./layouts/full/header/header.component";
import { SubHeaderComponent } from './layouts/full/sub-header/sub-header.component';
import { NgMaterialMultilevelMenuModule } from "ng-material-multilevel-menu";
import { FooterComponent } from "./layouts/full/footer/footer.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MenuListItemComponent } from "./layouts/full/menu-list-item/menu-list-item.component";
import { ClientRoutingModule } from './client-routing.module';
import {ClientComponent} from './client.component';
import {clientDashboardModule} from "./client-dashboard/client-dashboard.module";
import { ApplicationsModule } from './applications/applications.module';
import { ClientConnectivityModule } from './client-connectivity/client-connectivity.module';
import { ReportsModule } from './reports/reports.module';
import { SettingsModule } from "./settings/settings.module";
import { MatMenuModule } from '@angular/material/menu';
@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    MaterialModule,
    ClientRoutingModule,
    ClientConnectivityModule,
    NgMaterialMultilevelMenuModule,
    FlexLayoutModule,
    ApplicationsModule,
    NgMaterialMultilevelMenuModule,
    FlexLayoutModule,
    clientDashboardModule,
    ApplicationsModule,
    ReportsModule,
    SettingsModule,
    MatMenuModule,

    ],
  declarations: [
    ClientComponent,
    FullComponent,
    AppSidebarComponent,
    AppHeaderComponent,
    FooterComponent,
    MenuListItemComponent,
    SubHeaderComponent,

  ]
})
export class ClientModule { }
