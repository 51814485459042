import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator, MatTableDataSource} from '@angular/material';

export interface PeriodicElement {
  conferencebridgename : string;
  callerid : string;
  hours : string;
  user : number;
  marked : number;
  locked: string;
  muted: string
}

const ELEMENT_DATA: PeriodicElement[] = [
  { conferencebridgename:'Test Conference Bridge Name',callerid:'11-2345-7658',hours:'02:11:33', user:1,marked:4,locked:'No', muted:'No' },
  { conferencebridgename:'Conference Bridge Name',callerid:'12-3456-7890',hours:'01:12:14',user: 8, marked:6, locked:'No', muted:'No' }
  
];
@Component({
  selector: 'app-conference',
  templateUrl: './conference.component.html',
  styleUrls: ['./conference.component.css']
})
export class ConferenceComponent implements OnInit {
  displayedColumns: string[] = ['conferencebridgename','callerid','hours','user','marked','locked','muted'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  constructor() { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
  }
}
