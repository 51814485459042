import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, ValidatorFn } from '@angular/forms';
import * as toastr from "toastr";
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import { Select2Option, Select2Data } from 'ng-select2-component';

@Component({
  selector: 'app-createqueue',
  templateUrl: './createqueue.component.html',
  styleUrls: ['./createqueue.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {showError: true}
  }]
})
export class CreatequeueComponent implements OnInit {

  generalForm: FormGroup;
  queueagentForm: FormGroup;
  timingagentForm: FormGroup;
  callerForm: FormGroup;
  submitted: false;

  public recording

  public data2 : Select2Data = [
    { value: 'None', label: 'None' },
    { value: 'Announcement 1', label: 'Announcement 1'},
    { value: 'Announcement 2', label: 'Announcement 2' },
    { value: 'Announcement 3', label: 'Announcement 3' },
  ];

  public data3 : Select2Data = [
    {value:'None',label:'None'},
    {value:'Alert 1',label:'Alert 1'},
    {value:'Alert 2',label:'Alert 2'},
    {value:'Alert 3',label:'Alert 3'}
  ];

  public data4 : Select2Data = [
    {value:'Default',label:'Default'},
    {value:' 1',label:' 1'},
    {value:' 2',label:' 2'},
    {value:' 3',label:' 3'},
    {value:' 4',label:' 4'},
    {value:' 5',label:' 5'},
    {value:' 6',label:' 6'},
    {value:' 7',label:' 7'},
    {value:' 8',label:' 8'},
    {value:' 9',label:' 9'},
    {value:' 10',label:' 10'}
  ];

  public data5 : Select2Data = [
    { value: 'Ringall', label: 'Ringall' },
    { value: 'Hunt', label: 'Hunt',},
    { value: 'Memoryhunt', label: 'Memoryhunt' },
    { value: 'Firstunavailable', label: 'Firstunavailable' },
    { value: 'Firstnotonphone', label: 'Firstnotonphone' },
    { value: 'Random', label: 'Random' },
  ];

  public data6 : Select2Data = [
    {value:'Default',label:'Default'},
    {value:'Class 1',label:'Class 1'},
    {value:'Class 2',label:'Class 2'},
    {value:'Class 3',label:'Class 3'}
  ];

  public data7 : Select2Data = [
    {value:'Default',label:'Default'},
    {value:'Destination 1',label:'Destination 1'},
    {value:'Destination 2',label:'Destination 2'},
    {value:'Destination 3',label:'Destination 3'}
  ];

  public data8 : Select2Data = [
    {value:'Default',label:'100.0'},
    {value:'100.1',label:'100.1'},
    {value:'100.2',label:'100.2'},
    {value:'100.3',label:'100.3'},
    {value:'100.4',label:'100.4'},
    {value:'100.5',label:'100.5'},
    {value:'100.6',label:'100.6'}
  ];

  public data9 : Select2Data = [
    {value:' 5',label:' 5'},
    {value:' 10',label:' 10'},
    {value:' 15',label:' 15'},
    {value:' 20',label:' 20'},
    {value:' 25',label:' 25'},
    {value:' 30',label:' 30'},
    {value:' 40',label:' 40'},
    {value:' 60',label:' 60'},
    {value:' 80',label:' 80'},
    {value:' 100',label:' 100'}
  ];

  public data10 : Select2Data = [
    {value:' 5 Seconds',label:' 5 Seconds'},
    {value:' 10 Seconds',label:' 10 Seconds'},
    {value:' 15 Seconds',label:' 15 Seconds'},
    {value:' 20 Seconds',label:' 20 Seconds'},
  ];

  public data11 : Select2Data = [
    {value:'IVR 1',label:'IVR 1'},
    {value:'IVR 2',label:'IVR 2'},
    {value:'IVR 3',label:'IVR 3'},
    {value:'IVR 4',label:'IVR 4'},
    {value:'IVR 5',label:'IVR 5'},
    {value:'IVR 6',label:'IVR 6'},
    {value:'IVR 7',label:'IVR 7'},
    {value:'IVR 8',label:'IVR 8'},
    {value:'IVR 9',label:'IVR 9'},
    {value:'IVR 10',label:'IVR 10'}
  ];

  data: Select2Option[] = [];
  alert: Select2Option[] = [];
  ringerVolume: Select2Option[] = [];
  ringStrategy: Select2Option[] = [];
  class: Select2Option[] = [];
  destination: Select2Option[] = [];
  agents: Select2Option[] = [];
  callers: Select2Option[] = [];
  waitTime: Select2Option[] = [];
  breakOutMenu: Select2Option[] = [];

  constructor(private _formBuilder: FormBuilder) { }

  ngOnInit() {
    this.generalForm = this._formBuilder.group({
      queueNumber: ['', [Validators.required, Validators.max(300),Validators.min(1)]],
      queueName: ['',[Validators.required, Validators.maxLength(30)]],
      queuePassword: ['', [Validators.required, Validators.minLength(6)]],
      cidPrefix: ['',[Validators.required, Validators.maxLength(30)]],
      callConfirm: ['',Validators.required],
      alertInfo: ['',Validators.required],
      ringerVolume: ['',Validators.required],
      ringStrategy: ['',Validators.required],
      queueWeight: ['',[Validators.required, Validators.max(300), Validators.min(1)]],
      musicOnHold: ['',Validators.required],
      joinAnnouncement: ['',Validators.required],
      destination1: ['',Validators.required],
      destination2: ['',Validators.required],
    });

    this.queueagentForm = this._formBuilder.group({
      staticAgent: ['', Validators.required],
      dynamicAgent: ['', Validators.required],
    });

    this.timingagentForm = this._formBuilder.group({
      maxNoCalls: ['', Validators.required],
      maxWaitTime: ['', Validators.required],
      agentTimeout: ['', Validators.required],
      retry: ['', Validators.required],
      wrapUpTime: ['', Validators.required],
      memberDelay: ['', Validators.required],
      agentAnnounce: ['', Validators.required],
    });

    this.callerForm = this._formBuilder.group({

    });

  }

  get f() { return this.generalForm.controls; }

  form1(){
    console.log(this.generalForm.value);
  }

  form2(){
    console.log(this.queueagentForm.value);
  }

  form3(){
    console.log(this.timingagentForm.value);
  }

  form4(){
    console.log(this.timingagentForm.value);
  }



  
  update(value: string) {
    this.recording = value;
  }
  open() {
    this.data = JSON.parse(JSON.stringify(this.data2));
    this.alert = JSON.parse(JSON.stringify(this.data3));
    this.ringerVolume = JSON.parse(JSON.stringify(this.data4));
    this.ringStrategy = JSON.parse(JSON.stringify(this.data5));
    this.class = JSON.parse(JSON.stringify(this.data6));
    this.destination = JSON.parse(JSON.stringify(this.data7));
    this.agents = JSON.parse(JSON.stringify(this.data8));
    this.callers = JSON.parse(JSON.stringify(this.data9));
    this.waitTime = JSON.parse(JSON.stringify(this.data10));
    this.breakOutMenu = JSON.parse(JSON.stringify(this.data11));
  }

  search(text: string) {
    this.data = text
      ? (JSON.parse(JSON.stringify(this.data2)) as Select2Option[])
        .filter(option => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1)
      : JSON.parse(JSON.stringify(this.data2));
    this.alert = text
      ? (JSON.parse(JSON.stringify(this.data3)) as Select2Option[])
        .filter(option => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1)
      : JSON.parse(JSON.stringify(this.data3));
    this.ringerVolume = text
      ? (JSON.parse(JSON.stringify(this.data4)) as Select2Option[])
        .filter(option => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1)
      : JSON.parse(JSON.stringify(this.data4));
    this.ringStrategy = text
      ? (JSON.parse(JSON.stringify(this.data5)) as Select2Option[])
        .filter(option => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1)
      : JSON.parse(JSON.stringify(this.data5));
    this.class = text
      ? (JSON.parse(JSON.stringify(this.data6)) as Select2Option[])
        .filter(option => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1)
      : JSON.parse(JSON.stringify(this.data6));
    this.destination = text
      ? (JSON.parse(JSON.stringify(this.data7)) as Select2Option[])
        .filter(option => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1)
      : JSON.parse(JSON.stringify(this.data7));
    this.agents = text
      ? (JSON.parse(JSON.stringify(this.data8)) as Select2Option[])
        .filter(option => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1)
      : JSON.parse(JSON.stringify(this.data8));
    this.callers = text
      ? (JSON.parse(JSON.stringify(this.data9)) as Select2Option[])
        .filter(option => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1)
      : JSON.parse(JSON.stringify(this.data9));
    this.waitTime = text
      ? (JSON.parse(JSON.stringify(this.data10)) as Select2Option[])
        .filter(option => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1)
      : JSON.parse(JSON.stringify(this.data10));
    this.breakOutMenu = text
      ? (JSON.parse(JSON.stringify(this.data11)) as Select2Option[])
        .filter(option => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1)
      : JSON.parse(JSON.stringify(this.data11));
  }
}
