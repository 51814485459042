import { Action } from "@ngrx/store";
import { Device } from "../../models";
import { HttpErrorResponse } from "@angular/common/http";


export enum DeviceActionType {
    DEVICE_LOAD = "[Device] Load",
    DEVICE_LOAD_SUCCESS = "[Device] Load Success",
    DEVICE_LOAD_FAIL = "[Device] Load Fail",
}

export class DeviceLoad implements Action {
    readonly type = DeviceActionType.DEVICE_LOAD;
}

export class DeviceLoadSuccess implements Action {
    readonly type = DeviceActionType.DEVICE_LOAD_SUCCESS;
    constructor(public payload: Device[]) {}
}
export class DeviceLoadFail implements Action {
    readonly type = DeviceActionType.DEVICE_LOAD_FAIL;
    constructor(public payload: HttpErrorResponse) {}
}

export type DeviceAction = 
| DeviceLoad
| DeviceLoadSuccess
| DeviceLoadFail
;