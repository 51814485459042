import { Component, OnInit, Optional, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { FormBuilder } from "@angular/forms";
import { ClientService } from "../../services";
import { settings } from "cluster";
import Swal from "sweetalert2";

@Component({
  selector: "add-setting",
  templateUrl: "./add-device-setting.component.html",
  styleUrls: ["./add-device-setting.component.scss"]
})
export class AddDeviceSettingComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<AddDeviceSettingComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data,
    private _formBuilder: FormBuilder,
    private service: ClientService
  ) {}

  file: File;
  settings: any = [];
  defaults: any = this.data.defaults;
  match: boolean = null;

  errors = [];

  ngOnInit() {
    console.log(this.defaults);
  }

  convertFile(event: any) {
    this.file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsText(this.file);
    reader.onload = () => {
      let csv: string = reader.result.toString();
      var jsonData = this.csvJSON(csv);
      delete jsonData[0];
      var categories = [];
      var margedSettings = {};
      for (var i in jsonData) {
        var item = jsonData[i];

        if (!categories.includes(item[0])) {
          categories.push(item[0]);
          margedSettings[item[0]] = [item];
        } else {
          margedSettings[item[0]].push(item);
        }
      }
      for (var name in margedSettings) {
        var tempSetting = {};
        tempSetting["name"] = name;
        tempSetting["values"] = [];
        for (var val in margedSettings[name]) {
          var temp = {};
          temp["setting_name"] = margedSettings[name][val][1];
          temp["setting_description"] = margedSettings[name][val][2];
          temp["setting_variable_name"] = margedSettings[name][val][3];
          temp["setting_variable_type"] = margedSettings[name][val][4];
          if (margedSettings[name][val].length > 5) {
            temp["setting_options"] = [];
            for (
              var count = 5;
              count < margedSettings[name][val].length;
              count++
            ) {
              if (
                margedSettings[name][val][count] &&
                margedSettings[name][val][count + 1] &&
                margedSettings[name][val][count] !== "\\N" &&
                margedSettings[name][val][count + 1] !== "\\N"
              ) {
                temp["setting_options"].push({
                  value: margedSettings[name][val][count],
                  name: margedSettings[name][val][++count]
                });
              }
            }
            if (temp["setting_options"].length == 0) {
              delete temp["setting_options"];
            }
          }
          tempSetting["values"].push(temp);
        }
        this.settings.push(tempSetting);
      }
    };
  }

  csvJSON(csv) {
    let allTextLines = csv.split(/\r|\n|\r/);
    let headers = allTextLines[0].split(",");
    let lines = [];

    for (let i = 0; i < allTextLines.length; i++) {
      // split content based on comma
      let data = allTextLines[i].split(",");
      if (data.length === headers.length) {
        let tarr = [];
        for (let j = 0; j < headers.length; j++) {
          tarr.push(data[j].replace(/(^")|("$)/g, ""));
        }
        lines.push(tarr);
      }
    }
    // all rows in the csv file
    return lines;
  }

  onlyUnique(value, index, self) { 
    return self.indexOf(value) === index;
  }

  addDeviceSetting() {
    this.match = false;
    this.errors = [];
    // variable for storing matching indexes
    var matchedSetting = [];
    var newSettingValue = [];
    // loop of uploded settings
    for(var setting in this.settings){
      // loop of previous setting
      for(var def in this.defaults){
        // check if setting already exist
        if(this.settings[setting].name == this.defaults[def].name){
          // loop of uploaded setting values
          for(var setVal in this.settings[setting].values){
            // loop of previous setting values
            for(var defVal in this.defaults[def].values){
              // check if P value already exist
              if(this.settings[setting].values[setVal].setting_variable_name == this.defaults[def].values[defVal].setting_variable_name){
                if(!this.errors.includes(`${this.defaults[def].values[defVal].setting_variable_name} already exists`)){
                  this.errors.push(`${this.defaults[def].values[defVal].setting_variable_name} already exists`);
                }
                this.match = true;

              } else {
                // take record if not matched
                newSettingValue.push(this.settings[setting].values[setVal]);
              }
            }
          }
          // take unique value from unmatched records
          newSettingValue = newSettingValue.filter( this.onlyUnique );
          if(!this.match){
            // loop through each record(s)
            for(var newVal in newSettingValue){
              // push into settings
              this.defaults[def].values.push(newSettingValue[newVal]);
            }
          }
          // clear all values for next turn of loop
          newSettingValue = [];
          // take matched setting's index
          matchedSetting.push(setting);
        }
      }
    }
    if(this.match){
      return;
    }
    //  filter uploded setting for taking new setting
    var remainingSetting = this.settings.filter((val, key)=>{
      if(matchedSetting.includes(key.toString())){
        return false;
      }
      return true;
    })
    // loop through each new setting(s)
    for(var newSet in remainingSetting){
      // push into setting
      this.defaults.push(remainingSetting[newSet]);
    }
    this.service.addDeviceSetting(this.data._id, this.defaults).subscribe((data:any)=>{
      if(data){
        if(data.ok){
          this.dialogRef.close({
            added: true
          })
        }else{
          Swal.fire("Oopsss...", "Something went worng", "error");
        }
      }
    })
    
  }
}
