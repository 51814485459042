import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl  } from '@angular/forms';
import { MustMatch } from './must-match.validator';
import * as toastr from "toastr";
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

export interface Account {
  name: string;
}

@Component({
  selector: 'app-addnewuser',
  templateUrl: './addnewuser.component.html',
  styleUrls: ['./addnewuser.component.scss']
})
export class AddnewuserComponent implements OnInit {
  
  newUserForm: FormGroup;
  submitted = false;
  myControl = new FormControl();
  options: Account[] = [
    {name: 'Mary'},
    {name: 'Shelley'},
    {name: 'Igor'},
    {name: 'Kiani'},
    {name: 'Hamed'},
  ];
  filteredOptions: Observable<Account[]>;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {

    this.newUserForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      accountName: ['', Validators.required],
      userName: ['',Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required],
    },
    {
      validator: MustMatch('password', 'confirmPassword')
    });
    this.newUserForm.reset();

    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filter(name) : this.options.slice())
      );
  }

  displayFn(account?: Account): string | undefined {
    return account ? account.name : undefined;
  }

  private _filter(name: string): Account[] {
    const filterValue = name.toLowerCase();

    return this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  get f() { return this.newUserForm.controls; }

  onSubmit() {
      this.submitted = true;


      if (this.newUserForm.invalid) {
        toastr.error("Invalid Form !");
        return;
      }
      console.log(this.newUserForm.value);

      toastr.success("Form Submitted Successfully !")

  }

}
