import { Component, OnInit } from "@angular/core";
import { ClientService, readCookie } from "../../services";
import { ActivatedRoute, Router } from "@angular/router";
import { Clients } from "../../models";
import { resolve } from "q";

@Component({
  selector: "client-detail",
  templateUrl: "./client-detail.component.html",
  styleUrls: ["./client-detail.component.css"],
})
export class ClientDetailComponent implements OnInit {
  client: Clients;
  path = {};
  logs: any;
  intervalId: any;

  constructor(
    private service: ClientService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    if (!readCookie("authenticate")) {
      this.router.navigate(["/login"], {
        queryParams: { return: location.href },
      });
      return false;
    }
    this.route.params.subscribe((data: any) => {
      this.service.getClient(data.id).subscribe((data: any) => {
        this.client = data;
        data.client_settings_config.forEach((val1) => {
          if (val1.setting_variable == "P237") {
            this.path["config"] = val1.setting_value;
          }
          if (val1.setting_variable == "P192") {
            this.path["firmware"] = val1.setting_value;
          }
        });
      });
      this.intervalId = setInterval(() => {
        this.loadAccessLogs().then((data: any) => {
          var newlogs = [];
          for (var i in data) {
            if (!data[i].match(/DevId/)) {
              continue;
            }
            data[i] = data[i].replace("DevId", "DevId,");
            data[i] = data[i].split(",");
            newlogs.push(data[i]);
          }
          this.logs = newlogs;
        });
      }, 10000);
    });
  }

  loadAccessLogs() {
    return new Promise((resolve, reject) => {
      this.service
        .accessLogs(this.client.old_id ? this.client.old_id : this.client._id)
        .subscribe((data: any) => {
          return resolve(data);
        });
    });
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }
}
