import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import * as toastr from "toastr";
import { MatTableDataSource } from '@angular/material';

export interface MessagesNo {
  show: String;
  phone: String;
  type: String;
  label: String;
}

const ELEMENT_DATA: MessagesNo[] = [
  {show:'',phone:'(214)432-2757',type:'Direct Number',label:''},
  {show:'',phone:'403',type:'Extension',label:''},
];

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss']
})
export class ApplicationComponent implements OnInit {
  applicationForm: FormGroup;
  submitted = false;

  displayedColumns: string[] = ['show','phone','type','label'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  checked: boolean = true;
  disableCheckbox: boolean = true;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.applicationForm = this.formBuilder.group({
      defaultNo: ['', Validators.required],
    });
  }

  get f() { return this.applicationForm.controls; }

  onSubmit() {
      this.submitted = true;

      if (this.applicationForm.invalid) {
        toastr.error("Invalid Details !");
        return;
      }
      console.log(this.applicationForm.value);

      toastr.success("Form Submitted Successfully !");
      this.applicationForm.reset();
  }

}
