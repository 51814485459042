import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray, AbstractControl } from '@angular/forms';
import { Select2Option, Select2Data } from 'ng-select2-component';
import * as toastr from "toastr";
@Component({
  selector: 'app-addivr',
  templateUrl: './addivr.component.html',
  styleUrls: ['./addivr.component.scss']
})
export class AddivrComponent implements OnInit {

  ivrForm: FormGroup;
  submitted = false;
  arr: FormArray;

  announcementData = [
    {id:1, name:'None'},
    {id:2, name:'Announcement 1'},
    {id:3, name:'Announcement 2'},
    {id:4, name:'Announcement 3'},
    {id:5, name:'Announcement 4'},
    {id:6, name:'Announcement 5'},
  ];

  directDialData = [
    {id:1, name:'Disabled'},
    {id:2, name:'101'},
    {id:3, name:'102'},
    {id:4, name:'103'},
    {id:5, name:'104'},
    {id:6, name:'105'},
  ];

  alertInfoData = [
    {id:1, name:'None'},
    {id:2, name:'Alert 1'},
    {id:3, name:'Alert 2'},
    {id:4, name:'Alert 3'},
    {id:5, name:'Alert 4'},
    {id:6, name:'Alert 5'},
  ];

  retryRecordData = [
    {id:1, name:'Default'},
    {id:2, name:'Recording 1'},
    {id:3, name:'Recording 2'},
    {id:4, name:'Recording 3'},
    {id:5, name:'Recording 4'},
    {id:6, name:'Recording 5'},
  ];

  destinationData = [
    {id:1, name:'Default'},
    {id:2, name:'Destination 1'},
    {id:3, name:'Destination 2'},
    {id:4, name:'Destination 3'},
  ]

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.ivrForm = this.formBuilder.group({
      ivrName: ['', Validators.required],
      ivrDescription: ['', Validators.required],
      announcements: ['', Validators.required],
      directDial: ['', Validators.required],
      alertInfo: ['', Validators.required],
      invalidRetry: ['', Validators.required],
      invalidRetryRecording: ['', Validators.required],
      invalidRecording: ['', Validators.required],
      destination1: ['', Validators.required],
      destination2: ['', Validators.required],
      timeout: ['', Validators.required],
      timeoutRetry: ['', Validators.required],
      timeoutRetryRecording: ['', Validators.required],
      timeoutRecording: ['', Validators.required],
      tDestination1: ['', Validators.required],
      tDestination2: ['', Validators.required],
      arr: this.formBuilder.array([this.createItem()]),
    });

    this.ivrForm.reset();
  }

  createItem() {
    return this.formBuilder.group({
      digits: [''],
      entryDestination1: [''],
      entryDestination2: [''],
    })
  }

  addItem() {
    this.arr = this.ivrForm.get('arr') as FormArray;
    this.arr.push(this.createItem());
  }

  removeItem(index) {
    const form = this.ivrForm.get('arr') as FormArray
    form.removeAt(index);
  }




  get f() { return this.ivrForm.controls; }

  onSubmit() {
      this.submitted = true;

      if (this.ivrForm.invalid) {
        toastr.error("Invalid Details !");
        return;
      }
      console.log(this.ivrForm.value);

      toastr.success("Form Submitted Successfully !")
  }
}
