import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AdminComponent } from "../admin.component";
import { ProvisionerComponent } from "../provisioner/provisioner.component";
import { ClientsComponent } from "../provisioner/clients/clients.component";
import { GlobalsettingComponent } from "../provisioner/golbalsetting/globalsetting.component";
import { GroupsComponent } from "./clients/groups/groups.component";
import { GroupSettingsComponent } from "./clients/groups/group-settings/group-settings.component";
import { DevicesComponent } from "./clients/devices/devices.component";
import { GlobalSettingsComponent } from "./clients/global-settings/global-settings.component";
import { ClientDetailComponent } from "./clients/client-detail/client-detail.component";
import { CompanySettingsComponent } from "./company-settings/company-settings.component";
import { DeviceSettingsComponent } from "./clients/devices/device-settings/device-settings.component";
import { EditCompanySettingsComponent } from "./company-settings/edit-company-settings/edit-company-settings.component";
import { EditGroupSettingComponent } from "./clients/groups/edit-group-setting/edit-group-setting.component";
import { EditSettingsComponent } from "./clients/global-settings/edit-settings/edit-settings.component";
import { DeviceModelsComponent } from "./device-models/device-models.component";
import { LoginComponent } from "./login/login.component";
import { AuthGuard } from "./auth.guard";

const routes: Routes = [
  {
    path: "admin",
    component: AdminComponent,
    data: {
      title: "Applications",
      status: false
    },
    children: [
      {
        path: "provisioner",
        component: ProvisionerComponent,
        data: {
          breadcrumb: "Provisioner"
        },
        children: [
          {
            path: "device-models",
          component: DeviceModelsComponent
          },
          {
            path: "clients",
            component: ClientsComponent
          },
          {
            path: "details/:id",
            component: ClientDetailComponent
          },
          {
            path: "global-settings/:id",
            component: GlobalSettingsComponent
          },
          {
            path: "edit-global-settings/:client_id/:company_setting_id/:model_id/:id",
            component: EditSettingsComponent
          },
          {
            path: "company-settings",
            component: CompanySettingsComponent
          },
          {
            path: "company-settings/:id/:model_id",
            component: EditCompanySettingsComponent
          },
          {
            path: "groups/:id",
            component: GroupsComponent
          },
          {
            path: "group-settings/:client_id/:id",
            component: GroupSettingsComponent
          },
          {
            path: "group-settings-edit/:client_id/:group_id/:model_id/:id",
            component: EditGroupSettingComponent
          },
          {
            path: "devices/:id",
            component: DevicesComponent
          },
          {
            path: "device-settings/:client_id/:model_id/:device_id/:group_setting_id",
            component: DeviceSettingsComponent
          }
        ]
      },
      {
        path: "globalsetting",
        component: GlobalsettingComponent
      }
    ]
  }
];

// @NgModule({
//   imports: [RouterModule.forChild([
//     {
//       path: 'admin',
//       component: AdminComponent,
//       data: {
//         title: 'Applications',
//         status: false
//       },

//       children: [
//         {
//           path : 'provisioner',
//           component: ProvisionerComponent
//         },
//         {
//           path : 'globalsetting',
//           component : GlobalsettingComponent
//         }
//       ]
//     }
//   ]),
// ],
//   exports: [RouterModule]
// })

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProvisionerRoutingModule {}
