import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator, MatTableDataSource} from '@angular/material';


export interface PeriodicElement {
  category : string;
  type : string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {category :  'Default', type : 'File' },
  {category : 'Nstar', type : 'File' }
  
];
@Component({
  selector: 'app-music-on-holde-list',
  templateUrl: './music-on-holde-list.component.html',
  styleUrls: ['./music-on-holde-list.component.scss']
})
export class MusicOnHoldeListComponent implements OnInit {
  displayedColumns: string[] = [ 'category','type','action'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  constructor() { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
  }
  

}
