import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inbound-routes',
  templateUrl: './inbound-routes.component.html',
  styleUrls: ['./inbound-routes.component.css']
})
export class InboundRoutesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
