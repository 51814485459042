import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-callqueues',
  templateUrl: './callqueues.component.html',
  styleUrls: ['./callqueues.component.css']
})
export class CallqueuesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
