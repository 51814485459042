import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';



@Component({
  selector: 'app-add-outbound-route',
  templateUrl: './add-outbound-route.component.html',
  styleUrls: ['./add-outbound-route.component.scss']
})


export class AddOutboundRouteComponent implements OnInit {
  constructor(private _formBuilder: FormBuilder) { }

  ngOnInit() {

  }

}
