import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator, MatTableDataSource} from '@angular/material';

export interface PeriodicElement {
  user : number;
  hardware : string;
  contact : string;
  lanip : string;
  wanip : string;
  port : number;
  status: string;
  ping : number;
  dnd : string;
  lastreboot: string
}

const ELEMENT_DATA: PeriodicElement[] = [
  {user : 200, hardware:'Fanvil X6 R0.9.3.2',contact:'Avs Fusion 300',lanip:'192.12.23.34',wanip:'192.12.23.34',port:200,status:'Registered',ping:21, dnd:'success',lastreboot:'10-12-2018' },
  {user : 300, hardware:'Fanvil X6 R0.9.3.2',contact:'Avs Fusion 300',lanip:'192.12.23.34',wanip:'192.12.23.34',port:200,status:'Unknown',ping:21, dnd:'success',lastreboot:'10-12-2018' }
  
];

@Component({
  selector: 'app-extension-info-and-setting',
  templateUrl: './extension-info-and-setting.component.html',
  styleUrls: ['./extension-info-and-setting.component.css']
})
export class ExtensionInfoAndSettingComponent implements OnInit {

  displayedColumns: string[] = ['user','hardware','contact','lanip','wanip','port','status','ping','dnd','lastreboot','action'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  constructor() { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
  }
}
