import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from '../admin.component';
import { ReportsComponent } from '../reports/reports.component'

const routes: Routes = [
  {
    path: 'admin',
    component: AdminComponent,
    data: {
      title: 'Applications',
      status: false
    },

    children: [
      {
        path : 'report',
        component: ReportsComponent
      }
    ]
  }
];


// @NgModule({
//   imports: [RouterModule.forChild([
//     {
//         path: 'admin',
//         component: AdminComponent,
//         data: {
//           title: 'Applications',
//           status: false
//         },
   
//     children: [
//         {
//           path : 'report',
//           component: ReportsComponent
//         }
//       ]
//     }
//   ]),
// ],
//   exports: [RouterModule]
// })

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
export class ReportsRoutingModule { }