import { Component, OnInit, ViewChild } from '@angular/core';
import { PermissionsUsersService } from '../../../service/permissions-users.service';
import { Subscription } from 'rxjs';
// import { MatPaginator, MatTableDataSource} from '@angular/material';
// import { ActivatedRoute, Router } from '@angular/router';

// export interface PeriodicElement {
//   roles: string;
//   policies: string;
// }

// const ELEMENT_DATA: PeriodicElement[] = [
//   {roles: '1099 Installers (Projects & Tickets only)', policies:'1099 Installers (Projects & Tickets only)'},
//   {roles: 'Dev Team', policies:'Developers'},
//   {roles: 'Encon IT', policies:'Encon IT  '},
//   {roles: 'Executive Admin Dept', policies:'Executive Admin'},
//   {roles: 'Full System Access', policies:'Full Access'},
//   {roles: 'Limited HR', policies:'HR(Employees, Leave & Attendance)'},
//   {roles: 'Sales Dept', policies:'Sales'},
//   {roles: 'Support Dept', policies:'Support'},
//   {roles: 'Vendor Full Access', policies:'Vendor Edit'},
// ];

@Component({
  selector: 'app-permissions-users',
  templateUrl: './permissions-users.component.html',
  styleUrls: ['./permissions-users.component.css']
})
export class PermissionsUsersComponent implements OnInit {
  subscript: Subscription;  
  selectTabindex : number = 0;
  constructor(public permissionsUsersService : PermissionsUsersService) {
    this.subscript = this.permissionsUsersService.messageSource.subscribe((val)=> {
      if(val != 0) {
        this.selectTabindex = val;
      }
      else{
        this.selectTabindex = 0;
      }
    })
  }

  ngOnInit() {
    
  }

  ngOnDestroy(){
    this.subscript.unsubscribe();
    this.permissionsUsersService.messageSource.next(0);
  }
  
  
  // displayedColumns: string[] = ['roles','policies','action'];
  // dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

  // applyFilter(filterValue: string) {
  //   this.dataSource.filter = filterValue.trim().toLowerCase();
  // }

  // @ViewChild(MatPaginator) paginator: MatPaginator;

  // constructor(private route: ActivatedRoute, private router: Router) { }

  // ngOnInit() {
  //   this.dataSource.paginator = this.paginator;
  // }
}
