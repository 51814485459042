import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-conference-bridges',
  templateUrl: './conference-bridges.component.html',
  styleUrls: ['./conference-bridges.component.css']
})
export class ConferenceBridgesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
