import {Component, Input, OnInit} from '@angular/core';
import {MatTabGroup} from "@angular/material";
import {FormBuilder, FormGroup} from "@angular/forms";
import {Chart} from 'chart.js';
import { Select2Option, Select2Data } from 'ng-select2-component';



@Component({
    selector: 'app-company-summary',
    templateUrl: './company-summary.component.html',
    styleUrls: ['./company-summary.component.css']
})
export class CompanySummaryComponent implements OnInit {
    @Input() tabGroup: MatTabGroup;
    addCompanySummary: FormGroup;
    public totalcall: Select2Data = [];
    public alltotalcall: any = [];
    LineChart:any=[];
    recording : any

    constructor(private formBuilder: FormBuilder) {

    }

    

    ngOnInit() {
        this.createForm();
        this.alltotalcall = [{'value': 1, 'label': 'Total Calls'}];
        this.totalcall = this.alltotalcall;
        this.LineChart = new Chart('lineChart', {
          type: 'line',
          data: {
           labels: ["6/24", "6/25", "6/26", "6/27", "6/28", "6/29","6/30"],
           datasets: [{
               label: 'All',
               data: [0,50, 70, 35, 22, 0],
               fill:false,
               lineTension:0,
               backgroundColor:"#9a8ed2",
               borderColor :"#9a8ed2",
               borderWidth: 1
           },
           {
            label: 'Inbound',
            data: [0,41,50,20,12,0],
            fill:false,
            lineTension:0,
            backgroundColor:"#eb9b6e",
            borderColor :"#eb9b6e",
            borderWidth: 1
        },
        {
            label: 'International',
            data: [0,8,18,15,10,0],
            fill:false,
            lineTension:0,
            backgroundColor:"#e788d7",
            borderColor :"#e788d7",
            borderWidth: 1
        },
        {
            label: 'Intra-PBX',
            data: [0,0,0,0,0,0],
            fill:false,
            lineTension:0,
            backgroundColor:"#88c7df",
            borderColor :"#88c7df",
            borderWidth: 1
        },
        {
            label: 'Outbound',
            data: [0,0, 0,0,0,0],
            fill:false,
            lineTension:0,
            backgroundColor:"#a1ce85",
            borderColor :"#a1ce85",
            borderWidth: 1
        },
        ]
          }, 
          options: {
            elements: {
                line:{
                    tension : 0
                }
            },
           title:{
               text:"Line Chart",
               display:false
           },
           scales: {
               yAxes: [{
                   ticks: {
                       beginAtZero:true
                   }
               }],
               xAxes: [{
                gridLines : {
                    display : false
                }
               }]
           },
           legend :{
               position : "bottom",
               labels :{
                   boxWidth : 10,
                   
               }
           }
          }
          });
          
    }
    public Usercalldetails = [
        {name:'All Calls', inboundcalls: 29, outboundcalls: 10, totalcalls:49, internalcalls: 10},
        {name:'Inbound Calls', inboundcalls: 29, outboundcalls: 10, totalcalls:49, internalcalls: 10},
        {name:'Outbound Calls', inboundcalls: 29, outboundcalls: 10, totalcalls:49, internalcalls: 10},
        {name:'International Calls', inboundcalls: 29, outboundcalls: 10, totalcalls:49, internalcalls: 10},
        {name:'Intra-PBX Calls', inboundcalls: 29, outboundcalls: 10, totalcalls:49, internalcalls: 10}
      ]
      public data2 : Select2Data = [
        { value: 'tot_calls', label: 'Total Calls' },
        { value: 'inbound_calls', label: 'Inbound Calls',},
        { value: 'outbound_calls', label: 'Outbound Calls' },
        { value: 'internal_calls', label: 'Internal Calls' },
      ]
      
      data: Select2Option[] = [];

    createForm() {

        this.addCompanySummary = this.formBuilder.group({
            totalcalls: [''],
            startDate: ['']
        })

    }

    update(value: string) {
        this.recording = value;
      }
      open() {
        this.data = JSON.parse(JSON.stringify(this.data2));
      }
    
      search(text: string) {
        this.data = text
          ? (JSON.parse(JSON.stringify(this.data2)) as Select2Option[])
            .filter(option => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1)
          : JSON.parse(JSON.stringify(this.data2));
      }




}
