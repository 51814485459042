import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { API, API_ENDPOINT_URL } from "./constant";
import {BehaviorSubject, Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {
  public updateEventListener = new BehaviorSubject(0);
  constructor(private http: HttpClient) { }

  list(id?){
    if(id){
      return this.http.get(API.DEPARTMENT_LIST+id);
    }
    else{
      return this.http.get(API.DEPARTMENT_LIST);
    }
   
  }
  tree(){
    return this.http.get(API.DEPARTMENT_TREE);
  }
  deptHead(deptHead?){
    if(deptHead){
      const hparams=new HttpParams().set('departmentId',deptHead);
      return this.http.get(API.DEPARTMENT_DEPTHEAD,{params:hparams});
    }
    else{
      return this.http.get(API.DEPARTMENT_DEPTHEAD);
    }
    
  }
  delete(deptId){
    console.log("In service",deptId);
    let body: any = {};
    if (typeof deptId !== 'undefined' && deptId !== null) body.deptId = deptId;
    return this.http.delete(API.DEPARTMENT_DELETE+deptId);
  }
  
  add(name:string,description:any={},userId:number,roleId:any,parent:any){
    let body:any={};
    if (typeof name !== 'undefined' && name !== null) body.name = name;
    if (description !== ''  && description !== null) body.description = description;
    if (typeof userId !== 'undefined') body.userId = userId;
    if (roleId !== '' && roleId !== null) body.roleId = roleId;
    if (typeof parent !== 'undefined' && typeof parent !== null) body.parent = parent;
    console.log("add",body);
    return this.http.post(API.DEPARTMENT_ADD,body);
  }
  modify(id: number,name:string,description:any={},userId:number,roleId:any,parent:any){
    let body:any={};
    console.log(id,name,description,userId,roleId,parent);
    if (typeof name !== 'undefined' && name !== null) body.name = name;
    if (description !== ''  && description !== null) body.description = description;
    if (typeof userId !== 'undefined') body.userId = userId;
    if (roleId !== '' && roleId !== null) body.roleId = roleId;
    if (typeof parent !== 'undefined' && typeof parent !== null) body.parent = parent;
    console.log("modify",body);
    return this.http.post(API.DEPARTMENT_MODIFY+id,body);
  }

  DDList(id){
    return this.http.get(API.DEPARTMENT_DD_LIST + id);
  }
}
