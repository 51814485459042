import { Component, OnInit} from '@angular/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import { Subscription } from 'rxjs';
import { TrunkSettingService } from '../../../service/trunk-setting.service';

@Component({
  selector: 'app-trunk-setting',
  templateUrl: './trunk-setting.component.html',
  styleUrls: ['./trunk-setting.component.scss'],
  
})
export class TrunkSettingComponent implements OnInit {
  subscript: Subscription;  
  selectTabindex : number = 0;
  constructor(public trunkSettingService : TrunkSettingService) {
    this.subscript = this.trunkSettingService.messageSource.subscribe((val)=> {
      if(val != 0) {
        this.selectTabindex = val;
      }
      else{
        this.selectTabindex = 0;
      }
    })
  }
  ngOnInit() { 
  }
  ngOnDestroy(){
    this.subscript.unsubscribe();
    this.trunkSettingService.messageSource.next(0);
  }
}

