import { Component, OnInit, ViewChild } from '@angular/core';
import {
	MatPaginator,
	MatTableDataSource,
	MatDialog,
	MatSort,
} from '@angular/material';
import { ClientService, services, readCookie } from '../../services';
import { ActivatedRoute, Router } from '@angular/router';
import { AddDeviceComponent } from './add-device/add-device.component';
import { MoboAlert } from '../../notification';
import { DbResponse } from '../../models';
import Swal from 'sweetalert2';
import { FormControl } from '@angular/forms';
import { MacAddressValidator } from '../../helpers/macAddress-Validator';

@Component({
	selector: 'devices',
	templateUrl: './devices.component.html',
	styleUrls: ['./devices.component.scss'],
})
export class DevicesComponent implements OnInit {
	constructor(
		private service: ClientService,
		private route: ActivatedRoute,
		private dialog: MatDialog,
		private alert: MoboAlert,
		private router: Router
	) {}

	dataSource: any;
	displayedColumns: string[] = [
		'_id',
		'extNum',
		'extName',
		'macAddr',
		'group',
		'model',
		'created_at',
		'firmware',
		'actions',
	];

	client_id: string;
	client_name: string;

	@ViewChild(MatPaginator, {}) paginator: MatPaginator;
	@ViewChild(MatSort, {}) sort: MatSort;

	ngOnInit() {
		if (!readCookie('authenticate')) {
			this.router.navigate(['/login'], {
				queryParams: { return: location.href },
			});
			return false;
		}
		this.route.params.subscribe((data) => {
			this.client_id = data.id;
			this.service.clientDevices(this.client_id).subscribe((res: any) => {
				this.dataSource = new MatTableDataSource(res);
				this.dataSource.paginator = this.paginator;
				this.dataSource.sort = this.sort;
			});
			this.service.getClientName(this.client_id).subscribe((res: string) => {
				this.client_name = res;
			});
		});
	}

	openAddDeviceDialog() {
		const addDeviceDialog = this.dialog.open(AddDeviceComponent, {
			data: {
				client_id: this.client_id,
			},
		});
		addDeviceDialog.afterClosed().subscribe((data: any) => {
			if (data) {
				if (data.added) {
					Swal.fire('Added', 'Device has been added.', 'success');
					this.service.clientDevices(this.client_id).subscribe((res: any) => {
						this.dataSource = new MatTableDataSource(res);
						this.dataSource.paginator = this.paginator;
					});
				}
			}
		});
	}

	removeDevice(id: number) {
		this.alert
			.confirm({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
			})
			.then((result) => {
				if (result.value) {
					this.service
						.remove_device(this.client_id, id)
						.subscribe((data: DbResponse) => {
							if (data) {
								if (data.ok) {
									Swal.fire('Deleted!', 'Device has been deleted.', 'success');
									this.service
										.clientDevices(this.client_id)
										.subscribe((res: any) => {
											this.dataSource = new MatTableDataSource(res);
											this.dataSource.paginator = this.paginator;
										});
								} else {
									Swal.fire('Oopsss...', 'Something went worng', 'error');
								}
							}
						});
				}
			});
	}

	singleDeploy(id: number) {
		this.service.singleDeploy(this.client_id, id).subscribe((data: any) => {
			if (data) {
				if (data.ok) {
					Swal.fire({
						type: 'success',
						title: 'Success',
						text: 'Device deployed',
						allowOutsideClick: false,
						footer:
							'<a style="font-size:15px;" href="' +
							data.link +
							'" target="_blank" download="' +
							data.name +
							'">Download ' +
							data.name +
							'</a>',
					});
				}
			}
		});
	}

	deployAll() {
		this.service.deployAll(this.client_id).subscribe((data: any) => {
			if (data) {
				var footer = '<div>';
				for (let res of data) {
					footer +=
						'<a style="font-size:15px;" href="' +
						res.link +
						'" target="_blank" download="' +
						res.name +
						'">Download ' +
						res.name +
						'</a><br>';
				}
				Swal.fire({
					type: 'success',
					title: 'Success',
					text: 'Device deployed',
					allowOutsideClick: false,
					footer: footer + '</div>',
				});
			}
		});
	}

	editExtName(id: number, name: string) {
		Swal.fire({
			title: 'Edit extension name',
			input: 'text',
			inputValue: name,
			cancelButtonColor: '#EC4849',
			reverseButtons: true,
			confirmButtonColor: '#45CE30',
			showCancelButton: true,
			confirmButtonText: 'SAVE',
			cancelButtonText: 'CANCEL',
			allowOutsideClick: false,
			focusCancel: true,
		}).then((result: any) => {
			if (result.value) {
				this.service
					.editExtName(this.client_id, id, result.value)
					.subscribe((data: any) => {
						if (data) {
							if (data.ok) {
								Swal.fire(
									'Edited!',
									'Extension name has been edited.',
									'success'
								);
								this.service
									.clientDevices(this.client_id)
									.subscribe((res: any) => {
										this.dataSource = new MatTableDataSource(res);
										this.dataSource.paginator = this.paginator;
									});
							} else {
								Swal.fire('Oopsss...', 'Something went worng', 'error');
							}
						}
					});
			}
		});
	}

	editMac(id: number, name: string) {
		Swal.fire({
			title: 'Edit mac address',
			input: 'text',
			inputValue: name,
			cancelButtonColor: '#EC4849',
			reverseButtons: true,
			confirmButtonColor: '#45CE30',
			showCancelButton: true,
			confirmButtonText: 'SAVE',
			cancelButtonText: 'CANCEL',
			allowOutsideClick: false,
			focusCancel: true,
			preConfirm: (mac) => {
				const control = new FormControl(mac);
				const validationMessageElement = document.querySelector(
					'.swal2-validation-message'
				);
				const validationResult =
					MacAddressValidator.customPatternValidator(control);
				if (validationResult && validationResult.invalidPattern) {
					if (validationMessageElement) {
						validationMessageElement.classList.add('custom-validation-class');
					}
					Swal.showValidationMessage(
						`Hardware MAC ID Must be in any one of the Following Format <strong>C0:74:ad:13:e2:51 , c0-74-Ad-13-e2-51 , c074Ad13e251</strong>,Field should contain <strong>HexaDecimal Characters</strong> only. Maximum and Minimum length is<strong>12</strong> HexaDecimal Characters.`
					);
				} else {
					validationMessageElement.classList.remove('custom-validation-class');
				}
			},
		}).then((result: any) => {
			if (result.value) {
				var mac = result.value.replace(/:|\s|'|"|;|-|\./g, '').toLowerCase();
				this.service
					.checkDuplicateMac(id, [mac.toString()])
					.subscribe((data: any) => {
						if (!data.duplicate) {
							this.service
								.editMac(this.client_id, id, mac)
								.subscribe((data: any) => {
									if (data) {
										if (data.ok) {
											Swal.fire(
												'Edited!',
												'Mac address has been edited.',
												'success'
											);
											this.service
												.clientDevices(this.client_id)
												.subscribe((res: any) => {
													this.dataSource = new MatTableDataSource(res);
													this.dataSource.paginator = this.paginator;
												});
										} else {
											Swal.fire('Oopsss...', 'Something went worng', 'error');
										}
									}
								});
						} else {
							return Swal.fire(
								'Oopsss...',
								`MAC address ${data.macAddr} have already exists for client ${data.client}`,
								'error'
							);
						}
					});
			}
		});
	}

	applyFilter(filterValue: string) {
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

	clone(id: string) {
		this.alert
			.confirmClone({
				title: 'Are you sure?',
				text: 'You want to clone this device?',
			})
			.then((result) => {
				if (result.value) {
				}
			});
	}
  
	formateMacAddress(val) {
		// If the MAC address length is odd, don't replace the last character
		if (val.length % 2 === 1) {
			return val.toUpperCase().replace(/(..)/g, '$1-');
		} else {
			return val.toUpperCase().replace(/(..)/g, '$1-').slice(0, -1);
		}
	}
}
