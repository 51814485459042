import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API} from './constant';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class VendorService {
  private primaryContact: number = 0;
  totalTransaction: any = 0;
  public updateVendorEventListener = new BehaviorSubject(0);

  constructor(private http: HttpClient) {
  }

  viewVendorsOnMap() {
    return this.http.get(API.VENDORS_ON_MAP);
  }

  vendorDetails(id) {
    return this.http.get(API.VENDOR_DETAILS + id);
  }

  addVendor(data): Observable<any> {
    return this.http.post(API.ADD_VENDOR, data);
  }

  editVendor(id, data) {
    return this.http.post(API.EDIT_VENDOR + id, data);
  }

  vendorList(filter?): Observable<any> {
    return this.http.get(API.VENDOR_LIST + '?filter=' + JSON.stringify(filter));
  }

  deleteVendor(vendorId) {
    return this.http.delete(API.VENDOR_DELETE + vendorId);
  }

  vendorOrderdetails(vendorId) {
    return this.http.get(API.VENDOR_ORDER_DETAILS + vendorId);
  }

  uploadFile(fileObject) {
    return this.http.post(API.ATTACHMENTS_UPLOAD, fileObject);
  }

  importVendor(importData): Observable<any> {
    return this.http.post(API.IMPORT_VENDORS, importData);
  }

  vendorContactList(id, filter): Observable<any> {
    return this.http.get(API.VENDOR_CONTACTS + id + '?filter=' + JSON.stringify(filter));
  }

  vendorPurchaseOrderList(id, filter): Observable<any> {
    return this.http.get(API.VENDOR_PURCHASE_ORDER + id + '?filter=' + JSON.stringify(filter));
  }
  vendorPurchaseOrderCount(id): Observable<any> {
    return this.http.get(API.VENDOR_PURCHASE_ORDER + id);
  }

  vendorBillList(id, filter): Observable<any> {
    return this.http.get(API.VENDOR_BILL + id + '?filter=' + JSON.stringify(filter));
  }
  vendorBillListCount(id): Observable<any> {
    return this.http.get(API.VENDOR_BILL + id);
  }

  vendorDropdown() {
    return this.http.get(API.VENDOR_DROPDOWN_LIST);
  }

  vendorgetCount(id) {
    return this.http.get(API.VENDOR_COUNTS + id);
  }

  stateList(countryId, filter): Observable<any> {
    if (countryId != '') {
      return this.http.get(API.STATE_FILTER_LIST + '?id=' + countryId + '&filter=' + JSON.stringify(filter));
    } else {
      return this.http.get(API.STATE_FILTER_LIST + '?filter=' + JSON.stringify(filter));
    }
  }

  countryList(countryId) {
    if (countryId !== '') {
      return this.http.get(API.COUNTRY_DROPDOWN_LIST + '?id=' + countryId);
    } else {
      return this.http.get(API.COUNTRY_DROPDOWN_LIST);
    }
  }

  refundList(id, filter): Observable<any> {
    return this.http.get(API.VENDOR_REFUND + id + '?filter=' + JSON.stringify(filter));
  }

  refundListCount(id): Observable<any> {
    return this.http.get(API.VENDOR_REFUND + id);
  }

  purchaseOrderByVendor(vendorId) {
    return this.http.get(API.VENDOR_PURCHASEORDER + vendorId);
  }
  addrefund(data) {
    return this.http.post(API.ADD_VENDOR_REFUND, data);
  }
  agreementList(id, filter): Observable<any> {
    return this.http.get(API.VENDOR_AGREEMENT + id + '?filter=' + JSON.stringify(filter));
  }

  agreementListCount(id): Observable<any> {
    return this.http.get(API.VENDOR_AGREEMENT + id);
  }

  makePrimary(id: number, data) {
    return this.http.post(API.PRIMARY_CONTACT_UPDATE + id, data);
  }

  addContact(data) {
    return this.http.post(API.ADD_VENDOR_CONTACT, data);
  }

  contactDetails(accountId) {
    return this.http.get(API.ACCOUNT_DETAILS + accountId);
  }

  editContact(id, data) {
    return this.http.post(API.UPDATE_CONTACT + id, data);
  }

  deleteContact(contactId) {
    return this.http.delete(API.CONTACT_DELETE + contactId);
  }
  getAccountList(accountTypeId) {
    return this.http.get(API.ACCOUNT_TYPE + '?accountType=' + accountTypeId);
  }

  public setPrimaryContact(primaryContact) {
    this.primaryContact = primaryContact;
  }

  public getPrimaryContact() {
    return this.primaryContact;
  }
  deleteRefund(vendorId, refundId) {
    return this.http.delete(API.DELETE_REFUND_BY_VENDORID + vendorId + '/' + refundId);
  }
  vendorContactCount(vendor_id) {
    return this.http.get(API.VENDOR_CONTACTS + vendor_id);
  }
 }

