import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserComponent } from './user.component'
const routes: Routes = [
  {
    path: 'user',
    // component: UserComponent,
    redirectTo: '/user/dashboard', pathMatch: 'full',
  },
  {path: '', redirectTo: '/user/dashboard', pathMatch: 'full'}
];

// @NgModule({
//   imports: [RouterModule.forChild([
//     {
//         path: 'user',
//         component: UserComponent,
//     }
//   ]),
// ],
//   exports: [RouterModule]
// })


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
