import { Injectable } from "@angular/core";
import { API } from "./constant";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import "rxjs/add/operator/map";
@Injectable({
  providedIn: "root"
})
export class ProjectService {
  update: boolean;
  projectId: number;
  public updateProjectEventListener = new BehaviorSubject(0);
  constructor(private http: HttpClient) { }
  projectListByClientId(id) {
    return this.http.get(API.PROJECTS_DROPDOWN_BY_CLIENTID + "?clientId=" + id);
  }

  projectDropdown() {
    return this.http.get(API.PROJECT_DROPDOWN_LIST);
  }

  pipelineList() {
    let httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    };
    return this.http.get(API.PROJECT_PIPELINE_LIST);
  }

  stagesByPipelineId(pipelineId) {
    let httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    };
    return this.http.get(API.PROJECT_STAGE_LIST_BY_PIPE + pipelineId);
  }

  projectByPiplineList(pipelineId, filter?) {
    if (filter) {
      let httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        })
      };
      filter = JSON.stringify(filter);
      let params = new HttpParams();
      params = params.append("filter", filter);
      return this.http.get(API.PIPELINE_PROJECTS_LIST + pipelineId, {
        params: params
      });
    } else {
      return this.http.get(API.PIPELINE_PROJECTS_LIST + pipelineId);
    }
  }

  employesDDList() {
    return this.http.get(API.EMP_DD_LIST);
  }

  projectCategoryList() {
    return this.http.get(API.PROJECT_CATEGORY_DD_LIST);
  }

  projectList(type, filter) {
    return this.http.get(
      API.PROJECT_LIST + "?type=" + type + "&filter=" + JSON.stringify(filter)
    );
  }

  projectDelete(id) {
    return this.http.delete(API.PROJECT_DELETE + id);
  }

  clientList() {
    let httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    };
    return this.http.get(API.CLIENT_PARENT_DROPDOWN);
  }

  clientContacts(clientId) {
    let httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    };
    let params = new HttpParams();
    params = params.append("moduleId", clientId);
    params = params.append("type", "client");
    return this.http.get(API.CLIENT_CONTACT_LIST, { params: params });
  }

  clientLocationsList(clientId) {
    let httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    };
    return this.http.get(API.TICKET_CLIENT_LOCATION_DD_LIST + clientId);
  }

  departmentListByParent(id) {
    return this.http.get(API.PROJECT_DEPARTMENT_ID + id);
  }

  createProject(projectName: string, clientId: number, deptId: number, progress: number, startDate: string, endDate: string, projectCost: number, demoUrl: string, description: string, hourlyRate: number, projectStateReasonId: number, address: string, pointOfContact: number, owner: any = {}, projectCategoryId: number, manager: any = {}, fixedRate: string, withTasks: string) {

    let body: any = {};
    if (typeof projectName !== 'undefined' && projectName !== null) body.projectName = projectName;
    if (typeof clientId !== 'undefined' && clientId !== null) body.clientId = clientId;
    if (typeof deptId !== 'undefined' && deptId !== null) body.deptId = deptId;
    if (typeof progress !== 'undefined' && progress !== null) body.progress = progress;
    if (typeof startDate !== 'undefined' && startDate !== null) body.startDate = startDate;
    if (typeof endDate !== 'undefined' && endDate !== null) body.endDate = endDate;
    if (typeof projectCost !== 'undefined' && projectCost !== null) body.projectCost = projectCost;
    if (typeof demoUrl !== 'undefined' && demoUrl !== null) body.demoUrl = demoUrl;
    if (typeof description !== 'undefined' && description !== null) body.description = description;
    if (typeof hourlyRate !== 'undefined' && hourlyRate !== null) body.hourlyRate = hourlyRate;
    if (typeof projectStateReasonId !== 'undefined' && projectStateReasonId !== null) body.projectStateReasonId = projectStateReasonId;
    if (typeof address !== 'undefined' && address !== null) body.address = address;
    if (typeof pointOfContact !== 'undefined' && pointOfContact !== null) body.pointOfContact = pointOfContact;
    if (typeof owner !== 'undefined' && owner !== null) body.owner = owner;
    if (typeof projectCategoryId !== 'undefined' && projectCategoryId !== null) body.projectCategoryId = projectCategoryId;
    if (typeof manager !== 'undefined' && manager !== null) body.manager = manager;
    if (typeof fixedRate !== 'undefined' && fixedRate !== null) body.fixedRate = fixedRate;
    if (typeof withTasks !== 'undefined' && withTasks !== null) body.withTasks = withTasks;
    return this.http.post(API.CREATE_PROJECT, body);
  }

  getClientInfo(clientId) {
    return this.http.get(API.CLIENT_DETAILS + clientId);
  }

  getProjectById(projectId) {
    return this.http.get(API.PROJECT_DETAILS + projectId);
  }

  setShareValue(update, projectId?) {
    this.update = update;
    this.projectId = projectId;
  }
}