import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource} from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';

export interface OutboundRoutes {
  name: string;
  outboundcid: number;
}

const ELEMENT_DATA: OutboundRoutes[] = [
  {name:'Kazoo', outboundcid:469620888},
  {name:"International Calling Kiani's cell" , outboundcid:469620888},
  {name:'Loopback', outboundcid:469620888},
  {name:'Force Call Through VI', outboundcid:469620888},
  {name:'SetCIDAVS', outboundcid:469620888},
];

@Component({
  selector: 'app-outbound-routes-list',
  templateUrl: './outbound-routes-list.component.html',
  styleUrls: ['./outbound-routes-list.component.scss']
})
export class OutboundRoutesListComponent implements OnInit {
  displayedColumns: string[] = ['name','outboundcid','attributes','action'];
  dataSource = new MatTableDataSource<OutboundRoutes>(ELEMENT_DATA);
    
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
  }

}
