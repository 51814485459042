import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreRoutingModule } from './core-routing.module';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { RegisterComponent } from './register/register.component';
import {SharedModule, HttpLoaderFactory} from '../admin/shared/shared.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../material-module';
import { LockscreenComponent } from './lockscreen/lockscreen.component';
import { WizardComponent } from './wizard/wizard.component';
import { Select2Module } from "ng-select2-component";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MenuComponent } from './menu/menu.component';
import { MenudetailsComponent } from './menu/menudetails/menudetails.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { DialogComponent } from './subscription/dialog/dialog.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

@NgModule({
  imports: [
    CommonModule,
    CoreRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    Select2Module,
    GooglePlaceModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  declarations: [LoginComponent, ForgotPasswordComponent, RegisterComponent, LockscreenComponent, WizardComponent, SubscriptionComponent, DialogComponent,MenuComponent, MenudetailsComponent, ThankYouComponent, PageNotFoundComponent],
  entryComponents: [DialogComponent]
})
export class CoreModule { }
