import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { AgmCoreModule } from "@agm/core";
import { MaterialModule } from "../../material-module";
import { SubHeaderComponent } from "../layouts/full/sub-header/sub-header.component";
import { Select2Module } from "ng-select2-component";
import { DialPadComponent } from './dial-pad/dial-pad.component';


@NgModule({
  declarations: [
    SubHeaderComponent,
    DialPadComponent,
    
  ],
  exports: [
    SubHeaderComponent,
    DialPadComponent,
  ],
  providers: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    Select2Module,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyDTPYGvOGHQ0Af2Q-tmziSwZT8JbL0C7AM"
    })
  ],
  entryComponents: [DialPadComponent]
})
export class SharedModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
