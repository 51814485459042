import {ChartOfAccounts} from '../models/ChartOfAccounts';
import {Vendor} from '../models/Vendor';
import {TaxRate} from '../models/TaxRate';
import {Select2Option} from 'ng-select2-component';
import {ClientModuleCount} from '../models/ClientModuleCount';

export interface IResponse {
  data: any; // 200
  error: any; // "OK",
  info: IResStatus; // keep it any
}

export interface IResponseList {
  data: IResList; // 200
  error: any; // "OK",
  info: IResStatus; // keep it any
}

export interface IResList {
  list: any;
  totalCount: any;
  responseMsg: any;
  totalAmount: any;
  paidAmount: any;
  totalTransaction: any;
  details: any;
  total: any;
  allCheckInfoStatus: any;
  allCheckInfo: any;
}

export interface IResStatus {
  code: EStatusCode;
  status: string;
}

export enum EStatusCode {
  'OK' = 200,
  'CREATED' = 201,
  'CONFLICT' = 409,
  'INVALID_DATA' = 422,
  'LOCKED' = 423,
  'FAILURE' = 404,
  'INTERNAL_SERVER_ERROR' = 500
}


export interface NavItem {
  label: string;
  disabled?: boolean;
  icon: string;
  link?: string;
  items?: NavItem[];
  parent?: number;
}


export interface ThemeColorInterface {
  id: string;
  value: string;
}

export interface IIncomeAccount {
  data: IIncomeResponse; // 200
  error: any; // "OK",
  info: IResStatus; // keep it any
}

export interface IIncomeResponse {
  list: ChartOfAccounts[];
  responseMsg: any;
}

export interface IVendor {
  data: IVendorResponse; // 200
  error: any; // "OK",
  info: IResStatus; // keep it any
}

export interface IVendorResponse {
  list: Vendor[];
  responseMsg: any;
}

export interface ITaxRate {
  data: ITaxRateResponse; // 200
  error: any; // "OK",
  info: IResStatus; // keep it any
}

export interface ITaxRateResponse {
  list: TaxRate[];
  responseMsg: any;
}


export interface IExpenseGoodsAccount {
  data: IExpenseGoodsResponse; // 200
  error: any; // "OK",
  info: IResStatus; // keep it any
}

export interface IExpenseGoodsResponse {
  expense_account_coa: ChartOfAccounts[];
  cost_of_goods_coa: ChartOfAccounts[];
  responseMsg: any;
}

export interface ICountrySelect extends Select2Option {
  countryId: number;
}


export interface ICountrySelect2Option extends Select2Option {
  countryId: number;
}

export interface DialogData {
  refNo: any;
  amount: any;
  dueDate: any;
  currency: any;
  invoicesLink: any;
  invoicesId: any;
  name: string;
  eGroup: string;
  mailTo: string;
  mailCc: string;
  subject: string;
  title: string;
  cloneUrl: string;
  clientId: string;
  msg: string;
  check: boolean;
  credit: boolean;
  creditCard: boolean;
  account: number;
  paymentInfo: any;
  type: string;
}

export interface AlertCommonDialogData {
  title: string;
  message: string;
  cancelButtonText: string;
  submitButtonText: string;
}

export interface ClientDeleteAlertData {
  data: ClientModuleCount;
  clientName: string;
}


export interface LoggedUserInitialDetails {
  config: any[];
  userDetails: any;
  menuLinkList: string[];
}
