import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {API, StorageKey} from './constant';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ItemsService {

  public updateItemEventListener = new BehaviorSubject(0);

  constructor(private http: HttpClient) { }

  listIncomeAccount(){
    return this.http.get(API.INCOME_ACCOUNT_LIST);
  }

  listTaxRates(){
    return this.http.get(API.TAX_RATES_LIST);
  }

  expenseGoodsAccountsList(){
    return this.http.get(API.EXPENSE_GOODS_ACCOUNT_LIST);
  }

  addItems(data): Observable<any>{
    return this.http.post(API.ADD_ITEM, data);
  }

  itemList(filter): Observable<any> {
    if (filter !== '') {
      return this.http.get(API.ITEM_LIST + '?filter=' + JSON.stringify(filter));
    } else {
      return this.http.get(API.ITEM_LIST + '?');
    }
  }
  getDefaultItemsList(): Observable<any> {
    return this.http.get(API.DEFAULT_ITEM_LIST);
  }

  deleteIems(savedItemsId) {
    return this.http.delete(API.ITEM_DELETE + savedItemsId);
  }

  itemDetails(itemId) {
    return this.http.get(API.ITEM_DETAILS + itemId);
  }

  editItems(itemId, data) {
      return this.http.post(API.EDIT_ITEM + itemId, data);
  }

  uploadFile(fileObject) {
      return this.http.post(API.ATTACHMENTS_UPLOAD, fileObject);
  }

  importItem(importData): Observable<any> {
    return this.http.post(API.IMPORT_ITEMS, importData);
  }




}
