import { LoginService } from './../../service/login.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import * as toastr from 'toastr';

@Component({
  selector: 'app-forgot-password',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  submitted = false;
  public data: any;
  isDisabled: boolean = false;
  show: boolean = false;
  constructor(private fb: FormBuilder, private loginService: LoginService) { }

  ngOnInit() {
    this.forgotPasswordForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]]
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.forgotPasswordForm.invalid) {
      return this.forgotPasswordForm.controls;
    }

    this.data = {
      email: this.forgotPasswordForm.value.email
    };

    this.loginService.resetPassword(this.data).subscribe(
      (res: any) => {
        toastr.options.positionClass = "toast-bottom-right";
        if (res.info.code == 200) {
          toastr.success(res.data.responseMsg);
        } else {
          toastr.error(res.data.responseMsg);
        }
      }); 
    }
    
    checkEmail(email) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(email)) {
        this.loginService.userEmailExists(email).subscribe((res: any) => {
        if (res.data.emailExists) {
          this.isDisabled = false;
          this.show = false;
        } else {
          this.show = true;
          this.isDisabled = true;
        }
      });
    }
  }

  get f() {
    return this.forgotPasswordForm.controls;
  }

}
