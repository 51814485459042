import { NgModule } from '@angular/core';
import {AdminComponent} from '../admin.component';
import { Routes,RouterModule } from '@angular/router'
//import { UsersComponent } from './users/users.component';
//import { ClientlistComponent } from './clientlist/clientlist.component';
import { ClientComponent} from './client.component';

const routes: Routes=[
    {
    path: 'admin',
    component: AdminComponent,
    data: {
      title: 'client',
      status: false
    },
    children: [
      {
        path: 'client',
        component: ClientComponent
      }
    ]
    }
];

@NgModule({
    imports: [ RouterModule.forChild(routes)],
    exports: [ RouterModule ] 

})

export class ClientRoutingModule {}
//export const routingComponents =[UsersComponent]