import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import * as toastr from "toastr";
@Component({
  selector: 'app-voicemail',
  templateUrl: './voicemail.component.html',
  styleUrls: ['./voicemail.component.scss']
})
export class VoicemailComponent implements OnInit {
  voicemailForm: FormGroup;
  submitted = false;

  public activeemail : boolean = true;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.voicemailForm = this.formBuilder.group({
      voicemailPin: [''],
      email: [''],

    });
  }

  get f() { return this.voicemailForm.controls; }

  onSubmit() {
      this.submitted = true;

      if (this.voicemailForm.invalid) {
        toastr.error("Invalid Details !");
        return;
      }
      console.log(this.voicemailForm.value);

      toastr.success("Form Submitted Successfully !");
      this.voicemailForm.reset();
  }

  handleChange(event){
    if(event== true){
      this.voicemailForm.controls['email'].enable()
    }
    else{
      this.voicemailForm.controls['email'].disable()
    }
  }

}
