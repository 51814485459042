import { Component, OnInit, ViewChild } from '@angular/core';
import { RestService } from '../../../rest.service';
import { ActivatedRoute, Router } from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { Paginator } from 'primeng/primeng';

export interface PeriodicElement {
  clientName: string;
  noOfExtension: number;
  startOfExtension: number;
  createdOn: string;
  status: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {clientName: 'Zanne Holmes Design', noOfExtension:9, startOfExtension:2010, createdOn: '2018-10-09 16:38:25', status:'active'},
  {clientName: '1807 Levee LLC', noOfExtension:2, startOfExtension:1400, createdOn: '2018-03-02 08:59:34', status:'active'},
  {clientName: 'Abbey Eatery & Ales', noOfExtension:4, startOfExtension:1200, createdOn: '2018-10-31 09:36:21', status:'deactivated'},
  {clientName: 'Ability Connections', noOfExtension:13, startOfExtension:2200, createdOn: '2018-01-25 09:47:00', status:'active'},
  {clientName: 'Zanne Holmes Design', noOfExtension:9, startOfExtension:2010, createdOn: '2018-10-09 16:38:25', status:'active'},
  {clientName: '1807 Levee LLC', noOfExtension:2, startOfExtension:1400, createdOn: '2018-03-02 08:59:34', status:'active'},
  {clientName: 'Abbey Eatery & Ales', noOfExtension:4, startOfExtension:1200, createdOn: '2018-10-31 09:36:21', status:'deactivated'},
  {clientName: 'Ability Connections', noOfExtension:13, startOfExtension:2200, createdOn: '2018-01-25 09:47:00', status:'active'},
];

@Component({
  selector: 'app-clientlist',
  templateUrl: './clientlist.component.html',
  styleUrls: ['./clientlist.component.scss'],
  

})
export class ClientlistComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
 

  displayedColumns: string[] = ['clientName','noOfExtension','startOfExtension','createdOn','status','action'];
  data:any = [];
  // listdata: MatTableDataSource<any>;
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  
  
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  constructor(public rest:RestService, private route: ActivatedRoute, private router: Router, private _formBuilder: FormBuilder) { }

  ngOnInit() {
    //this.sendPutRequest();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  GoToClient(){
    this.router.navigateByUrl('/client')

  }
    
  sendPutRequest() {
    this.data = [];
    // this.rest.sendPutRequest().subscribe((data: {}) => {
    //   console.log(data);
    //   this.data = data['data'];
    //   this.dataSource = new MatTableDataSource(data['data'])
    //   this.dataSource.sort = this.sort;
    //   this.dataSource.paginator = this.paginator;
    // });
  }
  

}
