import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {AlertCommonDialogData} from '../../../service/Utils/Interfaces.class';

@Component({
  selector: 'app-common-dialog-model',
  templateUrl: './common-dialog-model.component.html',
  styleUrls: ['./common-dialog-model.component.css']
})
export class CommonDialogModelComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CommonDialogModelComponent>,
              @Inject(MAT_DIALOG_DATA) public alertCommonDialogData: AlertCommonDialogData) {

  }

  ngOnInit() {

  }

  cancelButton() {
    this.dialogRef.close(false);
  }


  submitButton() {
    this.dialogRef.close(true);
  }


}
