import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {API, StorageKey} from './constant';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {RequestOptions} from "@angular/http";
import { catchError, map } from 'rxjs/operators';
import "rxjs/add/operator/map";
import { forkJoin } from "rxjs";



@Injectable({
  providedIn: 'root'
})
export class SettingsService {



  constructor(private http: HttpClient) { }

  responseDataForSystemDropdown() {
    let defaultLanguageList = this.http.get(API.DEFAULT_LANGUAGE);
    let defaultLocationList = this.http.get(API.DEFAULT_LOCALE);
    let defaultCurrencyList = this.http.get(API.DEFAULT_CURRENCY);
    let defaultAccountList =  this.http.get(API.DEFAULT_ACCOUNTS);


    return forkJoin([
      defaultLanguageList,
      defaultLocationList,
      defaultCurrencyList,
      defaultAccountList
    ]);
  }


  updateCompanyDetails(companyData) {
    return this.http.post(API.UPDATE_COMPANY, companyData);
  }

  getWorkingDays() {
    return this.http.get(API.DEFAULT_WORKING_DAYS);
  }


  defaultTaxList(data) {
    return this.http.get(API.TAX_RATES_LIST + "?taxRateType =" + data );
  }

  updateSystemSetting(systemData) {
    return this.http.post(API.UPDATE_SYSTEM , systemData);
  }

  paymentSettingList() {
    return this.http.get(API.PAYMENT_SETTINGS);
  }

  updatePaymentSetting(paymentData) {
    return this.http.post(API.UPDATE_PAYMENTS_SETTING , paymentData);
  }

  paymentMethodList() {
    return this.http.get(API.PAYMENT_METHOD_LIST);
  }

  deletePaymentMethod(paymentMethodId) {
    return this.http.delete(API.PAYMENT_METHOD_DELETE + paymentMethodId);
  }

  addPaymentMethod(paymentMethodData) {
    return this.http.post(API.PAYMENT_METHOD_ADD , paymentMethodData);
  }

  updatePaymentMethod(id, data) {
    return this.http.post(API.PAYMENT_METHOD_UPDATE + id, data);
  }

  paymentMethodsById(paymentMethodId) {
    return this.http.get(API.PAYMENT_METHOD_BY_ID + paymentMethodId);
  }

  /*leads settings tab methods starts here*/
  leadSourceList() {
    return this.http.get(API.LEAD_SOURCE_LIST);
  }

  leadStatusList() {
    return this.http.get(API.LEAD_STATUS_LIST);
  }

  updateLeadSetting(leadsData) {
    return this.http.post(API.UPDATE_LEAD_SETTING, leadsData);
  }

  deleteLeadStatus(leadStatusId) {
    return this.http.delete(API.LEAD_STATUS_DELETE + leadStatusId);
  }

  addLeadStatus(leadsData) {
    return this.http.post(API.LEAD_STATUS_ADD , leadsData);
  }

  leadStatusById(id) {
    return this.http.get(API.LEAD_STATUS_BY_ID + id);
  }

  updateLeadStatus(id, data) {
    return this.http.post(API.LEAD_STATUS_UPDATE + id, data);
  }

  deleteLeadSource(leadSourceId) {
    return this.http.delete(API.LEAD_SOURCE_DELETE + leadSourceId);
  }

  addLeadSource(leadSource) {
    return this.http.post(API.LEAD_SOURCE_ADD , leadSource);
  }

  leadSourceById(sourceId) {
    return this.http.get(API.LEAD_SOURCE_BY_ID + sourceId);
  }

  updateLeadSource(sourceId, data) {
    return this.http.post(API.LEAD_SOURCE_UPDATE + sourceId, data);
  }

  responseDataForLeadSettingTab() {
    let leadSourceList = this.http.get(API.LEAD_SOURCE_LIST);
    let leadStatusList = this.http.get(API.LEAD_STATUS_LIST);

    return forkJoin([
      leadSourceList,
      leadStatusList,
    ]);
  }


  updateEstimateSettings(myEstimateObject) {
    return this.http.post(API.ESTIMATE_SETTINGS_DETAILS , myEstimateObject);
  }

  updatePOSettings(myPOObject) {
    return this.http.post(API.PURCHASEORDER_SETTINGS_DETAILS , myPOObject);
  }

  updateInvoiceSettings(myInvoiceObject) {
    return this.http.post(API.INVOICE_SETTINGS_DETAILS , myInvoiceObject);
  }

  uploadImageFiles(myFileName) {
    return this.http.post(API.ATTACHMENTS_UPLOAD , myFileName);
  }

  getProposalTemplateList() {
    return this.http.get(API.PROPOSAL_TEMPLATE_LIST);
  }

  getProposalTemplateById(data) {
    return this.http.get(API.PROPOSAL_TEMPLATE_LIST_BY_ID + '/' + data);
  }

  updateProposalTemplate(data) {
    return this.http.post(API.UPDATE_PROPOSAL_TEMPLATE, data);
  }

  createNewProposalTemplate(data) {
    return this.http.post(API.CREATE_NEW_PROPOSAL_TEMPLATE, data);
  }

  deleteTemplate(data) {
    return this.http.post(API.DELETE_PROPOSAL_TEMPLATE, data);
  }



  /*ticket settings tab methods starts here*/
  priorityDropdown() {
    return this.http.get(API.TICKETS_PRIORITY_LIST);
  }

  addTicketCategory(data) {
    return this.http.post(API.TICKETS_PRIORITY_ADD, data);
  }

}
