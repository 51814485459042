import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator, MatTableDataSource, MatSort} from '@angular/material';


export interface CallQueues {
  queue: String;
  description: String;
}

const ELEMENT_DATA: CallQueues[] = [
  {queue:'253', description:'OrthoAllenQ'},
  {queue:'353', description:'FM-Q'},
  {queue:'453', description:'FriscoQ'},
  {queue:'556', description:'Plano-Q'},
  {queue:'653', description:'DallasQ'}
];

@Component({
  selector: 'app-callqueuelist',
  templateUrl: './callqueuelist.component.html',
  styleUrls: ['./callqueuelist.component.scss']
})
export class CallqueuelistComponent implements OnInit {
  displayedColumns: string[] = ['queue','description','action'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  constructor() { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  hello(id){
    let a = id
    console.log(a)
   }

}
