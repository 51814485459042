import { NgModule} from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import { IvrRoutingModule } from './ivr-routing.module';
import { RestService } from '../../../rest.service';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';
import { MatInputModule, MatButtonModule, MatAutocompleteModule, MatIconModule,MatTabsModule,MatTableModule, MatFormFieldModule, MatSelectModule, MatSlideToggleModule, MatCardModule, MatPaginatorModule,MatSortModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import { IvrComponent } from "./ivr.component";
import { IvrlistComponent } from "./ivrlist/ivrlist.component";
import { AddivrComponent } from "./addivr/addivr.component";

@NgModule({
    declarations: [
        IvrComponent,
        IvrlistComponent,
        AddivrComponent
    ],
    imports: [
        BrowserModule,
        IvrRoutingModule,
        MatTableModule,
        MatTabsModule,
        MatInputModule,
        MatButtonModule,
        MatAutocompleteModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatIconModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatCardModule,
        MatPaginatorModule,
        FormsModule,
        MatSortModule,
        GooglePlaceModule,
        TranslateModule.forRoot()

        
    ],
    providers: [RestService],
    bootstrap: [IvrComponent],
    exports:[IvrlistComponent],
})

export class ivrModule { }