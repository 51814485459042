import { Component, OnInit,ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatSort} from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';


export interface UserList {
  users: string;
  emailid: string;
}

const ELEMENT_DATA: UserList[] = [
  {users:'Kartik Shetty',emailid:'kartik@vivantcorp.com'},
  {users:'Snehal Bora',emailid:'snehal@vivantcorp.com'},
  {users:'Gaurav Badgujar',emailid:'gaurav@vivantcorp.com'},
  {users:'Sagar Madgaonkar',emailid:'sagar@vivantcorp.com'},
  {users:'Mandar Jadhav',emailid:'mandar@vivantcorp.com'},
  {users:'Anupriya Srivastava',emailid:'anupriya@vivantcorp.com'},
  {users:'Devashree Hingne',emailid:'devashree@vivantcorp.com'},
];

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  displayedColumns: string[] = ['users','emailid','action'];
  dataSource = new MatTableDataSource<UserList>(ELEMENT_DATA);

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

}
