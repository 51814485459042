import { NgModule} from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import {TranslateModule} from '@ngx-translate/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { FlatpickrModule } from 'angularx-flatpickr';
import { MatStepperModule, MatInputModule, MatButtonModule, MatAutocompleteModule, MatIconModule,MatTabsModule,MatTableModule, MatFormFieldModule, MatSelectModule, MatSlideToggleModule, MatCardModule, MatPaginatorModule,MatSortModule,MatCheckboxModule } from '@angular/material';
import { TimegroupsComponent } from './timegroups.component';
import { GrouplistComponent } from './grouplist/grouplist.component';
import { AddtimegroupComponent } from './addtimegroup/addtimegroup.component';
import { HolidayscheduleComponent } from './holidayschedule/holidayschedule.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import 'flatpickr/dist/flatpickr.css';
import { Select2Module } from "ng-select2-component";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { TimePickerModule } from '@progress/kendo-angular-dateinputs';
import { CommonComponentModule } from "../../../common/commonComponent.module";

@NgModule({
    declarations: [
        TimegroupsComponent,
        GrouplistComponent,
        AddtimegroupComponent,
        HolidayscheduleComponent,
    ],

    imports: [
    BrowserModule,
        TranslateModule.forRoot(),
        FormsModule,
        MatStepperModule,
        MatInputModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatIconModule,
        MatTabsModule,
        MatTableModule,
        MatFormFieldModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatCardModule,
        MatPaginatorModule,
        MatSortModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        Select2Module,
        NgbModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        TimePickerModule,
        FlatpickrModule.forRoot(),
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        CommonComponentModule
    ],
    providers: [],
    bootstrap: [ TimegroupsComponent ],
    exports:[ AddtimegroupComponent],
})

export class TimegroupsModule { }