import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { API } from "./constant";
import {BehaviorSubject, Observable, Subject} from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  constructor(
    private http: HttpClient
  ) { }
  update:boolean;
  id:number;
  accountsDetailsId:number;
  showFormUpdate:boolean;
  public updateEventListener = new BehaviorSubject(0);
  list(filter?){
    return this.http.get(API.EMPLOYEE_LIST+'?filter='+JSON.stringify(filter));
  }
  rowCount(filter?){
    return this.http.get(API.EMPLOYEE_ROWCOUNT+'?filter='+JSON.stringify(filter));
  }
  delete(userId){
    return this.http.delete(API.EMPLOYEE_DELETE+userId);
  }
  status(status,userId){
    return this.http.post(API.EMPLOYEE_STATUS+status+'/'+userId,{});
  }
  ban(data){
    return this.http.post(API.EMPLOYEE_BAN,data);
  }

  activeDeactiveUserCount(){
    return this.http.get(API.EMPLOYEE_ACTIVE_DEACTIVE_ROWCOUNT);
  }

  add(fullname:string,employmentId:string,email:string,password:string,pbxId:any={},company:string, locale:string,language:string,phone:any={},mobile:any={},skypeId:any={},departmentId:number,userType:number,
    customRoleId:any){
        let body:any={};
        if (typeof fullname !== 'undefined' && fullname !== null) body.fullname = fullname;
        if (typeof employmentId !== 'undefined' && employmentId !== null) body.employmentId = employmentId;
        if (typeof email !== 'undefined' && email !== null) body.email = email;
        if (typeof password !== 'undefined' && password !== null) body.password = password;
        if (pbxId !== '' && typeof pbxId !== null) body.pbxId = pbxId;
        if (typeof company !== 'undefined' && company !== null) body.company = company;
        if (typeof locale !== 'undefined' && locale !== null) body.locale = locale;
        if (typeof language !== 'undefined' && language !== null) body.language = language;
        if (typeof phone !== 'undefined' && phone !== null) body.phone = phone;
        if (typeof mobile !== 'undefined' && mobile !== null) body.mobile = mobile;
        if (typeof skypeId !== 'undefined' && skypeId !== null) body.skypeId = skypeId;
        if (typeof departmentId !== 'undefined' && departmentId !== null) body.departmentId = departmentId;
        if (typeof userType !== 'undefined' && userType !== null) body.userType = userType;
        if (customRoleId !== '' && typeof customRoleId !== null) body.customRoleId = customRoleId;
    return this.http.post(API.EMPLOYEE_ADD,body);
  }
  modify(id,accountsDetailsid,fullname:string,employmentId:string,email:string,pbxId:any={},company:string, locale:string,language:string,phone:any={},mobile:any={},skypeId:any={},departmentId:number,userType:number,customRoleId:any={}){

    let body:any={};
    if (typeof accountsDetailsid!=='undefined' && accountsDetailsid !==null) body.accountDetailsId=accountsDetailsid;
    if (typeof fullname !== 'undefined' && fullname !== null) body.fullname = fullname;
    if (typeof employmentId !== 'undefined' && employmentId !== null) body.employmentId = employmentId;
    if (typeof email !== 'undefined' && email !== null) body.email = email;
    if (pbxId !== '' && typeof pbxId !== null) body.pbxId = pbxId;
    if (typeof company !== 'undefined' && company !== null) body.company = company;
    if (typeof locale !== 'undefined' && locale !== null) body.locale = locale;
    if (typeof language !== 'undefined' && language !== null) body.language = language;
    if (typeof phone !== 'undefined' && phone !== null) body.phone = phone;
    if (typeof mobile !== 'undefined' && mobile !== null) body.mobile = mobile;
    if (typeof skypeId !== 'undefined' && skypeId !== null) body.skypeId = skypeId;
    if (typeof departmentId !== 'undefined' && departmentId !== null) body.departmentId = departmentId;
    if (typeof userType !== 'undefined' && userType !== null) body.userType = userType;
    if (customRoleId !== '' && typeof customRoleId !== null) body.customRoleId = customRoleId;

   return this.http.post(API.EMPLOYEE_MODIFY+id,body);
  }

  detailsById(userId){
    return this.http.get(API.EMPLOYEE_DETAILS+userId);
  }
  setShareValue(update,id?,accountDetailsId?){
    this.update=update;
    this.id=id;
    this.accountsDetailsId=accountDetailsId;
  }
  getShareValue(){
    return this.update;
  }
  getId(){
    return this.id;
  }
  getAccountDetails(){
    return this.accountsDetailsId;
  }
  

}
