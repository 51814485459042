import { Injectable } from "@angular/core";
import { Effect, Actions, ofType } from "@ngrx/effects";
import { DeviceAction, DeviceActionType, DeviceLoadSuccess, DeviceLoadFail } from "../actions";
import { ClientService } from "../../services";
import { switchMap, catchError, map } from "rxjs/operators";
import { Device } from "../../models";
import { of } from "rxjs";


@Injectable()
export class DeviceEffects {
    constructor(
        private actions$: Actions<DeviceAction>,
        private clientService: ClientService
    ) {}

    @Effect()
    deviceLoad$ = this.actions$.pipe(
        ofType(DeviceActionType.DEVICE_LOAD),
        switchMap(() => 
            this.clientService.devices().pipe(
                map((result: Device[]) => new DeviceLoadSuccess(result)),
                catchError(err=> of(new DeviceLoadFail(err)))
            )
        )
    );
}