import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cdr-call-flow',
  templateUrl: './cdr-call-flow.component.html',
  styleUrls: ['./cdr-call-flow.component.scss']
})
export class CdrCallFlowComponent implements OnInit {

  constructor() { }

  commentList = [
    {comment:'RTP Num packets:4035 Duration:81.299s SSR: 0x899FB6B'},
    {comment:'RTP Num packets:4035 Duration:81.299s SSR: 0x899FB6B'},
    {comment:'SIP Form sip:12129092291@64.136.173.31 To:sip:9723953'},
    {comment:'SIP Status'},
    {comment:'SIP Status'},
    {comment:'SIP Status'},
    {comment:'SIP Status'},
    {comment:'RTP Num packets:4035 Duration:81.299s SSR: 0x899FB6B'},
    {comment:'RTP Num packets:4035 Duration:81.299s SSR: 0x899FB6B'},
    {comment:'RTP Num packets:4035 Duration:81.299s SSR: 0x899FB6B'},
    {comment:'RTP Num packets:4035 Duration:81.299s SSR: 0x899FB6B'},
  ]

  ngOnInit() {
  }

}
