import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API } from "./constant";

@Injectable({
    providedIn: 'root'
})
export class TenantExistsService {

    constructor(private http: HttpClient) { }
    tenantExists(email) {
        return this.http.get(API.CHECK_TENANT_EMAIL + '?email=' + email);

    }
    domainExists(domain) {
        return this.http.get(API.CHECK_TENANT_DOMAIN + '?domain=' + domain);
    }

    tenantList(filter) {
        filter = JSON.stringify(filter);
        return this.http.get(API.TENANT_LIST+'?filter='+filter);
    }
    activateTenant(user, activationKey){
        return this.http.post(API.ACTIVATE_TENANT + user, { activationKey: activationKey});
    }
}
