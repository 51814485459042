import { Component, OnInit } from '@angular/core';
import { Select2Option, Select2Data } from 'ng-select2-component';
import { FormGroup, FormBuilder, Validators, FormControl, } from '@angular/forms';
@Component({
  selector: 'app-addcomapnysetting',
  templateUrl: './addcomapnysetting.component.html',
  styleUrls: ['./addcomapnysetting.component.scss']
})
export class AddcomapnysettingComponent implements OnInit {

  companysettingFormGroup: FormGroup;
  submitted = true;

  public recording
  hide = true;
  public data2 : Select2Data = [
    { value: 'AK', label: 'Alaska' },
    { value: 'HI', label: 'Hawaii',},
    { value: 'CA', label: 'California' },
    { value: 'NV', label: 'Nevada' },
    { value: 'OR', label: 'Oregon' },
    { value: 'WA', label: 'Washington' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'CO', label: 'Colorado' },
    { value: 'ID', label: 'Idaho' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'ND', label: 'North Dakota' }
  ]
  data: Select2Option[] = [];

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
  }
 

  update(value: string) {
    this.recording = value;
  }
  open() {
    this.data = JSON.parse(JSON.stringify(this.data2));
  }
  search(text: string) {
    this.data = text
      ? (JSON.parse(JSON.stringify(this.data2)) as Select2Option[])
        .filter(option => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1)
      : JSON.parse(JSON.stringify(this.data2));
  }


}
