import { NgModule} from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import { RestService } from '../../rest.service';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';
import { MatStepperModule, MatInputModule, MatButtonModule, MatAutocompleteModule, MatIconModule,MatTabsModule,MatTableModule, MatFormFieldModule, MatSelectModule, MatSlideToggleModule, MatCardModule, MatPaginatorModule,MatSortModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import { BillingComponent} from './billing.component';
import { Select2Module } from "ng-select2-component";
import { ChartsModule } from 'ng2-charts';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BillingsRoutingModule } from './billing-routing.module';
import { InvoicesComponent } from './invoices/invoices.component'
//import { UsersComponent } from './users/users.component'; 


@NgModule({
    declarations: [
      BillingComponent,
      InvoicesComponent
    ],
    imports: [
        BrowserModule,
        MatTableModule,
        MatTabsModule,
        MatStepperModule,
        MatInputModule,
        MatButtonModule,
        MatAutocompleteModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatIconModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatCardModule,
        MatPaginatorModule,
        FormsModule,
        MatSortModule,
        GooglePlaceModule,
        TranslateModule.forRoot(),
        Select2Module,
        ChartsModule,
        MatDatepickerModule,
        BillingsRoutingModule

        
    ],
    providers: [],
    bootstrap: [BillingComponent],
    exports:[],
})

export class BillingModule { }