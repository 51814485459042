import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdminComponent } from "../admin.component";
import { PermissionsUsersComponent } from './permissions-users/permissions-users.component';
import { EmailSettingsComponent } from './email-settings/email-settings.component';

@NgModule({
    imports: [
      RouterModule.forChild([
        {
          path: "admin",
          component: AdminComponent,
          data: {
            title: "Settings",
            status: false
          },
          children: [
            {
              path: 'settings',
              children: [
                {
                  path: "permissions_users",
                  component: PermissionsUsersComponent
                },
                {
                  path: "email_settings",
                  component: EmailSettingsComponent
                }
              ]
            },
          ]
        }
      ])
    ],
    exports: [RouterModule]
  })
  export class SettingsRoutingModule {}
  