import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-voicemail',
  templateUrl: './voicemail.component.html',
  styleUrls: ['./voicemail.component.scss','../client-dashboard.component.scss']
})
export class VoicemailComponent implements OnInit {
  actualExtCount: number = 32;
  realExtCount: number = 21;
  
  constructor() { }

  ngOnInit() {
  }

}
