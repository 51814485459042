import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API } from "./constant";
import { Observable, Subject } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/toPromise';

@Injectable({
    providedIn: 'root'
})

export class PinactionService {

    constructor(private http: HttpClient) { }
    addPin(type, id) {
        var data = { id: id };
        return this.http.post(API.ADD_PIN_ACTION+type, data);
    }
    removePin(type, id) {
        return this.http.delete(API.DELETE_PIN_ACTION+type+'/'+ id);
    }
}