import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator, MatTableDataSource, MatSort} from '@angular/material';

export interface TimeConditions {
  time_condition: String;
  override_state: String;
  feature_code: String;
  linked_group: String;
}

const ELEMENT_DATA: TimeConditions[] = [
  {time_condition:'4693318463 Frisco to 214618420 Frisco',override_state:'No Override',feature_code:'*285',linked_group:'4693318463 Frisco to 214618420 Frisco'},
  {time_condition:'4693318463 Frisco to 214618420 billing',override_state:'No Override',feature_code:'*305',linked_group:'4693318463 Frisco to 214618420 Frisco'},
  {time_condition:'Billing-Business-Hours',override_state:'No Override',feature_code:'*320',linked_group:'Billing-Business-Hours'},
  {time_condition:'Allen-Business-Hours',override_state:'No Override',feature_code:'*345',linked_group:'Billing-Business-Hours'},
  {time_condition:'Dallas-Business-Hours',override_state:'No Override',feature_code:'*354',linked_group:'Billing-Business-Hours'},
  {time_condition:'4693318463 Frisco Warrpkwy to 214618420 Frisco',override_state:'No Override',feature_code:'*365',linked_group:'Clinic-Business-Hours'},
  {time_condition:'Billing-Business-Hours',override_state:'No Override',feature_code:'*385',linked_group:'4693318463 Frisco to 214618420 Frisco'},
  {time_condition:'4693318463 Frisco to 214618420 Frisco',override_state:'No Override',feature_code:'*415',linked_group:'Billing-Business-Hours'},
  {time_condition:'Dallas-Business-Hours',override_state:'No Override',feature_code:'*445',linked_group:'Clinic-Business-Hours'},

];

@Component({
  selector: 'app-conditionlist',
  templateUrl: './conditionlist.component.html',
  styleUrls: ['./conditionlist.component.scss']
})
export class ConditionlistComponent implements OnInit {

  displayedColumns: string[] = ['time_condition','override_state','feature_code','linked_group','action'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  
  constructor() { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

}
