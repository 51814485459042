import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatButton} from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray  } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as toastr from "toastr";
import { TreeNode } from 'primeng/primeng';

@Component({
  selector: 'app-new-policy',
  templateUrl: './new-policy.component.html',
  styleUrls: ['./new-policy.component.scss']
})
export class NewPolicyComponent implements OnInit {
  
  newPolicyForm: FormGroup;
  submitted = false;

  files: TreeNode[];
  selectedFiles: TreeNode[] = [];

  constructor(private route: ActivatedRoute, private router: Router, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.files = [
      {
        label: 'Dashboard',
      },
      {
        label: 'Calendar',
      },
      {
        label: 'Discussion',
      },
      {
        label: 'Mailbox',
      },
      {
        label: 'Client',
        children: [
          {
            label: 'Client Notes',
          },
          {
            label: 'Client Invoices',
          },
          {
            label: 'Client Estimates',
          },
        ]
      },
      {
        label: 'Vendor',
        children: [
          {
            label: 'Vendor Purchase Order',
          },
          {
            label: 'Vendor Transactions',
          },
          {
            label: 'Vendor Refunds',
          },
        ]
      },
      {
        label: 'Tasks',
      },
      {
        label: 'SMS',
      }
    ];

    this.selectedFiles.push(this.files[0]);
    this.selectedFiles.push(this.files[1]);

    this.newPolicyForm = this.formBuilder.group({
      policyName: ['', Validators.required],
    })
  }

  onSubmit() {
    this.submitted = true;

    if (this.newPolicyForm.invalid) {
      toastr.error("Invalid Details !");
      return;
    }
    console.log(this.newPolicyForm.value);

    toastr.success("Form Submitted Successfully !")
} 

  nodeSelect(event) {
    console.log("selected nodes :- ", this.selectedFiles);
  }
  nodeUnselect(event) {
    console.log("unselected nodes :- ", this.selectedFiles);
  }

}
