import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule
} from "@angular/common/http";
import { LocationStrategy, PathLocationStrategy } from "@angular/common";
import { AppRoutes } from "./app.routing";
import { AppComponent } from "./app.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialModule } from "./material-module";
import { HttpLoaderFactory, SharedModule } from "./admin/shared/shared.module";
import { AdminModule } from "./admin/admin.module";
import { HttpsRequestInterceptor } from "./app-request-handler.service";
import { CoreModule } from "./core/core.module";
import { NgMaterialMultilevelMenuModule } from "ng-material-multilevel-menu";
import { DragulaModule } from "ng2-dragula";
import { CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { QRCodeModule } from "angularx-qrcode";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import {ClientModule} from "./client/client.module";
import { UserModule } from './user/user.module';
import { SafePipe } from './service/models/safe.pipe';
import { StoreModule } from '@ngrx/store';
import { reducers } from './admin/provisioner/store';
import { EffectsModule } from "@ngrx/effects";
import { effects } from "./admin/provisioner/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import {
  ToastrModule,
  ToastNoAnimation,
  ToastNoAnimationModule
} from 'ngx-toastr';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { CommonComponentModule } from "./common/commonComponent.module";
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MomentModule } from 'angular2-moment';
import { JwtInterceptor } from './interceptor/jwt-interceptor.service';
import { AuthInterceptor } from './interceptor/unAuthorized-interceptor.service';



@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [AppComponent,SafePipe],
  imports: [
  BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    FlexLayoutModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(AppRoutes, {onSameUrlNavigation: 'reload'}),
    // RouterModule.forRoot(AppRoutes),
    AdminModule,
    CoreModule,
    NgMaterialMultilevelMenuModule,
    DragulaModule,
    QRCodeModule,
    ToastNoAnimationModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ClientModule,
    UserModule,
    
    DateInputsModule,
    CommonComponentModule,
    MomentModule,
    NgIdleKeepaliveModule.forRoot()
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpsRequestInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
