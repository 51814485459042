import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from '../admin.component';
import { ApplicationsComponent } from './applications.component';
import { ReportsComponent } from '../reports/reports.component'

const routes: Routes = [];

@NgModule({
  imports: [RouterModule.forChild([
    {
        path: 'admin',
        component: AdminComponent,
        data: {
          title: 'Applications',
          status: false
        },
   
    children: [
        {
          path: 'applications',
          component: ApplicationsComponent
        },
      ]
    }
  ]),
],
  exports: [RouterModule]
})

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
  })
export class applicationsRoutingModule { }