import { NgModule} from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import {TranslateModule} from '@ngx-translate/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule, MatInputModule, MatButtonModule, MatAutocompleteModule, MatIconModule,MatTabsModule,MatTableModule, MatFormFieldModule, MatSelectModule, MatSlideToggleModule, MatCardModule, MatPaginatorModule,MatSortModule,MatCheckboxModule } from '@angular/material';
import { SettingsComponent } from "./settings.component";
import { ProvisionerModule } from "./provisioner/provisioner.module";
import { PermissionsusersModule } from "./permissionsusers/permissionsusers.module";
import { IntrusionDetectionModule } from "./intrusion-detection/intrusion-detection.module";
import { SettingsRoutingModule } from "./settings-routing.module";
@NgModule({
    declarations: [
        SettingsComponent,
    ],
    imports: [
        BrowserModule,
        TranslateModule.forRoot(),
        FormsModule,
        MatStepperModule,
        MatInputModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatIconModule,
        MatTabsModule,
        MatTableModule,
        MatFormFieldModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatCardModule,
        MatPaginatorModule,
        MatSortModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        SettingsRoutingModule,
        ProvisionerModule,
        PermissionsusersModule,
        IntrusionDetectionModule,

    ],
    providers: [],
    bootstrap: [SettingsComponent],
    exports:[],
})

export class SettingsModule { }