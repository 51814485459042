import { Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, MatTableDataSource, MatSort} from '@angular/material';

export interface PeriodicElement {
  ring_group: number;
  description: String;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {ring_group: 150, description:'Billing All'},
  {ring_group: 150, description:'Billing-AfterHours'},
  {ring_group: 150, description:'Mammography RG'},
  {ring_group: 150, description:'Schedule Exam'},
  {ring_group: 150, description:'OrthonAllen'},
  {ring_group: 150, description:'OrthonAllen-to-AnsweringService'},
  {ring_group: 150, description:'Mammography RG'},
  {ring_group: 150, description:'Billing-AfterHours'},
  {ring_group: 150, description:'OrthonAllen-to-AnsweringService'},
  {ring_group: 150, description:'Billing All'},
];

@Component({
  selector: 'app-ringgrouplist',
  templateUrl: './ringgrouplist.component.html',
  styleUrls: ['./ringgrouplist.component.scss']
})
export class RinggrouplistComponent implements OnInit {

  displayedColumns: string[] = ['ring_group','description','action'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  
  constructor() { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

}
