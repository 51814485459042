import { Component, OnInit, ViewChild } from "@angular/core";
import {
  MatPaginator,
  MatTableDataSource,
  MatDialog,
  MatSort,
} from "@angular/material";
import { ClientService, readCookie } from "../services";
import { AddDeviceModelComponent } from "./add-device-model/add-device-model.component";
import Swal from "sweetalert2";
import { MoboAlert } from "../notification";
import { AddDeviceSettingComponent } from "./add-device-setting/add-device-setting.component";
import { EditDeviceSettingComponent } from "./edit-device-setting/edit-device-setting.component";
import { AddSingleDeviceSettingComponent } from "./add-single-device-setting/add-single-device-setting.component";
import { API_BASE } from "../env/provisioner.env";
import { Router } from "@angular/router";

@Component({
  selector: "device-models",
  templateUrl: "./device-models.component.html",
  styleUrls: ["./device-models.component.scss"],
})
// @ts-ignore
export class DeviceModelsComponent implements OnInit {
  dataSource: any;
  displayedColumns: string[] = ["device_manuf", "device_model", "actions"];
  // @ts-ignore
  @ViewChild(MatPaginator, {}) paginator: MatPaginator;
  @ViewChild(MatSort, {}) sort: MatSort;

  devices: any;

  constructor(
    private service: ClientService,
    private dialog: MatDialog,
    private alert: MoboAlert,
    private router: Router
  ) {}

  ngOnInit() {
    if (!readCookie("authenticate")) {
      this.router.navigate(["/login"], {
        queryParams: { return: location.href },
      });
    }
    this.service.devices().subscribe((data: any) => {
      this.devices = data;
      this.dataSource = new MatTableDataSource(this.devices);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  openAddDeviceModelDialog() {
    const addDeviceModelDialog = this.dialog.open(AddDeviceModelComponent);
    addDeviceModelDialog.afterClosed().subscribe((data: any) => {
      if (data) {
        if (data.added) {
          Swal.fire("Added!", "Device model has been added.", "success");
          this.service.devices().subscribe((data: any) => {
            this.devices = data;
            this.dataSource = new MatTableDataSource(this.devices);
            this.dataSource.paginator = this.paginator;
          });
        }
      }
    });
  }

  editDeviceManufName(id: string, name: string) {
    Swal.fire({
      title: "Edit device manufacture name",
      input: "text",
      inputValue: name,
      cancelButtonColor: "#EC4849",
      reverseButtons: true,
      confirmButtonColor: "#45CE30",
      showCancelButton: true,
      confirmButtonText: "SAVE",
      cancelButtonText: "CANCEL",
      focusCancel: true,
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.value) {
        this.service
          .editDeviceManufName(id, result.value)
          .subscribe((data: any) => {
            if (data) {
              if (data.ok) {
                Swal.fire(
                  "Edited!",
                  "Device manufacture name has been edited.",
                  "success"
                );
                this.service.devices().subscribe((data: any) => {
                  this.devices = data;
                  this.dataSource = new MatTableDataSource(this.devices);
                  this.dataSource.paginator = this.paginator;
                });
              } else {
                Swal.fire("Oopsss...", "Something went worng", "error");
              }
            }
          });
      }
    });
  }

  editDeviceModelName(id: string, name: string) {
    Swal.fire({
      title: "Edit device model name",
      input: "text",
      inputValue: name,
      cancelButtonColor: "#EC4849",
      reverseButtons: true,
      confirmButtonColor: "#45CE30",
      showCancelButton: true,
      confirmButtonText: "SAVE",
      cancelButtonText: "CANCEL",
      focusCancel: true,
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.value) {
        this.service
          .editDeviceModelName(id, result.value)
          .subscribe((data: any) => {
            if (data) {
              if (data.ok) {
                Swal.fire(
                  "Edited!",
                  "Device model name has been edited.",
                  "success"
                );
                this.service.devices().subscribe((data: any) => {
                  this.devices = data;
                  this.dataSource = new MatTableDataSource(this.devices);
                  this.dataSource.paginator = this.paginator;
                });
              } else {
                Swal.fire("Oopsss...", "Something went worng", "error");
              }
            }
          });
      }
    });
  }

  addSetting(id: string, rev: string) {
    console.log(this.devices);
    var device = null;
    for (var i in this.devices) {
      if (this.devices[i]._id == id) {
        device = this.devices[i];
      }
    }
    console.log(device);
    const addSettingDialog = this.dialog.open(AddDeviceSettingComponent, {
      data: device,
    });
    addSettingDialog.afterClosed().subscribe((data: any) => {
      device = null;
      if (data) {
        if (data.added) {
          Swal.fire("Updated!", "Device settings has been updated.", "success");
          this.service.devices().subscribe((data: any) => {
            this.devices = data;
            this.dataSource = new MatTableDataSource(this.devices);
            this.dataSource.paginator = this.paginator;
          });
        }
      }
    });
  }
  addSingleSetting(id: string, rev: string) {
    var device = null;
    for (var i in this.devices) {
      if (this.devices[i]._id == id) {
        device = this.devices[i];
      }
    }
    const addSingleSettingDialog = this.dialog.open(
      AddSingleDeviceSettingComponent,
      {
        data: device,
      }
    );
    addSingleSettingDialog.afterClosed().subscribe((data: any) => {
      if (data) {
        if (data.added) {
          Swal.fire("Added!", "Device settings has been added.", "success");
          this.service.devices().subscribe((data: any) => {
            this.devices = data;
            this.dataSource = new MatTableDataSource(this.devices);
            this.dataSource.paginator = this.paginator;
          });
        }
      }
    });
  }

  editSetting(id: string, rev: string) {
    var device = null;
    for (var i in this.devices) {
      if (this.devices[i]._id == id) {
        device = this.devices[i];
      }
    }
    const editSettingDialog = this.dialog.open(EditDeviceSettingComponent, {
      data: device,
    });
    editSettingDialog.afterClosed().subscribe((data: any) => {
      if (data) {
        if (data.added) {
          Swal.fire("Updated!", "Device settings has been updated.", "success");
          this.service.devices().subscribe((data: any) => {
            this.devices = data;
            this.dataSource = new MatTableDataSource(this.devices);
            this.dataSource.paginator = this.paginator;
          });
        }
      }
    });
  }

  exportSetting(id: string) {
    this.service.exportSetting(id).subscribe((data: any) => {
      console.log("** url **", data.file);
      window.open(data.file);
    });
  }

  removeDeviceModel(id: string, rev: string) {
    this.alert
      .confirm({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
      })
      .then((result) => {
        if (result.value) {
          this.service.removeDeviceModel(id, rev).subscribe((data: any) => {
            if (data) {
              if (data.ok) {
                Swal.fire("Deleted!", "Device model been deleted.", "success");
                this.service.devices().subscribe((data: any) => {
                  this.devices = data;
                  this.dataSource = new MatTableDataSource(this.devices);
                  this.dataSource.paginator = this.paginator;
                });
              } else {
                Swal.fire("Oopsss...", "Something went worng", "error");
              }
            }
          });
        }
      });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
