import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator, MatTableDataSource} from '@angular/material';


export interface PeriodicElement {
  destination : string
}

const ELEMENT_DATA: PeriodicElement[] = [
  {destination :  'Allen'},
  {destination : 'Frisco'}
  
];

@Component({
  selector: 'app-all-misc-destination',
  templateUrl: './all-misc-destination.component.html',
  styleUrls: ['./all-misc-destination.component.scss']
})
export class AllMiscDestinationComponent implements OnInit {

  displayedColumns: string[] = [ 'destination','action'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  constructor() { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
  }

}
