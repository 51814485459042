import { Component, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuService } from '../../../../service/menu.service';
import {IResponse, NavItem} from '../../../../service/Utils/Interfaces.class';
import { items } from '../../../../client/layouts/full/sidebar/sidebar.component';

export interface PeriodicElement {
  icon: string;
  label : string;
  link  :string;
  items : items [];
  menu_id :number;
  parent : number
}

const ELEMENT_DATA: PeriodicElement[] = [
  {icon: "fa fa-tachometer-alt" , label: "dashboard" ,link: "/user/dashboard",items:[] ,menu_id: 1,parent: 0},
  {icon: "fa fa-envelope" , label: "messages" ,link:"/user/messages",items:[], menu_id: 2,parent: 0},
  {icon: "fai fa-phone" , label: "calls" ,link:"/user/calls", items:[], menu_id: 3,parent: 0},
  {icon: "fas fa-microphone" , label: "voicemails" ,link:"/user/voicemails", items:[], menu_id: 4,parent: 0},
  {icon: "fas fa-address-book" , label: "contacts" ,link:"/user/contacts", items:[], menu_id: 5,parent: 0},
  {icon: "fa fa-bar-chart" , label: "reports" ,link:"/user/reports", items:[], menu_id: 6,parent: 0},
  {icon: "fa fa-cog" , label: "settings" ,link:"/user/settings", items:[], menu_id: 6,parent: 0}
];
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: []
})
export class AppSidebarComponent implements OnDestroy {
  myData: any;
  displayList = false;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  navItems: NavItem[];
  config = {
    paddingAtStart: true,
    listBackgroundColor: `#3A3F51`,
    backgroundColor: `#445137`,
    interfaceWithRoute: true,
    highlightOnSelect: true,
    selectedListFontColor: `#ffffff`,
    fontColor: `rgb(8, 54, 71)`,
    collapseOnSelect: true,
    classname: 'my-custom-class',
    rtlLayout: false
  };

  constructor(
    private menuService: MenuService,
    private router: Router,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
  ) 
  {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    var setupDone = localStorage.getItem('setup');
    if (setupDone == "1") {
      this.navItems = ELEMENT_DATA
    }  
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  selectedItem($event) {
    // console.log($event);
  }

  selectedLabel($event) {
    // console.log($event);
  }

  redirect(link) {
    this.router.navigate([link]);
    setTimeout(() => {
      this.displayList = true;
    }, 100);
  }
}
