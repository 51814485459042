import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { API_BASE } from "../env/provisioner.env";
import { Clients } from "../models";

@Injectable({
  providedIn: "root",
})
// @ts-ignore
export class ClientService {
  constructor(private http: HttpClient) {
    this.header = new HttpHeaders();
    this.header = this.header.append(
      "Access-Control-Allow-Credentials",
      "true"
    );
    this.header = this.header.append("Access-Control-Allow-Origin", "*");
    this.header = this.header.append("Access-Control-Allow-Origin", "*");
  }

  header: HttpHeaders;

  add(data: Clients) {
    return this.http.post(API_BASE + "clients/add", {
      headers: this.header,
      data: data,
    });
  }

  // list of all clients
  list() {
    return this.http.get(API_BASE + "clients/list");
  }

  // delete a client
  del(id: string, rev: string) {
    return this.http.put(API_BASE + "clients/delete", {
      headers: this.header,
      id: id,
      rev: rev,
    });
  }

  edit(id: string, rev: string, data: any) {
    return this.http.put(API_BASE + "clients/edit", {
      headers: this.header,
      id: id,
      rev: rev,
      name: data.name,
      groups: data.groups,
      extension_num: data.extension_num,
      extension_start: data.extension_start,
      blfs: data.blfs,
      vpbx: data.vpbx,
    });
  }

  // get single client
  getClient(client_id: string) {
    return this.http.post(API_BASE + "clients/get-client", {
      client_id: client_id,
      headers: this.header,
    });
  }

  // get company settings
  comapnySettings() {
    return this.http.get(API_BASE + "clients/company-settings");
  }

  remove_company_setting(id: string, rev: string) {
    return this.http.put(API_BASE + "clients/remove-company-setting", {
      id: id,
      rev: rev,
      headers: this.header,
    });
  }

  addComapnySetting(name: string, model_id: string) {
    return this.http.put(API_BASE + "clients/add-company-settings", {
      name: name,
      model_id: model_id,
      created_at: new Date().toString(),
      headers: this.header,
    });
  }

  devices() {
    return this.http.get(API_BASE + "clients/devices");
  }

  clientDeviceAddEdit(
    device_id: number,
    model_id: string,
    client_id: string,
    group_setting_id: number
  ) {
    return this.http.post(API_BASE + "clients/client-device-edit", {
      model_id: model_id,
      client_id: client_id,
      device_id: device_id,
      group_setting_id: group_setting_id,
      headers: this.header,
    });
  }

  companySettingsEdit(id: string, model_id: string, avoidSpinner = false) {
    return this.http.post(API_BASE + "clients/edit-company-setting", {
      model_id: model_id,
      id: id,
      avoidSpinner,
      headers: this.header,
    });
  }

  companySettingModify(setting_id: string, values: any, fields: any) {
    return this.http.put(API_BASE + "clients/modify-company-setting", {
      id: setting_id,
      values: values,
      fields: fields,
      headers: this.header,
    });
  }

  getGroups(id: any) {
    return this.http.post(API_BASE + "clients/groups", {
      client_id: id,
      headers: this.header,
    });
  }

  remove_group(client: string, id: any) {
    return this.http.put(API_BASE + "clients/remove-group", {
      client_id: client,
      group_id: id,
      headers: this.header,
    });
  }

  add_group(client: string, name: string) {
    return this.http.put(API_BASE + "clients/add-group", {
      client_id: client,
      name: name,
      created_at: new Date(),
      headers: this.header,
    });
  }

  getGroupSettings(client_id: string, group_id: number) {
    return this.http.post(API_BASE + "clients/group-settings", {
      client_id: client_id,
      group_id: group_id,
      headers: this.header,
    });
  }

  getClientSettings(client_id: string) {
    return this.http.post(API_BASE + "clients/client-settings", {
      client_id: client_id,
      headers: this.header,
    });
  }

  addGroupSetting(data: any) {
    return this.http.put(API_BASE + "clients/add-group-setting", {
      data: data,
      headers: this.header,
    });
  }

  remove_group_setting(
    client_id: string,
    group_id: any,
    group_setting_id: any
  ) {
    return this.http.put(API_BASE + "clients/remove-group-setting", {
      client_id: client_id,
      group_id: group_id,
      group_setting_id: group_setting_id,
      headers: this.header,
    });
  }

  editGroupSetting(
    client_id: string,
    group_id: number,
    model_id: string,
    id: number
  ) {
    return this.http.post(API_BASE + "clients/edit-group-setting", {
      client_id: client_id,
      group_id: group_id,
      model_id: model_id,
      id: id,
      headers: this.header,
    });
  }

  clientSettingsEdit(
    client_id: string,
    company_setting_id: string,
    model_id: string,
    id: number
  ) {
    return this.http.post(API_BASE + "clients/edit-client-settings", {
      client_id: client_id,
      company_setting_id: company_setting_id,
      model_id: model_id,
      id: id,
      headers: this.header,
    });
  }

  modifyGroupSetting(client_id: string, id: number, values: any, fields: any) {
    return this.http.put(API_BASE + "clients/modify-group-setting", {
      client_id: client_id,
      id: id,
      values: values,
      fields: fields,
      headers: this.header,
    });
  }

  clientSettingModify(client_id: string, id: number, values: any, fields: any) {
    return this.http.put(API_BASE + "clients/modify-client-setting", {
      client_id: client_id,
      id: id,
      values: values,
      fields: fields,
      headers: this.header,
    });
  }

  clientDevices(client_id: string) {
    return this.http.post(API_BASE + "clients/client-devices", {
      client_id: client_id,
      headers: this.header,
    });
  }

  remove_device(client_id: string, id: number) {
    return this.http.put(API_BASE + "clients/remove-device", {
      client_id: client_id,
      id: id,
      headers: this.header,
    });
  }

  add_device(client_id: string, data: any) {
    return this.http.put(API_BASE + "clients/add-device", {
      client_id: client_id,
      data: data,
      headers: this.header,
    });
  }

  remove_client_setting(client_id: string, id: number) {
    return this.http.put(API_BASE + "clients/remove-client-settings", {
      client_id: client_id,
      id: id,
      headers: this.header,
    });
  }

  add_client_setting(client_id: string, data: any) {
    return this.http.put(API_BASE + "clients/add-client-settings", {
      client_id: client_id,
      data: data,
      headers: this.header,
    });
  }

  modifyDeviceSetting(client_id: string, id: number, values: any, fields: any) {
    return this.http.put(API_BASE + "clients/client-device-modify", {
      client_id: client_id,
      id: id,
      values: values,
      fields: fields,
      headers: this.header,
    });
  }

  singleDeploy(client_id: string, id: number) {
    return this.http.post(API_BASE + "clients/single-deploy", {
      client_id: client_id,
      id: id,
      headers: this.header,
    });
  }

  deployAll(client_id: string) {
    return this.http.post(API_BASE + "clients/deploy-all", {
      client_id: client_id,
      headers: this.header,
    });
  }

  editClientName(client_id: string, name: string) {
    return this.http.post(API_BASE + "clients/edit-client-name", {
      client_id: client_id,
      name: name,
      headers: this.header,
    });
  }

  editVpbx(client_id: string, vpbx: string) {
    return this.http.post(API_BASE + "clients/edit-vpbx", {
      client_id: client_id,
      vpbx: vpbx,
      headers: this.header,
    });
  }

  editcompanySettingName(id: string, name: string) {
    return this.http.post(API_BASE + "clients/edit-company-setting-name", {
      id: id,
      name: name,
      headers: this.header,
    });
  }

  editclientSettingName(client_id: string, id: number, name: string) {
    return this.http.post(API_BASE + "clients/edit-client-setting-name", {
      client_id: client_id,
      id: id,
      name: name,
      headers: this.header,
    });
  }

  editExtName(client_id: string, id: number, name: string) {
    return this.http.post(API_BASE + "clients/edit-ext-name", {
      client_id: client_id,
      id: id,
      name: name,
      headers: this.header,
    });
  }

  editMac(client_id: string, id: number, mac: string) {
    return this.http.post(API_BASE + "clients/edit-mac", {
      client_id: client_id,
      id: id,
      mac: mac,
      headers: this.header,
    });
  }

  editGroupName(client_id: string, id: number, name: string) {
    return this.http.post(API_BASE + "clients/edit-group-name", {
      client_id: client_id,
      id: id,
      name: name,
      headers: this.header,
    });
  }

  editGroupSettingName(client_id: string, id: number, name: string) {
    return this.http.post(API_BASE + "clients/edit-group-setting-name", {
      client_id: client_id,
      id: id,
      name: name,
      headers: this.header,
    });
  }

  getClientName(client_id: string) {
    return this.http.post(API_BASE + "clients/client-name", {
      client_id: client_id,
      headers: this.header,
    });
  }

  getGroupName(client_id: string, id: number) {
    return this.http.post(API_BASE + "clients/group-name", {
      client_id: client_id,
      id: id,
      headers: this.header,
    });
  }

  addDeviceModel(data: any) {
    return this.http.post(API_BASE + "clients/add-device-model", {
      data: data,
      headers: this.header,
    });
  }

  accessLogs(client_id: number | string) {
    return this.http.post(API_BASE + "clients/access-logs", {
      client_id: client_id,
      headers: this.header,
    });
  }

  editDeviceManufName(id: string, name: string) {
    return this.http.post(API_BASE + "clients/edit-device-manuf", {
      id: id,
      name: name,
      headers: this.header,
    });
  }

  editDeviceModelName(id: string, name: string) {
    return this.http.post(API_BASE + "clients/edit-device-model", {
      id: id,
      name: name,
      headers: this.header,
    });
  }

  removeDeviceModel(id: string, rev: string) {
    return this.http.put(API_BASE + "clients/remove-device-model", {
      id: id,
      rev: rev,
      headers: this.header,
    });
  }

  addDeviceSetting(id: string, settings: any) {
    return this.http.put(API_BASE + "clients/add-device-setting", {
      id: id,
      settings: settings,
      headers: this.header,
    });
  }

  editDeviceSetting(id: string, settings: any) {
    return this.http.put(API_BASE + "clients/edit-device-setting", {
      id: id,
      settings: settings,
      headers: this.header,
    });
  }

  getConfigPath() {
    return this.http.get(API_BASE + "clients/config-path");
  }

  editConfigPath(path: string) {
    return this.http.put(API_BASE + "clients/edit-config-path", {
      path: path,
      headers: this.header,
    });
  }

  exportSetting(id: string) {
    return this.http.post(API_BASE + "clients/export-setting", {
      id: id,
      headers: this.header,
    });
  }

  duplicateMac(macAddr: any) {
    return this.http.post(API_BASE + "clients/duplicate-mac", {
      macAddr: macAddr,
      headers: this.header,
    });
  }
  checkDuplicateMac(id, macAddr: any) {
    return this.http.post(API_BASE + "clients/check-duplicate-mac", {
      _id: id,
      macAddr: macAddr,
      headers: this.header,
    });
  }
}
