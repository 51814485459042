import { NgModule} from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import {TranslateModule} from '@ngx-translate/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule, MatInputModule, MatButtonModule, MatAutocompleteModule, MatIconModule,MatTabsModule,MatTableModule, MatFormFieldModule, MatSelectModule, MatSlideToggleModule, MatCardModule, MatPaginatorModule,MatSortModule,MatCheckboxModule } from '@angular/material';
import { PermissionsusersComponent } from "./permissionsusers.component";
import { PermissionsUsersModule } from "../../../admin/settings/permissions-users/permissions-users.module"
import { CommonComponentModule } from "../../../common/commonComponent.module";

@NgModule({
    declarations: [
        PermissionsusersComponent,
    ],

    imports: [
    BrowserModule,
        TranslateModule.forRoot(),
        FormsModule,
        MatStepperModule,
        MatInputModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatIconModule,
        MatTabsModule,
        MatTableModule,
        MatFormFieldModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatCardModule,
        MatPaginatorModule,
        MatSortModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        PermissionsUsersModule,
        CommonComponentModule
    ],
    providers: [],
    bootstrap: [PermissionsusersComponent],
    exports:[],
})

export class PermissionsusersModule { }