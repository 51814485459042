import { Component, OnInit, Optional, Inject, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import { MatStepper } from "@angular/material/stepper";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatTableDataSource,
  MatPaginator,
  MatDialog,
} from "@angular/material";
import { ClientService } from "../../services";
import { HttpResponse } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { Store } from "@ngrx/store";
import { State } from "../../../../reducers";
import {
  CompanySettingLoad,
  selectAllCompanySettings,
  selectAllDevices,
  DeviceLoadSuccess,
  DeviceLoad,
  selectAddState,
  CLientAdd,
} from "../../store";
import { Observable } from "rxjs";
import { CompanySettings, Device, DbResponse, Clients } from "../../models";
import { MoboSnackBar } from "../../notification";
import Swal from "sweetalert2";
import { EditDialogComponent } from "./edit-dialog/edit-dialog.component";

@Component({
  selector: "app-add-client",
  templateUrl: "./add-client.component.html",
  styleUrls: ["./add-client.component.scss"],
})
export class AddClientComponent implements OnInit {
  isLinear = true;
  groups: {}[] = [];
  groupSetting: any[] = [];
  clientInfoForm: FormGroup;
  clientGlobalSettingForm: FormGroup;
  clientGroupSettingForm: FormGroup;
  clientExtensionsForm: FormGroup;
  clientSettings: any;
  clientSettingsData: any = [];
  groupSettings: any;
  groupSettingsData: any = [];
  companySettings$: Observable<CompanySettings[]>;
  companySettings: CompanySettings[];
  devices$: Observable<Device[]>;
  devices: Device[];

  finalData: Clients;

  added$: Observable<boolean | DbResponse>;

  displayedColumns: string[] = ["name", "ext_num", "ext_start", "actions"];
  @ViewChild("paginator1", {}) paginator1: MatPaginator;
  @ViewChild("paginator2", {}) paginator2: MatPaginator;

  constructor(
    private _formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<AddClientComponent>,
    private dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) private addClientData,
    private clientService: ClientService,
    private _toastr: ToastrService,
    private store: Store<State>,
    private snackBar: MoboSnackBar
  ) {
    // select company settings from state
    this.companySettings$ = this.store.select(selectAllCompanySettings);
    // select devices from state
    this.devices$ = this.store.select(selectAllDevices);

    this.added$ = this.store.select(selectAddState);
  }

  ngOnInit() {
    // client info form
    this.clientInfoForm = this._formBuilder.group({
      clientName: ["", Validators.required],
      clientEmail: [""],
      clientPhone: [""],
      clientAddress: [""],
      clientCityStateZip: [""],
      clientCountry: [""],
      sameAsContactAddress: [false],
      clientBillingAddress: [""],
      clientBillingCityStateZip: [""],
      clientBillingCountry: [""],
      clientContactName: [""],
      clientContactEmail: [""],
      clientContactPhone: [""],
      groupName: this._formBuilder.array([""]),
      extNum: [null, [Validators.required, Validators.pattern("^[0-9]*$")]],
      extStart: [null, [Validators.required, Validators.pattern("^[0-9]*$")]],
      blfs: [null, Validators.required],
      vpbx: [
        "",
        [
          Validators.required,
          // Validators.pattern(
          //   "(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]"
          // ),
        ],
      ],
    });

    // client global setting form
    this.clientGlobalSettingForm = this._formBuilder.group({
      setting_name: ["", Validators.required],
      company_setting: ["", Validators.required],
    });

    // client group setting form
    this.clientGroupSettingForm = this._formBuilder.group({
      setting_name: ["", Validators.required],
      group_id: ["", Validators.required],
      client_setting: ["", Validators.required],
    });

    // client eextension form
    this.clientExtensionsForm = this._formBuilder.group({
      devices: this._formBuilder.array([]),
    });
  }

  // generate a random password
  generatePassword() {
    var length = 30,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  id_global: number = 0;
  id_group: number = 0;
  id_device: number = 0;

  // generate IDs
  getId(condition = null) {
    if (condition == "reset") {
      this.id_global = 0;
      this.id_group = 0;
      return;
    } else if (condition == "global") {
      this.id_global = this.id_global + 1;
      return this.id_global;
    } else if (condition == "group") {
      this.id_group = this.id_group + 1;
      return this.id_group;
    } else if (condition == "device") {
      this.id_device = this.id_device + 1;
      return this.id_device;
    }
    return;
  }

  get groupName() {
    return this.clientInfoForm.get("groupName") as FormArray;
  }

  // add a new group input field
  addGroup() {
    return this.groupName.push(this._formBuilder.control(""));
  }

  // remove a perticular group input filed
  removeGroup(index: any) {
    return this.groupName.removeAt(index);
  }

  get device() {
    return this.clientExtensionsForm.get("devices") as FormArray;
  }

  // device input block
  addNewDevice(
    extNum: number = null,
    authPass: string = this.generatePassword()
  ): void {
    this.device.push(this.addDevice(extNum, authPass));
  }

  removeDevice(index: any) {
    return this.device.removeAt(index);
  }

  // add a new device block
  addDevice(extNum: number, authPass: string): FormGroup {
    return this._formBuilder.group({
      _id: [this.getId("device")],
      extNum: [extNum, [Validators.required, Validators.pattern("^[0-9]*$")]],
      extName: ["", Validators.required],
      authPass: [authPass, Validators.required],
      macAddr: ["", [Validators.required, Validators.minLength(12)]],
      group_id: [null, [Validators.required, Validators.pattern("^[0-9]*$")]],
      inherit_from_group_settings: [null, Validators.required],
      deploy_date: [""],
      created_at: [new Date()],
    });
  }

  checkMac(val: any, i: number): void {
    // this.clientExtensionsForm.controls.devices[i].controls.macAddr.setValue(val.replace(/[^A-Z0-9]+/ig, '').toLowerCase());
  }

  // submit client info form
  clientInfo(stepper: MatStepper) {
    if (this.clientInfoForm.valid) {
      this.groups = [];
      this.clientInfoForm.value.groupName.forEach((val, key) => {
        var d = {
          _id: key + 1,
          name: val,
          created_at: new Date(),
        };
        this.groups = [...this.groups, d];
      });
      var i = this.clientInfoForm.value.extNum;
      if (this.device.length == 0) {
        while (i > 0) {
          this.addNewDevice(
            this.clientInfoForm.value.extStart++,
            this.generatePassword()
          );
          i--;
        }
      }
      // trigger device load
      this.store.dispatch(new DeviceLoad());

      this.devices$.subscribe((data: Device[]) => {
        this.devices = data;
        stepper.next();
        // trigger client setting load
        this.store.dispatch(new CompanySettingLoad());

        // subscribe to company setting
        this.companySettings$.subscribe((data: CompanySettings[]) => {
          this.companySettings = data;
        });
      });
    }
  }

  addClientGlobalSetting() {
    if (
      this.clientGlobalSettingForm.valid &&
      this.clientGlobalSettingForm.value.setting_name &&
      this.clientGlobalSettingForm.value.company_setting
    ) {
      var data = null;
      this.companySettings.forEach((val) => {
        if (val._id == this.clientGlobalSettingForm.value.company_setting) {
          this.devices.forEach((val1) => {
            if (val1._id == val.model_id) {
              data = {
                _id: this.getId("global"),
                name: this.clientGlobalSettingForm.value.setting_name,
                config_name: val.name,
                inherit_from_company_settings: val._id,
                device: val1.device_manuf + " " + val1.device_model,
                created_at: new Date(),
              };
            }
          });
        }
      });
      this.clientSettingsData = [...this.clientSettingsData, data];
      this.clientSettings = new MatTableDataSource(this.clientSettingsData);
      this.clientSettings.paginator = this.paginator1;
      this.clientGlobalSettingForm.reset();
      this.clientGlobalSettingForm.get("setting_name").clearValidators();
      this.clientGlobalSettingForm.get("setting_name").updateValueAndValidity();
      this.clientGlobalSettingForm.get("company_setting").clearValidators();
      this.clientGlobalSettingForm
        .get("company_setting")
        .updateValueAndValidity();
    }
  }

  removeClientGlobalSetting(_id: number) {
    if (!confirm("Are you sure you want to delete?")) {
      return;
    }
    var index = null;
    for (var i in this.clientSettingsData) {
      if (this.clientSettingsData[i]._id == _id) {
        index = i;
        break;
      }
    }
    if (index) {
      var temp = [...this.clientSettingsData];
      temp.splice(index, 1);
      this.clientSettingsData = [...temp];
      this.clientSettings = new MatTableDataSource(this.clientSettingsData);
      this.clientSettings.paginator = this.paginator1;
    }
  }

  editClientGlobalSetting(_id: number) {
    var data = null;
    var index = null;
    for (var i in this.clientSettingsData) {
      if (this.clientSettingsData[i]._id == _id) {
        data = this.clientSettingsData[i];
        index = i;
        break;
      }
    }
    if (data) {
      const editDialog = this.dialog.open(EditDialogComponent, {
        disableClose: true,
        width: "40%",
        height: "*0%",
        data: {
          setting: data,
          from: "global",
          index,
        },
      });
      editDialog.afterClosed().subscribe((data) => {
        if (data && data.modified) {
          this.companySettings.forEach((val) => {
            if (val._id == data.value.company_setting) {
              this.devices.forEach((val1) => {
                if (val1._id == val.model_id) {
                  this.clientSettingsData[data.index] = {
                    ...this.clientSettingsData[data.index],
                    name: data.value.name.toString().trim(),
                    config_name: val.name,
                    inherit_from_company_settings: val._id,
                    device: val1.device_manuf + " " + val1.device_model,
                  };
                }
              });
            }
          });
          var temp = [...this.clientSettingsData];
          this.clientSettingsData = [...temp];
          this.clientSettings = new MatTableDataSource(this.clientSettingsData);
          this.clientSettings.paginator = this.paginator1;
        }
      });
    }
  }

  clientGlobalSetting(stepper: MatStepper) {
    if (this.clientSettingsData.length > 0) {
      stepper.next();
    } else {
      this.snackBar.error({
        msg: "Add minimum one setting",
        action: "Close",
      });
    }
  }

  addClientGroupSetting() {
    if (this.clientGroupSettingForm.valid) {
      var data = null;
      this.clientSettingsData.forEach((val) => {
        if (this.clientGroupSettingForm.value.client_setting == val._id) {
          data = {
            _id: this.getId("group"),
            group_id: this.clientGroupSettingForm.value.group_id,
            name: this.clientGroupSettingForm.value.setting_name,
            config_name: val.name,
            inherit_from_client_settings: val._id,
            device: val.device,
            created_at: new Date(),
          };
        }
      });
      this.groupSettingsData = [...this.groupSettingsData, data];
      this.groupSettings = new MatTableDataSource(this.groupSettingsData);
      this.groupSettings.paginator = this.paginator2;
      this.clientGroupSettingForm.reset();
      this.clientGroupSettingForm.get("setting_name").clearValidators();
      this.clientGroupSettingForm.get("setting_name").updateValueAndValidity();
      this.clientGroupSettingForm.get("client_setting").clearValidators();
      this.clientGroupSettingForm
        .get("client_setting")
        .updateValueAndValidity();
      this.clientGroupSettingForm.get("group_id").clearValidators();
      this.clientGroupSettingForm.get("group_id").updateValueAndValidity();
    }
  }

  removeClientGroupSetting(_id: number) {
    if (!confirm("Are you sure you want to delete?")) {
      return;
    }
    var index = null;
    for (var i in this.groupSettingsData) {
      if (this.groupSettingsData[i]._id == _id) {
        index = i;
        break;
      }
    }
    if (index) {
      this.groupSettingsData.splice(index, 1);
      this.groupSettings = new MatTableDataSource(this.groupSettingsData);
      this.groupSettings.paginator = this.paginator2;
    }
  }

  editClientGroupSetting(_id: number) {
    var index = null;
    var data = null;
    for (var i in this.groupSettingsData) {
      if (this.groupSettingsData[i]._id == _id) {
        index = i;
        data = this.groupSettingsData[i];
        break;
      }
    }
    if (index) {
      const editDialog = this.dialog.open(EditDialogComponent, {
        disableClose: true,
        width: "40%",
        height: "*0%",
        data: {
          setting: data,
          from: "group",
          index,
          groups: this.groups,
          clientSettingsData: this.clientSettingsData,
        },
      });
      editDialog.afterClosed().subscribe((data) => {
        if (data && data.modified) {
          this.clientSettingsData.forEach((val) => {
            if (data.value.client_setting == val._id) {
              this.groupSettingsData[data.index] = {
                ...this.groupSettingsData[data.index],
                group_id: data.value.group_id,
                name: data.value.name.toString().trim(),
                config_name: val.name,
                inherit_from_client_settings: val._id,
                device: val.device,
              };
            }
          });
          var temp = [...this.groupSettingsData];
          this.groupSettingsData = [...temp];
          this.groupSettings = new MatTableDataSource(this.groupSettingsData);
          this.groupSettings.paginator = this.paginator2;
        }
      });
    }
  }

  clientGroupSetting(stepper: MatStepper) {
    if (this.groupSettingsData.length > 0) {
      stepper.next();
    } else {
      this.snackBar.error({
        msg: "Add minimum one setting",
        action: "Close",
      });
    }
  }

  getGroupSettings(i, val: number) {
    var data = [];
    this.groupSettingsData.forEach((v) => {
      if (v.group_id == val) {
        data.push({
          _id: v._id,
          name: v.name,
        });
      }
    });
    this.groupSetting[i] = data;
  }

  sameAsContactAddress() {
    if (this.clientInfoForm.value.sameAsContactAddress) {
      this.clientInfoForm.controls.clientBillingAddress.setValue(
        this.clientInfoForm.value.clientAddress
      );
      this.clientInfoForm.controls.clientBillingCityStateZip.setValue(
        this.clientInfoForm.value.clientCityStateZip
      );
      this.clientInfoForm.controls.clientBillingCountry.setValue(
        this.clientInfoForm.value.clientCountry
      );
    } else {
      this.clientInfoForm.controls.clientBillingAddress.setValue(null);
      this.clientInfoForm.controls.clientBillingCityStateZip.setValue(null);
      this.clientInfoForm.controls.clientBillingCountry.setValue(null);
    }
  }

  save() {
    if (
      this.clientInfoForm.valid &&
      this.clientGlobalSettingForm.valid &&
      this.clientGroupSettingForm.valid &&
      this.clientExtensionsForm.valid
    ) {
      this.clientExtensionsForm.value.devices.forEach((val, key) => {
        this.clientExtensionsForm.value.devices[
          key
        ].macAddr = this.clientExtensionsForm.value.devices[key].macAddr
          .replace(/:|\s|'|"|;|-|\./g, "")
          .toLowerCase();
      });

      var allMac = [];
      var isDuplicateMac = false;
      for (var i in this.clientExtensionsForm.value.devices) {
        if (
          allMac.includes(this.clientExtensionsForm.value.devices[i].macAddr)
        ) {
          isDuplicateMac = true;
        } else {
          allMac.push(this.clientExtensionsForm.value.devices[i].macAddr);
        }
      }

      if (!isDuplicateMac) {
        this.clientService.duplicateMac(allMac).subscribe((data: any) => {
          if (data.duplicate) {
            isDuplicateMac = true;
            return Swal.fire(
              "Oopsss...",
              `MAC address ${data.macAddr} have already exists for client ${data.client}`,
              "error"
            );
          } else {
            if (!isDuplicateMac) {
              if (this.clientInfoForm.value.sameAsContactAddress) {
                this.clientInfoForm.controls.clientBillingAddress.setValue(
                  this.clientInfoForm.value.clientAddress
                );
                this.clientInfoForm.controls.clientBillingCityStateZip.setValue(
                  this.clientInfoForm.value.clientCityStateZip
                );
                this.clientInfoForm.controls.clientBillingCountry.setValue(
                  this.clientInfoForm.value.clientCountry
                );
              }
              this.finalData = {
                name: this.clientInfoForm.value.clientName,
                email: this.clientInfoForm.value.clientEmail,
                phone: this.clientInfoForm.value.clientPhone,
                address: this.clientInfoForm.value.clientAddress,
                cityStateZip: this.clientInfoForm.value.clientCityStateZip,
                country: this.clientInfoForm.value.clientCountry,
                billingAddress: this.clientInfoForm.value.clientBillingAddress,
                billingCityStateZip: this.clientInfoForm.value
                  .clientBillingCityStateZip,
                billingCountry: this.clientInfoForm.value.clientBillingCountry,
                contactName: this.clientInfoForm.value.clientContactName,
                contactEmail: this.clientInfoForm.value.clientContactEmail,
                contactPhone: this.clientInfoForm.value.clientContactPhone,
                groups: this.groups,
                group_settings: this.groupSettingsData,
                ext_num: parseInt(this.clientInfoForm.value.extNum),
                ext_start: parseInt(this.clientInfoForm.value.extStart),
                blfs: this.clientInfoForm.value.blfs,
                vpbx: this.clientInfoForm.value.vpbx,
                client_settings: this.clientSettingsData,
                client_devices: this.clientExtensionsForm.value,
                created_at: new Date().toString(),
              };

              this.store.dispatch(new CLientAdd({ data: this.finalData }));
              return this.added$.subscribe((data: DbResponse) => {
                if (data) {
                  if (data.ok) {
                    this.dialogRef.close({
                      added: true,
                    });
                  } else {
                    Swal.fire("Oopsss...", "Something went worng", "error");
                  }
                }
              });
            } else {
              return this.snackBar.error({
                msg: "Mac address could not be duplicated ",
                action: "Close",
              });
            }
          }
        });
      } else {
        return Swal.fire("Oopsss...", `Duplicate MAC address found!`, "error");
      }
    } else {
      this.snackBar.error({
        msg: "Fill all required fields!!!",
        action: "Close",
      });
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {}
}
