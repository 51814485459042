import { NgModule } from '@angular/core';
import { Routes,RouterModule } from '@angular/router'
import { IvrComponent} from './ivr.component';
import { AdminComponent } from '../../admin.component';

const routes: Routes=[
    {
    path: 'admin',
    component: AdminComponent,
    data: {
      title: 'ivr',
      status: false
    },
    children: [
      {
        path: 'ivr',
        component: IvrComponent
      }
    ]
    }
];

@NgModule({
    imports: [ RouterModule.forRoot(routes)],
    exports: [ RouterModule ] 

})

export class IvrRoutingModule { }