import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ClientService, readCookie } from "../../services";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { group } from "@angular/animations";
import { ToastrService } from "ngx-toastr";
import { MatDialog } from "@angular/material/dialog";
import { AddGroupComponent } from "./add-group/add-group.component";
import Swal from "sweetalert2";
import { MatSort } from "@angular/material";

@Component({
  selector: "groups",
  templateUrl: "./groups.component.html",
  styleUrls: ["./groups.component.scss"]
})
export class GroupsComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private clientService: ClientService,
    private _toastr: ToastrService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  client_id: string;
  client_name: string;
  groups: any;
  dataSource: any;
  displayedColumns: string[] = ["_id", "name", "created_at", "actions"];

  @ViewChild(MatPaginator, {}) paginator: MatPaginator;
  @ViewChild(MatSort, {}) sort: MatSort;

  ngOnInit() {
    if (!readCookie("authenticate")) {
      this.router.navigate(["/login"],{
        queryParams: { return: location.href },
      });
      return false;
    }
    this.route.params.subscribe(params => {
      this.client_id = params.id;
    });
    this.clientService.getGroups(this.client_id).subscribe(data => {
      this.groups = data;
      this.dataSource = new MatTableDataSource(this.groups);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
    this.clientService
      .getClientName(this.client_id)
      .subscribe((res: string) => {
        this.client_name = res;
      });
  }

  // opening add group dialog
  openAddClientDialog() {
    const addGroupDialog = this.dialog.open(AddGroupComponent, {
      data: {
        client_id: this.client_id
      }
    });
    addGroupDialog.afterClosed().subscribe(data => {
      this.clientService.getGroups(this.client_id).subscribe(data => {
        this.groups = data;
        this.dataSource = new MatTableDataSource(this.groups);
        this.dataSource.paginator = this.paginator;
      });
    });
  }

  remove_group(client: string, group_id: any) {
    if (!confirm("Are you sure?")) {
      return;
    }
    this.clientService.remove_group(client, group_id).subscribe((data: any) => {
      if (data.ok) {
        this._toastr.success("Group is deleted", "", {
          positionClass: "toast-top-center"
        });
        this.clientService.getGroups(this.client_id).subscribe(data => {
          this.groups = data;
          this.dataSource = new MatTableDataSource(this.groups);
          this.dataSource.paginator = this.paginator;
        });
      } else {
        this._toastr.error("Opps! Try again", "", {
          positionClass: "toast-top-center"
        });
      }
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  editGroupName(id: number, name: string) {
    Swal.fire({
      title: "Edit group name",
      input: "text",
      inputValue: name,
      cancelButtonColor: "#EC4849",
      reverseButtons: true,
      confirmButtonColor: "#45CE30",
      showCancelButton: true,
      confirmButtonText: "SAVE",
      cancelButtonText: "CANCEL",
      focusCancel: true,
      allowOutsideClick: false
    }).then((result: any) => {
      if (result.value) {
        this.clientService
          .editGroupName(this.client_id, id, result.value)
          .subscribe((data: any) => {
            if (data) {
              if (data.ok) {
                Swal.fire("Edited!", "Group name has been edited.", "success");
                this.clientService.getGroups(this.client_id).subscribe(data => {
                  this.groups = data;
                  this.dataSource = new MatTableDataSource(this.groups);
                  this.dataSource.paginator = this.paginator;
                });
              } else {
                Swal.fire("Oopsss...", "Something went worng", "error");
              }
            }
          });
      }
    });
  }
}
