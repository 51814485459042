import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator, MatTableDataSource} from '@angular/material';

export interface PeriodicElement {
  caller : string;
  calltype : string;
  duration : string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {caller : '111-222-333', calltype:'Inbound', duration: '02:30'}
  
];

@Component({
  selector: 'app-live-call-monitoring',
  templateUrl: './live-call-monitoring.component.html',
  styleUrls: ['./live-call-monitoring.component.css']
})
export class LiveCallMonitoringComponent implements OnInit {

  displayedColumns: string[] = ['caller','calltype','duration','action'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  constructor() { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
  }

}
