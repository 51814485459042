import { Component, OnInit, ViewChild } from "@angular/core";
import { MoboAlert } from "../../notification";
import { MatPaginator, MatDialog, MatTableDataSource, MatSort } from "@angular/material";
import { AddSettingComponent } from "./add-setting/add-setting.component";
import { ClientService, readCookie } from "../../services";
import { ActivatedRoute, Router } from "@angular/router";
import Swal from "sweetalert2";

@Component({
  selector: "global-settings",
  templateUrl: "./global-settings.component.html",
  styleUrls: ["./global-settings.component.scss"]
})
export class GlobalSettingsComponent implements OnInit {
  dataSource: any;
  displayedColumns: string[] = [
    "name",
    "model_name",
    "base_config",
    "actions"
  ];

  @ViewChild(MatPaginator, {}) paginator: MatPaginator;
  @ViewChild(MatSort, {}) sort: MatSort;

  constructor(
    private alert: MoboAlert,
    private dialog: MatDialog,
    private service: ClientService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  client_id: any;
  client_name: any;
  clientSettings: any;

  ngOnInit() {
    if (!readCookie("authenticate")) {
      this.router.navigate(["/login"],{
        queryParams: { return: location.href },
      });
      return false;
    }
    this.route.params.subscribe((data: any) => {
      this.client_id = data.id;
      this.service.getClientSettings(data.id).subscribe((data: any) => {
        this.clientSettings = data;
        this.dataSource = new MatTableDataSource(this.clientSettings);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
      this.service
      .getClientName(this.client_id)
      .subscribe((res: string) => {
        this.client_name = res;
      });
    });
  }

  openAddSettingDialog() {
    const addSettingDialog = this.dialog.open(AddSettingComponent, {
      data: {
        client_id: this.client_id
      }
    });
    addSettingDialog.afterClosed().subscribe((data: any) => {
      if (data) {
        if (data.added) {
          Swal.fire("Added!", "Setting has been deleted.", "success");
          this.service
            .getClientSettings(this.client_id)
            .subscribe((data: any) => {
              this.clientSettings = data;
              this.dataSource = new MatTableDataSource(this.clientSettings);
              this.dataSource.paginator = this.paginator;
            });
        }
      }
    });
  }

  removeSetting(id: number) {
    this.alert
      .confirm({
        title: "Are you sure?",
        text: "You won't be able to revert this!"
      })
      .then(result => {
        if (result.value) {
          this.service
            .remove_client_setting(this.client_id, id)
            .subscribe((data: any) => {
              if (data) {
                if (data.ok) {
                  Swal.fire("Deleted!", "CLient has been deleted.", "success");
                  this.service
                    .getClientSettings(this.client_id)
                    .subscribe((data: any) => {
                      this.clientSettings = data;
                      this.dataSource = new MatTableDataSource(
                        this.clientSettings
                      );
                      this.dataSource.paginator = this.paginator;
                    });
                } else {
                  Swal.fire("Oopsss...", "Something went worng", "error");
                }
              }
            });
        }
      });
  }

  editClientSettingName(id: number, name: string) {
    Swal.fire({
      title: "Edit client setting name",
      input: "text",
      inputValue: name,
      cancelButtonColor: "#EC4849",
      reverseButtons: true,
      confirmButtonColor: "#45CE30",
      showCancelButton: true,
      confirmButtonText: "SAVE",
      cancelButtonText: "CANCEL",
      focusCancel: true,
      allowOutsideClick: false
    }).then((result: any) => {
      if (result.value) {
        this.service
          .editclientSettingName(this.client_id, id, result.value)
          .subscribe((data: any) => {
            if (data) {
              if (data.ok) {
                Swal.fire(
                  "Edited!",
                  "Client setting name has been edited.",
                  "success"
                );
                this.service
                  .getClientSettings(this.client_id)
                  .subscribe((data: any) => {
                    this.clientSettings = data;
                    this.dataSource = new MatTableDataSource(
                      this.clientSettings
                    );
                    this.dataSource.paginator = this.paginator;
                  });
              } else {
                Swal.fire("Oopsss...", "Something went worng", "error");
              }
            }
          });
      }
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
