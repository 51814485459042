import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DashboardRoutingModule} from "./dashboard-routing.module";
import {DashboardComponent} from "./dashboard.component";
import {ClientModule} from "../client/client.module"; 
import { CommonComponentModule } from '../../common/commonComponent.module';

@NgModule({
  imports: [
    CommonModule,
    DashboardRoutingModule,
    ClientModule,
    CommonComponentModule
  ],
  declarations:[DashboardComponent],
})
export class DashboardModule { }
