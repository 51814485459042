import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Get the JWT token from wherever it is stored (e.g., localStorage, sessionStorage, etc.)
    const token = localStorage.getItem('token'); // You can adjust this based on your token storage mechanism
    if (token) {
      // Clone the request and add the token to the 'Authorization' header
      const clonedRequest = request.clone({
        setHeaders: {
          token: `${token}`,
        },
      });

      // Pass the cloned request to the next interceptor or the HttpClient if no more interceptors exist
      return next.handle(clonedRequest);
    } else {
      // If there's no token, proceed with the original request
      return next.handle(request);
    }
  }
}
