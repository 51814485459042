import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import * as toastr from "toastr";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  profileForm: FormGroup;
  submitted = false;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.profileForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
      confirmEmail: ['', Validators.required],
      backupNo: ['', Validators.required],
    });
  }

  get f() { return this.profileForm.controls; }

  onSubmit() {
      this.submitted = true;

      if (this.profileForm.invalid) {
        toastr.error("Invalid Details !");
        return;
      }
      console.log(this.profileForm.value);

      toastr.success("Form Submitted Successfully !");
      this.profileForm.reset();
  }

}
