import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-connectivity',
  templateUrl: './connectivity.component.html',
  styleUrls: ['./connectivity.component.css']
})
export class ConnectivityComponent implements OnInit {
  
  ngOnInit() {
   
  }

}

