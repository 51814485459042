// Angular imports
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Http, Headers, RequestOptions } from "@angular/http";

// Rxjs imports
import { BehaviorSubject, Observable, Subject } from "rxjs";
import "rxjs/add/operator/map";
import { forkJoin } from "rxjs"; // change to new RxJS 6 import syntax

// Custom import types
import { API } from "./constant";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" })
};

@Injectable({
  providedIn: "root"
})
export class TicketsService {
  update: boolean;
  id: number;
  public updateTicketEventListener = new BehaviorSubject(0);

  constructor(private http: HttpClient) {}

  requestDataFromMultipleSources() {
    let clientListResponse = this.http.get(API.CLIENT_PARENT_DROPDOWN);
    let ticketCategoryResponse = this.http.get(API.TICKETS_CATEGORY_LIST);
    let ticketPriorityResponse = this.http.get(API.TICKETS_PRIORITY_LIST);
    let departmentResponse = this.http.get(API.DEPARTMENT_LIST);
    let employesDDListResponse = this.http.get(API.EMP_DD_LIST);

    return forkJoin([
      clientListResponse,
      ticketCategoryResponse,
      ticketPriorityResponse,
      departmentResponse,
      employesDDListResponse
    ]);
  }

  requestDataFromMultipleSourcesForTicketDetails(ticketsId: number) {
    let ticketsDeatilsRes = this.http.get(API.TICKETS_LIST + "/" + ticketsId);
    let ticketCategoryResponse = this.http.get(API.TICKETS_CATEGORY_LIST);
    let ticketPriorityResponse = this.http.get(API.TICKETS_PRIORITY_LIST);
    let statusResponse = this.http.get(API.TICKET_STATUSES_LIST);
    let employesDDListResponse = this.http.get(API.EMP_DD_LIST);
    let countInDaysInfoResponse = this.http.get(
      API.TICKETS_INFO_IN_COUNT_DAYS + "/" + ticketsId
    );
    let timeWorkedRes = this.http.get(
      API.TICKETS_TIME_WORKED_SPENT + ticketsId
    );

    return forkJoin([
      ticketsDeatilsRes,
      ticketCategoryResponse,
      ticketPriorityResponse,
      statusResponse,
      employesDDListResponse,
      countInDaysInfoResponse,
      timeWorkedRes
    ]);
  }
  statusesList(filter?: string) {
    let url = API.TICKET_STATUSES_LIST;
    if (filter !== "") {
      url = url + filter;
    }
    return this.http.get(url);
  }

  ticketsStatusList(filter?) {
    if (filter) {
      let httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        })
      };
      let params = new HttpParams();
      filter = JSON.stringify(filter);
      params = params.append("filter", filter);
      return this.http.get(API.TICKETS_STATUS_LIST, { params: params });
    } else {
      return this.http.get(API.TICKETS_STATUS_LIST);
    }
  }

  ticketsInfoById(id: number): Observable<any> {
    return this.http.get(API.TICKETS_LIST + "/" + id);
  }

  changeTicketStatus(id: number, statusId: number) {
    let body: any = {};
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.http.post(
      API.TICKETS_CHANGE_STATUS_BY_ID + "/" + id + "/" + statusId,
      body,
      httpOptions
    );
  }

  ticketsInfoInCountDays(id) {
    return this.http.get(API.TICKETS_INFO_IN_COUNT_DAYS + "/" + id);
  }

  ticketsList(filter, moduleName?, moduleId?, isArchived?, billStatus?) {
    let httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    };
    filter = JSON.stringify(filter);
    let params = new HttpParams();
    if (isArchived) {
      params = params.append("isArchived", isArchived);
    }
    if (billStatus) {
      params = params.append("billStatus", billStatus);
    }
    if (moduleName) {
      if (moduleName === "client") {
        params = params.append("moduleName", moduleName);
        params = params.append("moduleId", moduleId);
      }
    }
    params = params.append("filter", filter);
    return this.http.get(API.TICKETS_LIST, { params: params });
  }

  ticketCategoryList() {
    return this.http.get(API.TICKETS_CATEGORY_LIST);
  }

  ticketPriorityList() {
    return this.http.get(API.TICKETS_PRIORITY_LIST);
  }

  employesDDList() {
    return this.http.get(API.EMP_DD_LIST);
  }

  getTicketCode() {
    return this.http.get(API.GET_TICKET_CODE);
  }

  uploadFile(fileObject) {
    let httpHeaders = new HttpHeaders({
      "Content-Type": "multipart/form-data"
    });

    let options = {
      headers: httpHeaders
    };
    return this.http.post(API.ATTACHMENTS_UPLOAD, fileObject);
  }

  clientList() {
    let httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    };
    return this.http.get(API.CLIENT_PARENT_DROPDOWN);
  }

  clientLocationsList(clientId) {
    let httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    };
    return this.http.get(API.TICKET_CLIENT_LOCATION_DD_LIST + clientId);
  }

  clientContacts(clientId) {
    let httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    };
    let params = new HttpParams();
    params = params.append("moduleId", clientId);
    params = params.append("type", "client");
    return this.http.get(API.CLIENT_CONTACT_LIST, { params: params });
  }

  getClientInfo(clientId) {
    return this.http.get(API.CLIENT_DETAILS + clientId);
  }

  createTicket(ticketsData: any, customHeaders?: Function): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    };

    let body = JSON.stringify(ticketsData);
    return this.http.post(API.CREATE_TICKET, body, httpOptions);
  }

  updateTicket(ticketsId: any, ticketsData: any) {
    return this.http.post(API.UPDATE_TICKET + "/" + ticketsId, ticketsData);
  }

  deleteTicket(ticketsId) {
    return this.http.delete(API.DELETE_TICKETS + ticketsId);
  }

  getShareId() {
    return this.id;
  }

  getShareValue() {
    return this.update;
  }

  setShareValue(update, id?) {
    this.update = update;
    this.id = id;
  }

  getTicketsById(ticketsId) {
    return this.http.get(API.TICKETS_LIST + "/" + ticketsId);
  }

  ticketListByClientId(id) {
    return this.http.get(API.TICKETS_DROPDOWN_BY_CLIENTID + "?clientId=" + id);
  }

  ticketChangesCategory(ticketsId: number, ticketCategoryId: number) {
    let body: any = {};
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.http.post(
      API.TICKETS_CHANGE_CATEGORY_BY_ID + ticketsId + "/" + ticketCategoryId,
      body,
      httpOptions
    );
  }

  ticketChangesPriority(ticketsId: number, prioritiesId: number) {
    let body: any = {};
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.http.post(
      API.TICKETS_CHANGE_PRIORITIES_BY_ID + ticketsId + "/" + prioritiesId,
      body,
      httpOptions
    );
  }

  ticketChangesBillStatus(ticketsId: number, billStatus: number) {
    let body: any = {};
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.http.post(
      API.TICKETS_CHANGE_BILL_STATUS_BY_ID + ticketsId + "/" + billStatus,
      body,
      httpOptions
    );
  }

  ticketChangesLockUnlockStatus(ticketsId: number, lockStatus: number) {
    let body: any = {};
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.http.post(
      API.TICKETS_CHANGE_LOCK_UNLOCK_STATUS_BY_ID +
        ticketsId +
        "/" +
        lockStatus,
      body,
      httpOptions
    );
  }

  ticketsNoOfDays(ticketsId, stageId?) {
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    let params = new HttpParams();
    params = params.append("moduleName", "ticket");
    params = params.append("moduleId", ticketsId);
    if (stageId) {
      params = params.append("stageId", stageId);
    }
    return this.http.get(API.TIMEBAR_NO_OF_DAYS_LIST, { params: params });
  }

  ticketsRepliesList(ticketsId: any) {
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    let params = new HttpParams();
    params = params.append("ticketsId", ticketsId);
    return this.http.get(API.TICKETS_REPLIES_LIST, { params: params });
  }
  deleteTicketReplies(ticketsRepliesId) {
    return this.http.delete(API.TICKETS_REPLIES_DELETE + ticketsRepliesId);
  }
}
