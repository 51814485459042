import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator, MatTableDataSource} from '@angular/material';

export interface PeriodicElement {
  user : string;
  hours : number
}

const ELEMENT_DATA: PeriodicElement[] = [
  {user : 'Test', hours:1},
  {user : 'Test', hours:2}
  
];
@Component({
  selector: 'app-queue-reports',
  templateUrl: './queue-reports.component.html',
  styleUrls: ['./queue-reports.component.css']
})
export class QueueReportsComponent implements OnInit {

  displayedColumns: string[] = ['user','hours'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  constructor() { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
  }

}
