import { NgModule } from '@angular/core';
import {ClientComponent } from '../client.component';
import { Routes,RouterModule } from '@angular/router'
import { PermissionsusersComponent } from "./permissionsusers/permissionsusers.component";
import { ProvisionerComponent } from "./provisioner/provisioner.component";
import { IntrusionDetectionComponent } from "./intrusion-detection/intrusion-detection.component";
const routes: Routes=[
    {
    path: 'client',
    component: ClientComponent,
    data: {
      title: 'client',
      status: false
    },
    children: [
      {
        path: 'settings',
        children: [
          {
            path : 'permissions-users',
            component: PermissionsusersComponent,
          },
          {
            path : 'provisioner',
            component: ProvisionerComponent,
          },
          {
            path : 'intrusion-detection',
            component: IntrusionDetectionComponent,
          },
        ]
      }
    ]
    }
];

@NgModule({
    imports: [ RouterModule.forChild(routes)],
    exports: [ RouterModule ] 

})

export class SettingsRoutingModule {}
