import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-provisioner',
  templateUrl: './provisioner.component.html',
  styleUrls: ['./provisioner.component.css']
})
export class ProvisionerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
