import { Injectable, OnDestroy, Injector } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material";
import { Common } from "../../../service/constant";
import { AppComponentBase } from "../../shared/AppComponentBase";
import { ImageMapCoordinate } from "../image-map/image-map.component";

@Injectable()
export class DialogService extends AppComponentBase {
  stack: MatDialogRef<any>[] = [];

  constructor(public dialog: MatDialog, inject: Injector) {
    super(inject);
  }

  open(
    content: any,
    dialogConfig: MatDialogConfig = {},
    data: any = {}
  ): MatDialogRef<any> {
    dialogConfig = Common.matDialogConfig;
    dialogConfig.panelClass = [
      "green-light",
      "animated",
      "slideInRight",
      "full-screen-modal",
    ];
    dialogConfig["data"] = data;
    const dialog = this.dialog.open(content, dialogConfig);
    this.stack.push(dialog);
    return dialog;
  }

  clear() {
    this.stack.forEach((dialogRef) => dialogRef.close());
    this.stack = [];
  }
}

export const COORDINATES: ImageMapCoordinate[] = [
  {
    name: "Line key 1",
    x: 406,
    y: 76,
    width: 45,
    height: 22,
    type: "Line key",
    values: [
      {
        setting_name: "Account Name",
        setting_description: "Name of the account, displayed on the phone.",
        setting_variable_name: "P270",
        setting_variable_type: "2",
      },
      {
        setting_name: "SIP Server",
        setting_description: "Address of the SIP server/PBX.",
        setting_variable_name: "P47",
        setting_variable_type: "2",
      },
      {
        setting_name: "Secondary SIP Server",
        setting_description: "Address of the Secondary SIP Server/PBX.",
        setting_variable_name: "P2312",
        setting_variable_type: "2",
      },
      {
        setting_name: "Outbound Proxy",
        setting_description: "In case a proxy is used.",
        setting_variable_name: "P48",
        setting_variable_type: "2",
      },
      {
        setting_name: "BLF Server",
        setting_description:
          "Only if you have a specific Busy Lamp Field server.",
        setting_variable_name: "P2375",
        setting_variable_type: "2",
      },
      {
        setting_name: "SIP User ID",
        setting_description: "Usually the extension number.",
        setting_variable_name: "P35",
        setting_variable_type: "2",
      },
      {
        setting_name: "Auth ID",
        setting_description: "Usually the extension number.",
        setting_variable_name: "P36",
        setting_variable_type: "2",
      },
      {
        setting_name: "Auth Password",
        setting_description: "Password or secret set on the PBX.",
        setting_variable_name: "P34",
        setting_variable_type: "2",
      },
      {
        setting_name: "Name",
        setting_description: "Name of the account, not displayed on the phone.",
        setting_variable_name: "P3",
        setting_variable_type: "2",
      },
      {
        setting_name: "Voicemail UserID",
        setting_description: "Dial this to get voicemail. Asterisk is *97.",
        setting_variable_name: "P33",
        setting_variable_type: "2",
      },
      {
        setting_name: "Show Account ID Only?",
        setting_description:
          "Select NO to only show the account name, no extension.",
        setting_variable_name: "P2380",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "No",
          },
          {
            value: "1",
            name: "Yes",
          },
        ],
      },
    ],
  },
  {
    name: "Line key 2",
    x: 406,
    y: 108,
    width: 45,
    height: 22,
    type: "Line key",
    values: [
      {
        setting_name: "Account Active?",
        setting_description: "Mark this account as active or inactive.",
        setting_variable_name: "P401",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "No",
          },
          {
            value: "1",
            name: "Yes",
          },
        ],
      },
      {
        setting_name: "Account Name",
        setting_description: "Name of the account, displayed on the phone.",
        setting_variable_name: "P417",
        setting_variable_type: "2",
      },
      {
        setting_name: "SIP Server",
        setting_description: "Address of the SIP server/PBX.",
        setting_variable_name: "P402",
        setting_variable_type: "2",
      },
      {
        setting_name: "Secondary SIP Server",
        setting_description: "Address of the Secondary SIP Server/PBX.",
        setting_variable_name: "P2412",
        setting_variable_type: "2",
      },
      {
        setting_name: "Outbound Proxy",
        setting_description: "In case a proxy is used.",
        setting_variable_name: "P403",
        setting_variable_type: "2",
      },
      {
        setting_name: "BLF Server",
        setting_description:
          "Only if you have a specific Busy Lamp Field server.",
        setting_variable_name: "P2475",
        setting_variable_type: "2",
      },
      {
        setting_name: "SIP User ID",
        setting_description: "Usually the extension number.",
        setting_variable_name: "P404",
        setting_variable_type: "2",
      },
      {
        setting_name: "Auth ID",
        setting_description: "Usually the extension number.",
        setting_variable_name: "P405",
        setting_variable_type: "2",
      },
      {
        setting_name: "Auth Password",
        setting_description: "Password or secret set on the PBX.",
        setting_variable_name: "P406",
        setting_variable_type: "2",
      },
      {
        setting_name: "Name",
        setting_description: "Name of the account, not displayed on the phone.",
        setting_variable_name: "P407",
        setting_variable_type: "2",
      },
      {
        setting_name: "Voicemail UserID",
        setting_description: "Dial this to get voicemail. Asterisk is *97.",
        setting_variable_name: "P426",
        setting_variable_type: "2",
      },
      {
        setting_name: "Show Account Name Only?",
        setting_description:
          "Select YES to only show the account name, no extension.",
        setting_variable_name: "P2480",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "No",
          },
          {
            value: "1",
            name: "Yes",
          },
        ],
      },
    ],
  },
  {
    name: "Line key 3",
    x: 406,
    y: 140,
    width: 45,
    height: 22,
    type: "Line key",
    values: [
      {
        setting_name: "Account Active?",
        setting_description: "Mark this account as active or inactive.",
        setting_variable_name: "P501",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "No",
          },
          {
            value: "1",
            name: "Yes",
          },
        ],
      },
      {
        setting_name: "Account Name",
        setting_description: "Name of the account, displayed on the phone.",
        setting_variable_name: "P517",
        setting_variable_type: "2",
      },
      {
        setting_name: "SIP Server",
        setting_description: "Address of the SIP server/PBX.",
        setting_variable_name: "P502",
        setting_variable_type: "2",
      },
      {
        setting_name: "Secondary SIP Server",
        setting_description: "Address of the Secondary SIP Server/PBX.",
        setting_variable_name: "P2512",
        setting_variable_type: "2",
      },
      {
        setting_name: "Outbound Proxy",
        setting_description: "In case a proxy is used.",
        setting_variable_name: "P503",
        setting_variable_type: "2",
      },
      {
        setting_name: "BLF Server",
        setting_description:
          "Only if you have a specific Busy Lamp Field server.",
        setting_variable_name: "P2575",
        setting_variable_type: "2",
      },
      {
        setting_name: "SIP User ID",
        setting_description: "Usually the extension number.",
        setting_variable_name: "P504",
        setting_variable_type: "2",
      },
      {
        setting_name: "Auth ID",
        setting_description: "Usually the extension number.",
        setting_variable_name: "P505",
        setting_variable_type: "2",
      },
      {
        setting_name: "Auth Password",
        setting_description: "Password or secret set on the PBX.",
        setting_variable_name: "P506",
        setting_variable_type: "2",
      },
      {
        setting_name: "Name",
        setting_description: "Name of the account, not displayed on the phone.",
        setting_variable_name: "P507",
        setting_variable_type: "2",
      },
      {
        setting_name: "Voicemail UserID",
        setting_description: "Dial this to get voicemail. Asterisk is *97.",
        setting_variable_name: "P526",
        setting_variable_type: "2",
      },
      {
        setting_name: "Show Account Name Only?",
        setting_description:
          "Select YES to only show the account name, no extension.",
        setting_variable_name: "P2580",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "No",
          },
          {
            value: "1",
            name: "Yes",
          },
        ],
      },
    ],
  },
  {
    name: "Line key 4",
    x: 406,
    y: 172,
    width: 45,
    height: 22,
    type: "Line key",
    values: [
      {
        setting_name: "Account Active?",
        setting_description: "Mark this account as active or inactive.",
        setting_variable_name: "P601",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "No",
          },
          {
            value: "1",
            name: "Yes",
          },
        ],
      },
      {
        setting_name: "Account Name",
        setting_description: "Name of the account, displayed on the phone.",
        setting_variable_name: "P617",
        setting_variable_type: "2",
      },
      {
        setting_name: "SIP Server",
        setting_description: "Address of the SIP server/PBX.",
        setting_variable_name: "P602",
        setting_variable_type: "2",
      },
      {
        setting_name: "Secondary SIP Server",
        setting_description: "Address of the Secondary SIP Server/PBX.",
        setting_variable_name: "P2612",
        setting_variable_type: "2",
      },
      {
        setting_name: "Outbound Proxy",
        setting_description: "In case a proxy is used.",
        setting_variable_name: "P603",
        setting_variable_type: "2",
      },
      {
        setting_name: "BLF Server",
        setting_description:
          "Only if you have a specific Busy Lamp Field server.",
        setting_variable_name: "P2675",
        setting_variable_type: "2",
      },
      {
        setting_name: "SIP User ID",
        setting_description: "Usually the extension number.",
        setting_variable_name: "P604",
        setting_variable_type: "2",
      },
      {
        setting_name: "Auth ID",
        setting_description: "Usually the extension number.",
        setting_variable_name: "P605",
        setting_variable_type: "2",
      },
      {
        setting_name: "Auth Password",
        setting_description: "Password or secret set on the PBX.",
        setting_variable_name: "P606",
        setting_variable_type: "2",
      },
      {
        setting_name: "Name",
        setting_description: "Name of the account, not displayed on the phone.",
        setting_variable_name: "P607",
        setting_variable_type: "2",
      },
      {
        setting_name: "Voicemail UserID",
        setting_description: "Dial this to get voicemail. Asterisk is *97.",
        setting_variable_name: "P626",
        setting_variable_type: "2",
      },
      {
        setting_name: "Show Account Name Only?",
        setting_description:
          "Select YES to only show the account name, no extension.",
        setting_variable_name: "P2680",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "No",
          },
          {
            value: "1",
            name: "Yes",
          },
        ],
      },
    ],
  },
  {
    name: "Line key 5",
    x: 406,
    y: 204,
    width: 45,
    height: 22,
    type: "Line key",
    values: [
      {
        setting_name: "Account Active?",
        setting_description: "Mark this account as active or inactive.",
        setting_variable_name: "P1701",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "1",
            name: "Yes",
          },
          {
            value: "0",
            name: "No",
          },
        ],
      },
      {
        setting_name: "Account Name",
        setting_description: "Name of the account, displayed on the phone.",
        setting_variable_name: "P1717",
        setting_variable_type: "2",
      },
      {
        setting_name: "SIP Server",
        setting_description: "Address of the SIP server/PBX.",
        setting_variable_name: "P1702",
        setting_variable_type: "2",
      },
      {
        setting_name: "Secondary SIP Server",
        setting_description: "Address of the Secondary SIP Server/PBX.",
        setting_variable_name: "P2712",
        setting_variable_type: "2",
      },
      {
        setting_name: "Outbound Proxy",
        setting_description: "In case a proxy is used.",
        setting_variable_name: "P1703",
        setting_variable_type: "2",
      },
      {
        setting_name: "BLF Server",
        setting_description:
          "Only if you have a specific Busy Lamp Field server.",
        setting_variable_name: "P2775",
        setting_variable_type: "2",
      },
      {
        setting_name: "SIP User ID",
        setting_description: "Usually the extension number.",
        setting_variable_name: "P1704",
        setting_variable_type: "2",
      },
      {
        setting_name: "Auth ID",
        setting_description: "Usually the extension number.",
        setting_variable_name: "P1705",
        setting_variable_type: "2",
      },
      {
        setting_name: "Auth Password",
        setting_description: "Password or secret set on the PBX.",
        setting_variable_name: "P1706",
        setting_variable_type: "2",
      },
      {
        setting_name: "Name",
        setting_description: "Name of the account, not displayed on the phone.",
        setting_variable_name: "P1707",
        setting_variable_type: "2",
      },
      {
        setting_name: "Voicemail UserID",
        setting_description: "Dial this to get voicemail. Asterisk is *97.",
        setting_variable_name: "P1726",
        setting_variable_type: "2",
      },
      {
        setting_name: "Show Account Name Only?",
        setting_description:
          "Select YES to only show the account name, no extension.",
        setting_variable_name: "P2780",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "No",
          },
          {
            value: "1",
            name: "Yes",
          },
        ],
      },
    ],
  },
  {
    name: "Line key 6",
    x: 406,
    y: 239,
    width: 45,
    height: 22,
    type: "Line key",
    values: [
      {
        setting_name: "Account Active?",
        setting_description: "Mark this account as active or inactive.",
        setting_variable_name: "P1801",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "No",
          },
          {
            value: "1",
            name: "Yes",
          },
        ],
      },
      {
        setting_name: "Account Name",
        setting_description: "Name of the account, displayed on the phone.",
        setting_variable_name: "P1817",
        setting_variable_type: "2",
      },
      {
        setting_name: "SIP Server",
        setting_description: "Address of the SIP server/PBX.",
        setting_variable_name: "P1802",
        setting_variable_type: "2",
      },
      {
        setting_name: "Secondary SIP Server",
        setting_description: "Address of the Secondary SIP Server/PBX.",
        setting_variable_name: "P2812",
        setting_variable_type: "2",
      },
      {
        setting_name: "Outbound Proxy",
        setting_description: "In case a proxy is used.",
        setting_variable_name: "P1803",
        setting_variable_type: "2",
      },
      {
        setting_name: "BLF Server",
        setting_description:
          "Only if you have a specific Busy Lamp Field server.",
        setting_variable_name: "P2875",
        setting_variable_type: "2",
      },
      {
        setting_name: "SIP User ID",
        setting_description: "Usually the extension number.",
        setting_variable_name: "P1804",
        setting_variable_type: "2",
      },
      {
        setting_name: "Auth ID",
        setting_description: "Usually the extension number.",
        setting_variable_name: "P1805",
        setting_variable_type: "2",
      },
      {
        setting_name: "Auth Password",
        setting_description: "Password or secret set on the PBX.",
        setting_variable_name: "P1806",
        setting_variable_type: "2",
      },
      {
        setting_name: "Name",
        setting_description: "Name of the account, not displayed on the phone.",
        setting_variable_name: "P1807",
        setting_variable_type: "2",
      },
      {
        setting_name: "Voicemail UserID",
        setting_description: "Dial this to get voicemail. Asterisk is *97.",
        setting_variable_name: "P1826",
        setting_variable_type: "2",
      },
      {
        setting_name: "Show Account Name Only?",
        setting_description:
          "Select YES to only show the account name, no extension.",
        setting_variable_name: "P2880",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "No",
          },
          {
            value: "1",
            name: "Yes",
          },
        ],
      },
    ],
  },
  {
    name: "BLF key 1",
    x: 855,
    y: 226,
    width: 45,
    height: 22,
    type: "BLF key",
    values: [
      {
        setting_name: "MPK 1 Mode",
        setting_description: "",
        setting_variable_name: "P323",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "-1",
            name: "None",
          },
          {
            value: "0",
            name: "Speed Dial",
          },
          {
            value: "1",
            name: "Busy Lamp Field (BLF)",
          },
          {
            value: "2",
            name: "Presence Watcher",
          },
          {
            value: "3",
            name: "Eventlist BLF",
          },
          {
            value: "4",
            name: "Speed Dial via Active Account",
          },
          {
            value: "5",
            name: "Dial DTMF",
          },
          {
            value: "6",
            name: "Voicemail",
          },
          {
            value: "7",
            name: "Call Return",
          },
          {
            value: "8",
            name: "Transfer",
          },
          {
            value: "9",
            name: "Call Park",
          },
          {
            value: "10",
            name: "Intercom",
          },
          {
            value: "11",
            name: "LDAP Search",
          },
          {
            value: "12",
            name: "Confrence",
          },
          {
            value: "13",
            name: "Multicast Paging",
          },
          {
            value: "14",
            name: "Record",
          },
          {
            value: "15",
            name: "Call Log",
          },
          {
            value: "16",
            name: "Monitored Call Park",
          },
          {
            value: "17",
            name: "Menu",
          },
        ],
      },
      {
        setting_name: "MPK 1 Account",
        setting_description: "",
        setting_variable_name: "P301",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "Account 1",
          },
          {
            value: "1",
            name: "Account 2",
          },
          {
            value: "2",
            name: "Account 3",
          },
          {
            value: "3",
            name: "Account 4",
          },
          {
            value: "4",
            name: "Account 5",
          },
          {
            value: "5",
            name: "Account 6",
          },
        ],
      },
      {
        setting_name: "MPK 1 Name",
        setting_description: "Max: 128 chars",
        setting_variable_name: "P302",
        setting_variable_type: "2",
      },
      {
        setting_name: "MPK 1 User ID",
        setting_description: "Max: 64 chars",
        setting_variable_name: "P303",
        setting_variable_type: "2",
      },
    ],
  },
  {
    name: "BLF key 2",
    x: 855,
    y: 258,
    width: 45,
    height: 22,
    type: "BLF key",
    values: [
      {
        setting_name: "MPK 2 Mode",
        setting_description: "",
        setting_variable_name: "P324",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "-1",
            name: "None",
          },
          {
            value: "0",
            name: "Speed Dial",
          },
          {
            value: "1",
            name: "Busy Lamp Field (BLF)",
          },
          {
            value: "2",
            name: "Presence Watcher",
          },
          {
            value: "3",
            name: "Eventlist BLF",
          },
          {
            value: "4",
            name: "Speed Dial via Active Account",
          },
          {
            value: "5",
            name: "Dial DTMF",
          },
          {
            value: "6",
            name: "Voicemail",
          },
          {
            value: "7",
            name: "Call Return",
          },
          {
            value: "8",
            name: "Transfer",
          },
          {
            value: "9",
            name: "Call Park",
          },
          {
            value: "10",
            name: "Intercom",
          },
          {
            value: "11",
            name: "LDAP Search",
          },
          {
            value: "12",
            name: "Confrence",
          },
          {
            value: "13",
            name: "Multicast Paging",
          },
          {
            value: "14",
            name: "Record",
          },
          {
            value: "15",
            name: "Call Log",
          },
          {
            value: "16",
            name: "Monitored Call Park",
          },
          {
            value: "17",
            name: "Menu",
          },
        ],
      },
      {
        setting_name: "MPK 2 Account",
        setting_description: "",
        setting_variable_name: "P304",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "Account 1",
          },
          {
            value: "1",
            name: "Account 2",
          },
          {
            value: "2",
            name: "Account 3",
          },
          {
            value: "3",
            name: "Account 4",
          },
          {
            value: "4",
            name: "Account 5",
          },
          {
            value: "5",
            name: "Account 6",
          },
        ],
      },
      {
        setting_name: "MPK 2 Name",
        setting_description: "Max: 128 chars",
        setting_variable_name: "P305",
        setting_variable_type: "2",
      },
      {
        setting_name: "MPK 2 User ID",
        setting_description: "Max: 64 chars",
        setting_variable_name: "P306",
        setting_variable_type: "2",
      },
    ],
  },
  {
    name: "BLF key 3",
    x: 855,
    y: 294,
    width: 45,
    height: 22,
    type: "BLF key",
    values: [
      {
        setting_name: "MPK 3 Mode",
        setting_description: "",
        setting_variable_name: "P325",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "-1",
            name: "None",
          },
          {
            value: "0",
            name: "Speed Dial",
          },
          {
            value: "1",
            name: "Busy Lamp Field (BLF)",
          },
          {
            value: "2",
            name: "Presence Watcher",
          },
          {
            value: "3",
            name: "Eventlist BLF",
          },
          {
            value: "4",
            name: "Speed Dial via Active Account",
          },
          {
            value: "5",
            name: "Dial DTMF",
          },
          {
            value: "6",
            name: "Voicemail",
          },
          {
            value: "7",
            name: "Call Return",
          },
          {
            value: "8",
            name: "Transfer",
          },
          {
            value: "9",
            name: "Call Park",
          },
          {
            value: "10",
            name: "Intercom",
          },
          {
            value: "11",
            name: "LDAP Search",
          },
          {
            value: "12",
            name: "Confrence",
          },
          {
            value: "13",
            name: "Multicast Paging",
          },
          {
            value: "14",
            name: "Record",
          },
          {
            value: "15",
            name: "Call Log",
          },
          {
            value: "16",
            name: "Monitored Call Park",
          },
          {
            value: "17",
            name: "Menu",
          },
        ],
      },
      {
        setting_name: "MPK 3 Account",
        setting_description: "",
        setting_variable_name: "P307",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "Account 1",
          },
          {
            value: "1",
            name: "Account 2",
          },
          {
            value: "2",
            name: "Account 3",
          },
          {
            value: "3",
            name: "Account 4",
          },
          {
            value: "4",
            name: "Account 5",
          },
          {
            value: "5",
            name: "Account 6",
          },
        ],
      },
      {
        setting_name: "MPK 3 Name",
        setting_description: "Max: 128 chars",
        setting_variable_name: "P308",
        setting_variable_type: "2",
      },
      {
        setting_name: "MPK 3 User ID",
        setting_description: "Max: 64 chars",
        setting_variable_name: "P309",
        setting_variable_type: "2",
      },
    ],
  },
  {
    name: "BLF key 4",
    x: 855,
    y: 330,
    width: 45,
    height: 22,
    type: "BLF key",
    values: [
      {
        setting_name: "MPK 4 Mode",
        setting_description: "",
        setting_variable_name: "P326",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "-1",
            name: "None",
          },
          {
            value: "0",
            name: "Speed Dial",
          },
          {
            value: "1",
            name: "Busy Lamp Field (BLF)",
          },
          {
            value: "2",
            name: "Presence Watcher",
          },
          {
            value: "3",
            name: "Eventlist BLF",
          },
          {
            value: "4",
            name: "Speed Dial via Active Account",
          },
          {
            value: "5",
            name: "Dial DTMF",
          },
          {
            value: "6",
            name: "Voicemail",
          },
          {
            value: "7",
            name: "Call Return",
          },
          {
            value: "8",
            name: "Transfer",
          },
          {
            value: "9",
            name: "Call Park",
          },
          {
            value: "10",
            name: "Intercom",
          },
          {
            value: "11",
            name: "LDAP Search",
          },
          {
            value: "12",
            name: "Confrence",
          },
          {
            value: "13",
            name: "Multicast Paging",
          },
          {
            value: "14",
            name: "Record",
          },
          {
            value: "15",
            name: "Call Log",
          },
          {
            value: "16",
            name: "Monitored Call Park",
          },
          {
            value: "17",
            name: "Menu",
          },
        ],
      },
      {
        setting_name: "MPK 4 Account",
        setting_description: "",
        setting_variable_name: "P310",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "Account 1",
          },
          {
            value: "1",
            name: "Account 2",
          },
          {
            value: "2",
            name: "Account 3",
          },
          {
            value: "3",
            name: "Account 4",
          },
          {
            value: "4",
            name: "Account 5",
          },
          {
            value: "5",
            name: "Account 6",
          },
        ],
      },
      {
        setting_name: "MPK 4 Name",
        setting_description: "Max: 128 chars",
        setting_variable_name: "P311",
        setting_variable_type: "2",
      },
      {
        setting_name: "MPK 4 User ID",
        setting_description: "Max: 64 chars",
        setting_variable_name: "P312",
        setting_variable_type: "2",
      },
    ],
  },
  {
    name: "BLF key 5",
    x: 855,
    y: 364,
    width: 45,
    height: 22,
    type: "BLF key",
    values: [
      {
        setting_name: "MPK 5 Mode",
        setting_description: "",
        setting_variable_name: "P327",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "-1",
            name: "None",
          },
          {
            value: "0",
            name: "Speed Dial",
          },
          {
            value: "1",
            name: "Busy Lamp Field (BLF)",
          },
          {
            value: "2",
            name: "Presence Watcher",
          },
          {
            value: "3",
            name: "Eventlist BLF",
          },
          {
            value: "4",
            name: "Speed Dial via Active Account",
          },
          {
            value: "5",
            name: "Dial DTMF",
          },
          {
            value: "6",
            name: "Voicemail",
          },
          {
            value: "7",
            name: "Call Return",
          },
          {
            value: "8",
            name: "Transfer",
          },
          {
            value: "9",
            name: "Call Park",
          },
          {
            value: "10",
            name: "Intercom",
          },
          {
            value: "11",
            name: "LDAP Search",
          },
          {
            value: "12",
            name: "Confrence",
          },
          {
            value: "13",
            name: "Multicast Paging",
          },
          {
            value: "14",
            name: "Record",
          },
          {
            value: "15",
            name: "Call Log",
          },
          {
            value: "16",
            name: "Monitored Call Park",
          },
          {
            value: "17",
            name: "Menu",
          },
        ],
      },
      {
        setting_name: "MPK 5 Account",
        setting_description: "",
        setting_variable_name: "P313",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "Account 1",
          },
          {
            value: "1",
            name: "Account 2",
          },
          {
            value: "2",
            name: "Account 3",
          },
          {
            value: "3",
            name: "Account 4",
          },
          {
            value: "4",
            name: "Account 5",
          },
          {
            value: "5",
            name: "Account 6",
          },
        ],
      },
      {
        setting_name: "MPK 5 Name",
        setting_description: "Max: 128 chars",
        setting_variable_name: "P314",
        setting_variable_type: "2",
      },
      {
        setting_name: "MPK 5 User ID",
        setting_description: "Max: 64 chars",
        setting_variable_name: "P315",
        setting_variable_type: "2",
      },
    ],
  },
  {
    name: "BLF key 6",
    x: 855,
    y: 398,
    width: 45,
    height: 22,
    type: "BLF key",
    values: [
      {
        setting_name: "MPK 6 Mode",
        setting_description: "",
        setting_variable_name: "P328",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "-1",
            name: "None",
          },
          {
            value: "0",
            name: "Speed Dial",
          },
          {
            value: "1",
            name: "Busy Lamp Field (BLF)",
          },
          {
            value: "2",
            name: "Presence Watcher",
          },
          {
            value: "3",
            name: "Eventlist BLF",
          },
          {
            value: "4",
            name: "Speed Dial via Active Account",
          },
          {
            value: "5",
            name: "Dial DTMF",
          },
          {
            value: "6",
            name: "Voicemail",
          },
          {
            value: "7",
            name: "Call Return",
          },
          {
            value: "8",
            name: "Transfer",
          },
          {
            value: "9",
            name: "Call Park",
          },
          {
            value: "10",
            name: "Intercom",
          },
          {
            value: "11",
            name: "LDAP Search",
          },
          {
            value: "12",
            name: "Confrence",
          },
          {
            value: "13",
            name: "Multicast Paging",
          },
          {
            value: "14",
            name: "Record",
          },
          {
            value: "15",
            name: "Call Log",
          },
          {
            value: "16",
            name: "Monitored Call Park",
          },
          {
            value: "17",
            name: "Menu",
          },
        ],
      },
      {
        setting_name: "MPK 6 Account",
        setting_description: "",
        setting_variable_name: "P316",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "Account 1",
          },
          {
            value: "1",
            name: "Account 2",
          },
          {
            value: "2",
            name: "Account 3",
          },
          {
            value: "3",
            name: "Account 4",
          },
          {
            value: "4",
            name: "Account 5",
          },
          {
            value: "5",
            name: "Account 6",
          },
        ],
      },
      {
        setting_name: "MPK 6 Name",
        setting_description: "Max: 128 chars",
        setting_variable_name: "P317",
        setting_variable_type: "2",
      },
      {
        setting_name: "MPK 6 User ID",
        setting_description: "Max: 64 chars",
        setting_variable_name: "P318",
        setting_variable_type: "2",
      },
    ],
  },
  {
    name: "BLF key 7",
    x: 855,
    y: 432,
    width: 45,
    height: 22,
    type: "BLF key",
    values: [
      {
        setting_name: "MPK 7 Mode",
        setting_description: "",
        setting_variable_name: "P329",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "-1",
            name: "None",
          },
          {
            value: "0",
            name: "Speed Dial",
          },
          {
            value: "1",
            name: "Busy Lamp Field (BLF)",
          },
          {
            value: "2",
            name: "Presence Watcher",
          },
          {
            value: "3",
            name: "Eventlist BLF",
          },
          {
            value: "4",
            name: "Speed Dial via Active Account",
          },
          {
            value: "5",
            name: "Dial DTMF",
          },
          {
            value: "6",
            name: "Voicemail",
          },
          {
            value: "7",
            name: "Call Return",
          },
          {
            value: "8",
            name: "Transfer",
          },
          {
            value: "9",
            name: "Call Park",
          },
          {
            value: "10",
            name: "Intercom",
          },
          {
            value: "11",
            name: "LDAP Search",
          },
          {
            value: "12",
            name: "Confrence",
          },
          {
            value: "13",
            name: "Multicast Paging",
          },
          {
            value: "14",
            name: "Record",
          },
          {
            value: "15",
            name: "Call Log",
          },
          {
            value: "16",
            name: "Monitored Call Park",
          },
          {
            value: "17",
            name: "Menu",
          },
        ],
      },
      {
        setting_name: "MPK 7 Account",
        setting_description: "",
        setting_variable_name: "P319",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "Account 1",
          },
          {
            value: "1",
            name: "Account 2",
          },
          {
            value: "2",
            name: "Account 3",
          },
          {
            value: "3",
            name: "Account 4",
          },
          {
            value: "4",
            name: "Account 5",
          },
          {
            value: "5",
            name: "Account 6",
          },
        ],
      },
      {
        setting_name: "MPK 7 Name",
        setting_description: "Max: 128 chars",
        setting_variable_name: "P320",
        setting_variable_type: "2",
      },
      {
        setting_name: "MPK 7 User ID",
        setting_description: "Max: 64 chars",
        setting_variable_name: "P321",
        setting_variable_type: "2",
      },
    ],
  },
  {
    name: "BLF key 8",
    x: 855,
    y: 466,
    width: 45,
    height: 22,
    type: "BLF key",
    values: [
      {
        setting_name: "MPK 8 Mode",
        setting_description: "",
        setting_variable_name: "P353",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "-1",
            name: "None",
          },
          {
            value: "0",
            name: "Speed Dial",
          },
          {
            value: "1",
            name: "Busy Lamp Field (BLF)",
          },
          {
            value: "2",
            name: "Presence Watcher",
          },
          {
            value: "3",
            name: "Eventlist BLF",
          },
          {
            value: "4",
            name: "Speed Dial via Active Account",
          },
          {
            value: "5",
            name: "Dial DTMF",
          },
          {
            value: "6",
            name: "Voicemail",
          },
          {
            value: "7",
            name: "Call Return",
          },
          {
            value: "8",
            name: "Transfer",
          },
          {
            value: "9",
            name: "Call Park",
          },
          {
            value: "10",
            name: "Intercom",
          },
          {
            value: "11",
            name: "LDAP Search",
          },
          {
            value: "12",
            name: "Confrence",
          },
          {
            value: "13",
            name: "Multicast Paging",
          },
          {
            value: "14",
            name: "Record",
          },
          {
            value: "15",
            name: "Call Log",
          },
          {
            value: "16",
            name: "Monitored Call Park",
          },
          {
            value: "17",
            name: "Menu",
          },
        ],
      },
      {
        setting_name: "MPK 8 Account",
        setting_description: "",
        setting_variable_name: "P354",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "Account 1",
          },
          {
            value: "1",
            name: "Account 2",
          },
          {
            value: "2",
            name: "Account 3",
          },
          {
            value: "3",
            name: "Account 4",
          },
          {
            value: "4",
            name: "Account 5",
          },
          {
            value: "5",
            name: "Account 6",
          },
        ],
      },
      {
        setting_name: "MPK 8 Name",
        setting_description: "Max: 128 chars",
        setting_variable_name: "P355",
        setting_variable_type: "2",
      },
      {
        setting_name: "MPK 8 User ID",
        setting_description: "Max: 64 chars",
        setting_variable_name: "P356",
        setting_variable_type: "2",
      },
    ],
  },
  {
    name: "BLF key 9",
    x: 855,
    y: 500,
    width: 45,
    height: 22,
    type: "BLF key",
    values: [
      {
        setting_name: "MPK 9 Mode",
        setting_description: "",
        setting_variable_name: "P357",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "-1",
            name: "None",
          },
          {
            value: "0",
            name: "Speed Dial",
          },
          {
            value: "1",
            name: "Busy Lamp Field (BLF)",
          },
          {
            value: "2",
            name: "Presence Watcher",
          },
          {
            value: "3",
            name: "Eventlist BLF",
          },
          {
            value: "4",
            name: "Speed Dial via Active Account",
          },
          {
            value: "5",
            name: "Dial DTMF",
          },
          {
            value: "6",
            name: "Voicemail",
          },
          {
            value: "7",
            name: "Call Return",
          },
          {
            value: "8",
            name: "Transfer",
          },
          {
            value: "9",
            name: "Call Park",
          },
          {
            value: "10",
            name: "Intercom",
          },
          {
            value: "11",
            name: "LDAP Search",
          },
          {
            value: "12",
            name: "Confrence",
          },
          {
            value: "13",
            name: "Multicast Paging",
          },
          {
            value: "14",
            name: "Record",
          },
          {
            value: "15",
            name: "Call Log",
          },
          {
            value: "16",
            name: "Monitored Call Park",
          },
          {
            value: "17",
            name: "Menu",
          },
        ],
      },
      {
        setting_name: "MPK 9 Account",
        setting_description: "",
        setting_variable_name: "P358",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "Account 1",
          },
          {
            value: "1",
            name: "Account 2",
          },
          {
            value: "2",
            name: "Account 3",
          },
          {
            value: "3",
            name: "Account 4",
          },
          {
            value: "4",
            name: "Account 5",
          },
          {
            value: "5",
            name: "Account 6",
          },
        ],
      },
      {
        setting_name: "MPK 9 Name",
        setting_description: "Max: 128 chars",
        setting_variable_name: "P359",
        setting_variable_type: "2",
      },
      {
        setting_name: "MPK 9 User ID",
        setting_description: "Max: 64 chars",
        setting_variable_name: "P360",
        setting_variable_type: "2",
      },
    ],
  },
  {
    name: "BLF key 10",
    x: 855,
    y: 536,
    width: 45,
    height: 22,
    type: "BLF key",
    values: [
      {
        setting_name: "MPK 10 Mode",
        setting_description: "",
        setting_variable_name: "P361",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "-1",
            name: "None",
          },
          {
            value: "0",
            name: "Speed Dial",
          },
          {
            value: "1",
            name: "Busy Lamp Field (BLF)",
          },
          {
            value: "2",
            name: "Presence Watcher",
          },
          {
            value: "3",
            name: "Eventlist BLF",
          },
          {
            value: "4",
            name: "Speed Dial via Active Account",
          },
          {
            value: "5",
            name: "Dial DTMF",
          },
          {
            value: "6",
            name: "Voicemail",
          },
          {
            value: "7",
            name: "Call Return",
          },
          {
            value: "8",
            name: "Transfer",
          },
          {
            value: "9",
            name: "Call Park",
          },
          {
            value: "10",
            name: "Intercom",
          },
          {
            value: "11",
            name: "LDAP Search",
          },
          {
            value: "12",
            name: "Confrence",
          },
          {
            value: "13",
            name: "Multicast Paging",
          },
          {
            value: "14",
            name: "Record",
          },
          {
            value: "15",
            name: "Call Log",
          },
          {
            value: "16",
            name: "Monitored Call Park",
          },
          {
            value: "17",
            name: "Menu",
          },
        ],
      },
      {
        setting_name: "MPK 10 Account",
        setting_description: "",
        setting_variable_name: "P362",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "Account 1",
          },
          {
            value: "1",
            name: "Account 2",
          },
          {
            value: "2",
            name: "Account 3",
          },
          {
            value: "3",
            name: "Account 4",
          },
          {
            value: "4",
            name: "Account 5",
          },
          {
            value: "5",
            name: "Account 6",
          },
        ],
      },
      {
        setting_name: "MPK 10 Name",
        setting_description: "Max: 128 chars",
        setting_variable_name: "P363",
        setting_variable_type: "2",
      },
      {
        setting_name: "MPK 10 User ID",
        setting_description: "Max: 64 chars",
        setting_variable_name: "P364",
        setting_variable_type: "2",
      },
    ],
  },

  {
    name: "BLF key 11",
    x: 855,
    y: 570,
    width: 45,
    height: 22,
    type: "BLF key",
    values: [
      {
        setting_name: "MPK 11 Mode",
        setting_description: "",
        setting_variable_name: "P365",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "-1",
            name: "None",
          },
          {
            value: "0",
            name: "Speed Dial",
          },
          {
            value: "1",
            name: "Busy Lamp Field (BLF)",
          },
          {
            value: "2",
            name: "Presence Watcher",
          },
          {
            value: "3",
            name: "Eventlist BLF",
          },
          {
            value: "4",
            name: "Speed Dial via Active Account",
          },
          {
            value: "5",
            name: "Dial DTMF",
          },
          {
            value: "6",
            name: "Voicemail",
          },
          {
            value: "7",
            name: "Call Return",
          },
          {
            value: "8",
            name: "Transfer",
          },
          {
            value: "9",
            name: "Call Park",
          },
          {
            value: "10",
            name: "Intercom",
          },
          {
            value: "11",
            name: "LDAP Search",
          },
          {
            value: "12",
            name: "Confrence",
          },
          {
            value: "13",
            name: "Multicast Paging",
          },
          {
            value: "14",
            name: "Record",
          },
          {
            value: "15",
            name: "Call Log",
          },
          {
            value: "16",
            name: "Monitored Call Park",
          },
          {
            value: "17",
            name: "Menu",
          },
        ],
      },
      {
        setting_name: "MPK 11 Account",
        setting_description: "",
        setting_variable_name: "P366",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "Account 1",
          },
          {
            value: "1",
            name: "Account 2",
          },
          {
            value: "2",
            name: "Account 3",
          },
          {
            value: "3",
            name: "Account 4",
          },
          {
            value: "4",
            name: "Account 5",
          },
          {
            value: "5",
            name: "Account 6",
          },
        ],
      },
      {
        setting_name: "MPK 11 Name",
        setting_description: "Max: 128 chars",
        setting_variable_name: "P367",
        setting_variable_type: "2",
      },
      {
        setting_name: "MPK 11 User ID",
        setting_description: "Max: 64 chars",
        setting_variable_name: "P368",
        setting_variable_type: "2",
      },
    ],
  },

  {
    name: "BLF key 12",
    x: 855,
    y: 604,
    width: 45,
    height: 22,
    type: "BLF key",
    values: [
      {
        setting_name: "MPK 12 Mode",
        setting_description: "",
        setting_variable_name: "P369",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "-1",
            name: "None",
          },
          {
            value: "0",
            name: "Speed Dial",
          },
          {
            value: "1",
            name: "Busy Lamp Field (BLF)",
          },
          {
            value: "2",
            name: "Presence Watcher",
          },
          {
            value: "3",
            name: "Eventlist BLF",
          },
          {
            value: "4",
            name: "Speed Dial via Active Account",
          },
          {
            value: "5",
            name: "Dial DTMF",
          },
          {
            value: "6",
            name: "Voicemail",
          },
          {
            value: "7",
            name: "Call Return",
          },
          {
            value: "8",
            name: "Transfer",
          },
          {
            value: "9",
            name: "Call Park",
          },
          {
            value: "10",
            name: "Intercom",
          },
          {
            value: "11",
            name: "LDAP Search",
          },
          {
            value: "12",
            name: "Confrence",
          },
          {
            value: "13",
            name: "Multicast Paging",
          },
          {
            value: "14",
            name: "Record",
          },
          {
            value: "15",
            name: "Call Log",
          },
          {
            value: "16",
            name: "Monitored Call Park",
          },
          {
            value: "17",
            name: "Menu",
          },
        ],
      },
      {
        setting_name: "MPK 12 Account",
        setting_description: "",
        setting_variable_name: "P370",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "Account 1",
          },
          {
            value: "1",
            name: "Account 2",
          },
          {
            value: "2",
            name: "Account 3",
          },
          {
            value: "3",
            name: "Account 4",
          },
          {
            value: "4",
            name: "Account 5",
          },
          {
            value: "5",
            name: "Account 6",
          },
        ],
      },
      {
        setting_name: "MPK 12 Name",
        setting_description: "Max: 128 chars",
        setting_variable_name: "P371",
        setting_variable_type: "2",
      },
      {
        setting_name: "MPK 12 User ID",
        setting_description: "Max: 64 chars",
        setting_variable_name: "P372",
        setting_variable_type: "2",
      },
    ],
  },
  {
    name: "BLF key 13",
    x: 1094,
    y: 209,
    width: 45,
    height: 22,
    type: "BLF key",
    values: [
      {
        setting_name: "MPK 13 Mode",
        setting_description: "",
        setting_variable_name: "P373",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "-1",
            name: "None",
          },
          {
            value: "0",
            name: "Speed Dial",
          },
          {
            value: "1",
            name: "Busy Lamp Field (BLF)",
          },
          {
            value: "2",
            name: "Presence Watcher",
          },
          {
            value: "3",
            name: "Eventlist BLF",
          },
          {
            value: "4",
            name: "Speed Dial via Active Account",
          },
          {
            value: "5",
            name: "Dial DTMF",
          },
          {
            value: "6",
            name: "Voicemail",
          },
          {
            value: "7",
            name: "Call Return",
          },
          {
            value: "8",
            name: "Transfer",
          },
          {
            value: "9",
            name: "Call Park",
          },
          {
            value: "10",
            name: "Intercom",
          },
          {
            value: "11",
            name: "LDAP Search",
          },
          {
            value: "12",
            name: "Confrence",
          },
          {
            value: "13",
            name: "Multicast Paging",
          },
          {
            value: "14",
            name: "Record",
          },
          {
            value: "15",
            name: "Call Log",
          },
          {
            value: "16",
            name: "Monitored Call Park",
          },
          {
            value: "17",
            name: "Menu",
          },
        ],
      },
      {
        setting_name: "MPK 13 Account",
        setting_description: "",
        setting_variable_name: "P374",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "Account 1",
          },
          {
            value: "1",
            name: "Account 2",
          },
          {
            value: "2",
            name: "Account 3",
          },
          {
            value: "3",
            name: "Account 4",
          },
          {
            value: "4",
            name: "Account 5",
          },
          {
            value: "5",
            name: "Account 6",
          },
        ],
      },
      {
        setting_name: "MPK 13 Name",
        setting_description: "Max: 128 chars",
        setting_variable_name: "P375",
        setting_variable_type: "2",
      },
      {
        setting_name: "MPK 13 User ID",
        setting_description: "Max: 64 chars",
        setting_variable_name: "P376",
        setting_variable_type: "2",
      },
    ],
  },
  {
    name: "BLF key 14",
    x: 1094,
    y: 243,
    width: 45,
    height: 22,
    type: "BLF key",
    values: [
      {
        setting_name: "MPK 14 Mode",
        setting_description: "",
        setting_variable_name: "P377",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "-1",
            name: "None",
          },
          {
            value: "0",
            name: "Speed Dial",
          },
          {
            value: "1",
            name: "Busy Lamp Field (BLF)",
          },
          {
            value: "2",
            name: "Presence Watcher",
          },
          {
            value: "3",
            name: "Eventlist BLF",
          },
          {
            value: "4",
            name: "Speed Dial via Active Account",
          },
          {
            value: "5",
            name: "Dial DTMF",
          },
          {
            value: "6",
            name: "Voicemail",
          },
          {
            value: "7",
            name: "Call Return",
          },
          {
            value: "8",
            name: "Transfer",
          },
          {
            value: "9",
            name: "Call Park",
          },
          {
            value: "10",
            name: "Intercom",
          },
          {
            value: "11",
            name: "LDAP Search",
          },
          {
            value: "12",
            name: "Confrence",
          },
          {
            value: "13",
            name: "Multicast Paging",
          },
          {
            value: "14",
            name: "Record",
          },
          {
            value: "15",
            name: "Call Log",
          },
          {
            value: "16",
            name: "Monitored Call Park",
          },
          {
            value: "17",
            name: "Menu",
          },
        ],
      },
      {
        setting_name: "MPK 14 Account",
        setting_description: "",
        setting_variable_name: "P378",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "Account 1",
          },
          {
            value: "1",
            name: "Account 2",
          },
          {
            value: "2",
            name: "Account 3",
          },
          {
            value: "3",
            name: "Account 4",
          },
          {
            value: "4",
            name: "Account 5",
          },
          {
            value: "5",
            name: "Account 6",
          },
        ],
      },
      {
        setting_name: "MPK 14 Name",
        setting_description: "Max: 128 chars",
        setting_variable_name: "P379",
        setting_variable_type: "2",
      },
      {
        setting_name: "MPK 14 User ID",
        setting_description: "Max: 64 chars",
        setting_variable_name: "P380",
        setting_variable_type: "2",
      },
    ],
  },
  {
    name: "BLF key 15",
    x: 1094,
    y: 277,
    width: 45,
    height: 22,
    type: "BLF key",
    values: [
      {
        setting_name: "MPK 15 Mode",
        setting_description: "",
        setting_variable_name: "P381",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "-1",
            name: "None",
          },
          {
            value: "0",
            name: "Speed Dial",
          },
          {
            value: "1",
            name: "Busy Lamp Field (BLF)",
          },
          {
            value: "2",
            name: "Presence Watcher",
          },
          {
            value: "3",
            name: "Eventlist BLF",
          },
          {
            value: "4",
            name: "Speed Dial via Active Account",
          },
          {
            value: "5",
            name: "Dial DTMF",
          },
          {
            value: "6",
            name: "Voicemail",
          },
          {
            value: "7",
            name: "Call Return",
          },
          {
            value: "8",
            name: "Transfer",
          },
          {
            value: "9",
            name: "Call Park",
          },
          {
            value: "10",
            name: "Intercom",
          },
          {
            value: "11",
            name: "LDAP Search",
          },
          {
            value: "12",
            name: "Confrence",
          },
          {
            value: "13",
            name: "Multicast Paging",
          },
          {
            value: "14",
            name: "Record",
          },
          {
            value: "15",
            name: "Call Log",
          },
          {
            value: "16",
            name: "Monitored Call Park",
          },
          {
            value: "17",
            name: "Menu",
          },
        ],
      },
      {
        setting_name: "MPK 15 Account",
        setting_description: "",
        setting_variable_name: "P382",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "Account 1",
          },
          {
            value: "1",
            name: "Account 2",
          },
          {
            value: "2",
            name: "Account 3",
          },
          {
            value: "3",
            name: "Account 4",
          },
          {
            value: "4",
            name: "Account 5",
          },
          {
            value: "5",
            name: "Account 6",
          },
        ],
      },
      {
        setting_name: "MPK 15 Name",
        setting_description: "Max: 128 chars",
        setting_variable_name: "P383",
        setting_variable_type: "2",
      },
      {
        setting_name: "MPK 15 User ID",
        setting_description: "Max: 64 chars",
        setting_variable_name: "P384",
        setting_variable_type: "2",
      },
    ],
  },
  {
    name: "BLF key 16",
    x: 1094,
    y: 311,
    width: 45,
    height: 22,
    type: "BLF key",
    values: [
      {
        setting_name: "MPK 16 Mode",
        setting_description: "",
        setting_variable_name: "P385",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "-1",
            name: "None",
          },
          {
            value: "0",
            name: "Speed Dial",
          },
          {
            value: "1",
            name: "Busy Lamp Field (BLF)",
          },
          {
            value: "2",
            name: "Presence Watcher",
          },
          {
            value: "3",
            name: "Eventlist BLF",
          },
          {
            value: "4",
            name: "Speed Dial via Active Account",
          },
          {
            value: "5",
            name: "Dial DTMF",
          },
          {
            value: "6",
            name: "Voicemail",
          },
          {
            value: "7",
            name: "Call Return",
          },
          {
            value: "8",
            name: "Transfer",
          },
          {
            value: "9",
            name: "Call Park",
          },
          {
            value: "10",
            name: "Intercom",
          },
          {
            value: "11",
            name: "LDAP Search",
          },
          {
            value: "12",
            name: "Confrence",
          },
          {
            value: "13",
            name: "Multicast Paging",
          },
          {
            value: "14",
            name: "Record",
          },
          {
            value: "15",
            name: "Call Log",
          },
          {
            value: "16",
            name: "Monitored Call Park",
          },
          {
            value: "17",
            name: "Menu",
          },
        ],
      },
      {
        setting_name: "MPK 16 Account",
        setting_description: "",
        setting_variable_name: "P386",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "Account 1",
          },
          {
            value: "1",
            name: "Account 2",
          },
          {
            value: "2",
            name: "Account 3",
          },
          {
            value: "3",
            name: "Account 4",
          },
          {
            value: "4",
            name: "Account 5",
          },
          {
            value: "5",
            name: "Account 6",
          },
        ],
      },
      {
        setting_name: "MPK 16 Name",
        setting_description: "Max: 128 chars",
        setting_variable_name: "P387",
        setting_variable_type: "2",
      },
      {
        setting_name: "MPK 16 User ID",
        setting_description: "Max: 64 chars",
        setting_variable_name: "P388",
        setting_variable_type: "2",
      },
    ],
  },
  {
    name: "BLF key 17",
    x: 1094,
    y: 345,
    width: 45,
    height: 22,
    type: "BLF key",
    values: [
      {
        setting_name: "MPK 17 Mode",
        setting_description: "",
        setting_variable_name: "P389",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "-1",
            name: "None",
          },
          {
            value: "0",
            name: "Speed Dial",
          },
          {
            value: "1",
            name: "Busy Lamp Field (BLF)",
          },
          {
            value: "2",
            name: "Presence Watcher",
          },
          {
            value: "3",
            name: "Eventlist BLF",
          },
          {
            value: "4",
            name: "Speed Dial via Active Account",
          },
          {
            value: "5",
            name: "Dial DTMF",
          },
          {
            value: "6",
            name: "Voicemail",
          },
          {
            value: "7",
            name: "Call Return",
          },
          {
            value: "8",
            name: "Transfer",
          },
          {
            value: "9",
            name: "Call Park",
          },
          {
            value: "10",
            name: "Intercom",
          },
          {
            value: "11",
            name: "LDAP Search",
          },
          {
            value: "12",
            name: "Confrence",
          },
          {
            value: "13",
            name: "Multicast Paging",
          },
          {
            value: "14",
            name: "Record",
          },
          {
            value: "15",
            name: "Call Log",
          },
          {
            value: "16",
            name: "Monitored Call Park",
          },
          {
            value: "17",
            name: "Menu",
          },
        ],
      },
      {
        setting_name: "MPK 17 Account",
        setting_description: "",
        setting_variable_name: "P390",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "Account 1",
          },
          {
            value: "1",
            name: "Account 2",
          },
          {
            value: "2",
            name: "Account 3",
          },
          {
            value: "3",
            name: "Account 4",
          },
          {
            value: "4",
            name: "Account 5",
          },
          {
            value: "5",
            name: "Account 6",
          },
        ],
      },
      {
        setting_name: "MPK 17 Name",
        setting_description: "Max: 128 chars",
        setting_variable_name: "P391",
        setting_variable_type: "2",
      },
      {
        setting_name: "MPK 17 User ID",
        setting_description: "Max: 64 chars",
        setting_variable_name: "P392",
        setting_variable_type: "2",
      },
    ],
  },
  {
    name: "BLF key 18",
    x: 1094,
    y: 381,
    width: 45,
    height: 22,
    type: "BLF key",
    values: [
      {
        setting_name: "MPK 18 Mode",
        setting_description: "",
        setting_variable_name: "P393",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "-1",
            name: "None",
          },
          {
            value: "0",
            name: "Speed Dial",
          },
          {
            value: "1",
            name: "Busy Lamp Field (BLF)",
          },
          {
            value: "2",
            name: "Presence Watcher",
          },
          {
            value: "3",
            name: "Eventlist BLF",
          },
          {
            value: "4",
            name: "Speed Dial via Active Account",
          },
          {
            value: "5",
            name: "Dial DTMF",
          },
          {
            value: "6",
            name: "Voicemail",
          },
          {
            value: "7",
            name: "Call Return",
          },
          {
            value: "8",
            name: "Transfer",
          },
          {
            value: "9",
            name: "Call Park",
          },
          {
            value: "10",
            name: "Intercom",
          },
          {
            value: "11",
            name: "LDAP Search",
          },
          {
            value: "12",
            name: "Confrence",
          },
          {
            value: "13",
            name: "Multicast Paging",
          },
          {
            value: "14",
            name: "Record",
          },
          {
            value: "15",
            name: "Call Log",
          },
          {
            value: "16",
            name: "Monitored Call Park",
          },
          {
            value: "17",
            name: "Menu",
          },
        ],
      },
      {
        setting_name: "MPK 18 Account",
        setting_description: "",
        setting_variable_name: "P394",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "Account 1",
          },
          {
            value: "1",
            name: "Account 2",
          },
          {
            value: "2",
            name: "Account 3",
          },
          {
            value: "3",
            name: "Account 4",
          },
          {
            value: "4",
            name: "Account 5",
          },
          {
            value: "5",
            name: "Account 6",
          },
        ],
      },
      {
        setting_name: "MPK 18 Name",
        setting_description: "Max: 128 chars",
        setting_variable_name: "P395",
        setting_variable_type: "2",
      },
      {
        setting_name: "MPK 18 User ID",
        setting_description: "Max: 64 chars",
        setting_variable_name: "P396",
        setting_variable_type: "2",
      },
    ],
  },
  {
    name: "BLF key 19",
    x: 1094,
    y: 415,
    width: 45,
    height: 22,
    type: "BLF key",
    values: [
      {
        setting_name: "MPK 19 Mode",
        setting_description: "",
        setting_variable_name: "P1440",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "-1",
            name: "None",
          },
          {
            value: "0",
            name: "Speed Dial",
          },
          {
            value: "1",
            name: "Busy Lamp Field (BLF)",
          },
          {
            value: "2",
            name: "Presence Watcher",
          },
          {
            value: "3",
            name: "Eventlist BLF",
          },
          {
            value: "4",
            name: "Speed Dial via Active Account",
          },
          {
            value: "5",
            name: "Dial DTMF",
          },
          {
            value: "6",
            name: "Voicemail",
          },
          {
            value: "7",
            name: "Call Return",
          },
          {
            value: "8",
            name: "Transfer",
          },
          {
            value: "9",
            name: "Call Park",
          },
          {
            value: "10",
            name: "Intercom",
          },
          {
            value: "11",
            name: "LDAP Search",
          },
          {
            value: "12",
            name: "Confrence",
          },
          {
            value: "13",
            name: "Multicast Paging",
          },
          {
            value: "14",
            name: "Record",
          },
          {
            value: "15",
            name: "Call Log",
          },
          {
            value: "16",
            name: "Monitored Call Park",
          },
          {
            value: "17",
            name: "Menu",
          },
        ],
      },
      {
        setting_name: "MPK 19 Account",
        setting_description: "",
        setting_variable_name: "P1441",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "Account 1",
          },
          {
            value: "1",
            name: "Account 2",
          },
          {
            value: "2",
            name: "Account 3",
          },
          {
            value: "3",
            name: "Account 4",
          },
          {
            value: "4",
            name: "Account 5",
          },
          {
            value: "5",
            name: "Account 6",
          },
        ],
      },
      {
        setting_name: "MPK 19 Name",
        setting_description: "Max: 128 chars",
        setting_variable_name: "P1442",
        setting_variable_type: "2",
      },
      {
        setting_name: "MPK 19 User ID",
        setting_description: "Max: 64 chars",
        setting_variable_name: "P1443",
        setting_variable_type: "2",
      },
    ],
  },
  {
    name: "BLF key 20",
    x: 1094,
    y: 450,
    width: 45,
    height: 22,
    type: "BLF key",
    values: [
      {
        setting_name: "MPK 20 Mode",
        setting_description: "",
        setting_variable_name: "P1444",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "-1",
            name: "None",
          },
          {
            value: "0",
            name: "Speed Dial",
          },
          {
            value: "1",
            name: "Busy Lamp Field (BLF)",
          },
          {
            value: "2",
            name: "Presence Watcher",
          },
          {
            value: "3",
            name: "Eventlist BLF",
          },
          {
            value: "4",
            name: "Speed Dial via Active Account",
          },
          {
            value: "5",
            name: "Dial DTMF",
          },
          {
            value: "6",
            name: "Voicemail",
          },
          {
            value: "7",
            name: "Call Return",
          },
          {
            value: "8",
            name: "Transfer",
          },
          {
            value: "9",
            name: "Call Park",
          },
          {
            value: "10",
            name: "Intercom",
          },
          {
            value: "11",
            name: "LDAP Search",
          },
          {
            value: "12",
            name: "Confrence",
          },
          {
            value: "13",
            name: "Multicast Paging",
          },
          {
            value: "14",
            name: "Record",
          },
          {
            value: "15",
            name: "Call Log",
          },
          {
            value: "16",
            name: "Monitored Call Park",
          },
          {
            value: "17",
            name: "Menu",
          },
        ],
      },
      {
        setting_name: "MPK 20 Account",
        setting_description: "",
        setting_variable_name: "P1445",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "Account 1",
          },
          {
            value: "1",
            name: "Account 2",
          },
          {
            value: "2",
            name: "Account 3",
          },
          {
            value: "3",
            name: "Account 4",
          },
          {
            value: "4",
            name: "Account 5",
          },
          {
            value: "5",
            name: "Account 6",
          },
        ],
      },
      {
        setting_name: "MPK 20 Name",
        setting_description: "Max: 128 chars",
        setting_variable_name: "P1446",
        setting_variable_type: "2",
      },
      {
        setting_name: "MPK 20 User ID",
        setting_description: "Max: 64 chars",
        setting_variable_name: "P1447",
        setting_variable_type: "2",
      },
    ],
  },
  {
    name: "BLF key 21",
    x: 1094,
    y: 484,
    width: 45,
    height: 22,
    type: "BLF key",
    values: [
      {
        setting_name: "MPK 21 Mode",
        setting_description: "",
        setting_variable_name: "P1448",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "-1",
            name: "None",
          },
          {
            value: "0",
            name: "Speed Dial",
          },
          {
            value: "1",
            name: "Busy Lamp Field (BLF)",
          },
          {
            value: "2",
            name: "Presence Watcher",
          },
          {
            value: "3",
            name: "Eventlist BLF",
          },
          {
            value: "4",
            name: "Speed Dial via Active Account",
          },
          {
            value: "5",
            name: "Dial DTMF",
          },
          {
            value: "6",
            name: "Voicemail",
          },
          {
            value: "7",
            name: "Call Return",
          },
          {
            value: "8",
            name: "Transfer",
          },
          {
            value: "9",
            name: "Call Park",
          },
          {
            value: "10",
            name: "Intercom",
          },
          {
            value: "11",
            name: "LDAP Search",
          },
          {
            value: "12",
            name: "Confrence",
          },
          {
            value: "13",
            name: "Multicast Paging",
          },
          {
            value: "14",
            name: "Record",
          },
          {
            value: "15",
            name: "Call Log",
          },
          {
            value: "16",
            name: "Monitored Call Park",
          },
          {
            value: "17",
            name: "Menu",
          },
        ],
      },
      {
        setting_name: "MPK 21 Account",
        setting_description: "",
        setting_variable_name: "P1449",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "Account 1",
          },
          {
            value: "1",
            name: "Account 2",
          },
          {
            value: "2",
            name: "Account 3",
          },
          {
            value: "3",
            name: "Account 4",
          },
          {
            value: "4",
            name: "Account 5",
          },
          {
            value: "5",
            name: "Account 6",
          },
        ],
      },
      {
        setting_name: "MPK 21 Name",
        setting_description: "Max: 128 chars",
        setting_variable_name: "P1450",
        setting_variable_type: "2",
      },
      {
        setting_name: "MPK 21 User ID",
        setting_description: "Max: 64 chars",
        setting_variable_name: "P1451",
        setting_variable_type: "2",
      },
    ],
  },
  {
    name: "BLF key 22",
    x: 1094,
    y: 519,
    width: 45,
    height: 22,
    type: "BLF key",
    values: [
      {
        setting_name: "MPK 22 Mode",
        setting_description: "",
        setting_variable_name: "P1452",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "-1",
            name: "None",
          },
          {
            value: "0",
            name: "Speed Dial",
          },
          {
            value: "1",
            name: "Busy Lamp Field (BLF)",
          },
          {
            value: "2",
            name: "Presence Watcher",
          },
          {
            value: "3",
            name: "Eventlist BLF",
          },
          {
            value: "4",
            name: "Speed Dial via Active Account",
          },
          {
            value: "5",
            name: "Dial DTMF",
          },
          {
            value: "6",
            name: "Voicemail",
          },
          {
            value: "7",
            name: "Call Return",
          },
          {
            value: "8",
            name: "Transfer",
          },
          {
            value: "9",
            name: "Call Park",
          },
          {
            value: "10",
            name: "Intercom",
          },
          {
            value: "11",
            name: "LDAP Search",
          },
          {
            value: "12",
            name: "Confrence",
          },
          {
            value: "13",
            name: "Multicast Paging",
          },
          {
            value: "14",
            name: "Record",
          },
          {
            value: "15",
            name: "Call Log",
          },
          {
            value: "16",
            name: "Monitored Call Park",
          },
          {
            value: "17",
            name: "Menu",
          },
        ],
      },
      {
        setting_name: "MPK 22 Account",
        setting_description: "",
        setting_variable_name: "P1453",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "Account 1",
          },
          {
            value: "1",
            name: "Account 2",
          },
          {
            value: "2",
            name: "Account 3",
          },
          {
            value: "3",
            name: "Account 4",
          },
          {
            value: "4",
            name: "Account 5",
          },
          {
            value: "5",
            name: "Account 6",
          },
        ],
      },
      {
        setting_name: "MPK 22 Name",
        setting_description: "Max: 128 chars",
        setting_variable_name: "P1454",
        setting_variable_type: "2",
      },
      {
        setting_name: "MPK 22 User ID",
        setting_description: "Max: 64 chars",
        setting_variable_name: "P1455",
        setting_variable_type: "2",
      },
    ],
  },
  {
    name: "BLF key 23",
    x: 1094,
    y: 553,
    width: 45,
    height: 22,
    type: "BLF key",
    values: [
      {
        setting_name: "MPK 23 Mode",
        setting_description: "",
        setting_variable_name: "P1456",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "-1",
            name: "None",
          },
          {
            value: "0",
            name: "Speed Dial",
          },
          {
            value: "1",
            name: "Busy Lamp Field (BLF)",
          },
          {
            value: "2",
            name: "Presence Watcher",
          },
          {
            value: "3",
            name: "Eventlist BLF",
          },
          {
            value: "4",
            name: "Speed Dial via Active Account",
          },
          {
            value: "5",
            name: "Dial DTMF",
          },
          {
            value: "6",
            name: "Voicemail",
          },
          {
            value: "7",
            name: "Call Return",
          },
          {
            value: "8",
            name: "Transfer",
          },
          {
            value: "9",
            name: "Call Park",
          },
          {
            value: "10",
            name: "Intercom",
          },
          {
            value: "11",
            name: "LDAP Search",
          },
          {
            value: "12",
            name: "Confrence",
          },
          {
            value: "13",
            name: "Multicast Paging",
          },
          {
            value: "14",
            name: "Record",
          },
          {
            value: "15",
            name: "Call Log",
          },
          {
            value: "16",
            name: "Monitored Call Park",
          },
          {
            value: "17",
            name: "Menu",
          },
        ],
      },
      {
        setting_name: "MPK 23 Account",
        setting_description: "",
        setting_variable_name: "P1457",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "Account 1",
          },
          {
            value: "1",
            name: "Account 2",
          },
          {
            value: "2",
            name: "Account 3",
          },
          {
            value: "3",
            name: "Account 4",
          },
          {
            value: "4",
            name: "Account 5",
          },
          {
            value: "5",
            name: "Account 6",
          },
        ],
      },
      {
        setting_name: "MPK 23 Name",
        setting_description: "Max: 128 chars",
        setting_variable_name: "P1458",
        setting_variable_type: "2",
      },
      {
        setting_name: "MPK 23 User ID",
        setting_description: "Max: 64 chars",
        setting_variable_name: "P1459",
        setting_variable_type: "2",
      },
    ],
  },
  {
    name: "BLF key 24",
    x: 1094,
    y: 587,
    width: 45,
    height: 22,
    type: "BLF key",
    values: [
      {
        setting_name: "MPK 24 Mode",
        setting_description: "",
        setting_variable_name: "P1460",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "-1",
            name: "None",
          },
          {
            value: "0",
            name: "Speed Dial",
          },
          {
            value: "1",
            name: "Busy Lamp Field (BLF)",
          },
          {
            value: "2",
            name: "Presence Watcher",
          },
          {
            value: "3",
            name: "Eventlist BLF",
          },
          {
            value: "4",
            name: "Speed Dial via Active Account",
          },
          {
            value: "5",
            name: "Dial DTMF",
          },
          {
            value: "6",
            name: "Voicemail",
          },
          {
            value: "7",
            name: "Call Return",
          },
          {
            value: "8",
            name: "Transfer",
          },
          {
            value: "9",
            name: "Call Park",
          },
          {
            value: "10",
            name: "Intercom",
          },
          {
            value: "11",
            name: "LDAP Search",
          },
          {
            value: "12",
            name: "Confrence",
          },
          {
            value: "13",
            name: "Multicast Paging",
          },
          {
            value: "14",
            name: "Record",
          },
          {
            value: "15",
            name: "Call Log",
          },
          {
            value: "16",
            name: "Monitored Call Park",
          },
          {
            value: "17",
            name: "Menu",
          },
        ],
      },
      {
        setting_name: "MPK 24 Account",
        setting_description: "",
        setting_variable_name: "P1461",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "Account 1",
          },
          {
            value: "1",
            name: "Account 2",
          },
          {
            value: "2",
            name: "Account 3",
          },
          {
            value: "3",
            name: "Account 4",
          },
          {
            value: "4",
            name: "Account 5",
          },
          {
            value: "5",
            name: "Account 6",
          },
        ],
      },
      {
        setting_name: "MPK 24 Name",
        setting_description: "Max: 128 chars",
        setting_variable_name: "P1462",
        setting_variable_type: "2",
      },
      {
        setting_name: "MPK 24 User ID",
        setting_description: "Max: 64 chars",
        setting_variable_name: "P1463",
        setting_variable_type: "2",
      },
    ],
  },
  {
    name: "BLF key 13",
    x: 902,
    y: 210,
    width: 183,
    height: 17,
    type: "description",
    textAlign: "right",
  },
  {
    name: "BLF key 1",
    x: 905,
    y: 229,
    width: 181,
    height: 17,
    type: "description",
    background: "#f2f2f2",
    textAlign: "left",
  },
  {
    name: "BLF key 14",
    x: 902,
    y: 246,
    width: 183,
    height: 17,
    type: "description",
    textAlign: "right",
  },
  {
    name: "BLF key 2",
    x: 905,
    y: 264,
    width: 181,
    height: 17,
    type: "description",
    background: "#f2f2f2",
    textAlign: "left",
  },
  {
    name: "BLF key 15",
    x: 902,
    y: 281,
    width: 183,
    height: 17,
    type: "description",
    textAlign: "right",
  },
  {
    name: "BLF key 3",
    x: 905,
    y: 298,
    width: 181,
    height: 17,
    type: "description",
    background: "#f2f2f2",
    textAlign: "left",
  },
  {
    name: "BLF key 16",
    x: 902,
    y: 315,
    width: 183,
    height: 17,
    type: "description",
    textAlign: "right",
  },
  {
    name: "BLF key 4",
    x: 905,
    y: 332,
    width: 181,
    height: 17,
    type: "description",
    background: "#f2f2f2",
    textAlign: "left",
  },
  {
    name: "BLF key 17",
    x: 902,
    y: 349,
    width: 183,
    height: 17,
    type: "description",
    textAlign: "right",
  },
  {
    name: "BLF key 5",
    x: 905,
    y: 367,
    width: 181,
    height: 17,
    type: "description",
    background: "#f2f2f2",
    textAlign: "left",
  },
  {
    name: "BLF key 18",
    x: 902,
    y: 383,
    width: 183,
    height: 17,
    type: "description",
    textAlign: "right",
  },
  {
    name: "BLF key 6",
    x: 905,
    y: 400,
    width: 181,
    height: 17,
    type: "description",
    background: "#f2f2f2",
    textAlign: "left",
  },
  {
    name: "BLF key 19",
    x: 902,
    y: 417,
    width: 183,
    height: 17,
    type: "description",
    textAlign: "right",
  },
  {
    name: "BLF key 7",
    x: 905,
    y: 436,
    width: 181,
    height: 17,
    type: "description",
    background: "#f2f2f2",
    textAlign: "left",
  },
  {
    name: "BLF key 20",
    x: 902,
    y: 453,
    width: 183,
    height: 17,
    type: "description",
    textAlign: "right",
  },
  {
    name: "BLF key 8",
    x: 905,
    y: 470,
    width: 181,
    height: 17,
    type: "description",
    background: "#f2f2f2",
    textAlign: "left",
  },
  {
    name: "BLF key 21",
    x: 902,
    y: 487,
    width: 183,
    height: 17,
    type: "description",
    textAlign: "right",
  },
  {
    name: "BLF key 9",
    x: 905,
    y: 505,
    width: 181,
    height: 17,
    type: "description",
    background: "#f2f2f2",
    textAlign: "left",
  },
  {
    name: "BLF key 22",
    x: 902,
    y: 522,
    width: 183,
    height: 17,
    type: "description",
    textAlign: "right",
  },
  {
    name: "BLF key 10",
    x: 905,
    y: 539,
    width: 181,
    height: 17,
    type: "description",
    background: "#f2f2f2",
    textAlign: "left",
  },
  {
    name: "BLF key 23",
    x: 902,
    y: 556,
    width: 183,
    height: 17,
    type: "description",
    textAlign: "right",
  },

  {
    name: "BLF key 11",
    x: 905,
    y: 573,
    width: 181,
    height: 17,
    type: "description",
    background: "#f2f2f2",
    textAlign: "left",
  },
  {
    name: "BLF key 24",
    x: 902,
    y: 590,
    width: 183,
    height: 17,
    type: "description",
    textAlign: "right",
  },
  {
    name: "BLF key 12",
    x: 905,
    y: 607,
    width: 181,
    height: 17,
    type: "description",
    background: "#f2f2f2",
    textAlign: "left",
  },
];

export const GPX2130Coordinates: ImageMapCoordinate[] = [
  {
    name: "Line key 1",
    x: 564,
    y: 151,
    width: 50,
    height: 22,
    type: "Line key",
    values: [
      {
        setting_name: "Account Name",
        setting_description: "Name of the account, displayed on the phone.",
        setting_variable_name: "P270",
        setting_variable_type: "2",
      },
      {
        setting_name: "SIP Server",
        setting_description: "Address of the SIP server/PBX.",
        setting_variable_name: "P47",
        setting_variable_type: "2",
      },
      {
        setting_name: "Secondary SIP Server",
        setting_description: "Address of the Secondary SIP Server/PBX.",
        setting_variable_name: "P2312",
        setting_variable_type: "2",
      },
      {
        setting_name: "Outbound Proxy",
        setting_description: "In case a proxy is used.",
        setting_variable_name: "P48",
        setting_variable_type: "2",
      },
      {
        setting_name: "BLF Server",
        setting_description:
          "Only if you have a specific Busy Lamp Field server.",
        setting_variable_name: "P2375",
        setting_variable_type: "2",
      },
      {
        setting_name: "SIP User ID",
        setting_description: "Usually the extension number.",
        setting_variable_name: "P35",
        setting_variable_type: "2",
      },
      {
        setting_name: "Auth ID",
        setting_description: "Usually the extension number.",
        setting_variable_name: "P36",
        setting_variable_type: "2",
      },
      {
        setting_name: "Auth Password",
        setting_description: "Password or secret set on the PBX.",
        setting_variable_name: "P34",
        setting_variable_type: "2",
      },
      {
        setting_name: "Name",
        setting_description: "Name of the account, not displayed on the phone.",
        setting_variable_name: "P3",
        setting_variable_type: "2",
      },
      {
        setting_name: "Voicemail UserID",
        setting_description: "Dial this to get voicemail. Asterisk is *97.",
        setting_variable_name: "P33",
        setting_variable_type: "2",
      },
      {
        setting_name: "Show Account ID Only?",
        setting_description:
          "Select NO to only show the account name, no extension.",
        setting_variable_name: "P2380",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "No",
          },
          {
            value: "1",
            name: "Yes",
          },
        ],
      },
    ],
  },
  {
    name: "Line key 2",
    x: 564,
    y: 189,
    width: 50,
    height: 22,
    type: "Line key",
    values: [
      {
        setting_name: "Account Active?",
        setting_description: "Mark this account as active or inactive.",
        setting_variable_name: "P401",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "No",
          },
          {
            value: "1",
            name: "Yes",
          },
        ],
      },
      {
        setting_name: "Account Name",
        setting_description: "Name of the account, displayed on the phone.",
        setting_variable_name: "P417",
        setting_variable_type: "2",
      },
      {
        setting_name: "SIP Server",
        setting_description: "Address of the SIP server/PBX.",
        setting_variable_name: "P402",
        setting_variable_type: "2",
      },
      {
        setting_name: "Secondary SIP Server",
        setting_description: "Address of the Secondary SIP Server/PBX.",
        setting_variable_name: "P2412",
        setting_variable_type: "2",
      },
      {
        setting_name: "Outbound Proxy",
        setting_description: "In case a proxy is used.",
        setting_variable_name: "P403",
        setting_variable_type: "2",
      },
      {
        setting_name: "BLF Server",
        setting_description:
          "Only if you have a specific Busy Lamp Field server.",
        setting_variable_name: "P2475",
        setting_variable_type: "2",
      },
      {
        setting_name: "SIP User ID",
        setting_description: "Usually the extension number.",
        setting_variable_name: "P404",
        setting_variable_type: "2",
      },
      {
        setting_name: "Auth ID",
        setting_description: "Usually the extension number.",
        setting_variable_name: "P405",
        setting_variable_type: "2",
      },
      {
        setting_name: "Auth Password",
        setting_description: "Password or secret set on the PBX.",
        setting_variable_name: "P406",
        setting_variable_type: "2",
      },
      {
        setting_name: "Name",
        setting_description: "Name of the account, not displayed on the phone.",
        setting_variable_name: "P407",
        setting_variable_type: "2",
      },
      {
        setting_name: "Voicemail UserID",
        setting_description: "Dial this to get voicemail. Asterisk is *97.",
        setting_variable_name: "P426",
        setting_variable_type: "2",
      },
      {
        setting_name: "Show Account Name Only?",
        setting_description:
          "Select YES to only show the account name, no extension.",
        setting_variable_name: "P2480",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "No",
          },
          {
            value: "1",
            name: "Yes",
          },
        ],
      },
    ],
  },
  {
    name: "Line key 3",
    x: 564,
    y: 228,
    width: 50,
    height: 22,
    type: "Line key",
    values: [
      {
        setting_name: "Account Active?",
        setting_description: "Mark this account as active or inactive.",
        setting_variable_name: "P501",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "No",
          },
          {
            value: "1",
            name: "Yes",
          },
        ],
      },
      {
        setting_name: "Account Name",
        setting_description: "Name of the account, displayed on the phone.",
        setting_variable_name: "P517",
        setting_variable_type: "2",
      },
      {
        setting_name: "SIP Server",
        setting_description: "Address of the SIP server/PBX.",
        setting_variable_name: "P502",
        setting_variable_type: "2",
      },
      {
        setting_name: "Secondary SIP Server",
        setting_description: "Address of the Secondary SIP Server/PBX.",
        setting_variable_name: "P2512",
        setting_variable_type: "2",
      },
      {
        setting_name: "Outbound Proxy",
        setting_description: "In case a proxy is used.",
        setting_variable_name: "P503",
        setting_variable_type: "2",
      },
      {
        setting_name: "BLF Server",
        setting_description:
          "Only if you have a specific Busy Lamp Field server.",
        setting_variable_name: "P2575",
        setting_variable_type: "2",
      },
      {
        setting_name: "SIP User ID",
        setting_description: "Usually the extension number.",
        setting_variable_name: "P504",
        setting_variable_type: "2",
      },
      {
        setting_name: "Auth ID",
        setting_description: "Usually the extension number.",
        setting_variable_name: "P505",
        setting_variable_type: "2",
      },
      {
        setting_name: "Auth Password",
        setting_description: "Password or secret set on the PBX.",
        setting_variable_name: "P506",
        setting_variable_type: "2",
      },
      {
        setting_name: "Name",
        setting_description: "Name of the account, not displayed on the phone.",
        setting_variable_name: "P507",
        setting_variable_type: "2",
      },
      {
        setting_name: "Voicemail UserID",
        setting_description: "Dial this to get voicemail. Asterisk is *97.",
        setting_variable_name: "P526",
        setting_variable_type: "2",
      },
      {
        setting_name: "Show Account Name Only?",
        setting_description:
          "Select YES to only show the account name, no extension.",
        setting_variable_name: "P2580",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "No",
          },
          {
            value: "1",
            name: "Yes",
          },
        ],
      },
    ],
  },
  {
    name: "BLF key 1",
    x: 770,
    y: 463,
    width: 50,
    height: 18,
    type: "BLF key",
    values: [
      {
        setting_name: "MPK 1 Mode",
        setting_description: "",
        setting_variable_name: "P323",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "-1",
            name: "None",
          },
          {
            value: "0",
            name: "Speed Dial",
          },
          {
            value: "1",
            name: "Busy Lamp Field (BLF)",
          },
          {
            value: "2",
            name: "Presence Watcher",
          },
          {
            value: "3",
            name: "Eventlist BLF",
          },
          {
            value: "4",
            name: "Speed Dial via Active Account",
          },
          {
            value: "5",
            name: "Dial DTMF",
          },
          {
            value: "6",
            name: "Voicemail",
          },
          {
            value: "7",
            name: "Call Return",
          },
          {
            value: "8",
            name: "Transfer",
          },
          {
            value: "9",
            name: "Call Park",
          },
          {
            value: "10",
            name: "Intercom",
          },
          {
            value: "11",
            name: "LDAP Search",
          },
          {
            value: "12",
            name: "Confrence",
          },
          {
            value: "13",
            name: "Multicast Paging",
          },
          {
            value: "14",
            name: "Record",
          },
          {
            value: "15",
            name: "Call Log",
          },
          {
            value: "16",
            name: "Monitored Call Park",
          },
          {
            value: "17",
            name: "Menu",
          },
        ],
      },
      {
        setting_name: "MPK 1 Account",
        setting_description: "",
        setting_variable_name: "P301",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "Account 1",
          },
          {
            value: "1",
            name: "Account 2",
          },
          {
            value: "2",
            name: "Account 3",
          },
          {
            value: "3",
            name: "Account 4",
          },
          {
            value: "4",
            name: "Account 5",
          },
          {
            value: "5",
            name: "Account 6",
          },
        ],
      },
      {
        setting_name: "MPK 1 Name",
        setting_description: "Max: 128 chars",
        setting_variable_name: "P302",
        setting_variable_type: "2",
      },
      {
        setting_name: "MPK 1 User ID",
        setting_description: "Max: 64 chars",
        setting_variable_name: "P303",
        setting_variable_type: "2",
      },
    ],
  },
  {
    name: "BLF key 2",
    x: 770,
    y: 504,
    width: 50,
    height: 18,
    type: "BLF key",
    values: [
      {
        setting_name: "MPK 2 Mode",
        setting_description: "",
        setting_variable_name: "P324",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "-1",
            name: "None",
          },
          {
            value: "0",
            name: "Speed Dial",
          },
          {
            value: "1",
            name: "Busy Lamp Field (BLF)",
          },
          {
            value: "2",
            name: "Presence Watcher",
          },
          {
            value: "3",
            name: "Eventlist BLF",
          },
          {
            value: "4",
            name: "Speed Dial via Active Account",
          },
          {
            value: "5",
            name: "Dial DTMF",
          },
          {
            value: "6",
            name: "Voicemail",
          },
          {
            value: "7",
            name: "Call Return",
          },
          {
            value: "8",
            name: "Transfer",
          },
          {
            value: "9",
            name: "Call Park",
          },
          {
            value: "10",
            name: "Intercom",
          },
          {
            value: "11",
            name: "LDAP Search",
          },
          {
            value: "12",
            name: "Confrence",
          },
          {
            value: "13",
            name: "Multicast Paging",
          },
          {
            value: "14",
            name: "Record",
          },
          {
            value: "15",
            name: "Call Log",
          },
          {
            value: "16",
            name: "Monitored Call Park",
          },
          {
            value: "17",
            name: "Menu",
          },
        ],
      },
      {
        setting_name: "MPK 2 Account",
        setting_description: "",
        setting_variable_name: "P304",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "Account 1",
          },
          {
            value: "1",
            name: "Account 2",
          },
          {
            value: "2",
            name: "Account 3",
          },
          {
            value: "3",
            name: "Account 4",
          },
          {
            value: "4",
            name: "Account 5",
          },
          {
            value: "5",
            name: "Account 6",
          },
        ],
      },
      {
        setting_name: "MPK 2 Name",
        setting_description: "Max: 128 chars",
        setting_variable_name: "P305",
        setting_variable_type: "2",
      },
      {
        setting_name: "MPK 2 User ID",
        setting_description: "Max: 64 chars",
        setting_variable_name: "P306",
        setting_variable_type: "2",
      },
    ],
  },
  {
    name: "BLF key 3",
    x: 770,
    y: 545,
    width: 50,
    height: 18,
    type: "BLF key",
    values: [
      {
        setting_name: "MPK 3 Mode",
        setting_description: "",
        setting_variable_name: "P325",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "-1",
            name: "None",
          },
          {
            value: "0",
            name: "Speed Dial",
          },
          {
            value: "1",
            name: "Busy Lamp Field (BLF)",
          },
          {
            value: "2",
            name: "Presence Watcher",
          },
          {
            value: "3",
            name: "Eventlist BLF",
          },
          {
            value: "4",
            name: "Speed Dial via Active Account",
          },
          {
            value: "5",
            name: "Dial DTMF",
          },
          {
            value: "6",
            name: "Voicemail",
          },
          {
            value: "7",
            name: "Call Return",
          },
          {
            value: "8",
            name: "Transfer",
          },
          {
            value: "9",
            name: "Call Park",
          },
          {
            value: "10",
            name: "Intercom",
          },
          {
            value: "11",
            name: "LDAP Search",
          },
          {
            value: "12",
            name: "Confrence",
          },
          {
            value: "13",
            name: "Multicast Paging",
          },
          {
            value: "14",
            name: "Record",
          },
          {
            value: "15",
            name: "Call Log",
          },
          {
            value: "16",
            name: "Monitored Call Park",
          },
          {
            value: "17",
            name: "Menu",
          },
        ],
      },
      {
        setting_name: "MPK 3 Account",
        setting_description: "",
        setting_variable_name: "P307",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "Account 1",
          },
          {
            value: "1",
            name: "Account 2",
          },
          {
            value: "2",
            name: "Account 3",
          },
          {
            value: "3",
            name: "Account 4",
          },
          {
            value: "4",
            name: "Account 5",
          },
          {
            value: "5",
            name: "Account 6",
          },
        ],
      },
      {
        setting_name: "MPK 3 Name",
        setting_description: "Max: 128 chars",
        setting_variable_name: "P308",
        setting_variable_type: "2",
      },
      {
        setting_name: "MPK 3 User ID",
        setting_description: "Max: 64 chars",
        setting_variable_name: "P309",
        setting_variable_type: "2",
      },
    ],
  },
  {
    name: "BLF key 4",
    x: 770,
    y: 590,
    width: 50,
    height: 18,
    type: "BLF key",
    values: [
      {
        setting_name: "MPK 4 Mode",
        setting_description: "",
        setting_variable_name: "P326",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "-1",
            name: "None",
          },
          {
            value: "0",
            name: "Speed Dial",
          },
          {
            value: "1",
            name: "Busy Lamp Field (BLF)",
          },
          {
            value: "2",
            name: "Presence Watcher",
          },
          {
            value: "3",
            name: "Eventlist BLF",
          },
          {
            value: "4",
            name: "Speed Dial via Active Account",
          },
          {
            value: "5",
            name: "Dial DTMF",
          },
          {
            value: "6",
            name: "Voicemail",
          },
          {
            value: "7",
            name: "Call Return",
          },
          {
            value: "8",
            name: "Transfer",
          },
          {
            value: "9",
            name: "Call Park",
          },
          {
            value: "10",
            name: "Intercom",
          },
          {
            value: "11",
            name: "LDAP Search",
          },
          {
            value: "12",
            name: "Confrence",
          },
          {
            value: "13",
            name: "Multicast Paging",
          },
          {
            value: "14",
            name: "Record",
          },
          {
            value: "15",
            name: "Call Log",
          },
          {
            value: "16",
            name: "Monitored Call Park",
          },
          {
            value: "17",
            name: "Menu",
          },
        ],
      },
      {
        setting_name: "MPK 4 Account",
        setting_description: "",
        setting_variable_name: "P310",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "Account 1",
          },
          {
            value: "1",
            name: "Account 2",
          },
          {
            value: "2",
            name: "Account 3",
          },
          {
            value: "3",
            name: "Account 4",
          },
          {
            value: "4",
            name: "Account 5",
          },
          {
            value: "5",
            name: "Account 6",
          },
        ],
      },
      {
        setting_name: "MPK 4 Name",
        setting_description: "Max: 128 chars",
        setting_variable_name: "P311",
        setting_variable_type: "2",
      },
      {
        setting_name: "MPK 4 User ID",
        setting_description: "Max: 64 chars",
        setting_variable_name: "P312",
        setting_variable_type: "2",
      },
    ],
  },
  {
    name: "BLF key 5",
    x: 927,
    y: 443,
    width: 50,
    height: 18,
    type: "BLF key",
    values: [
      {
        setting_name: "MPK 5 Mode",
        setting_description: "",
        setting_variable_name: "P327",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "-1",
            name: "None",
          },
          {
            value: "0",
            name: "Speed Dial",
          },
          {
            value: "1",
            name: "Busy Lamp Field (BLF)",
          },
          {
            value: "2",
            name: "Presence Watcher",
          },
          {
            value: "3",
            name: "Eventlist BLF",
          },
          {
            value: "4",
            name: "Speed Dial via Active Account",
          },
          {
            value: "5",
            name: "Dial DTMF",
          },
          {
            value: "6",
            name: "Voicemail",
          },
          {
            value: "7",
            name: "Call Return",
          },
          {
            value: "8",
            name: "Transfer",
          },
          {
            value: "9",
            name: "Call Park",
          },
          {
            value: "10",
            name: "Intercom",
          },
          {
            value: "11",
            name: "LDAP Search",
          },
          {
            value: "12",
            name: "Confrence",
          },
          {
            value: "13",
            name: "Multicast Paging",
          },
          {
            value: "14",
            name: "Record",
          },
          {
            value: "15",
            name: "Call Log",
          },
          {
            value: "16",
            name: "Monitored Call Park",
          },
          {
            value: "17",
            name: "Menu",
          },
        ],
      },
      {
        setting_name: "MPK 5 Account",
        setting_description: "",
        setting_variable_name: "P313",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "Account 1",
          },
          {
            value: "1",
            name: "Account 2",
          },
          {
            value: "2",
            name: "Account 3",
          },
          {
            value: "3",
            name: "Account 4",
          },
          {
            value: "4",
            name: "Account 5",
          },
          {
            value: "5",
            name: "Account 6",
          },
        ],
      },
      {
        setting_name: "MPK 5 Name",
        setting_description: "Max: 128 chars",
        setting_variable_name: "P314",
        setting_variable_type: "2",
      },
      {
        setting_name: "MPK 5 User ID",
        setting_description: "Max: 64 chars",
        setting_variable_name: "P315",
        setting_variable_type: "2",
      },
    ],
  },
  {
    name: "BLF key 6",
    x: 927,
    y: 486,
    width: 50,
    height: 18,
    type: "BLF key",
    values: [
      {
        setting_name: "MPK 6 Mode",
        setting_description: "",
        setting_variable_name: "P328",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "-1",
            name: "None",
          },
          {
            value: "0",
            name: "Speed Dial",
          },
          {
            value: "1",
            name: "Busy Lamp Field (BLF)",
          },
          {
            value: "2",
            name: "Presence Watcher",
          },
          {
            value: "3",
            name: "Eventlist BLF",
          },
          {
            value: "4",
            name: "Speed Dial via Active Account",
          },
          {
            value: "5",
            name: "Dial DTMF",
          },
          {
            value: "6",
            name: "Voicemail",
          },
          {
            value: "7",
            name: "Call Return",
          },
          {
            value: "8",
            name: "Transfer",
          },
          {
            value: "9",
            name: "Call Park",
          },
          {
            value: "10",
            name: "Intercom",
          },
          {
            value: "11",
            name: "LDAP Search",
          },
          {
            value: "12",
            name: "Confrence",
          },
          {
            value: "13",
            name: "Multicast Paging",
          },
          {
            value: "14",
            name: "Record",
          },
          {
            value: "15",
            name: "Call Log",
          },
          {
            value: "16",
            name: "Monitored Call Park",
          },
          {
            value: "17",
            name: "Menu",
          },
        ],
      },
      {
        setting_name: "MPK 6 Account",
        setting_description: "",
        setting_variable_name: "P316",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "Account 1",
          },
          {
            value: "1",
            name: "Account 2",
          },
          {
            value: "2",
            name: "Account 3",
          },
          {
            value: "3",
            name: "Account 4",
          },
          {
            value: "4",
            name: "Account 5",
          },
          {
            value: "5",
            name: "Account 6",
          },
        ],
      },
      {
        setting_name: "MPK 6 Name",
        setting_description: "Max: 128 chars",
        setting_variable_name: "P317",
        setting_variable_type: "2",
      },
      {
        setting_name: "MPK 6 User ID",
        setting_description: "Max: 64 chars",
        setting_variable_name: "P318",
        setting_variable_type: "2",
      },
    ],
  },
  {
    name: "BLF key 7",
    x: 927,
    y: 526,
    width: 50,
    height: 18,
    type: "BLF key",
    values: [
      {
        setting_name: "MPK 7 Mode",
        setting_description: "",
        setting_variable_name: "P329",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "-1",
            name: "None",
          },
          {
            value: "0",
            name: "Speed Dial",
          },
          {
            value: "1",
            name: "Busy Lamp Field (BLF)",
          },
          {
            value: "2",
            name: "Presence Watcher",
          },
          {
            value: "3",
            name: "Eventlist BLF",
          },
          {
            value: "4",
            name: "Speed Dial via Active Account",
          },
          {
            value: "5",
            name: "Dial DTMF",
          },
          {
            value: "6",
            name: "Voicemail",
          },
          {
            value: "7",
            name: "Call Return",
          },
          {
            value: "8",
            name: "Transfer",
          },
          {
            value: "9",
            name: "Call Park",
          },
          {
            value: "10",
            name: "Intercom",
          },
          {
            value: "11",
            name: "LDAP Search",
          },
          {
            value: "12",
            name: "Confrence",
          },
          {
            value: "13",
            name: "Multicast Paging",
          },
          {
            value: "14",
            name: "Record",
          },
          {
            value: "15",
            name: "Call Log",
          },
          {
            value: "16",
            name: "Monitored Call Park",
          },
          {
            value: "17",
            name: "Menu",
          },
        ],
      },
      {
        setting_name: "MPK 7 Account",
        setting_description: "",
        setting_variable_name: "P319",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "Account 1",
          },
          {
            value: "1",
            name: "Account 2",
          },
          {
            value: "2",
            name: "Account 3",
          },
          {
            value: "3",
            name: "Account 4",
          },
          {
            value: "4",
            name: "Account 5",
          },
          {
            value: "5",
            name: "Account 6",
          },
        ],
      },
      {
        setting_name: "MPK 7 Name",
        setting_description: "Max: 128 chars",
        setting_variable_name: "P320",
        setting_variable_type: "2",
      },
      {
        setting_name: "MPK 7 User ID",
        setting_description: "Max: 64 chars",
        setting_variable_name: "P321",
        setting_variable_type: "2",
      },
    ],
  },
  {
    name: "BLF key 8",
    x: 927,
    y: 568,
    width: 50,
    height: 18,
    type: "BLF key",
    values: [
      {
        setting_name: "MPK 8 Mode",
        setting_description: "",
        setting_variable_name: "P353",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "-1",
            name: "None",
          },
          {
            value: "0",
            name: "Speed Dial",
          },
          {
            value: "1",
            name: "Busy Lamp Field (BLF)",
          },
          {
            value: "2",
            name: "Presence Watcher",
          },
          {
            value: "3",
            name: "Eventlist BLF",
          },
          {
            value: "4",
            name: "Speed Dial via Active Account",
          },
          {
            value: "5",
            name: "Dial DTMF",
          },
          {
            value: "6",
            name: "Voicemail",
          },
          {
            value: "7",
            name: "Call Return",
          },
          {
            value: "8",
            name: "Transfer",
          },
          {
            value: "9",
            name: "Call Park",
          },
          {
            value: "10",
            name: "Intercom",
          },
          {
            value: "11",
            name: "LDAP Search",
          },
          {
            value: "12",
            name: "Confrence",
          },
          {
            value: "13",
            name: "Multicast Paging",
          },
          {
            value: "14",
            name: "Record",
          },
          {
            value: "15",
            name: "Call Log",
          },
          {
            value: "16",
            name: "Monitored Call Park",
          },
          {
            value: "17",
            name: "Menu",
          },
        ],
      },
      {
        setting_name: "MPK 8 Account",
        setting_description: "",
        setting_variable_name: "P354",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "Account 1",
          },
          {
            value: "1",
            name: "Account 2",
          },
          {
            value: "2",
            name: "Account 3",
          },
          {
            value: "3",
            name: "Account 4",
          },
          {
            value: "4",
            name: "Account 5",
          },
          {
            value: "5",
            name: "Account 6",
          },
        ],
      },
      {
        setting_name: "MPK 8 Name",
        setting_description: "Max: 128 chars",
        setting_variable_name: "P355",
        setting_variable_type: "2",
      },
      {
        setting_name: "MPK 8 User ID",
        setting_description: "Max: 64 chars",
        setting_variable_name: "P356",
        setting_variable_type: "2",
      },
    ],
  },
  {
    name: "BLF key 1",
    x: 826,
    y: 463,
    width: 95,
    height: 23,
    type: "description",
    background: "none",
    textAlign: "left",
  },
  {
    name: "BLF key 2",
    x: 826,
    y: 507,
    width: 95,
    height: 23,
    type: "description",
    background: "none",
    textAlign: "left",
  },
  {
    name: "BLF key 3",
    x: 826,
    y: 550,
    width: 95,
    height: 23,
    type: "description",
    background: "none",
    textAlign: "left",
  },
  {
    name: "BLF key 4",
    x: 826,
    y: 592,
    width: 95,
    height: 23,
    type: "description",
    background: "none",
    textAlign: "left",
  },
  {
    name: "BLF key 5",
    x: 826,
    y: 443,
    width: 90,
    height: 23,
    type: "description",
    background: "none",
    textAlign: "right",
  },
  {
    name: "BLF key 6",
    x: 826,
    y: 485,
    width: 90,
    height: 23,
    type: "description",
    background: "none",
    textAlign: "right",
  },

  {
    name: "BLF key 7",
    x: 826,
    y: 525,
    width: 90,
    height: 25,
    type: "description",
    background: "none",
    textAlign: "right",
  },

  {
    name: "BLF key 8",
    x: 826,
    y: 569,
    width: 90,
    height: 23,
    type: "description",
    background: "none",
    textAlign: "right",
  },
];

export const GPX2140Coordinates: ImageMapCoordinate[] = [
  {
    name: "Line key 1",
    x: 518,
    y: 115,
    width: 50,
    height: 20,
    type: "Line key",
    values: [
      {
        setting_name: "Account Name",
        setting_description: "Name of the account, displayed on the phone.",
        setting_variable_name: "P270",
        setting_variable_type: "2",
      },
      {
        setting_name: "SIP Server",
        setting_description: "Address of the SIP server/PBX.",
        setting_variable_name: "P47",
        setting_variable_type: "2",
      },
      {
        setting_name: "Secondary SIP Server",
        setting_description: "Address of the Secondary SIP Server/PBX.",
        setting_variable_name: "P2312",
        setting_variable_type: "2",
      },
      {
        setting_name: "Outbound Proxy",
        setting_description: "In case a proxy is used.",
        setting_variable_name: "P48",
        setting_variable_type: "2",
      },
      {
        setting_name: "BLF Server",
        setting_description:
          "Only if you have a specific Busy Lamp Field server.",
        setting_variable_name: "P2375",
        setting_variable_type: "2",
      },
      {
        setting_name: "SIP User ID",
        setting_description: "Usually the extension number.",
        setting_variable_name: "P35",
        setting_variable_type: "2",
      },
      {
        setting_name: "Auth ID",
        setting_description: "Usually the extension number.",
        setting_variable_name: "P36",
        setting_variable_type: "2",
      },
      {
        setting_name: "Auth Password",
        setting_description: "Password or secret set on the PBX.",
        setting_variable_name: "P34",
        setting_variable_type: "2",
      },
      {
        setting_name: "Name",
        setting_description: "Name of the account, not displayed on the phone.",
        setting_variable_name: "P3",
        setting_variable_type: "2",
      },
      {
        setting_name: "Voicemail UserID",
        setting_description: "Dial this to get voicemail. Asterisk is *97.",
        setting_variable_name: "P33",
        setting_variable_type: "2",
      },
      {
        setting_name: "Show Account ID Only?",
        setting_description:
          "Select NO to only show the account name, no extension.",
        setting_variable_name: "P2380",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "No",
          },
          {
            value: "1",
            name: "Yes",
          },
        ],
      },
    ],
  },
  {
    name: "Line key 2",
    x: 518,
    y: 156,
    width: 50,
    height: 20,
    type: "Line key",
    values: [
      {
        setting_name: "Account Active?",
        setting_description: "Mark this account as active or inactive.",
        setting_variable_name: "P401",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "No",
          },
          {
            value: "1",
            name: "Yes",
          },
        ],
      },
      {
        setting_name: "Account Name",
        setting_description: "Name of the account, displayed on the phone.",
        setting_variable_name: "P417",
        setting_variable_type: "2",
      },
      {
        setting_name: "SIP Server",
        setting_description: "Address of the SIP server/PBX.",
        setting_variable_name: "P402",
        setting_variable_type: "2",
      },
      {
        setting_name: "Secondary SIP Server",
        setting_description: "Address of the Secondary SIP Server/PBX.",
        setting_variable_name: "P2412",
        setting_variable_type: "2",
      },
      {
        setting_name: "Outbound Proxy",
        setting_description: "In case a proxy is used.",
        setting_variable_name: "P403",
        setting_variable_type: "2",
      },
      {
        setting_name: "BLF Server",
        setting_description:
          "Only if you have a specific Busy Lamp Field server.",
        setting_variable_name: "P2475",
        setting_variable_type: "2",
      },
      {
        setting_name: "SIP User ID",
        setting_description: "Usually the extension number.",
        setting_variable_name: "P404",
        setting_variable_type: "2",
      },
      {
        setting_name: "Auth ID",
        setting_description: "Usually the extension number.",
        setting_variable_name: "P405",
        setting_variable_type: "2",
      },
      {
        setting_name: "Auth Password",
        setting_description: "Password or secret set on the PBX.",
        setting_variable_name: "P406",
        setting_variable_type: "2",
      },
      {
        setting_name: "Name",
        setting_description: "Name of the account, not displayed on the phone.",
        setting_variable_name: "P407",
        setting_variable_type: "2",
      },
      {
        setting_name: "Voicemail UserID",
        setting_description: "Dial this to get voicemail. Asterisk is *97.",
        setting_variable_name: "P426",
        setting_variable_type: "2",
      },
      {
        setting_name: "Show Account Name Only?",
        setting_description:
          "Select YES to only show the account name, no extension.",
        setting_variable_name: "P2480",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "No",
          },
          {
            value: "1",
            name: "Yes",
          },
        ],
      },
    ],
  },
  {
    name: "Line key 3",
    x: 518,
    y: 198,
    width: 50,
    height: 20,
    type: "Line key",
    values: [
      {
        setting_name: "Account Active?",
        setting_description: "Mark this account as active or inactive.",
        setting_variable_name: "P501",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "No",
          },
          {
            value: "1",
            name: "Yes",
          },
        ],
      },
      {
        setting_name: "Account Name",
        setting_description: "Name of the account, displayed on the phone.",
        setting_variable_name: "P517",
        setting_variable_type: "2",
      },
      {
        setting_name: "SIP Server",
        setting_description: "Address of the SIP server/PBX.",
        setting_variable_name: "P502",
        setting_variable_type: "2",
      },
      {
        setting_name: "Secondary SIP Server",
        setting_description: "Address of the Secondary SIP Server/PBX.",
        setting_variable_name: "P2512",
        setting_variable_type: "2",
      },
      {
        setting_name: "Outbound Proxy",
        setting_description: "In case a proxy is used.",
        setting_variable_name: "P503",
        setting_variable_type: "2",
      },
      {
        setting_name: "BLF Server",
        setting_description:
          "Only if you have a specific Busy Lamp Field server.",
        setting_variable_name: "P2575",
        setting_variable_type: "2",
      },
      {
        setting_name: "SIP User ID",
        setting_description: "Usually the extension number.",
        setting_variable_name: "P504",
        setting_variable_type: "2",
      },
      {
        setting_name: "Auth ID",
        setting_description: "Usually the extension number.",
        setting_variable_name: "P505",
        setting_variable_type: "2",
      },
      {
        setting_name: "Auth Password",
        setting_description: "Password or secret set on the PBX.",
        setting_variable_name: "P506",
        setting_variable_type: "2",
      },
      {
        setting_name: "Name",
        setting_description: "Name of the account, not displayed on the phone.",
        setting_variable_name: "P507",
        setting_variable_type: "2",
      },
      {
        setting_name: "Voicemail UserID",
        setting_description: "Dial this to get voicemail. Asterisk is *97.",
        setting_variable_name: "P526",
        setting_variable_type: "2",
      },
      {
        setting_name: "Show Account Name Only?",
        setting_description:
          "Select YES to only show the account name, no extension.",
        setting_variable_name: "P2580",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "No",
          },
          {
            value: "1",
            name: "Yes",
          },
        ],
      },
    ],
  },
  {
    name: "Line key 4",
    x: 518,
    y: 236,
    width: 50,
    height: 20,
    type: "Line key",
    values: [
      {
        setting_name: "Account Active?",
        setting_description: "Mark this account as active or inactive.",
        setting_variable_name: "P601",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "No",
          },
          {
            value: "1",
            name: "Yes",
          },
        ],
      },
      {
        setting_name: "Account Name",
        setting_description: "Name of the account, displayed on the phone.",
        setting_variable_name: "P617",
        setting_variable_type: "2",
      },
      {
        setting_name: "SIP Server",
        setting_description: "Address of the SIP server/PBX.",
        setting_variable_name: "P602",
        setting_variable_type: "2",
      },
      {
        setting_name: "Secondary SIP Server",
        setting_description: "Address of the Secondary SIP Server/PBX.",
        setting_variable_name: "P2612",
        setting_variable_type: "2",
      },
      {
        setting_name: "Outbound Proxy",
        setting_description: "In case a proxy is used.",
        setting_variable_name: "P603",
        setting_variable_type: "2",
      },
      {
        setting_name: "BLF Server",
        setting_description:
          "Only if you have a specific Busy Lamp Field server.",
        setting_variable_name: "P2675",
        setting_variable_type: "2",
      },
      {
        setting_name: "SIP User ID",
        setting_description: "Usually the extension number.",
        setting_variable_name: "P604",
        setting_variable_type: "2",
      },
      {
        setting_name: "Auth ID",
        setting_description: "Usually the extension number.",
        setting_variable_name: "P605",
        setting_variable_type: "2",
      },
      {
        setting_name: "Auth Password",
        setting_description: "Password or secret set on the PBX.",
        setting_variable_name: "P606",
        setting_variable_type: "2",
      },
      {
        setting_name: "Name",
        setting_description: "Name of the account, not displayed on the phone.",
        setting_variable_name: "P607",
        setting_variable_type: "2",
      },
      {
        setting_name: "Voicemail UserID",
        setting_description: "Dial this to get voicemail. Asterisk is *97.",
        setting_variable_name: "P626",
        setting_variable_type: "2",
      },
      {
        setting_name: "Show Account Name Only?",
        setting_description:
          "Select YES to only show the account name, no extension.",
        setting_variable_name: "P2680",
        setting_variable_type: "1",
        setting_options: [
          {
            value: "0",
            name: "No",
          },
          {
            value: "1",
            name: "Yes",
          },
        ],
      },
    ],
  },
];
