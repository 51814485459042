import { Component, OnInit,ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatSort} from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';


export interface PeriodicElement {
  roles: string;
  policies: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {roles: '1099 Installers (Projects & Tickets only)', policies:'1099 Installers (Projects & Tickets only)'},
  {roles: 'Dev Team', policies:'Developers'},
  {roles: 'Encon IT', policies:'Encon IT  '},
  {roles: 'Executive Admin Dept', policies:'Executive Admin'},
  {roles: 'Full System Access', policies:'Full Access'},
  {roles: 'Limited HR', policies:'HR(Employees, Leave & Attendance)'},
  {roles: 'Sales Dept', policies:'Sales'},
  {roles: 'Support Dept', policies:'Support'},
  {roles: 'Vendor Full Access', policies:'Vendor Edit'},
];

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {
  displayedColumns: string[] = ['roles','policies','action'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}
