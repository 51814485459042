import { Injectable, OnDestroy, Injector,Inject } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { Common } from '../../../../service/constant';
import { AppComponentBase } from '../../../../admin/shared/AppComponentBase';

export interface CdrData {
  dialogOpt: number;
}

@Injectable()
export class CdrDialogService extends AppComponentBase{

  stack: MatDialogRef<any>[] = [];

  constructor(public dialog: MatDialog,inject:Injector,@Inject(MAT_DIALOG_DATA) public data: CdrData) {
    super(inject)
  }

  

  open(content: any, dialogConfig: MatDialogConfig = {}): MatDialogRef<any> {
    dialogConfig = Common.matDialogConfig;
    dialogConfig.panelClass = [this.configService.userTheme,"animated","slideInRight","full-screen-modal"];
    const dialog = this.dialog.open(content, dialogConfig);
    this.stack.push(dialog);
    return dialog;
  }
  
  clear() {
    this.stack.forEach(dialogRef => dialogRef.close());
    this.stack = [];
  }

}