import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { API } from "./constant";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { forkJoin } from "rxjs"; // change to new RxJS 6 import syntax

@Injectable({
  providedIn: "root"
})
export class OpportunitiesService {
  update: boolean;
  id: number;

  public updateOpportunityEventListener = new BehaviorSubject(0);

  constructor(private http: HttpClient) {}

  clientList() {
    let httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    };

    return this.http.get(API.CLIENT_DROPDOWN_LIST);
  }

  leadsList() {
    let httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    };

    return this.http.get(API.LEAD_DROPDOWN_LIST);
  }

  pipelineList() {
    let httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    };

    return this.http.get(API.OPPO_PIPELINE_LIST);
  }

  stagesByPipelineId(pipelineId) {
    let httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    };
    // console.log(data);
    return this.http.get(API.STAGE_LIST_BY_PIPE + pipelineId);
  }

  oppoSourceList() {
    let httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    };

    return this.http.get(API.OPPORTUNITY_SOURCE);
  }

  stateReasonParent() {
    return this.http.get(API.OPPO_STATE_REASON_PARENT);
  }

  stateReason() {
    return this.http.get(API.OPPO_STATE_REASON + "3");
  }

  clientsContacts(data) {
    let httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    };

    return this.http.get(API.CLIENT_CONTACTS + data.id);
  }

  leadsContacts(data) {
    let httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    };

    return this.http.get(API.LEADS_CONTACTS + data.id);
  }

  EmployesDDList() {
    return this.http.get(API.EMP_DD_LIST);
  }

  OppportunitiesList(filter, moduleName, moduleId) {
    filter = JSON.stringify(filter);
    let paramer = '';
    if (moduleName === 'leads') {
      paramer = paramer + '0?moduleId=' + moduleId.leadsId+'&moduleName=' + moduleName;
    }else if (moduleName === 'client'){
      paramer = paramer + '0?moduleId=' + moduleId+'&moduleName=' + moduleName;
    }
    paramer = paramer ? paramer + "&filter=" + filter : "1?filter=" + filter;
    return this.http.get(API.OPPORTUNITY_LIST + paramer);
  }

  createOpportunity(
    opportunityData: any,
    customHeaders?: Function
  ): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    };

    let body = JSON.stringify(opportunityData);
    return this.http.post(API.CREATE_OPPORTUNITY, body, httpOptions);
  }

  OppportunitiesDetails(id) {
    let opportunityDetailsRes = this.http.get(API.OPPORTUNITY_DETAILS + id);
    let OpportunityStatusRes = this.http.get(API.OPPO_STATE_REASON + "3");
    let inactiveDaysRes = this.http.get(API.OPPO_INACTIVE_DAYS + id);

    return forkJoin([
      opportunityDetailsRes,
      OpportunityStatusRes,
      inactiveDaysRes
    ]);
  }

  opportunityDropdown() {
    return this.http.get(API.OPPORTUNITY_DROPDOWN_LIST);
  }

  deleteOpportunity(id) {
    return this.http.delete(API.OPPORTUNITY_DELETE + id);
  }

  updateOpportunity(
    opportunityId: any,
    opportunityData: any
  ) {
    console.log("id", opportunityId);
    console.log("data", opportunityData);
    // let body = JSON.stringify(opportunityData);
    return this.http.post(API.UPDATE_OPPORTUNITY + opportunityId, opportunityData);
  }

  oppByPiplineList(pipelineId, filter?) {
    if (filter) {
      let httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        })
      };
      filter = JSON.stringify(filter);
      let params = new HttpParams();
      params = params.append("filter", filter);
      return this.http.get(API.PIPELINE_OPPORTUNITIES_LIST + pipelineId, {
        params: params
      });
    } else {
      return this.http.get(API.PIPELINE_OPPORTUNITIES_LIST + pipelineId);
    }
  }

  stagesInfoByPipelineId(pipelineId) {
    let httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    };
    let params = new HttpParams();
    params = params.append("id", pipelineId);
    // console.log(data);
    return this.http.get(API.OPPORTUNITY_PIPELINE_STAGES_INFO, {
      params: params
    });
  }

  changeOppStages(
    opportunitiesId: string,
    stageId: string,
    prevStageId: string
  ) {
    let body: any = {
      stageId: stageId,
      prevStageId: prevStageId
    };
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.http.post(
      API.OPPORTUNITY_CHANGE_STAGE + opportunitiesId,
      body,
      httpOptions
    );
  }
  changeOppFinalStatus(opportunitiesId: string, statusId: string) {
    let body: any = { statusId: statusId };
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.http.post(
      API.OPPORTUNITY_CHANGE_FINAL_STATUS + opportunitiesId,
      body,
      httpOptions
    );
  }

  updateOppoStatus(opportunityId, statusData: any){
   console.log(statusData)
    return this.http.post(API.UPDATE_OPPORTUNITY_STATUS + opportunityId, statusData);
  }

  kanbabPopUpData(opportunityId){

     return this.http.get(API.OPPORTUNITY_KANBABN_POPUP + opportunityId);
   }
  
}
