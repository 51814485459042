import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ClientsState } from "../reducers/clients.reducer";

export const getClientsState = createFeatureSelector<ClientsState>('clients');

export const selectAllClients = createSelector(
    getClientsState,
    state => state.data
);

export const selectDeleteState = createSelector(
    getClientsState,
    state => state.deleted
);

export const selectAddState = createSelector(
    getClientsState,
    state => state.added
);