import { Component, OnInit } from "@angular/core";
import { DialogComponent } from "../../dialog/dialog.component";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { ClientService, readCookie } from "../../services";
import Swal from "sweetalert2";
import { EditFormComponent } from "../../edit-form/edit-form.component";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DialogService } from "../../dialog/dialog.service";
import { Observable } from "rxjs";
import { MoboSnackBar } from "../../notification";
import { isShowPhoneApplicable } from "../../helpers/showPhoneHelper";

interface IOptions {
  item_id: string;
  item_text: string;
  item_desc: string;
}

@Component({
  selector: "app-device-settings",
  templateUrl: "./edit-company-settings.component.html",
  styleUrls: ["./edit-company-settings.component.scss"],
})
export class EditCompanySettingsComponent implements OnInit {
  id: string;
  model_id: string;
  device: any;
  companySetting: any;
  companySettingConfigId: any = [];
  phone: MatDialogRef<any>;
  originalData: any;
  filteredFlag: string = "default";

  editForm: FormGroup;
  displayPhone: boolean = false;

  device_manuf: string;
  device_model: string;

  constructor(
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private service: ClientService,
    private dialog: MatDialog,
    private router: Router,
    private snackbar: MoboSnackBar
  ) {}

  ngOnInit() {
    if (!readCookie("authenticate")) {
      this.router.navigate(["/login"], {
        queryParams: { return: location.href },
      });
      return false;
    }
    this.route.params.subscribe((data: any) => {
      this.id = data.id;
      this.model_id = data.model_id;
      this.service
        .companySettingsEdit(this.id, this.model_id)
        .subscribe((data: any) => {
          this.displayPhone = isShowPhoneApplicable(data.device.device_model);
          this.device = data.device;
          this.device_manuf = this.device.device_manuf;
          this.device_model = this.device.device_model;
          this.originalData = JSON.parse(JSON.stringify(data.device));
          this.companySetting = data.company_setting;
          this.companySetting.config.forEach((val, key) => {
            this.companySettingConfigId.push(val.variable);
          });
        });
    });
  }
  showPhone() {
    this.phone = this.dialogService.open(
      DialogComponent,
      {
        width: "80%",
      },
      {
        for: "companySetting",
        companyConfig: this.companySetting.config,
        companySettingId: this.id,
        device: this.displayPhone,
      }
    );
    this.phone.afterClosed().subscribe((data: any) => {
      if (data) {
        if (data.edited) {
          this.companySettingConfigId = [];
          this.service
            .companySettingsEdit(this.id, this.model_id)
            .subscribe((data: any) => {
              this.device = data.device;
              this.companySetting = data.company_setting;
              this.companySetting.config.forEach((val, key) => {
                this.companySettingConfigId.push(val.variable);
              });
              this.showPhone();
            });
        }
      }
    });
  }

  showDescription(name: string, description: string) {
    Swal.fire(name, description);
  }

  edit(settings: any) {
    if (this.filteredFlag !== "defaults") {
      const { defaults } = JSON.parse(JSON.stringify(this.originalData));
      settings = defaults.find((data) => data.name === settings.name);
    }

    const editFormDialog = this.dialog.open(EditFormComponent, {
      width: "50%",
      disableClose: true,
      data: {
        for: "companySetting",
        settings: settings,
        companySetting: this.companySetting,
      },
    });
    editFormDialog.afterClosed().subscribe((data: any) => {
      if (data) {
        if (data.edited) {
          Swal.fire("Edited!", "Company setting has been edited.", "success");
          this.companySettingConfigId = [];
          this.service
            .companySettingsEdit(this.id, this.model_id)
            .subscribe((data: any) => {
              this.device = data.device;
              this.companySetting = data.company_setting;
              this.companySetting.config.forEach((val, key) => {
                this.companySettingConfigId.push(val.variable);
              });
            });
        }
      }
    });
  }

  checkClass(variable: string) {
    this.companySetting.config.forEach((val, key) => {
      if (val.variable == variable) {
        return true;
      }
    });
    return false;
  }

  back() {
    history.back();
  }

  showFilter() {
    var x = document.getElementById("filter-options");
    if (x.style.display == "none" || x.style.display == "") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  }

  firstTime: boolean = false;

  allUnchecked() {
    // @ts-ignore
    if (!document.getElementById("changed").checked) {
      return true;
    }
    return false;
  }

  toggleDisplay(input, Ids, name) {
    if (input.checked) {
      for (var i in Ids) {
        const item = document.getElementById(Ids[i]);
        item.style.display = "block";
        const card = item.parentElement.parentElement.parentElement;
        card.style.display = "block";
        card.classList.add(name);
      }
    } else {
      for (var i in Ids) {
        const item = document.getElementById(Ids[i]);
        item.style.display = "none";
        const card = item.parentElement.parentElement.parentElement;
        card.classList.remove(name);
        if (card.classList.length <= 4) {
          card.style.display = "none";
        }
      }
    }
  }

  settingFilter(name: string) {
    const input = document.getElementById(name);
    if (!this.firstTime) {
      this.firstTime = true;
      const cards = document.getElementsByClassName("setting-card");
      const items = document.getElementsByClassName("setting-item");
      for (var i in cards) {
        // @ts-ignore
        if (cards[i].style) {
          // @ts-ignore
          cards[i].style.display = "none";
        }
      }
      for (var i in items) {
        // @ts-ignore
        if (items[i].style) {
          // @ts-ignore
          items[i].style.display = "none";
        }
      }
    }

    if (!this.allUnchecked()) {
      switch (name) {
        case "changed":
          this.toggleDisplay(input, this.companySettingConfigId, name);
          break;

        default:
          break;
      }
    } else {
      this.firstTime = false;
      const cards = document.getElementsByClassName("setting-card");
      const items = document.getElementsByClassName("setting-item");
      for (var i in cards) {
        // @ts-ignore
        if (cards[i].style) {
          // @ts-ignore
          cards[i].style.display = "block";
        }
      }
      for (var i in items) {
        // @ts-ignore
        if (items[i].style) {
          // @ts-ignore
          items[i].style.display = "block";
        }
      }
    }
  }

  search(value: any) {
    const pPattern = /^p[0-9]+/i;
    if (pPattern.test(value)) {
      const element = document.getElementById(value);
      if (element) {
      }
    }
  }

  // *************** filter data functionality ************

  selectedOptions = [
    {
      item_id: "changedSetting",
      item_text: "Changed Setting",
      item_desc: "(This is a setting that YOU changed, not default)",
    },

    {
      item_id: "normalSetting",
      item_text: "Normal Setting",
      item_desc: "(Unchanged, Default)",
    },
  ];

  options = [
    {
      item_id: "changedSetting",
      item_text: "Changed Setting",
      item_desc: "(This is a setting that YOU changed, not default)",
    },

    {
      item_id: "normalSetting",
      item_text: "Normal Setting",
      item_desc: "(Unchanged, Default)",
    },
  ];
  public filteredDataArray = [];

  onChangeFilterData() {
    this.filteredDataArray = [];

    if (this.selectedOptions.length === this.options.length) {
      return (this.device = JSON.parse(JSON.stringify(this.originalData)));
    } else if (this.selectedOptions.length === 0) {
      return (this.device = []);
    }

    for (let index = 0; index < this.selectedOptions.length; index++) {
      const element = this.selectedOptions[index];
      let filteredData = this.filteredData(element.item_id);

      if (
        this.filteredDataArray.length > 0 &&
        this.selectedOptions.length !== this.options.length
      ) {
        this.filteredDataArray = this.mergeValuesWithDuplicateNames([
          ...this.filteredDataArray,
          ...filteredData,
        ]);
        // this.device.defaults = result2;
      } else {
        this.filteredDataArray.push(...filteredData);
      }
    }
    this.device.defaults = this.filteredDataArray;
  }
  // Function to merge the 'values' arrays for objects with the same 'id'
  mergeValuesWithDuplicateNames(arr) {
    const mergedArray = arr.reduce((result, obj, i) => {
      const existingObj = result.find((item) => item.name === obj.name);
      if (existingObj) {
        existingObj.values.push(...obj.values);
      } else {
        result.push({ ...obj });
      }
      return result;
    }, []);

    return mergedArray.sort((a: any, b: any) => {
      let nA = a.name.toLowerCase();
      let nB = b.name.toLowerCase();

      if (nA < nB) return -1;
      else if (nA > nB) return 1;
      return 0;
    });
  }

  // drop down  filtered  data
  filteredData(str: string) {
    const matchedElementsWithParent = [];
    const { defaults } = JSON.parse(JSON.stringify(this.originalData));

    for (const group of defaults) {
      const matchedValues = [];
      for (const setting of group.values) {
        if (
          this.companySettingConfigId.includes(setting.setting_variable_name) &&
          str === "changedSetting"
        ) {
          matchedValues.push(setting);
        }
        if (
          !this.companySettingConfigId.includes(
            setting.setting_variable_name
          ) &&
          str === "normalSetting"
        ) {
          matchedValues.push(setting);
        }
      }
      if (matchedValues.length > 0) {
        matchedElementsWithParent.push({ ...group, values: matchedValues });
      }
    }
    return matchedElementsWithParent;
  }

  showOptions = false;
  selectAll: boolean = true;

  toggleOption(option: any): void {
    if (this.selectedOptions.some((item) => item.item_id === option.item_id)) {
      this.selectedOptions = this.selectedOptions.filter(
        (o) => o.item_id !== option.item_id
      );
    } else {
      this.selectedOptions.push(option);
    }
    if (this.options.length === this.selectedOptions.length)
      this.selectAll = true;
    else {
      this.selectAll = false;
    }
    this.onChangeFilterData();
  }

  toggleDropdown(): void {
    this.showOptions = !this.showOptions;
  }

  checkSelectedOption(option: IOptions) {
    return this.selectedOptions.some((item) => item.item_id === option.item_id);
  }

  //  on select all and de select all
  onSelectAll(selectall: boolean) {
    this.selectAll = selectall;
    if (selectall) {
      this.device = JSON.parse(JSON.stringify(this.originalData));
      this.selectedOptions = this.options;
    } else {
      this.selectedOptions = [];
      this.device = [];
    }
  }

  // html class for drop down options
  getClass(option: IOptions): string {
    switch (option.item_id) {
      case "changedSetting":
        return "text-danger";
      case "inheritedClientSetting":
        return "text-pink";
      case "inheritedCompanySetting":
        return "text-warning";
      case "inheritedGroupSetting":
        return "text-purple";
      default:
        return "text-muted";
    }
  }
}
