import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class RestService {
  url: string = 'http://35.190.171.10:8000/v2/accounts/89b202e9849d773428704233b2a677d0/users';
  constructor(private http: HttpClient) { }

  sendPutRequest() {
    const headers = new HttpHeaders()
        .set('X-Auth-Token', 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImRmNzU4ZGJmODE4NzM1NjY3YTJmNjA2YjVlNWU4NmY4In0.eyJpc3MiOiJrYXpvbyIsImlkZW50aXR5X3NpZyI6IkZPcWJGQTdva0hTQlBRR0N1d0NzNEhFQThHNjBtZTJocE1xNUMxOU9FODAiLCJhY2NvdW50X2lkIjoiODliMjAyZTk4NDlkNzczNDI4NzA0MjMzYjJhNjc3ZDAiLCJvd25lcl9pZCI6IjgzYTg4ZWVlNGJlMTIzZGU0MDRhZDliNDZlNzI3NmUyIiwibWV0aG9kIjoiY2JfdXNlcl9hdXRoIiwiZXhwIjoxNTQ5NDQwMTYyfQ.sOmFeORNIKonB1jFI96qPGljOeK6usQCtqynVLIFD_Gj5eF-iVHYARs2xcTU5qcfYo2EU4xrbiNxlQ0FifPuhn0e9Rg1fYRXEVQXJbE-XsPIC2DxVCnqymR5QZI7e4B09KHVNjmTh2FsKA3uvh5fRnTS_GjYrFIKgVukh4wgFmJOupUUAYy1D1ckODGQ6d2yeV5xhtzHV5joTAAxsGQ_dIIkNlUWQH9Dg7Ci25NQu2p2iYShQ6E60TnqF_fWb_HRaccvEqUO86rVD29n22HYZMf56gPYCi2qsC_ROlJ36irMiz-qeAk4c_dLdfk0Ax81U65RfLDaYXPbXrWGIgFvgA')
        .set('content-type', 'application/json');
    // const body = {
    //     credentials : '38f77086ecaacb7fa01e789509faabd7',
    //     account_name : 'techspawn',
    //     method : 'md5'
    // }

    return this.http
               .get(this.url,{ headers: headers });
}       
// private handleError<T> (operation = 'operation', result?: T) {
//   return (error: any): Observable<T> => {

//     // TODO: send the error to remote logging infrastructure
//     console.error(error); // log to console instead

//     // TODO: better job of transforming error for user consumption
//     console.log(`${operation} failed: ${error.message}`);

//     // Let the app keep running by returning an empty result.
//     return of(result as T);
//   };
// }
}