import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API } from "./constant";
import { Observable, Subject } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/toPromise';

@Injectable({
  providedIn: 'root'
})
export class TenantCompaniesService {

  constructor(private http: HttpClient) { }
  addCompany(tenantData: any, customHeaders?: Function): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    // console.log(tenantData)
    let body = JSON.stringify(tenantData);
    return this.http.post(API.ADD_TENANT_COMPANY, body, httpOptions);
  }

  subscription(subscriptionData){
    return this.http.post(API.ADD_SUBSCRIPTION, subscriptionData);
  }

  addSeats(seatCount){
    return this.http.post(API.ADD_SEATS, seatCount);
  }

  removeSeats(seatCount){
    return this.http.post(API.REMOVE_SEATS, seatCount);
  }

  getReservedSeats(){
    return this.http.get(API.RESERVED_SEATS);
  }
}
