import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';

export interface PeriodicElement {
  name: String;
  tech: String;
  callerId: number;
  status: String;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {name:'VI-Primary',tech:'SIP',callerId:61462621665,status:'Enabled'},
  {name:'VI-Secondary',tech:'SIP',callerId:61462621665,status:'Enabled'},
  {name:'VI-Tertiary',tech:'SIP',callerId:61462621665,status:'Enabled'},
  {name:'VI-Quaternary',tech:'SIP',callerId:61462621665,status:'Disabled'},
  {name:'VI-Quinary',tech:'SIP',callerId:61462621665,status:'Enabled'},
  {name:'Twilio Out',tech:'SIP',callerId:61462621665,status:'Enabled'},
  {name:'Loopback',tech:'Custom',callerId:61462621665,status:'Enabled'},
  {name:'Twilio Virginia Incoming 1',tech:'Custom',callerId:61462621665,status:'Disabled'},
];
@Component({
  selector: 'app-trunk-setting-list',
  templateUrl: './trunk-setting-list.component.html',
  styleUrls: ['./trunk-setting-list.component.scss'],
})
export class TrunkSettingListComponent implements OnInit {

  displayedColumns: string[] = ['name', 'tech', 'callerId','status', 'action'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor() { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
