import { RegisterTenantService } from './../../service/register-tenant.service';
import { Component, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit {
  progressbarValue = 0;
  curSec: number = 0;
  domain: string;
  showsubdomainCreated: boolean = false;
  showEmailSent: boolean = false;
  showSetupComplete: boolean = false;
  hideProgress: boolean = false;
  showGif: boolean = false;
  isLive: boolean = false;
  constructor(
    private tenantRegister: RegisterTenantService
  ) { }

  ngOnInit() {
    this.startTimer(10);
    this.domain = this.tenantRegister.getShareValue();
    if (window.location.hostname == 'localhost') {
      this.isLive = true;
    } else{ 
      this.isLive = false;
    }
  }

  startTimer(seconds: number) {
    console.log(this.domain)
    const time = seconds;
    const timer$ = interval(1000);
    const sub = timer$.subscribe((sec) => {
      if (sec <= 3) {
        this.showsubdomainCreated = true;
        this.showEmailSent = false;
        this.showSetupComplete = false;
      } else if (sec > 3 && sec <= 6) {
        this.showsubdomainCreated = false;
        this.showEmailSent = true;
        this.showSetupComplete = false;
      } else if (sec > 6 && sec < 10) {
        this.showsubdomainCreated = false;
        this.showEmailSent = false;
        this.showSetupComplete = true;
      } if (sec == 10) {
        this.showsubdomainCreated = false;
        this.showEmailSent = false;
        this.showSetupComplete = false;
        this.hideProgress = true;
        this.showGif = true;
      }
      this.progressbarValue = 0 + sec * 100 / seconds;
      this.curSec = sec;

      if (this.curSec === seconds) {
        sub.unsubscribe();
      }
    });
  }

}
