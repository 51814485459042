import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as toastr from "toastr";
@Component({
  selector: 'app-add-new-announcement',
  templateUrl: './add-new-announcement.component.html',
  styleUrls: ['./add-new-announcement.component.scss']
})


export class AddNewAnnouncementComponent implements OnInit {

  addAnnouncementsFormGroup : FormGroup
  playback1: boolean = false;
  playback2: boolean = false;
  submitted = false;

  recordingData = [
    {id:1, name:'None'},
    {id:2, name:'Recording 1'},
    {id:3, name:'Recording 2'},
    {id:4, name:'Recording 3'},
    {id:5, name:'Recording 4'},
    {id:6, name:'Recording 5'},
  ];

  repeatData = [
    {id:1, name:'0'},
    {id:2, name:'1'},
    {id:3, name:'2'},
    {id:4, name:'3'},
    {id:5, name:'4'},
    {id:6, name:'5'},
  ];

  optDestData1 = [
    {id:1, name:'None'},
    {id:2, name:'Destination 1'},
    {id:3, name:'Destination 2'},
    {id:4, name:'Destination 3'},
    {id:5, name:'Destination 4'},
    {id:6, name:'Destination 5'},
  ];

  constructor(private _formBuilder: FormBuilder) { }

  ngOnInit() {
    this.addAnnouncementsFormGroup =this._formBuilder.group({
      description : ['',Validators.required],
      recording : ['', Validators.required],
      repeat : ['', Validators.required],
      destination1 : ['', Validators.required],
      destination2 : ['', Validators.required], 
    });
    this.addAnnouncementsFormGroup.reset();
  }

  updatePlayback(value: string) {
    this.playback1 = true;
  }

  resetDestinations() {
    this.playback1 = false;
    
  }

  get f() { return this.addAnnouncementsFormGroup.controls; }

  onSubmit() {
      this.submitted = true;

      if (this.addAnnouncementsFormGroup.invalid) {
        toastr.error("Invalid Details !");
        return;
      }
      console.log(this.addAnnouncementsFormGroup.value);

      toastr.success("Form Submitted Successfully !");
      this.addAnnouncementsFormGroup.reset();
  }

}
