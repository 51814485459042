import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator, MatTableDataSource} from '@angular/material';

export interface PeriodicElement {
  description : string;
  destination : string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {description : 'Virtual Assistant', destination : 'IVR: Shotgun blaster-IVR' },
  {description : 'Virtual Assistant', destination : 'IVR: Shotgun blaster-IVR' }
  
];
@Component({
  selector: 'app-all-announcements',
  templateUrl: './all-announcements.component.html',
  styleUrls: ['./all-announcements.component.scss']
})
export class AllAnnouncementsComponent implements OnInit {

  displayedColumns: string[] = [ 'description','destination','action'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  constructor() { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
  }

}
