import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray, AbstractControl } from '@angular/forms';
import { Select2Option, Select2Data } from 'ng-select2-component';
import * as toastr from "toastr";

@Component({
  selector: 'app-add-route',
  templateUrl: './add-route.component.html',
  styleUrls: ['./add-route.component.scss']
})
export class AddRouteComponent implements OnInit {
  inboundForm: FormGroup;
  submitted = false;

  alertInfoData = [
    {id:1, name:'None'},
    {id:2, name:'Alert 1'},
    {id:3, name:'Alert 2'},
    {id:4, name:'Alert 3'},
    {id:5, name:'Alert 4'},
    {id:6, name:'Alert 5'},
  ];

  mohData = [
    {id:1, name:'Default'},
    {id:2, name:'Music 1'},
    {id:3, name:'Music 2'},
    {id:4, name:'Music 3'},
    {id:5, name:'Music 4'},
    {id:6, name:'Music 5'},
  ];

  destinationData = [
    {id:1, name:'Default'},
    {id:2, name:'Destination 1'},
    {id:3, name:'Destination 2'},
    {id:4, name:'Destination 3'},
  ]

  constructor(
    private route: ActivatedRoute, 
    private router: Router,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.inboundForm = this.formBuilder.group({
      description: ['', Validators.required],
      didNumber: ['', Validators.required],
      alertInfo: ['', Validators.required],
      cidNamePrefix: ['', Validators.required],
      mohControl: ['', Validators.required],
      destination1: ['', Validators.required],
      destination2: ['', Validators.required],
    });
    this.inboundForm.reset();
  }

  get f() { return this.inboundForm.controls; }

  onSubmit() {
      this.submitted = true;

      if (this.inboundForm.invalid) {
        toastr.error("Invalid Details !");
        return;
      }
      console.log(this.inboundForm.value);

      toastr.success("Form Submitted Successfully !")
  }

}
