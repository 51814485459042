import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ClientComponent} from "./client.component";

const routes: Routes = [
  {
    path: 'client',
    // component: ClientComponent,
    redirectTo: '/client/dashboard', pathMatch: 'full',
  },
  {path: '', redirectTo: '/client/dashboard', pathMatch: 'full'}
];

// @NgModule({
//   imports: [RouterModule.forChild([
//     {
//         path: 'client',
//         component: ClientComponent,
//     }
//   ]),
// ],
//   exports: [RouterModule]
// })


@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class ClientRoutingModule { }
