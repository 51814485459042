import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ivrlist',
  templateUrl: './ivrlist.component.html',
  styleUrls: ['./ivrlist.component.css']
})
export class IvrlistComponent implements OnInit {


  displayedColumns = ['ivrName','action'];
  constructor() { }

  ngOnInit() {
  }

}
