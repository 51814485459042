import { NgModule } from "@angular/core";
import {
  AccordionAnchorDirective,
  AccordionDirective,
  AccordionLinkDirective
} from "./accordion";
import { SpinnerComponent } from "./spinner.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { AgmCoreModule } from "@agm/core";
import { MaterialModule } from "../../material-module";
import { SubHeaderComponent } from "../layouts/full/sub-header/sub-header.component";
import { RemoveUnderscorePipe } from "./pipes/remove-underscore-filter.pipe";
import { CommonDialogModelComponent } from "./common-dialog-model/common-dialog-model.component";
import { MatchHeightDirective } from "./custom-directives/match-height.directive";
import { Select2Module } from "ng-select2-component";
import {PermissionDialogComponent} from "./permission-dialog/permission-dialog.component";


@NgModule({
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    SpinnerComponent,
    SubHeaderComponent,
    RemoveUnderscorePipe,
    CommonDialogModelComponent,
    MatchHeightDirective,
    PermissionDialogComponent,
    
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    SpinnerComponent,
    SubHeaderComponent,
    RemoveUnderscorePipe,
    CommonDialogModelComponent,
    MatchHeightDirective,
    PermissionDialogComponent,
    
  ],
  providers: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    Select2Module,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyDTPYGvOGHQ0Af2Q-tmziSwZT8JbL0C7AM"
    })
  ],
  entryComponents: [CommonDialogModelComponent, PermissionDialogComponent]
})
export class SharedModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
