import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API} from './constant';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BankCashService {

  constructor(private http: HttpClient) { }

  getAccountList(): Observable<any> {
    return this.http.get(API.ACCOUNT_LIST);
  }
  addNewAccount(data):Observable<any>{
    return this.http.post(API.ADD_ACOUNT, data);
  }
  deleteAccount(id) : Observable<any> {
    return this.http.delete(API.DELETE_ACOUNT + id);
  }
  getUsers(): Observable<any>{
    return this.http.get(API.USERS_LIST);
  }

}
