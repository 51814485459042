import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

import { ModuleListService } from "../../service/module-list.service";
import { TenantExistsService } from "../../service/tenant-exists.service";
import { RegisterTenantService } from "../../service/register-tenant.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as toastr from 'toastr';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  public moduleList: any;
  public ischecked: any;
  public data: any;
  // checkedListModule: any = [1, 2, 5, 6, 7, 39, 65, 76];
  registerForm: FormGroup;
  submitted = false;
  show: boolean = false;
  showDomainAvailability: boolean;
  yourDomain: string;
  domainChecked: boolean = false;
  isDisabled: boolean = false;
  isDisabledCheck: boolean = false;

  constructor(private router: Router,
    private moduleListService: ModuleListService,
    private tenantExistsService: TenantExistsService,
    private fb: FormBuilder,
    private tenantRegister: RegisterTenantService
  ) { }

  ngOnInit() {
    this.yourDomain = ".utiliko.io";
    this.registerForm = this.fb.group({
      fullname: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      companyName: ["", Validators.required],
      domain: ["", Validators.required],
      companyContact: ['', [Validators.required, Validators.pattern('^[0-9.()-+]+$')]],
      selectModules: [],
      password: ["", [Validators.required, Validators.minLength(6)]],
      confirmPassword: ["", [Validators.required, Validators.minLength(6)]]
    });

    document.querySelector('body').setAttribute('themebg-pattern', 'theme1');
    this.moduleListService.getModules().subscribe(data => {
      var dataLength = Object.keys(data).length
      for (let index = 0; index < dataLength; index++) {
        const element = data[index];
        if (element.label == 'dashboard' || element.label == 'calendar' || element.label == 'client' || element.label == 'vendor' || element.label == 'tasks' || element.label == 'hr' || element.label == 'report' || element.label == 'utilities') {
          element.ischecked = 1;
          element.isDisabledCheck = true;
        } else {
          element.ischecked = 0;
          element.isDisabledCheck = false;
        }
      }
      this.moduleList = data;
    });
  }

  // Add new modules to subscribe module array
  /* onCheckboxChange(option, event) {
    console.log(option)
    if (event.checked) {
      this.checkedListModule.push(option.menuId);
    }
    else {
      for (var i = 0; i < this.moduleList.length; i++) {
        if (this.checkedListModule[i] == option.menuId) {
          this.checkedListModule.splice(i, 1);
        }
      }
    }
    console.log(this.checkedListModule);
  } */

  checkEmail(email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(email)) {
      this.tenantExistsService.tenantExists(email).subscribe((res: any) => {
        if (res.data.isTenantExist) {
          this.isDisabled = !this.show;
          this.show = true;
        } else {
          this.show = false;
          this.isDisabled = false;
        }
      });
    }
  }

  checkDomain(domain) {
    if (domain) {
      this.domainChecked = true;
      domain = domain.replace(/ /g, "").toLowerCase();
      this.tenantExistsService.domainExists(domain + ".utiliko.io").subscribe((res: any) => {
        if (res.data.isDomainExist) {
          this.isDisabled = !this.show;
          this.showDomainAvailability = false;
          this.yourDomain = "https://"+domain+".utiliko.io";
        } else {
          this.showDomainAvailability = true;
          this.isDisabled = false;
          this.yourDomain = "https://"+domain+".utiliko.io";
        }
      });
    } else{
      this.domainChecked = false;
    }
  }

  get f() {
    return this.registerForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    // console.log(this.registerForm.value);
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return this.registerForm.controls;
    }
    if (this.registerForm.value.confirmPassword !== this.registerForm.value.password) {
      toastr.options.positionClass = "toast-bottom-right";
      toastr.error("Please enter password and confirm password same");
    } else {
      this.data = {
        fullname: this.registerForm.value.fullname,
        email: this.registerForm.value.email,
        companyName: this.registerForm.value.companyName,
        companyEmail: this.registerForm.value.email,
        domain: this.registerForm.value.domain+".utiliko.io",
        contact: this.registerForm.value.companyContact,
        // modules: this.checkedListModule.toString(),
        password: this.registerForm.value.password
      };

      this.tenantRegister
        .signup(
          this.data
        ).subscribe(
          res => {
            if (res.info.code == 200) {
              this.tenantRegister.setShareValue(
                this.yourDomain
              );
              toastr.options.positionClass = "toast-bottom-right";
              toastr.success("Registration successful");
              this.router.navigate(["/thank-you"], { replaceUrl: true });
            } else {
              toastr.error(res.data.responseMsg);
            }
          },
          error => {
            toastr.options.positionClass = "toast-bottom-right";
            toastr.error("Registeration Failed");
            console.log(error);
          }
        );

    }
  }

}
