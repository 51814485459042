import { Component, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuService } from '../../../../service/menu.service';
import {IResponse, NavItem} from '../../../../service/Utils/Interfaces.class';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: []
})
export class AppSidebarComponent implements OnDestroy {
  myData: any;
  displayList = false;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  navItems: NavItem[];
  config = {
    paddingAtStart: true,
    listBackgroundColor: `#3A3F51`,
    backgroundColor: `#445137`,
    interfaceWithRoute: true,
    highlightOnSelect: true,
    selectedListFontColor: `#ffffff`,
    fontColor: `rgb(8, 54, 71)`,
    collapseOnSelect: true,
    classname: 'my-custom-class',
    rtlLayout: false
  };

  constructor(
    private menuService: MenuService,
    private router: Router,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
  ) 
  {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    // var setupDone = localStorage.getItem('setup');
    // if (setupDone == "1") {
    //   this.menuService.dynamicMenu().subscribe((allMenus: IResponse) => {
    //     if (allMenus.info.code == 200) {
    //       // this.myData = allMenus.data;
    //       this.navItems = allMenus.data;
    //     }
    //   });
    // }  

    this.navItems = [
      {
        label: "Device Models",
        link: 'admin/provisioner/device-models',
        icon: 'tablet_mac'
      },
      {
        label: "Company Settings",
        link: 'admin/provisioner/company-settings',
        icon: 'settings'
      },
      {
        label: "Clients",
        link: 'admin/provisioner/clients',
        icon: 'account_box'
      }
    ]

  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  selectedItem($event) {
    // console.log($event);
  }

  selectedLabel($event) {
    // console.log($event);
  }

  redirect(link) {
    this.router.navigate([link]);
    setTimeout(() => {
      this.displayList = true;
    }, 100);
  }
}
