import { Injectable } from "@angular/core";
import { ClientService } from "../../services/clients.service";
import { Actions, Effect, ofType } from "@ngrx/effects";
import {
  ClientsAction,
  ClientsActionType,
  ClientsLoadSuccess,
  ClientsLoadFail,
  CLientDeleteSuccess,
  CLientDeleteFail,
  CLientAddSuccess,
  CLientAddFail
} from "../actions/index";
import { switchMap, catchError, map } from "rxjs/operators";
import { Clients, DbResponse } from "../../models";
import { of } from "rxjs";

@Injectable()
export class ClientsEffects {
  constructor(
    private actions$: Actions<ClientsAction>,
    private clientService: ClientService
  ) {}

  @Effect()
  clientLoad$ = this.actions$.pipe(
    ofType(ClientsActionType.CLIENTS_LOAD),
    switchMap(() =>
      this.clientService.list().pipe(
        map((result: Clients[]) => new ClientsLoadSuccess(result)),
        catchError(error => of(new ClientsLoadFail(error)))
      )
    )
  );

  @Effect()
  clientDelete$ = this.actions$.pipe(
    ofType(ClientsActionType.CLIENT_DELETE),
    switchMap(action =>
      this.clientService.del(action.payload._id, action.payload._rev).pipe(
        map((result: any) => new CLientDeleteSuccess(result)),
        catchError(error => of(new CLientDeleteFail(error)))
      )
    )
  );

  @Effect()
  clientAdd$ = this.actions$.pipe(
    ofType(ClientsActionType.CLIENT_ADD),
    switchMap(action =>
      this.clientService.add(action.payload.data).pipe(
        map((result: DbResponse) => new CLientAddSuccess(result)),
        catchError(error => of(new CLientAddFail(error)))
      )
    )
  );
}
