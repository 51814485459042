import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatCardModule, MatButtonModule, MatFormFieldModule, MatDialogModule, MatTableModule, MatSortModule, MatPaginatorModule, MatInputModule, MatTabsModule, MatTooltipModule, MatCheckboxModule, MatRadioModule } from '@angular/material';
import { Select2Module } from "ng-select2-component";
import { SettingsRoutingModule } from "./settings-routing.module";
import { SettingsComponent } from "./settings.component";
import { ProfileComponent } from './profile/profile.component';
import { ApplicationComponent } from './application/application.component';
import { NevermisscallComponent } from './nevermisscall/nevermisscall.component';
import { VoicemailComponent } from './voicemail/voicemail.component';
import { WeblauncherComponent } from './weblauncher/weblauncher.component';
import { CommonComponentModule } from "../../common/commonComponent.module";
import { SharedModule } from "../shared/shared.module";

@NgModule({
    imports: [  

  CommonModule,
      BrowserModule,
      MatButtonModule,
      MatCardModule,
      MatDialogModule,
      MatFormFieldModule,
      MatTableModule,
      MatSortModule,
      MatPaginatorModule,
      MatInputModule,
      MatTabsModule,
      MatTooltipModule,
      MatCheckboxModule,
      MatRadioModule,
      FormsModule,
      ReactiveFormsModule,
      Select2Module,
      SettingsRoutingModule,
      CommonComponentModule,
      SharedModule
    ],
    declarations :[
      SettingsComponent,
      ProfileComponent,
      ApplicationComponent,
      NevermisscallComponent,
      VoicemailComponent,
      WeblauncherComponent,
    ],
    entryComponents: [],
})

export class SettingsModule { }