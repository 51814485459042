import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource} from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';

export interface PeriodicElement {
  label: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {label: 'Developers'},
  {label: 'Enco IT'},
  {label: 'Executive Admin'},
  {label: 'Full Access'},
  {label: 'HR(Empoloyees, Leave & Attendance)'},
  {label: 'Opportunity & Leads to Admin'},
  {label: 'Sales'},
  {label: 'Support'},
  {label: 'Vendors Edit'}
];

@Component({
  selector: 'app-policies',
  templateUrl: './policies.component.html',
  styleUrls: ['./policies.component.scss']
})
export class PoliciesComponent implements OnInit {
  displayedColumns: string[] = ['label', 'action'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
  }

}
