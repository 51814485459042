import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from '../admin.component';
import { BillingComponent } from './billing.component'

const routes: Routes = [
  {
    path: 'admin',
    component: AdminComponent,

children: [
    {
      path : 'billing',
      component: BillingComponent
    }
  ]
}
];

// @NgModule({
//   imports: [RouterModule.forChild([
//     {
//         path: 'admin',
//         component: AdminComponent,
   
//     children: [
//         {
//           path : 'billing',
//           component: BillingComponent
//         }
//       ]
//     }
//   ]),
// ],
//   exports: [RouterModule]
// })

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
export class BillingsRoutingModule { }