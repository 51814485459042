import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-systema-recordings',
  templateUrl: './systema-recordings.component.html',
  styleUrls: ['./systema-recordings.component.scss']
})
export class SystemaRecordingsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
