import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator, MatTableDataSource, MatSort} from '@angular/material';

export interface TimeConditions {
  time_group: String;
}

const ELEMENT_DATA: TimeConditions[] = [
  {time_group:'Grapevine wokring hours (except Friday)'},
  {time_group:'Billing-Business Hours'},
  {time_group:'Clinic-Business Hours'},
  {time_group:'Frisco-Warren Pkwy and Plano-Pkwy 5pm till 7:45am'},
  {time_group:'Grapevine to billing-during business hours'},
];

@Component({
  selector: 'app-grouplist',
  templateUrl: './grouplist.component.html',
  styleUrls: ['./grouplist.component.scss']
})
export class GrouplistComponent implements OnInit {

  displayedColumns: string[] = ['time_group','action'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  
  constructor() { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

}
