import { LoginService } from "./service/login.service";
import { TenantExistsService } from "./service/tenant-exists.service";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { StorageKey } from "./service/constant";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { ConfigService } from "./service/config.service";
import { TranslateService } from "@ngx-translate/core";
import * as toastr from "toastr";
import { MatTooltipModule } from "@angular/material/tooltip";
import { Idle, DEFAULT_INTERRUPTSOURCES } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import { MoboSnackBar } from "./admin/provisioner/notification";
import { logoutDelCookie, readCookie } from "./admin/provisioner/services";
import { MatDialog } from "@angular/material";

@Component({
  selector: "app-root",
  encapsulation: ViewEncapsulation.None,
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  public href: string = "";

  idleState = "Not started.";
  timedOut = false;
  lastPing?: Date = null;

  constructor(
    private router: Router,
    private tenantExistsService: TenantExistsService,
    private activatedRoute: ActivatedRoute,
    private configService: ConfigService,
    private loginService: LoginService,
    private translate: TranslateService,
    private snackBar: MoboSnackBar,
    private dialogRef: MatDialog,
    private idle: Idle,
    private keepalive: Keepalive
  ) {
    translate.setDefaultLang("en");
    // timeout handle
    idle.setIdle(290);
    idle.setTimeout(10);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    idle.onIdleEnd.subscribe(() => (this.idleState = "No longer idle."));
    idle.onTimeout.subscribe(() => {
      if (readCookie("authenticate")) {
        this.logout(true);
      } else {
        this.reset();
      }
      this.idleState = "Timed out!";
      this.timedOut = true;
    });
    idle.onIdleStart.subscribe(() => (this.idleState = "You've gone idle!"));
    idle.onTimeoutWarning.subscribe((countdown) => {
      if (!readCookie("authenticate")) {
        return;
      }
      this.snackBar.warn({
        msg: `Your session is about to expire!! ${countdown} seconds remaining.`,
      });
    });
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

    this.reset();
  }

  reset() {
    this.idle.watch();
    this.idleState = "Started.";
    this.timedOut = false;
  }

  logout(r = false) {
    this.dialogRef.closeAll();
    localStorage.clear();
    this.deleteAllCookies();
    logoutDelCookie();
    var extra = {};
    if (r) {
      extra = {
        queryParams: { return: location.href },
      };
    }
    this.router.navigate(["/login"], extra);
  }

  deleteAllCookies() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }

  ngOnInit() {
    const accesstoken = localStorage.getItem(StorageKey.ACCESSTOKEN);
    if (accesstoken) {
      this.configService.getAllConfigDetails();
    } else {
      this.configService.clearValues();
      // this.router.navigate(['/login']);
    }
    this.href = this.router.url;
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (Object.keys(params).length > 0) {
        const user = params.user;
        const activationKey = params.activationKey;
        const email = params.email;
        const password = params.password;
        const domain = params.domain;
        const ck = params.ck;
        if (!accesstoken && user && activationKey) {
          this.tenantExistsService
            .activateTenant(user, activationKey)
            .subscribe((res: any) => {
              toastr.options.positionClass = "toast-bottom-right";
              if (res.info.code === 200) {
                toastr.success(res.data.responseMsg);
              } else {
                toastr.error(res.data.responseMsg);
              }
            });
        } else if (!accesstoken && email && password && domain) {
          const data = {
            email: email,
            password: password,
            domain: domain,
            tenantCompanyKey: ck,
          };

          this.loginService.userLogin(data).subscribe(
            (res: any) => {
              toastr.options.positionClass = "toast-bottom-right";
              if (
                (res.statusCode == 200 && res.domain == domain) ||
                (res.statusCode == 200 && domain == "localhost")
              ) {
                toastr.success("Login successful");
                localStorage.setItem(StorageKey.ACCESSTOKEN, res.accessToken);
                localStorage.setItem("userId", res.userId);
                localStorage.setItem("theme", res.theme);
                localStorage.setItem("owner", res.isOwner);
                localStorage.setItem("setup", res.issetupdone);
                localStorage.setItem("isUbm", res.isUBM);
                localStorage.setItem("domain", res.domain);
                localStorage.setItem("tk", res.tkey);
                if (res.issetupdone == 1 && res.isSubscriptionOver == 0) {
                  localStorage.setItem("subscription", "1");
                  this.router.navigate(["/admin"], { replaceUrl: true });
                } else if (
                  res.issetupdone == 1 &&
                  res.isSubscriptionOver == 1
                ) {
                  localStorage.setItem("subscription", "0");
                  toastr.options.positionClass = "toast-bottom-right";
                  toastr.warning("Please renew subscription.");
                  this.router.navigate(["/subscription"], { replaceUrl: true });
                } else {
                  localStorage.setItem("subscription", "1");
                  toastr.options.positionClass = "toast-bottom-right";
                  toastr.success("Complete setup to get started.");
                  this.router.navigate(["/wizard"], { replaceUrl: true });
                }
              } else {
                toastr.error(
                  "Login credentials do not match the system. Please try again"
                );
                this.router.navigate(["/page-not-found"], { replaceUrl: true });
              }
            },
            (error) => {
              toastr.options.positionClass = "toast-bottom-right";
              if (
                error.error &&
                error.error.error &&
                error.error.error.data &&
                error.error.error.data.responseMsg
              ) {
                toastr.error(
                  "Login credentials do not match the system. Please try again"
                );
              } else {
                toastr.error(
                  "Login credentials do not match the system. Please try again"
                );
              }
              // console.log(error);
            }
          );
          // this.router.navigate(['login']);
        }
      } else {
        if (!accesstoken) {
          //&& this.href != '/') {
          //this.router.navigate(['login']);
        }
      }
    });
  }
}
