import { Component, OnInit,ViewChild, Injector } from '@angular/core';
import { Select2Option, Select2Data } from 'ng-select2-component';
import {MatPaginator, MatTableDataSource} from '@angular/material';
import { ExcelGenerator } from '../../../service/Utils/ExcelGenerator';
import { CdrDialogComponent } from './cdr-dialog/cdr-dialog.component'
// import { CdrDialogService } from './cdr-dialog/cdr-dialog.service';
import {MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA} from '@angular/material';
import { Common } from '../../../service/constant';
import { AppComponentBase } from '../../../admin/shared/AppComponentBase';
export interface PeriodicElement {
  checked: boolean;
  callername: string;
  callernumber: number;
  callerdestination: number;
  destination: number;
  recording: string;
  start: string;
  tta: number;
  duration: string;
  pdd: number;
  hangupcause: string
  status: string
}


const ELEMENT_DATA: PeriodicElement[] = [
  {checked: false , status:'delete',callername:'AVS-Fusion-300', callernumber: 302, callerdestination: 300, destination: 300, recording:'', start:'24-12-2018', tta:13,duration:'00:14:00',pdd:13.54,hangupcause:'Normal Clearing' },
  {checked: false , status:'dial',callername:'AVS-Fusion-300', callernumber: 302, callerdestination: 300, destination: 300, recording:'', start:'24-12-2018', tta:13,duration:'00:14:00',pdd:13.54,hangupcause:'Normal Clearing' },
  {checked: false , status:'delete',callername:'AVS-Fusion-300', callernumber: 302, callerdestination: 300, destination: 300, recording:'', start:'24-12-2018', tta:13,duration:'00:14:00',pdd:13.54,hangupcause:'Normal Clearing' },
  {checked: false , status:'cancel',callername:'AVS-Fusion-300', callernumber: 302, callerdestination: 300, destination: 300, recording:'', start:'24-12-2018', tta:13,duration:'00:14:00',pdd:13.54,hangupcause:'Normal Clearing' },
  {checked: false , status:'cancel',callername:'AVS-Fusion-300', callernumber: 302, callerdestination: 300, destination: 300, recording:'', start:'24-12-2018', tta:13,duration:'00:14:00',pdd:13.54,hangupcause:'Normal Clearing' },
  {checked: false , status:'dial',callername:'AVS-Fusion-300', callernumber: 302, callerdestination: 300, destination: 300, recording:'', start:'24-12-2018', tta:13,duration:'00:14:00',pdd:13.54,hangupcause:'Normal Clearing' },
  {checked: false , status:'delete',callername:'AVS-Fusion-300', callernumber: 302, callerdestination: 300, destination: 300, recording:'', start:'24-12-2018', tta:13,duration:'00:14:00',pdd:13.54,hangupcause:'Normal Clearing' },
  {checked: false , status:'missed',callername:'AVS-Fusion-300', callernumber: 302, callerdestination: 300, destination: 300, recording:'', start:'24-12-2018', tta:13,duration:'00:14:00',pdd:13.54,hangupcause:'Normal Clearing' },
  {checked: false , status:'delete',callername:'AVS-Fusion-300', callernumber: 302, callerdestination: 300, destination: 300, recording:'', start:'24-12-2018', tta:13,duration:'00:14:00',pdd:13.54,hangupcause:'Normal Clearing' },
  {checked: false , status:'cancel',callername:'AVS-Fusion-300', callernumber: 302, callerdestination: 300, destination: 300, recording:'', start:'24-12-2018', tta:13,duration:'00:14:00',pdd:13.54,hangupcause:'Normal Clearing' },
  
  
];

@Component({
  selector: 'app-call-details-record',
  templateUrl: './call-details-record.component.html',
  styleUrls: ['./call-details-record.component.scss']
})
export class CallDetailsRecordComponent extends AppComponentBase implements OnInit {

  recording1: any
  public data2 : Select2Data = [
    { value: 'tot_calls', label: 'Total Calls' },
    { value: 'inbound_calls', label: 'Inbound Calls',},
    { value: 'outbound_calls', label: 'Outbound Calls' },
    { value: 'internal_calls', label: 'Internal Calls' },
    { value: 'answered', label: 'Answered'},
    { value: 'missed', label: 'Missed'},
    { value: 'busy', label: 'Busy'},
    
  ]
  displayedColumns: string[] = ['checked','status','callername', 'callernumber', 'callerdestination', 'destination','recording','start','tta','duration','pdd','hangupcause','action'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  
    
  dialogConfig: MatDialogConfig = {};
  stack: MatDialogRef<any>[] = [];
  
  data: Select2Option[] = [];
  CdrListExcel = new ExcelGenerator();
  dialogOpt: number;

  constructor(
    // private dialogService: CdrDialogService
    public dialog: MatDialog,
    public inject: Injector
    ) {
      super(inject);
     }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
  }
  update(value: string) {
    this.recording1 = value;
  }
  open() {
    this.data = JSON.parse(JSON.stringify(this.data2));
  }

  search(text: string) {
    this.data = text
      ? (JSON.parse(JSON.stringify(this.data2)) as Select2Option[])
        .filter(option => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1)
      : JSON.parse(JSON.stringify(this.data2));
  }

  downloadExcel() {
    const rows = [];
    this.dataSource.data.forEach(function (column) {

      const excelData = {
        "Caller Name" : column['callername'],
        "Caller Number": column['callernumber'],
        "Caller Destination": column['callerdestination'],
        "Destination": column['destination'],
        "Recording": column['recording'],
        "Start": column['start'],
        "TTA" : column['tta'],
        "Duration" : column['duration'],
        "Pdd" : column['pdd'],
        "Hangup cause" : column['hangupcause'],
      };
      rows.push(excelData);
    });
    this.CdrListExcel.generatExcel('Call Details Records', rows, 'Call Details Records');
  }

  showLogin(option) {
    this.dialogOpt = option;
    this.dialogConfig = Common.matDialogConfig;
    this.dialogConfig.panelClass = [this.configService.userTheme,"animated","slideInRight","full-screen-modal"];
    this.dialogConfig.data = {dialogOpt: this.dialogOpt}
    console.log("tableOption in CDR:- ", this.dialogOpt);
    this.dialog.open(CdrDialogComponent, this.dialogConfig)
  }

}
