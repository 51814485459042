import { Component, OnInit } from '@angular/core';
import { Select2Option, Select2Data } from 'ng-select2-component';

@Component({
  selector: 'app-client-reports',
  templateUrl: './client-reports.component.html',
  styleUrls: ['./client-reports.component.scss']
})
export class ClientReportsComponent implements OnInit {
  recording :any
  public Usercalldetails = [
    {name:'abc', inboundcalls: 29, outboundcalls: 10, totalcalls:49, internalcalls: 10},
    {name:'abc', inboundcalls: 29, outboundcalls: 10, totalcalls:49, internalcalls: 10},
    {name:'abc', inboundcalls: 29, outboundcalls: 10, totalcalls:49, internalcalls: 10},
    {name:'abc', inboundcalls: 29, outboundcalls: 10, totalcalls:49, internalcalls: 10}
  ]

  
  public data2 : Select2Data = [
    { value: 'tot_calls', label: 'Total Calls' },
    { value: 'inbound_calls', label: 'Inbound Calls',},
    { value: 'outbound_calls', label: 'Outbound Calls' },
    { value: 'internal_calls', label: 'Internal Calls' },
  ]
  
  data: Select2Option[] = [];



  constructor() { }

  ngOnInit() {
  }
  update(value: string) {
    this.recording = value;
  }
  open() {
    this.data = JSON.parse(JSON.stringify(this.data2));
  }

  search(text: string) {
    this.data = text
      ? (JSON.parse(JSON.stringify(this.data2)) as Select2Option[])
        .filter(option => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1)
      : JSON.parse(JSON.stringify(this.data2));
  }

}
