import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-dial-pad',
  templateUrl: './dial-pad.component.html',
  styleUrls: ['./dial-pad.component.scss']
})
export class DialPadComponent implements OnInit {
  dialPadShow : boolean;
  numberStr: string = "";
  displayContact: string;
  list = [
    {initials:'K',name:'Kerisa Knezha',callNumber:'+1 860-226-5623'},
    {initials:'N',name:'Nicholas Inman',callNumber:'+1 720-369-2733'},
    {initials:'C',name:'Clint Barton',callNumber:'+1 860-226-5623'},
    {initials:'S',name:'Steve Rogers',callNumber:'+1 720-369-2733'},
    {initials:'B',name:'Bruce Wayne',callNumber:'+12 980-399-7432'},
    {initials:'B',name:'Barry Allen',callNumber:'+25 092-555-0982'},
    {initials:'T',name:'Tony Stark',callNumber:'+91 772-675-6647'}
  ];
  newArray = this.list;
  constructor() { }

  ngOnInit() {
    this.dialPadShow = true;
  }

  toggleDialPad() {
    this.dialPadShow = !this.dialPadShow; 
    this.numberStr = '';   
  }

  clearNumber(numberStr) {
    var numLength;
    var numClear;
    this.newArray = [];
    if(numberStr.length > 0) {
      numLength = numberStr.length - 1;
      numClear = numberStr.substring(0, numLength);
      numberStr = numClear;
    }
    else {
      numberStr = '';
    }
    this.list.forEach(element=>{
      if(element.callNumber.includes(numberStr)) {
        this.newArray.push(element);
      }
    });
    return numberStr;
  }

  addNumber(str, event) {
    this.numberStr = this.numberStr + str;
    this.newArray = [];
    this.list.forEach(element=>{
      if(element.callNumber.includes(this.numberStr)) {
        this.newArray.push(element);
      }
    });
  }

  showPhoneNum(str) {
    this.numberStr = str;
  }
}
