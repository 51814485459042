import { Injectable } from "@angular/core";
import { Effect, Actions, ofType } from "@ngrx/effects";
import { CompanySettingAction, CompanySettingActionType, CompanySettingLoadSuccess, CompanySettingLoadFail } from "../actions";
import { ClientService } from "../../services";
import { switchMap, catchError, map } from "rxjs/operators";
import { CompanySettings } from "../../models";
import { of } from "rxjs";


@Injectable()
export class CompanySettingEffects {
    constructor(
        private actions$: Actions<CompanySettingAction>,
        private clientService: ClientService
    ) {}

    @Effect()
    companySettingLoad$ = this.actions$.pipe(
        ofType(CompanySettingActionType.COMPANY_SETTING_LOAD),
        switchMap(() => 
            this.clientService.comapnySettings().pipe(
                map((result: CompanySettings[]) => new CompanySettingLoadSuccess(result)),
                catchError(err=> of(new CompanySettingLoadFail(err)))
            )
        )
    );
}