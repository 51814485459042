import { Component, OnInit,ViewChild } from '@angular/core';
import {MatPaginator, MatTableDataSource} from '@angular/material';

export interface PeriodicElement {
  billdate : string;
  amount : string;
  payment : string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { billdate:'26-6-18',amount:'$10', payment:'$20'},
  { billdate:'27-7-18',amount:'$20',payment: '$40'}
  
];
@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css']
})
export class InvoicesComponent implements OnInit {
  displayedColumns: string[] = ['user','billdate','amount','payment'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  constructor() { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
  }

}
