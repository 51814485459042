import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API } from "./constant";
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/toPromise';

@Injectable({
    providedIn: 'root'
})
export class LeadsService {
    update: boolean;
    id: number;
    public updateLeadsEventListener = new BehaviorSubject(0);
    constructor(private http: HttpClient) { }

    leadStatusList() {
        return this.http.get(API.LEAD_STATUS_LIST);
    }
    leadSourceList() {
        return this.http.get(API.LEAD_SOURCE_LIST);
    }
    EmployesDDList() {
        return this.http.get(API.EMP_DD_LIST);
    }
    createLead(leadData: any){
        console.log(leadData);
        return this.http.post(API.CREATE_LEAD,leadData);
    }
    list(type?, filter?) {
        return this.http.get(API.LEADS_LIST + type +'?filter=' + JSON.stringify(filter));
    }
    rowCount(filter?) {
        return this.http.get(API.LEADS_ROWCOUNT + '?filter=' + JSON.stringify(filter));
    }
    setShareValue(update, id?) {
        this.update = update;
        this.id = id;
    }
    getShareId(){
        return this.id;
    }
    getShareValue() {
        return this.update;
    }
    detailsById(leadsId) {
        return this.http.get(API.LEAD_DETAILS + leadsId);
    }
    
    updateLead(leadsId: any, leadData: any) {
        console.log(leadsId,leadData);
        return this.http.post(API.UPDATE_LEAD+leadsId, leadData);
    }
    updateLeadStatus(leadData: any) {
        return this.http.post(API.UPDATE_LEAD_STATUS, leadData);
    }

    deleteLead(leadsId) {
        return this.http.delete(API.LEAD_DELETE + leadsId);
    }
    uploadFile(fileObject) {
        return this.http.post(API.ATTACHMENTS_UPLOAD, fileObject);
    }
    importLeads(importData): Observable<any> {
        return this.http.post(API.IMPORT_LEADS, importData);
    }
    
    leadContacts(leadsId, filter?) {
        return this.http.get(API.LEADS_CONTACTS_LIST + leadsId + '?filter=' + JSON.stringify(filter));
    }
    contactDetails(contactsId) {
        return this.http.get(API.LEAD_CONTACT_DETAILS + contactsId);
    }
    updateContact(contactsId, updateData): Observable<any> {
        return this.http.post(API.UPDATE_LEAD_CONTACT + contactsId, updateData);
    }
    addContact(contactData): Observable<any> {
        return this.http.post(API.ADD_LEAD_CONTACT, contactData);
    }
    deleteContact(contactsId) {
        return this.http.delete(API.DELETE_LEAD_CONTACT + contactsId);
    }
    makePrimary(contactsId, data) {
        return this.http.post(API.LEADS_PRIMARY_CONTACT_UPDATE + contactsId, data);
    }
    contactsDDList(leadsId) {
        return this.http.get(API.LEADS_CONTACTS_LIST_DD + leadsId);
    }
    convertToClient(leadsId, request){
        return this.http.post(API.CONVERT_LEAD_TO_CLIENT + leadsId, request);

    }
}
