import { NgModule, Component } from "@angular/core";
import { RouterModule, Router, Routes } from "@angular/router";
import { ClientComponent } from "../client.component";
import { ClientReportsComponent } from './client-reports/client-reports.component'
import { CallDetailsRecordComponent } from './call-details-record/call-details-record.component'
import { ExtensionInfoAndSettingComponent } from './extension-info-and-setting/extension-info-and-setting.component'
import { ConferenceComponent } from './conference/conference.component'
import { QueueReportsComponent } from './queue-reports/queue-reports.component'
import { LiveCallMonitoringComponent }  from './live-call-monitoring/live-call-monitoring.component'
import { VoicemailComponent } from './voicemail/voicemail.component'

const routes: Routes = [
    {
        path: 'client',
        component: ClientComponent,
        children: [
            {
                path: 'reports',
                children: [
                    {
                        path : 'clientreport',
                        component : ClientReportsComponent
                    },
                    {
                        path: 'calldetails',
                        component : CallDetailsRecordComponent
                    },
                    {
                        path:'extensioninfoandsetting',
                        component: ExtensionInfoAndSettingComponent
                    },
                    {
                        path : 'conference',
                        component: ConferenceComponent
                    },
                    {
                        path : 'queuereport',
                        component : QueueReportsComponent
                    },
                    {
                        path : 'livecallmonitoring',
                        component : LiveCallMonitoringComponent
                    },
                    {
                        path : 'voicemail',
                        component : VoicemailComponent
                    }
                  
                ]
            }
        ]
    }
]
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ReportsRoutingModule {}