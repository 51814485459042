import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PermissionsUsersComponent } from "./permissions-users.component";
import { MatTabsModule, MatAutocompleteModule, MatSortModule, MatButtonModule, MatRadioModule,MatCheckboxModule, MatTableModule,MatInputModule, MatFormFieldModule, MatPaginatorModule, MatCardModule} from "@angular/material";
import { TreeModule } from 'primeng/primeng';
import { CheckboxModule} from 'primeng/checkbox';
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { PoliciesComponent } from './policies/policies.component';
import { NewPolicyComponent } from './new-policy/new-policy.component';
import { RolesComponent } from './roles/roles.component';
import { UsersComponent } from './users/users.component';
import { AddnewuserComponent } from './addnewuser/addnewuser.component';
import { CommonComponentModule } from "../../../common/commonComponent.module";


@NgModule({
    imports: [
      CommonModule,
      BrowserAnimationsModule,
      ReactiveFormsModule,
      MatPaginatorModule,
      MatTabsModule,
      MatTableModule,
      MatInputModule,
      MatSortModule,
      MatFormFieldModule,
      MatCardModule,
      HttpClientModule,
      MatButtonModule,
      MatCheckboxModule,
      MatRadioModule,
      MatAutocompleteModule,
      TreeModule,
      CheckboxModule,
      FormsModule,
      CommonComponentModule

    ],
    providers: [],
    declarations: [
        PermissionsUsersComponent,
        PoliciesComponent,
        NewPolicyComponent,
        RolesComponent,
        UsersComponent,
        AddnewuserComponent,
    ],
    exports: [PermissionsUsersComponent],
  })
  export class PermissionsUsersModule { }