import { Component, OnInit, Inject, Optional } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import { ClientService } from "../../services";
import { MoboSnackBar } from "../../notification";
import Swal from "sweetalert2";

@Component({
  selector: "add-single-device-setting",
  templateUrl: "./add-single-device-setting.component.html",
  styleUrls: ["./add-single-device-setting.component.scss"],
})
export class AddSingleDeviceSettingComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<AddSingleDeviceSettingComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data,
    private _formBuilder: FormBuilder,
    private service: ClientService,
    private snackBar: MoboSnackBar
  ) {}

  deviceSetting: FormGroup;
  found: boolean = true;
  searchValue: any = null;
  load: boolean = false;
  categories = [];

  ngOnInit() {
    this.deviceSetting = this._formBuilder.group({
      category: [null, Validators.required],
      setting_name: [null, Validators.required],
      setting_description: [null, Validators.required],
      setting_variable_name: [null, Validators.required],
      setting_variable_type: [null, Validators.required],
      new_options: this._formBuilder.array([]),
    });
    for (var i in this.data.defaults) {
      this.categories.push(this.data.defaults[i].name);
    }
  }

  get newOptions() {
    return this.deviceSetting.get("new_options") as FormArray;
  }

  addSettingOption() {
    return this.newOptions.push(this.addOption());
  }

  addOption(): FormGroup {
    return this._formBuilder.group({
      value: ["", Validators.required],
      name: ["", Validators.required],
    });
  }

  removeSettingOption(index: any) {
    return this.newOptions.removeAt(index);
  }

  search(reset = true) {
    if (this.searchValue == "") {
      return false;
    }
    if (reset) {
      this.deviceSetting.reset();
      this.deviceSetting.setControl("new_options", this._formBuilder.array([]));
    }
    this.found = false;
    for (var i in this.data.defaults) {
      for (var j in this.data.defaults[i].values) {
        if (
          this.data.defaults[i].values[j].setting_variable_name ==
          this.searchValue
        ) {
          this.found = true;
          break;
        }
      }
      if (this.found) {
        break;
      }
    }
    if (this.found) {
      this.snackBar.error({
        msg: "P value already exists",
        action: "Close",
      });
      return false;
    }
    this.deviceSetting.controls.setting_variable_name.setValue(
      this.searchValue
    );
    return true;
  }

  addDeviceSetting() {
    if (this.deviceSetting.valid && this.search(false)) {
      if (
        this.deviceSetting.value.setting_variable_type == "1" &&
        this.deviceSetting.value.new_options.length < 1
      ) {
        return this.snackBar.error({
          msg: "Minimum one option is required",
          action: "Close",
        });
      }
      var newSetting = this.data;
      this.load = true;
      for (var i in newSetting.defaults) {
        if (newSetting.defaults[i].name == this.deviceSetting.value.category) {
          var setting_options = [],setting_options_V = [];
          for (var o in this.deviceSetting.value.new_options) {
            if (
              setting_options_V.includes(
                this.deviceSetting.value.new_options[o].value
              ) &&
              this.deviceSetting.value.setting_variable_type == "1"
            ) {
              Swal.fire(
                "Oopsss...",
                "All the values of setting options should be unique.",
                "error"
              );
              return;
            }setting_options_V.push(this.deviceSetting.value.new_options[o].value);
            setting_options.push(this.deviceSetting.value.new_options[o]);
          }
          if (
            setting_options.length > 0 &&
            this.deviceSetting.value.setting_variable_type == "1"
          ) {
            newSetting.defaults[i].values.push({
              setting_description: this.deviceSetting.value.setting_description,
              setting_name: this.deviceSetting.value.setting_name,
              setting_options: setting_options,
              setting_variable_name: this.deviceSetting.value
                .setting_variable_name,
              setting_variable_type: this.deviceSetting.value
                .setting_variable_type,
            });
          } else {
            newSetting.defaults[i].values.push({
              setting_description: this.deviceSetting.value.setting_description,
              setting_name: this.deviceSetting.value.setting_name,
              setting_variable_name: this.deviceSetting.value
                .setting_variable_name,
              setting_variable_type: this.deviceSetting.value
                .setting_variable_type,
            });
          }
        }
      }

      this.service
        .editDeviceSetting(this.data._id, newSetting.defaults)
        .subscribe((data: any) => {
          if (data) {
            if (data.ok) {
              this.dialogRef.close({
                added: true,
              });
            } else {
              this.load = false;
              Swal.fire("Oopsss...", "Something went worng", "error");
            }
          }
        });
    }
  }
}
