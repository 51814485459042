import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as toastr from "toastr";

@Component({
  selector: 'app-add-call-flow-toggle',
  templateUrl: './add-call-flow-toggle.component.html',
  styleUrls: ['./add-call-flow-toggle.component.scss']
})
export class AddCallFlowToggleComponent implements OnInit {

  addcallFlowToggleFormGroup : FormGroup
  submitted = false;
  hide = true;

  recordingData = [
    {id:1, name:'None'},
    {id:2, name:'Recording 1'},
    {id:3, name:'Recording 2'},
    {id:4, name:'Recording 3'},
    {id:5, name:'Recording 4'},
    {id:6, name:'Recording 5'},
  ];
  destinationData = [
    {id:1, name:'Default'},
    {id:2, name:'Destination 1'},
    {id:3, name:'Destination 2'},
    {id:4, name:'Destination 3'},
    {id:5, name:'Destination 4'},
    {id:6, name:'Destination 5'},
  ];

  constructor(private _formBuilder: FormBuilder) { }

  ngOnInit() {
    this.addcallFlowToggleFormGroup =this._formBuilder.group({
      featurecode  : ['',Validators.required],
      description : ['',Validators.required],
      recordNormal : ['',Validators.required],
      recordOverride : ['',Validators.required],
      optPassword : ['',Validators.required], 
      normalFlow1 : ['', Validators.required],
      normalFlow2 : ['', Validators.required],
      overrideFlow1 : ['', Validators.required],
      overrideFlow2 : ['', Validators.required],
    });

    this.addcallFlowToggleFormGroup.reset();
  }

  addcallFlowToggleForm(){
    console.log(this.addcallFlowToggleFormGroup.value)
  }

  get f() { return this.addcallFlowToggleFormGroup.controls; }

  onSubmit() {
      this.submitted = true;

      if (this.addcallFlowToggleFormGroup.invalid) {
        toastr.error("Invalid Details !");
        return;
      }
      console.log(this.addcallFlowToggleFormGroup.value);

      toastr.success("Form Submitted Successfully !");
      this.addcallFlowToggleFormGroup.reset();
  }
  


}
