import { Component, OnInit,ViewChild } from '@angular/core';
import {MatPaginator, MatTableDataSource} from '@angular/material';
export interface PeriodicElement {
  conference: number;
  description : string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {conference:9000 , description : 'Virtual Assistant' }
  
];
@Component({
  selector: 'app-all-conference-bridges',
  templateUrl: './all-conference-bridges.component.html',
  styleUrls: ['./all-conference-bridges.component.scss']
})
export class AllConferenceBridgesComponent implements OnInit {

  displayedColumns: string[] = ['conference', 'description','action'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  constructor() { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
  }

}
