import { Component, OnInit } from '@angular/core';
import {  } from 'ng2-charts';
import { Chart, ChartType, ChartOptions } from 'chart.js';
@Component({
  selector: 'app-call-status',
  templateUrl: './call-status.component.html',
  styleUrls: ['./call-status.component.css','../client-dashboard.component.scss']
})
export class CallStatusComponent implements OnInit {
  public doughnutChartLabels = ['Answered','Missed','Voicemail','Busy'];
  public doughnutChartData = [350, 200, 200,100];
  public doughnutChartType: ChartType = 'doughnut';
  public doughnutChartOptions: ChartOptions = {
    layout: {
      padding: {
        left: 50,
        right: 20,
        top: 0,
        bottom: 0
      }
    },
    cutoutPercentage: 60,
    responsive: true,
    legend: {
      labels: {
        usePointStyle: true,
      },
      position: "right",
    }
  }
  public doughnutChartColors:Array<any> = [
    {
      backgroundColor: [
        'rgba(100, 88, 174,1)',
        'rgba(230, 124, 76,1)',
        'rgba(121, 193, 126,1)',
        'rgba(101, 197, 221,1)'
      ],
    }

  ];
  constructor() { }

  ngOnInit() {
  }
  
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }
}
