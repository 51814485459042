import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API } from './constant';

@Injectable({
    providedIn: 'root'
})
export class MenuService {

    constructor(private http: HttpClient) { }

    dynamicMenu() {
        return this.http.get(API.DYNAMIC_MENU);
    }
    getSubscribedModules(){
        return this.http.get(API.SUBSCRIBED_MODULES);
    }

}
