import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray, AbstractControl  } from '@angular/forms';
import { Select2Option, Select2Data } from 'ng-select2-component';
import * as toastr from "toastr";
import {NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-addtimegroup',
  templateUrl: './addtimegroup.component.html',
  styleUrls: ['./addtimegroup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddtimegroupComponent implements OnInit {

  timeGroupForm: FormGroup;
  submitted = false;
  // public timeValue: Date = new Date();

  public recording

  public data2 : Select2Data = [
    { value: 'AK', label: 'Alaska' },
    { value: 'HI', label: 'Hawaii',},
    { value: 'CA', label: 'California' },
    { value: 'NV', label: 'Nevada' },
    { value: 'OR', label: 'Oregon' },
    { value: 'WA', label: 'Washington' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'CO', label: 'Colorado' },
    { value: 'ID', label: 'Idaho' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'ND', label: 'North Dakota' }
  ];

  public data4 : Select2Data = [
    { value: '00', label: '00' },
    { value: '01', label: '01' },
    { value: '02', label: '02',},
    { value: '03', label: '03' },
    { value: '04', label: '04' },
    { value: '05', label: '05' },
    { value: '06', label: '06' },
    { value: '07', label: '07' },
    { value: '08', label: '08' },
    { value: '09', label: '09' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
    { value: '13', label: '13' },
    { value: '14', label: '14' },
    { value: '15', label: '15' },
    { value: '16', label: '16' },
    { value: '17', label: '17' },
    { value: '18', label: '18' },
    { value: '19', label: '19' },
    { value: '20', label: '20' },
    { value: '21', label: '21' },
    { value: '22', label: '22' },
    { value: '23', label: '23' },
    { value: '24', label: '24' },
    { value: '25', label: '25' },
    { value: '26', label: '26' },
    { value: '27', label: '27' },
    { value: '28', label: '28' },
    { value: '29', label: '29' },
    { value: '30', label: '30' },
    { value: '31', label: '31' },
  ];

  public data5 : Select2Data = [
    { value: 'Sunday', label: 'Sunday' },
    { value: 'Monday', label: 'Monday',},
    { value: 'Tuesday', label: 'Tuesday' },
    { value: 'Wednesday', label: 'Wednesday' },
    { value: 'Thursday', label: 'Thursday' },
    { value: 'Friday', label: 'Friday' },
    { value: 'Saturday', label: 'Saturday' },
  ];

  public data6 : Select2Data = [
    { value: 'January', label: 'January' },
    { value: 'February', label: 'February',},
    { value: 'March', label: 'March' },
    { value: 'April', label: 'April' },
    { value: 'May', label: 'May' },
    { value: 'June', label: 'June' },
    { value: 'July', label: 'July' },
    { value: 'August', label: 'August' },
    { value: 'September', label: 'September' },
    { value: 'October', label: 'October' },
    { value: 'November', label: 'November' },
    { value: 'December', label: 'December' }

  ];

  data: Select2Option[] = [];
  hours: Select2Option[] = [];
  minutes: Select2Option[] = [];
  weekDay: Select2Option[] = [];
  monthDay: Select2Option[] = [];
  months: Select2Option[] = [];
  arr: FormArray;
  time: NgbTimeStruct = {hour: 13, minute: 30, second: 30};
  seconds = false;
  time2 = {minutes: 20};

  constructor(private formBuilder: FormBuilder) { }
  

  ngOnInit() {
    this.timeGroupForm = this.formBuilder.group({
      groupDescription: ['', Validators.required],
      arr: this.formBuilder.array([this.createItem()]),
    })
  }
  addItem() {
    this.arr = this.timeGroupForm.get('arr') as FormArray;
    this.arr.push(this.createItem());
  }

  removeItem(index) {
    const form = this.timeGroupForm.get('arr') as FormArray
    form.removeAt(index);
  }
  createItem() {
    return this.formBuilder.group({
      startHours: [''],
      finishHours: [''],
      finishMinutes: [''],
      weekDayStart: [''],
      weekDayFinish: [''],
      monthDayStart: [''],
      monthDayFinish: [''],
      monthStart: [''],
      monthFinish: [''],
    })
  }
  get f() { return this.timeGroupForm.controls; }

  onSubmit() {
      this.submitted = true;

      if (this.timeGroupForm.invalid) {
        toastr.error("Invalid Details !");
        return;
      }
      console.log(this.timeGroupForm.value);

      toastr.success("Form Submitted Successfully !")
  }

  update(value: string) {
    this.recording = value;
  }
  open() {
    this.data = JSON.parse(JSON.stringify(this.data2));
    this.weekDay = JSON.parse(JSON.stringify(this.data5));
    this.monthDay = JSON.parse(JSON.stringify(this.data4));
    this.months = JSON.parse(JSON.stringify(this.data6));
  }

  search(text: string) {
    this.data = text
      ? (JSON.parse(JSON.stringify(this.data2)) as Select2Option[])
        .filter(option => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1)
      : JSON.parse(JSON.stringify(this.data2));
    this.weekDay = text
      ? (JSON.parse(JSON.stringify(this.data5)) as Select2Option[])
        .filter(option => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1)
      : JSON.parse(JSON.stringify(this.data5));
    this.monthDay = text
      ? (JSON.parse(JSON.stringify(this.data4)) as Select2Option[])
        .filter(option => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1)
      : JSON.parse(JSON.stringify(this.data4));
    this.months = text
      ? (JSON.parse(JSON.stringify(this.data6)) as Select2Option[])
        .filter(option => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1)
      : JSON.parse(JSON.stringify(this.data6));
  }
}
