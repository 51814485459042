import { Injectable } from '@angular/core';
import {
	HttpInterceptor,
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MoboSnackBar } from '../admin/provisioner/notification';
import { logoutDelCookie, readCookie } from "../../app/admin/provisioner/services";
import { Router } from "@angular/router";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private snackBar: MoboSnackBar,    private router: Router,
        ) {}

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError((error: HttpErrorResponse) => {
				if (error.status === 401) {
					this.snackBar.error({
						msg: 'Unauthorized access. Please log in again.',
					});
                    localStorage.clear();
                    const cookies = document.cookie.split(";");

                    for (let i = 0; i < cookies.length; i++) {
                      let cookie = cookies[i];
                      let eqPos = cookie.indexOf("=");
                      let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                    }
                    logoutDelCookie();
                    let extra = {};
                      extra = {
                        queryParams: { return: location.href },
                      };
                    this.router.navigate(["/login"], extra);
				}
				return throwError(error);
			})
		);
	}
}
