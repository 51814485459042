import { Injectable } from '@angular/core';
import {HttpClient,HttpParams} from '@angular/common/http';
import {API} from './constant';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeadlineService {

  constructor(
    private http: HttpClient
  ) { }

  list(id){
    let httpParams=new HttpParams()
    .set('opportunityId',id);

    return this.http.get(API.DEADLINE_LIST,{params: httpParams});
  }
}
