import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API } from "./constant";
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/toPromise';

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  public updateTasksEventListener = new BehaviorSubject(0);
  constructor(private http: HttpClient) { }

  update: boolean;
  taskId: number;

  private play: boolean = false;
  private pause: boolean = false;
  private stop: boolean = true;
  public playPauseStop$ = new EventEmitter();

  public playTimer() {
    this.play = true;
    this.pause = false;
    this.stop = false;

    this.playPauseStop$.emit({
      play: this.play
    });
  }

  public pauseTimer() {
    this.play = false;
    this.pause = true;
    this.stop = false;

    this.playPauseStop$.emit({
      pause: this.pause
    });
  }

  public stopTimer() {
    this.play = false;
    this.pause = false;
    this.stop = true;

    this.playPauseStop$.emit({
      stop: this.stop
    });
  }

  responsibleUserList() {
    return this.http.get(API.RESPONSIBLE_TASK_USER_LIST);
  }

  projectList() {
    return this.http.get(API.TASK_PROJECT_LIST);
  }

  milestoneListbyProjectId(taskId) {
    return this.http.get(API.TASK_MILESTONE_LIST + '/' + taskId);

  }

  /* list(type, filter, moduleName, moduleId) {
    return this.http.get(API.TASKS_LIST + '?type=' + type + '&filter=' + JSON.stringify(filter));
  } */

  list(type, filter, moduleName, moduleId) {
    filter = JSON.stringify(filter);
    let paramer = '';
    if (moduleName === 'leads') {
      paramer = paramer + '?moduleId=' + moduleId.leadsId;
    } else if (moduleName === 'client') {
      paramer = paramer + '?moduleId=' + moduleId;
    }
    paramer = paramer ? paramer + '&type=' + moduleName + '&filter=' + filter : '?type=' + type + '&filter=' + filter;
    return this.http.get(API.TASKS_LIST + paramer);
  }

  // createTask(taskData: any, customHeaders?: Function): Observable<any> {
  //   let httpOptions = {
  //     headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  //   };

  //   let body = JSON.stringify(taskData);
  //   return this.http.post(API.CREATE_TASK, body, httpOptions);
  // }

  opportunityList() {
    return this.http.get(API.TASK_OPPORTUNITY_LIST);
  }

  bugList() {
    return this.http.get(API.TASK_BUG_LIST);
  }

  goalList() {
    return this.http.get(API.TASK_GOAL_LIST);
  }

  leadsList() {
    return this.http.get(API.TASK_LEAD_LIST);
  }

  departmentList() {
    return this.http.get(API.DEPARTMENT_LIST);
  }

  deletedTask(id) {
    return this.http.delete(API.TASK_DELETE + id);
  }

  deleteComments(id, type) {
    return this.http.delete(API.TASK_COMMENT_DELETE + id);
  }

  deletenotes(id, taskId, type) {
    return this.http.delete(API.TASK_NOTES_DELETE + id);
  }

  employeedropdownList() {
    return this.http.get(API.EMP_DD_LIST);
  }

  setShareValue(update, taskId?) {
    this.update = update;
    this.taskId = taskId;
  }
  getShareId() {
    return this.taskId;
  }
  getShareValue() {
    return this.update;
  }

  detailById(taskId) {
    return this.http.get(API.TASK_DETAILS + taskId);
  }

  getId() {
    return this.taskId;
  }

  createTask(projectId: number, milestonesId: number, opportunitiesId: number, goalTrackingId: number, deptId: number, leadsId: number, clientId: number, bugId: number, responsibleUserId: number, taskName: string, taskDescription: string, taskStartdate: string, dueDate: string, taskStatus: string, taskProgress: number, taskHour: number, permission: number, clientVisible: string, customParticipants: any = {}) {

    let body: any = {};
    if (typeof projectId !== 'undefined' && projectId !== null) body.projectId = projectId;
    if (typeof milestonesId !== 'undefined' && milestonesId !== null) body.milestonesId = milestonesId;
    if (typeof opportunitiesId !== 'undefined' && opportunitiesId !== null) body.opportunitiesId = opportunitiesId;
    if (typeof goalTrackingId !== 'undefined' && goalTrackingId !== null) body.goalTrackingId = goalTrackingId;
    if (typeof deptId !== 'undefined' && deptId !== null) body.deptId = deptId;
    if (typeof leadsId !== 'undefined' && leadsId !== null) body.leadsId = leadsId;
    if (typeof clientId !== 'undefined' && clientId !== null) body.clientId = clientId;
    if (typeof bugId !== 'undefined' && bugId !== null) body.bugId = bugId;
    if (typeof responsibleUserId !== 'undefined' && responsibleUserId !== null) body.responsibleUserId = responsibleUserId;
    if (typeof taskName !== 'undefined' && taskName !== null) body.taskName = taskName;
    if (typeof taskDescription !== 'undefined' && taskDescription !== null) body.taskDescription = taskDescription;
    if (typeof taskStartdate !== 'undefined' && taskStartdate !== null) body.taskStartdate = taskStartdate;
    if (typeof dueDate !== 'undefined' && dueDate !== null) body.dueDate = dueDate;
    if (typeof taskStatus !== 'undefined' && taskStatus !== null) body.taskStatus = taskStatus;
    if (typeof taskProgress !== 'undefined' && taskProgress !== null) body.taskProgress = taskProgress;
    if (typeof taskHour !== 'undefined' && taskHour !== null) body.taskHour = taskHour;
    if (typeof permission !== 'undefined' && permission !== null) body.permission = permission;
    if (typeof clientVisible !== null) body.clientVisible = clientVisible;
    if (typeof customParticipants !== 'undefined' && customParticipants !== null) body.assignedTo = customParticipants;

    return this.http.post(API.CREATE_TASK, body);
  }

  modify(taskId: number, projectId: number, milestonesId: number, opportunitiesId: number, goalTrackingId: number, deptId: number, leadsId: number, clientId: number, bugId: number, responsibleUserId: number, taskName: string, taskDescription: string, taskStartdate: string, dueDate: string, taskStatus: string, taskProgress: number, taskHour: number, permission: number, clientVisible: string, customParticipants: any = {}) {

    let body: any = {};
    if (typeof taskId !== 'undefined' && taskId !== null) body.taskId = taskId;
    if (typeof projectId !== 'undefined' && projectId !== null) body.projectId = projectId;
    if (typeof milestonesId !== 'undefined' && milestonesId !== null) body.milestonesId = milestonesId;
    if (typeof opportunitiesId !== 'undefined' && opportunitiesId !== null) body.opportunitiesId = opportunitiesId;
    if (typeof goalTrackingId !== 'undefined' && goalTrackingId !== null) body.goalTrackingId = goalTrackingId;
    if (typeof deptId !== 'undefined' && deptId !== null) body.deptId = deptId;
    if (typeof leadsId !== 'undefined' && leadsId !== null) body.leadsId = leadsId;
    if (typeof clientId !== 'undefined' && clientId !== null) body.clientId = clientId;
    if (typeof bugId !== 'undefined' && bugId !== null) body.bugId = bugId;
    if (typeof responsibleUserId !== 'undefined' && responsibleUserId !== null) body.responsibleUserId = responsibleUserId;
    if (typeof taskName !== 'undefined' && taskName !== null) body.taskName = taskName;
    if (typeof taskDescription !== 'undefined' && taskDescription !== null) body.taskDescription = taskDescription;
    if (typeof taskStartdate !== 'undefined' && taskStartdate !== null) body.taskStartdate = taskStartdate;
    if (typeof dueDate !== 'undefined' && dueDate !== null) body.dueDate = dueDate;
    if (typeof taskStatus !== 'undefined' && taskStatus !== null) body.taskStatus = taskStatus;
    if (typeof taskProgress !== 'undefined' && taskProgress !== null) body.taskProgress = taskProgress;
    if (typeof taskHour !== 'undefined' && taskHour !== null) body.taskHour = taskHour;
    if (typeof permission !== 'undefined' && permission !== null) body.permission = permission;
    if (typeof clientVisible !== null) body.clientVisible = clientVisible;
    if (typeof customParticipants !== 'undefined' && customParticipants !== null) body.assignedTo = customParticipants;

    return this.http.post(API.TASK_MODIFY + taskId, body);
  }

  createtimesheet(type: string, moduleId: number, startTime: string, endTime: string, reason: string) {

    let body: any = {};
    if (typeof type !== 'undefined' && type !== null) body.type = type;
    if (typeof moduleId !== 'undefined' && moduleId !== null) body.moduleId = moduleId;
    if (typeof startTime !== 'undefined' && startTime !== null) body.startTime = startTime;
    if (typeof endTime !== 'undefined' && endTime !== null) body.endTime = endTime;
    if (typeof reason !== 'undefined' && reason !== null) body.reason = reason;
    return this.http.post(API.CREATE_TIMESHEET, body);
  }

  timesheetDetails(id) {
    return this.http.get(API.TASK_TIMESHEET_DETAILS + id);
  }

  updateTimesheet(id, timesheetdata) {
    return this.http.post(API.UPDATE_TIMESHEET + id, timesheetdata);
  }


  uploadFile(fileObject) {
    return this.http.post(API.ATTACHMENTS_UPLOAD, fileObject);
  }
  importTasks(importData): Observable<any> {
    return this.http.post(API.IMPORT_TASK, importData);
  }

  taskCommentList(moduleId, type, filter) {
    if (type == 'leads') {
      moduleId = moduleId.leadsId;
    }
    else if(type == 'opportunities'){
      moduleId = moduleId.opportunityId;
    }
    return this.http.get(API.TASK_COMMENTS + '?moduleId=' + moduleId + '&type=' + type + '&filter=' + JSON.stringify(filter));
  }


  timesheetDelete(id, type) {
    return this.http.delete(API.TASK_TIMESHEET_DELETE + id);
  }

  taskNotesList(moduleId, type, filter) {
    return this.http.get(API.TASK_NOTES + '?moduleId=' + moduleId + '&type=' + type + '&filter=' + JSON.stringify(filter));
  }

  addTaskComment(moduleId: number, type: string, comment: string) {
    let body: any = {};
    if (typeof moduleId !== 'undefined' && moduleId !== null) body.moduleId = moduleId;
    if (typeof type !== 'undefined' && type !== null) body.type = type;
    if (typeof comment !== 'undefined' && comment !== null) body.comment = comment;

    return this.http.post(API.CREATE_TASK_COMMENT, body);
  }

  addTaskNotes(moduleId: number, type: string, notes: string) {
    let body: any = {};
    if (typeof moduleId !== 'undefined' && moduleId !== null) body.moduleId = moduleId;
    if (typeof type !== 'undefined' && type !== null) body.type = type;
    if (typeof notes !== 'undefined' && notes !== null) body.notes = notes;

    return this.http.post(API.CREATE_TASK_NOTES, body);
  }

  taskActivity(moduleId, type) {
    if (type == 'leads') {
      moduleId = moduleId.leadsId;
    }
    return this.http.get(API.ACTIVITY_LIST + '?moduleId=' + moduleId + '&type=' + type);
  }

  EmployesDDList() {

    return this.http.get(API.EMP_DD_LIST);

  }

  timesheetList(moduleId, type, filter) {
    return this.http.get(API.TIMESHEET_LIST + '?moduleId=' + moduleId + '&type=' + type + '&filter=' + JSON.stringify(filter));
  }

  detailsById(id) {
    return this.http.get(API.TASK_DETAIL_ID + id);
  }

  totalTimeById(id) {
    return this.http.get(API.TASK_TOTAL_TIME_ID + id);
  }

  taskFileAttchments(data) {
    return this.http.get(API.ATTACHMENTS_LIST + '?moduleId=' + data.moduleId + '&type=' + data.type, data);

  }

  addAttachments(attachmentsData) {
    return this.http.post(API.ADD_BUG_ATTACHMENTS, attachmentsData);
  }

  deleteAttachments(attachmentId) {
    return this.http.post(API.DELETE_BUG_ATTACHMENTS + attachmentId, attachmentId);
  }

  clientList() {
    return this.http.get(API.TASK_CLIENT_LIST);
  }

  taskTimer(status, type, moduleId, address, lat, lng) {
    return this.http.get(API.TASK_TIMERCLOCK + '?status=' + status + '&type=' + type + '&moduleId=' + moduleId + '&address=' + address + '&lat=' + lat + '&lng=' + lng);
  }

  taskTimerOff(status, type, moduleId, timerClockinClockoutId, address, lat, lng) {
    return this.http.get(API.TASK_TIMERCLOCK + '?status=' + status + '&type=' + type + '&moduleId=' + moduleId + '&timerClockinClockoutId=' + timerClockinClockoutId + '&address=' + address + '&lat=' + lat + '&lng=' + lng);
  }

  checkTask(id) {
    return this.http.get(API.TASK_CHECK_ID + id);
  }

  timerStatus(id) {
    return this.http.get(API.TASK_TIMER_STATUS + id);
  }
}
