import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatCardModule, MatButtonModule, MatFormFieldModule, MatDialogModule, MatTableModule, MatSortModule, MatPaginatorModule, MatInputModule, MatSliderModule } from '@angular/material';
import { Select2Module } from "ng-select2-component";
import { MdePopoverModule } from '@material-extended/mde';
import { ReportsComponent } from './reports.component';
import { ReportsRoutingModule } from "./reports-routing.module";
import { GeneralStatsComponent } from './general-stats/general-stats.component';
import { ReportsListComponent } from './reports-list/reports-list.component'; 
import { GoogleChartsModule } from 'angular-google-charts';
import { ChartsModule } from 'ng2-charts';

@NgModule({
    imports: [  
      CommonModule,
      BrowserModule,
      MatButtonModule,
      MatCardModule,
      MatDialogModule,
      MatFormFieldModule,
      MatTableModule,
      MatSortModule,
      MatPaginatorModule,
      MatInputModule,
      MatSliderModule,
      FormsModule,
      ReactiveFormsModule,
      Select2Module,
      MdePopoverModule,
      ReportsRoutingModule,
      GoogleChartsModule,
      ChartsModule
    ],
    declarations :[
      ReportsComponent,
      GeneralStatsComponent,
      ReportsListComponent
    ],
    entryComponents: [],
})

export class ReportsModule { }