import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl  } from '@angular/forms';
import { Select2Option, Select2Data } from 'ng-select2-component';
import * as toastr from "toastr";

@Component({
  selector: 'app-addringgroup',
  templateUrl: './addringgroup.component.html',
  styleUrls: ['./addringgroup.component.scss']
})
export class AddringgroupComponent implements OnInit {

  ringGroupForm: FormGroup;
  submitted = false;

  ringStrategyData = [
    {id:1, name:'Ringall'},
    {id:2, name:'Hunt'},
    {id:3, name:'Memoryhunt'},
    {id:4, name:'Firstunavailable'},
    {id:5, name:'Firstnotonphone'},
    {id:6, name:'Random'},
  ];

  MohData = [
    {id:1, name:'Default'},
    {id:2, name:'Music 1'},
    {id:3, name:'Music 2'},
    {id:4, name:'Music 3'},
    {id:5, name:'Music 4'},
    {id:6, name:'Music 5'},
  ];

  confirmCallsData = [
    {id:1, name:'Default'},
    {id:2, name:'Destination 1'},
    {id:3, name:'Destination 2'},
    {id:4, name:'Destination 3'},
    {id:5, name:'Destination 4'},
    {id:6, name:'Destination 5'},
  ];


  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.ringGroupForm = this.formBuilder.group({
      ringGroupNumber: ['', Validators.required],
      groupDescription: ['', Validators.required],
      followMeList: ['', Validators.required],
      // recording : this.recording,
      // ringStrategy: ['', Validators.required],
      ringTime: ['', Validators.required],
      // announceMent: ['', Validators.required],
      // musicOnHold: ['', Validators.required],
      cidPrefix: ['', Validators.required],
      alertInfo: ['', Validators.required],
      // ringerOverride: ['', Validators.required],
      // remoteAnnounce: ['', Validators.required],
      // tooLate: ['', Validators.required],
      // cidConf: ['', Validators.required],
      fixedCid: ['', Validators.required],
      // destination: ['', Validators.required],
    })
  }

  get f() { return this.ringGroupForm.controls; }

  onSubmit() {
      this.submitted = true;

      if (this.ringGroupForm.invalid) {
        toastr.error("Invalid Details !");
        return;
      }
      console.log(this.ringGroupForm.value);

      toastr.success("Form Submitted Successfully !")
  }

}
