import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../service/dashboard.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css']
})
export class ClientComponent implements OnInit {
  subscript: Subscription;  
  selectTabindex : number = 0;
  constructor(public dashboardService : DashboardService) {
    this.subscript = this.dashboardService.messageSource.subscribe((val)=> {
      if(val != 0) {
        this.selectTabindex = val;
      }
      else{
        this.selectTabindex = 0;
      }
    })
   }



  ngOnInit() {
    // this.dashboardService.currentMessage.subscribe(message => this.selectTabindex = message)
  }

  ngOnDestroy(){
    // this.dashboardService.changeMessage(0)
    this.subscript.unsubscribe();
    this.dashboardService.messageSource.next(0);
  }

}
