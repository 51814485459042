import { FormControl } from "@angular/forms";

export class MacAddressValidator {
  // Custom validator function
  public static customPatternValidator(control: FormControl) {
    const value = control.value;

    if (!value) return null; // No validation if the field is empty

    // Determine the first separator used
    const firstSeparator = value.charAt(2); // Assuming the first separator is at index 2
    if (firstSeparator === ":" || firstSeparator === "-") {
      console.log("val.length",value.length)
      if (value.length < 17 || value.length > 17) {
        return { invalidPattern: true };
      }
    } else {
      if (value.length < 12 || value.length > 12) {
        return { invalidPattern: true };
      }
    }
    // Define the regex pattern based on the first separator
    // Define the regex pattern based on the first separator
    const pattern =
      firstSeparator === ":"
        ? /^([a-fA-F0-9]{2}:){5}[a-fA-F0-9]{2}$/
        : firstSeparator === "-"
        ? /^([a-fA-F0-9]{2}-){5}[a-fA-F0-9]{2}$/
        : /^[0-9A-Fa-f]+$/;

    // Check if the value matches the pattern
    return pattern.test(value) ? null : { invalidPattern: true };
  }
}
