import { Component, OnInit, ViewChild } from '@angular/core';
// import { ActivatedRoute, Router } from '@angular/router';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import { Select2Option, Select2Data } from 'ng-select2-component';
import * as toastr from "toastr";
@Component({
  selector: 'app-notification-email',
  templateUrl: './notification-email.component.html',
  styleUrls: ['./notification-email.component.scss']
})
export class NotificationEmailComponent implements OnInit {

  notificationForm: FormGroup;
  submitted = false;

  protocolData = [
    {id:1, name:'None'},
    {id:2, name:'Protocol 1'},
    {id:3, name:'Protocol 2'},
    {id:4, name:'Protocol 3'},
    {id:5, name:'Protocol 4'},
    {id:6, name:'Protocol 5'},
  ];

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.notificationForm = this.formBuilder.group({
      companyEmail: ['', Validators.required],
      emailProtocol:['', Validators.required],
      smtpHost: ['', Validators.required],
      smtpUser: ['', Validators.required],
      smtpPassword: ['', Validators.required],
      smtpPort: ['', Validators.required],
      testEmail: ['', [Validators.required,Validators.email]]
    });
    this.notificationForm.reset();
  }

  get f() { return this.notificationForm.controls; }

  onSubmit() {
      this.submitted = true;

      if (this.notificationForm.invalid) {
        toastr.error("Invalid Details !");
        return;
      }
      console.log(this.notificationForm.value);

      toastr.success("Form Submitted Successfully !")
  }

}
