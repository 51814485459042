import {Deserializable} from '../Utils/deserialize';


export class Config implements Deserializable<Config> {

  public id: number;
  public configKey: string;
  public value: string;
  public tenantCompanyKey: string;

  deserialize(input: any): Config {
    Object.assign(this, input);
    return this;
  }

  constructor() {
    this.id = 0;
    this.configKey = '';
    this.value = '';
    this.tenantCompanyKey = '';
  }

}
