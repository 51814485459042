import { ClientService } from "./clients.service";

export const services = [ClientService];

export * from './clients.service';


export const readCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

export const logoutDelCookie = () => {
    document.cookie = 'authenticate=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie = 'sessionTime=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}