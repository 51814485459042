import { DeviceConfigDialogComponent } from "./../device-config-dialog/device-config-dialog.component";
import { Component, OnInit, Inject, Optional } from "@angular/core";
import { FormBuilder, FormGroup, Validators, NgForm } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ImageMapCoordinate } from "../image-map/image-map.component";
import {
  COORDINATES,
  GPX2130Coordinates,
  GPX2140Coordinates,
} from "./dialog.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.scss"],
})
export class DialogComponent implements OnInit {
  name: string;
  firstkeyFormGroup: FormGroup;

  LCDP = ["P270", "P417", "P517", "P617", "P1717", "P1817"];
  LCD = [];
  speedDialP = [
    "P302",
    "P305",
    "P308",
    "P311",
    "P314",
    "P317",
    "P320",
    "P355",
    "P359",
    "P363",
    "P367",
    "P371",
    "P375",
    "P379",
    "P383",
    "P387",
    "P391",
    "P395",
    "P1442",
    "P1446",
    "P1450",
    "P1454",
    "P1458",
    "P1462",
  ];
  speedDial = [];
  image: string;

  coordinates: ImageMapCoordinate[];

  constructor(
    private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<DialogComponent>,
    private dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.getImges(this.data.device);
    this.firstkeyFormGroup = this._formBuilder.group({
      description: ["", Validators.required],
    });

    switch (this.data.for) {
      case "companySetting":
        for (var i in this.data.companyConfig) {
          if (this.LCDP.includes(this.data.companyConfig[i].variable)) {
            for (
              var M = 0;
              M < this.LCDP.indexOf(this.data.companyConfig[i].variable);
              M++
            ) {
              if (!this.LCD[M]) {
                this.LCD[M] = "";
              }
            }
            this.LCD[this.LCDP.indexOf(this.data.companyConfig[i].variable)] =
              this.data.companyConfig[i].value;
          }
          if (this.speedDialP.includes(this.data.companyConfig[i].variable)) {
            for (
              var M = 0;
              M < this.speedDialP.indexOf(this.data.companyConfig[i].variable);
              M++
            ) {
              if (!this.speedDial[M]) {
                this.speedDial[M] = "";
              }
            }
            this.speedDial[
              this.speedDialP.indexOf(this.data.companyConfig[i].variable)
            ] = this.data.companyConfig[i].value;
          }
        }

        while (this.speedDial.length <= 23) {
          this.speedDial.push("");
        }
        while (this.LCD.length <= 5) {
          this.LCD.push("");
        }

        break;

      case "deviceSetting":
        for (var i in this.data.config) {
          if (this.LCDP.includes(this.data.config[i].setting_variable)) {
            for (
              var M = 0;
              M < this.LCDP.indexOf(this.data.config[i].setting_variable);
              M++
            ) {
              if (!this.LCD[M]) {
                this.LCD[M] = "";
              }
            }
            this.LCD[this.LCDP.indexOf(this.data.config[i].setting_variable)] =
              this.data.config[i].setting_value;
          }
          if (this.speedDialP.includes(this.data.config[i].setting_variable)) {
            for (
              var M = 0;
              M < this.speedDialP.indexOf(this.data.config[i].setting_variable);
              M++
            ) {
              if (!this.speedDial[M]) {
                this.speedDial[M] = "";
              }
            }
            this.speedDial[
              this.speedDialP.indexOf(this.data.config[i].setting_variable)
            ] = this.data.config[i].setting_value;
          }
        }
        while (this.speedDial.length <= 23) {
          this.speedDial.push("");
        }
        while (this.LCD.length <= 5) {
          this.LCD.push("");
        }
        for (var i in this.LCD) {
          if (this.LCD[i] == "") {
            for (var G in this.data.groupSettingConfig) {
              if (
                this.LCDP[i] ==
                this.data.groupSettingConfig[G].setting_variable_name
              ) {
                this.LCD[i] =
                  this.data.groupSettingConfig[G].setting_variable_value;
              }
            }
          }
          if (this.LCD[i] == "") {
            for (var C in this.data.clientSettingsConfig) {
              if (
                this.LCDP[i] ==
                this.data.clientSettingsConfig[C].setting_variable
              ) {
                this.LCD[i] = this.data.clientSettingsConfig[C].setting_value;
              }
            }
          }
          if (this.LCD[i] == "") {
            for (var C in this.data.companySettingConfig) {
              if (this.LCDP[i] == this.data.companySettingConfig[C].variable) {
                this.LCD[i] = this.data.companySettingConfig[C].value;
              }
            }
          }
        }
        for (var i in this.speedDial) {
          if (this.speedDial[i] == "") {
            for (var G in this.data.groupSettingConfig) {
              if (
                this.speedDialP.includes(
                  this.data.groupSettingConfig[G].setting_variable_name
                ) &&
                this.speedDialP.indexOf(
                  this.data.groupSettingConfig[G].setting_variable_name
                ) == parseInt(i)
              ) {
                this.speedDial[i] =
                  this.data.groupSettingConfig[G].setting_variable_value;
              }
            }
          }
          if (this.speedDial[i] == "") {
            for (var C in this.data.clientSettingsConfig) {
              if (
                this.speedDialP.includes(
                  this.data.clientSettingsConfig[C].setting_variable
                ) &&
                this.speedDialP.indexOf(
                  this.data.clientSettingsConfig[C].setting_variable
                ) == parseInt(i)
              ) {
                this.speedDial[i] =
                  this.data.clientSettingsConfig[C].setting_value;
              }
            }
          }
          if (this.speedDial[i] == "") {
            for (var C in this.data.companySettingConfig) {
              if (
                this.speedDialP.includes(
                  this.data.companySettingConfig[C].variable
                ) &&
                this.speedDialP.indexOf(
                  this.data.companySettingConfig[C].variable
                ) == parseInt(i)
              ) {
                this.speedDial[i] = this.data.companySettingConfig[C].value;
              }
            }
          }
        }

        break;

      case "groupSetting":
        for (var i in this.data.config) {
          if (this.LCDP.includes(this.data.config[i].setting_variable_name)) {
            for (
              var M = 0;
              M < this.LCDP.indexOf(this.data.config[i].setting_variable_name);
              M++
            ) {
              if (!this.LCD[M]) {
                this.LCD[M] = "";
              }
            }
            this.LCD[
              this.LCDP.indexOf(this.data.config[i].setting_variable_name)
            ] = this.data.config[i].setting_variable_value;
          }
          if (
            this.speedDialP.includes(this.data.config[i].setting_variable_name)
          ) {
            for (
              var M = 0;
              M <
              this.speedDialP.indexOf(
                this.data.config[i].setting_variable_name
              );
              M++
            ) {
              if (!this.speedDial[M]) {
                this.speedDial[M] = "";
              }
            }
            this.speedDial[
              this.speedDialP.indexOf(this.data.config[i].setting_variable_name)
            ] = this.data.config[i].setting_variable_value;
          }
        }
        while (this.speedDial.length <= 23) {
          this.speedDial.push("");
        }
        while (this.LCD.length <= 5) {
          this.LCD.push("");
        }
        for (var i in this.LCD) {
          if (this.LCD[i] == "") {
            for (var C in this.data.client_setting_config) {
              if (
                this.LCDP[i] ==
                this.data.client_setting_config[C].setting_variable
              ) {
                this.LCD[i] = this.data.client_setting_config[C].setting_value;
              }
            }
          }
          if (this.LCD[i] == "") {
            for (var C in this.data.company_setting_config) {
              if (
                this.LCDP[i] == this.data.company_setting_config[C].variable
              ) {
                this.LCD[i] = this.data.company_setting_config[C].value;
              }
            }
          }
        }
        for (var i in this.speedDial) {
          if (this.speedDial[i] == "") {
            for (var C in this.data.client_setting_config) {
              if (
                this.speedDialP.includes(
                  this.data.client_setting_config[C].setting_variable
                ) &&
                this.speedDialP.indexOf(
                  this.data.client_setting_config[C].setting_variable
                ) == parseInt(i)
              ) {
                this.speedDial[i] =
                  this.data.client_setting_config[C].setting_value;
              }
            }
          }
          if (this.speedDial[i] == "") {
            for (var C in this.data.company_setting_config) {
              if (
                this.speedDialP.includes(
                  this.data.company_setting_config[C].variable
                ) &&
                this.speedDialP.indexOf(
                  this.data.company_setting_config[C].variable
                ) == parseInt(i)
              ) {
                this.speedDial[i] = this.data.company_setting_config[C].value;
              }
            }
          }
        }

        break;

      case "clientSetting":
        for (var i in this.data.config) {
          if (this.LCDP.includes(this.data.config[i].setting_variable)) {
            for (
              var M = 0;
              M < this.LCDP.indexOf(this.data.config[i].setting_variable);
              M++
            ) {
              if (!this.LCD[M]) {
                this.LCD[M] = "";
              }
            }
            this.LCD[this.LCDP.indexOf(this.data.config[i].setting_variable)] =
              this.data.config[i].setting_value;
          }
          if (this.speedDialP.includes(this.data.config[i].setting_variable)) {
            for (
              var M = 0;
              M < this.speedDialP.indexOf(this.data.config[i].setting_variable);
              M++
            ) {
              if (!this.speedDial[M]) {
                this.speedDial[M] = "";
              }
            }
            this.speedDial[
              this.speedDialP.indexOf(this.data.config[i].setting_variable)
            ] = this.data.config[i].setting_value;
          }
        }
        while (this.speedDial.length <= 23) {
          this.speedDial.push("");
        }
        while (this.LCD.length <= 5) {
          this.LCD.push("");
        }
        for (var i in this.LCD) {
          if (this.LCD[i] == "") {
            for (var C in this.data.companySettingConfig) {
              if (this.LCDP[i] == this.data.companySettingConfig[C].variable) {
                this.LCD[i] = this.data.companySettingConfig[C].value;
              }
            }
          }
        }
        for (var i in this.speedDial) {
          if (this.speedDial[i] == "") {
            for (var C in this.data.companySettingConfig) {
              if (
                this.speedDialP.includes(
                  this.data.companySettingConfig[C].variable
                ) &&
                this.speedDialP.indexOf(
                  this.data.companySettingConfig[C].variable
                ) == parseInt(i)
              ) {
                this.speedDial[i] = this.data.companySettingConfig[C].value;
              }
            }
          }
        }

        break;

      default:
        break;
    }
  }
  hello() {}
  addAnnouncementsForm() {
    this.name = this.firstkeyFormGroup.value["description"];
  }

  getClick(coordinate: ImageMapCoordinate) {
    if (coordinate.type != "description") {
      const editSetting = this.dialog.open(DeviceConfigDialogComponent, {
        data: {
          ...coordinate,
          ...this.data,
        },
        disableClose: true,
        panelClass: "device_config_dialog",
      });

      editSetting.afterClosed().subscribe((data: any) => {
        if (data) {
          if (data.edited) {
            // Swal.fire("Edited!", "Company setting has been edited.", "success");
            this.dialogRef.close({ edited: true });
          }
        }
      });
    }
  }

  getImges(device: any) {
    if (device === "2130") {
      this.image = "assets/img/GXP-dia-2130.jpg";
      this.coordinates = GPX2130Coordinates;
    }
    if (device === "2160") {
      this.image = "assets/img/GSphone.jpeg";
      this.coordinates = COORDINATES;
    }
    if (device === "2140") {
      this.image = "assets/img/GXP-dia-2140.jpg";
      this.coordinates = GPX2140Coordinates;
    }
  }
}
