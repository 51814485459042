import { NgModule} from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import {TranslateModule} from '@ngx-translate/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule, MatInputModule, MatButtonModule, MatAutocompleteModule, MatIconModule,MatTabsModule,MatTableModule, MatFormFieldModule, MatSelectModule, MatSlideToggleModule, MatCardModule, MatPaginatorModule,MatSortModule,MatCheckboxModule } from '@angular/material';
import { UiSwitchModule } from 'ngx-ui-switch';
import { Select2Module } from "ng-select2-component";
import {FileUploadModule} from 'ng2-file-upload';
import { MusicOnHoldComponent } from './music-on-hold.component';
import { MusicOnHoldeListComponent } from './music-on-holde-list/music-on-holde-list.component';
import { AddMusicOnHoldComponent } from './add-music-on-hold/add-music-on-hold.component'
import { CommonComponentModule } from "../../../common/commonComponent.module";

@NgModule({
    declarations: [
      MusicOnHoldComponent,
      MusicOnHoldeListComponent,
      AddMusicOnHoldComponent

    ],
    imports: [
        BrowserModule,
        TranslateModule.forRoot(),
        FormsModule,
        MatStepperModule,
        MatInputModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatIconModule,
        MatTabsModule,
        MatTableModule,
        MatFormFieldModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatCardModule,
        MatPaginatorModule,
        MatSortModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        UiSwitchModule,
        Select2Module,
        FileUploadModule,
        CommonComponentModule

        
    ],
    providers: [],
    bootstrap: [MusicOnHoldComponent],
    exports:[],
})

export class MusicOnHoldModule { }