import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { FlexLayoutModule } from "@angular/flex-layout";
import { CallDetailsRecordComponent } from './call-details-record.component'
import { MatStepperModule, MatInputModule, MatButtonModule, MatAutocompleteModule, MatIconModule,MatTabsModule,MatTableModule, MatFormFieldModule, MatSelectModule, MatSlideToggleModule, MatCardModule, MatPaginatorModule,MatSortModule,MatCheckboxModule} from '@angular/material';
import { Select2Module } from "ng-select2-component";
import {MatDatepickerModule} from '@angular/material/datepicker';
import { CdrDialogService } from './cdr-dialog/cdr-dialog.service';
import {CdrDialogComponent} from './cdr-dialog/cdr-dialog.component';
import {PopoverModule} from "ngx-popover";
import { PcapComponent } from './pcap/pcap.component';
import { CdrCallFlowComponent } from './cdr-call-flow/cdr-call-flow.component';
import { CommonComponentModule } from "../../../common/commonComponent.module";

@NgModule({
    declarations: [
        CallDetailsRecordComponent,
        CdrDialogComponent,
        PcapComponent,
        CdrCallFlowComponent
    ],
    imports: [
        BrowserModule,
        CommonModule,
        FlexLayoutModule,
        MatCardModule,
        Select2Module,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatTabsModule,
        MatCheckboxModule,
        MatTableModule,
        MatPaginatorModule,
        MatSelectModule,
        MatSortModule,
        MatAutocompleteModule,
        MatIconModule,
        MatSlideToggleModule,
        PopoverModule,
        CommonComponentModule
    ],
    providers: [CdrDialogService],
    entryComponents:[CdrDialogComponent],
    bootstrap: [CallDetailsRecordComponent],
    exports:[],
})

export class CallDetailsRecordModule { }