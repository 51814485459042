import { Action } from "@ngrx/store";
import { CompanySettings } from "../../models";
import { HttpErrorResponse } from "@angular/common/http";


export enum CompanySettingActionType {
    COMPANY_SETTING_LOAD = "[COMPANY SETTING] Load",
    COMPANY_SETTING_LOAD_SUCCESS = "[COMPANY SETTING] Load Success",
    COMPANY_SETTING_LOAD_FAIL = "[COMPANY SETTING] Load Fail",
}

export class CompanySettingLoad implements Action {
    readonly type = CompanySettingActionType.COMPANY_SETTING_LOAD;
}

export class CompanySettingLoadSuccess implements Action {
    readonly type = CompanySettingActionType.COMPANY_SETTING_LOAD_SUCCESS;
    constructor(public payload: CompanySettings[]) {}
}
export class CompanySettingLoadFail implements Action {
    readonly type = CompanySettingActionType.COMPANY_SETTING_LOAD_FAIL;
    constructor(public payload: HttpErrorResponse) {}
}

export type CompanySettingAction = 
| CompanySettingLoad
| CompanySettingLoadSuccess
| CompanySettingLoadFail
;