import {Deserializable} from '../Utils/deserialize';

export class SelectModel implements Deserializable<SelectModel> {

  label: string;
  value: string;

  constructor() {
    this.label = null;
    this.value = null;
  }

  deserialize(input: any): SelectModel {
    Object.assign(this, input);
    return this;
  }

  public getLabel(): string {
    return this.label;
  }

  public setLabel(value: string) {
    this.label = value;
  }

  public getValue(): string {
    return this.value;
  }

  public setValue(value: string) {
    this.value = value;
  }

}
