import { Component, OnInit, Output, EventEmitter, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import {API, StorageKey} from '../../service/constant';
import { LoginService } from "../../service/login.service";
import { MenuqrService } from "../../service/menuqr.service";
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  public menu_image: string = null;
  submitted = false;

  constructor(private menuqrService: MenuqrService,private route: ActivatedRoute) {
  
    var cur_time = new Date().getHours();
   }

  ngOnInit() {
    this.menuqrService.activeMenu().subscribe(
      (res: any) => {
        window.location.href=res.url;
      }
    );
  }

  
}
