import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {API} from './constant';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TransactionsService {
  public updateCheckEventListener = new BehaviorSubject(0);
  constructor(private http: HttpClient) { }

  getTransactionsReportList(filter): Observable<any> {
    return this.http.get(API.TRANSACTIONS_REPORT_LIST + '?filter=' + JSON.stringify(filter));
  }
  getChecksList(): Observable<any>{
    return this.http.get(API.GET_CHECKLIST);
  }
  deleteCheck(element): Observable<any>{
    let parameters = {'action':'delete_check','checkId': element.checkId}
    return this.http.delete(API.DELETE_CHECK + element.checkId, {params:parameters});
  }
  getEndingBalanceById(id):Observable<any>{
    return this.http.get(API.GET_BALANCE_BY_ACC_ID + id);
  }
  getAllUsers(){
    return this.http.get(API.GET_PAYTO_LIST);
  }
  createCheck(data): Observable<any>{
    return this.http.post(API.CREATE_CHECK, data);
  }
  getCheckDetails(checkNo):Observable<any>{
    return this.http.get(API.GET_CHECK_DETAILS + checkNo);
  }
}
