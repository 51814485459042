import { environment } from "../../environments/environment";
import { ThemeColorInterface } from "./Utils/Interfaces.class";
import { MatDialogConfig } from "@angular/material";

export const API_ENDPOINT_URL = environment.BASE_URL + environment.API_ENDPOINT;

export const COMMON = {
  MAP_LONGITUDE: 80.149797,
  MAP_LATITUDE: 13.026413,
  DELETE_MODEL_HEIGHT: "570px",
  DELETE_MODEL_WIDTH: "700px",
  EMAIL_MODEL_HEIGHT: "750px",
  EMAIL_MODEL_WIDTH: "780px",
  DELETE_ALERT_HEIGHT: "205px",
  DELETE_ALERT_WIDTH: "505px",
  CLONE_INVOICE_HEIGHT: "250px",
  CLONE_INVOICE_WIDTH: "515px",
  CC_WIDTH: "575px",
  CC_HEIGHT: "355px",
  CC_PAYMENT_WIDTH: "750px",
  CC_PAYMENT_HEIGHT: "750px",
  EMAIL_PATTERN: "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$"
};

export const API = {
  GET_PARENT_MODULES: API_ENDPOINT_URL + "/Menus",
  REGISTER_TENANT: API_ENDPOINT_URL + "/Tenants/signup",
  CHECK_TENANT_EMAIL: API_ENDPOINT_URL + "/Tenants/tenantExists",
  USER_LOGIN: API_ENDPOINT_URL + "/Users/login",
  OPPORTUNITY_LIST: API_ENDPOINT_URL + "/Opportunities/list/",
  CLIENT_DROPDOWN_LIST: API_ENDPOINT_URL + "/Clients/dropdownList",
  LEAD_LIST: API_ENDPOINT_URL + "/Leads/dropdownList",
  OPPO_PIPELINE_LIST: API_ENDPOINT_URL + "/OpportunityPipelines/list",
  OPPORTUNITY_SOURCE: API_ENDPOINT_URL + "/OpportunitySources/list",
  STAGE_LIST_BY_PIPE: API_ENDPOINT_URL + "/OpportunityStages/stagePipeDDList/",
  LEADS_CONTACTS: API_ENDPOINT_URL + "/Opportunities/leadContacts/",
  CLIENT_CONTACTS: API_ENDPOINT_URL + "/Opportunities/clientContacts/",
  OPPO_STATE_REASON_PARENT:
    API_ENDPOINT_URL + "/OpportunityStateReasonParents/list/",
  OPPO_STATE_REASON:
    API_ENDPOINT_URL + "/OpportunityStateReasons/listByParentId/",
  CREATE_OPPORTUNITY: API_ENDPOINT_URL + "/Opportunities/add",
  REFRESHTOKEN_API: API_ENDPOINT_URL + "/Users/refreshToken",
  CLIENT_LIST: API_ENDPOINT_URL + "/Clients/list",
  LEAD_STATUS_LIST: API_ENDPOINT_URL + "/LeadStatuses/list",
  LEAD_SOURCE_LIST: API_ENDPOINT_URL + "/LeadSources/list",
  EMP_DD_LIST: API_ENDPOINT_URL + "/Employees/dropdownList",
  DYNAMIC_MENU: API_ENDPOINT_URL + "/Menus/dynamicMenu",
  TASKS_LIST: API_ENDPOINT_URL + "/Tasks/list",
  TIMESHEET_LIST: API_ENDPOINT_URL + "/Timesheets/list",
  RESPONSIBLE_TASK_USER_LIST: API_ENDPOINT_URL + "/Tasks/responsibleUserList",
  TASK_PROJECT_LIST: API_ENDPOINT_URL + "/Tasks/projectList",
  TASK_MEMBERLIST: API_ENDPOINT_URL + "/Globals/memberList/tasks/",
  TASK_MILESTONE_LIST: API_ENDPOINT_URL + "/Tasks/milestoneListbyProjectId",
  TASK_OPPORTUNITY_LIST: API_ENDPOINT_URL + "/Tasks/opportunityList",
  TASK_BUG_LIST: API_ENDPOINT_URL + "/Tasks/bugList",
  TASK_GOAL_LIST: API_ENDPOINT_URL + "/Tasks/goalList",
  TASK_CLIENT_LIST: API_ENDPOINT_URL + "/Tasks/clientList",
  TASK_LEAD_LIST: API_ENDPOINT_URL + "/Tasks/leadsList",
  TASK_TIMESHEET_DETAILS: API_ENDPOINT_URL + "/Timesheets/listById/",
  TASK_TIMER_STATUS: API_ENDPOINT_URL + "/Tasks/getTimerClockStatus/",
  TASK_DELETE: API_ENDPOINT_URL + "/Tasks/delete/",
  TASK_CHECK_ID: API_ENDPOINT_URL + "/Tasks/checktaskdetail/",
  TASK_COMMENT_DELETE: API_ENDPOINT_URL + "/Comments/delete/",
  TASK_NOTES_DELETE: API_ENDPOINT_URL + "/Notes/delete/",
  TASK_DETAILS: API_ENDPOINT_URL + "/Tasks/listById/",
  IMPORT_TASK: API_ENDPOINT_URL + "/Tasks/import/",
  TASK_COMMENTS: API_ENDPOINT_URL + "/Comments/list",
  TASK_TIMESHEET_DELETE: API_ENDPOINT_URL + "/Timesheets/delete/",
  TASK_NOTES: API_ENDPOINT_URL + "/Notes/list/",
  TASK_MODIFY: API_ENDPOINT_URL + "/Tasks/modify/",
  TASK_TIMERCLOCK: API_ENDPOINT_URL + "/Tasks/tasksTimer",
  TASK_DETAIL_ID: API_ENDPOINT_URL + "/Tasks/listById/",
  TASK_TOTAL_TIME_ID: API_ENDPOINT_URL + "/Tasks/totalTimeById/",
  CREATE_TASK_COMMENT: API_ENDPOINT_URL + "/Comments/add",
  CREATE_TIMESHEET: API_ENDPOINT_URL + "/Timesheets/add",
  CREATE_TASK_NOTES: API_ENDPOINT_URL + "/Notes/add",
  CREATE_TASK: API_ENDPOINT_URL + "/Tasks/add",
  EMPLOYEE_LIST: API_ENDPOINT_URL + "/Employees/list",
  EMPLOYEE_ROWCOUNT: API_ENDPOINT_URL + "/Employees/rowCount",
  EMPLOYEE_DELETE: API_ENDPOINT_URL + "/Employees/delete/",
  EMPLOYEE_STATUS: API_ENDPOINT_URL + "/Employees/status/",
  EMPLOYEE_BAN: API_ENDPOINT_URL + "/Employees/ban/",
  EMPLOYEE_ADD: API_ENDPOINT_URL + "/Employees/add/",
  EMPLOYEE_MODIFY: API_ENDPOINT_URL + "/Employees/modify/",
  EMPLOYEE_DETAILS: API_ENDPOINT_URL + "/Employees/detailsById/",
  ROLES_LIST: API_ENDPOINT_URL + "/Roles/list",
  ROLES_ADD: API_ENDPOINT_URL + "/Roles/add",
  ROLES_MODIFY: API_ENDPOINT_URL + "/Roles/modify/",
  ROLES_DELETE: API_ENDPOINT_URL + "/Roles/delete",
  ROLES_LISTBYID: API_ENDPOINT_URL + "/Roles/listById/",
  DEPARTMENT_LIST: API_ENDPOINT_URL + "/Departments/list/",
  DEPARTMENT_TREE: API_ENDPOINT_URL + "/Departments/tree",
  DEPARTMENT_DEPTHEAD: API_ENDPOINT_URL + "/Departments/users/",
  DEPARTMENT_DELETE: API_ENDPOINT_URL + "/Departments/delete/",
  DEPARTMENT_ADD: API_ENDPOINT_URL + "/Departments/add",
  DEPARTMENT_MODIFY: API_ENDPOINT_URL + "/Departments/modify/",
  EMPLOYEE_ACTIVE_DEACTIVE_ROWCOUNT:
    API_ENDPOINT_URL + "/Employees/activeDeactiveUserCount",
  LEAD_DROPDOWN_LIST: API_ENDPOINT_URL + "/Leads/dropdownList",
  VENDOR_LIST: API_ENDPOINT_URL + "/Vendors/list",
  VENDOR_DELETE: API_ENDPOINT_URL + "/Vendors/delete/",
  VENDOR_ORDER_DETAILS: API_ENDPOINT_URL + "/Vendors/vendorOrderDetails/",
  INVOICE_LIST: API_ENDPOINT_URL + "/Invoices/list",
  INVOICE_DLTLIST: API_ENDPOINT_URL + "/Invoices/delete",
  INVOICE_DETLIST: API_ENDPOINT_URL + "/Invoices/details",
  THEME_API: API_ENDPOINT_URL + "/Users/updateTheme",
  CLONE_INVOICE: API_ENDPOINT_URL + "/Invoices/clone",
  CLIENT_LIST_ON_MAP: API_ENDPOINT_URL + "/Clients/listOnMap",
  TENANT_SETUP: API_ENDPOINT_URL + "/Globals/tenantSetup",
  SUBSCRIBED_MODULES: API_ENDPOINT_URL + "/Menus/getSubscribedModuleList",
  TICKET_STATUSES_LIST: API_ENDPOINT_URL + "/TicketStatuses",
  TICKETS_STATUS_LIST: API_ENDPOINT_URL + "/Tickets/getTicketsListByStatus",
  VENDORS_ON_MAP: API_ENDPOINT_URL + "/Vendors/listOnMap",
  COUNTRY_LIST: API_ENDPOINT_URL + "/Countries/list",
  STATE_LIST: API_ENDPOINT_URL + "/States/listById/",
  VENDOR_DETAILS: API_ENDPOINT_URL + "/Vendors/details/",
  ADD_VENDOR: API_ENDPOINT_URL + "/Vendors/add",
  EDIT_VENDOR: API_ENDPOINT_URL + "/Vendors/modify/",
  INVOICE_CALCULATION: API_ENDPOINT_URL + "/Invoices/calculateTo",
  CREATE_LEAD: API_ENDPOINT_URL + "/Leads/add",
  CLIENT_DETAILS: API_ENDPOINT_URL + "/Clients/details/",
  SEND_INV_MAIL: API_ENDPOINT_URL + "/Invoices/email",
  EMAIL_TEMPLATE: API_ENDPOINT_URL + "/EmailTemplates/listByEGroup",
  ACTIVITY_LIST: API_ENDPOINT_URL + "/Activities/list",
  PROFILE_LIST: API_ENDPOINT_URL + "/Invoices/listByUserId",
  TICKETS_LIST: API_ENDPOINT_URL + "/Tickets/list",
  LANGUAGE_LIST: API_ENDPOINT_URL + "/Languages/list",
  LOCALE_LIST: API_ENDPOINT_URL + "/Locales/list",
  TICKETS_CHANGE_STATUS_BY_ID: API_ENDPOINT_URL + "/Tickets/changeTicketStatus",
  TICKETS_CHANGE_PRIORITIES_BY_ID:
    API_ENDPOINT_URL + "/Tickets/changeTicketPriorities/",
  TICKETS_CHANGE_CATEGORY_BY_ID:
    API_ENDPOINT_URL + "/Tickets/changeTicketCategory/",
  TICKETS_GET_CODE: API_ENDPOINT_URL + "/Tickets/generateTicketCode",
  ATTACHMENTS_UPLOAD: API_ENDPOINT_URL + "/Attachments/upload",
  IMPORT_VENDORS: API_ENDPOINT_URL + "/Vendors/import",
  VENDOR_CONTACTS: API_ENDPOINT_URL + "/Vendors/contactList/",
  VENDOR_PURCHASE_ORDER: API_ENDPOINT_URL + "/purchaseOrders/list/vendor/",
  VENDOR_BILL: API_ENDPOINT_URL + "/Bills/list/vendor/",
  INCOME_ACCOUNT_LIST:
    API_ENDPOINT_URL + "/ChartOfAccounts/listByIncomeAccount",
  VENDOR_COUNTS: API_ENDPOINT_URL + "/Vendors/vendorgetCounts/",

  TAX_RATES_LIST: API_ENDPOINT_URL + "/TaxRates/list",
  EXPENSE_GOODS_ACCOUNT_LIST:
    API_ENDPOINT_URL + "/ChartOfAccounts/listByExpenseAccount",
  ADD_ITEM: API_ENDPOINT_URL + "/savedItems/add",
  VENDOR_DROPDOWN_LIST: API_ENDPOINT_URL + "/Vendors/vendorDropdown",
  STATE_FILTER_LIST: API_ENDPOINT_URL + "/States/dropDown",
  COUNTRY_DROPDOWN_LIST: API_ENDPOINT_URL + "/Countries/dropdown",
  ITEM_LIST: API_ENDPOINT_URL + "/savedItems/list",
  DEFAULT_ITEM_LIST: API_ENDPOINT_URL + "/savedItems/defaultItemList",
  ITEM_DELETE: API_ENDPOINT_URL + "/savedItems/delete/",
  TICKETS_INFO_IN_COUNT_DAYS:
    API_ENDPOINT_URL + "/Tickets/ticketsInfoInCountDays",
  ADD_TENANT_COMPANY: API_ENDPOINT_URL + "/TenantCompanies/add",
  POLICY_LIST: API_ENDPOINT_URL + "/Policies/list",
  POLICY_LISTBYID: API_ENDPOINT_URL + "/Policies/list/",
  POLICY_TREEDATABYID: API_ENDPOINT_URL + "/Policies/treeDataById",
  POLICY_MODIFY: API_ENDPOINT_URL + "/Policies/modify/",
  POLICY_ADD: API_ENDPOINT_URL + "/Policies/add",
  POLICY_DELETE: API_ENDPOINT_URL + "/Policies/delete",
  RESOURCE_LIST: API_ENDPOINT_URL + "/Resources/list",
  RESOURCE_TREEDATA: API_ENDPOINT_URL + "/Resources/treeData",
  ACCESSRULES_LIST: API_ENDPOINT_URL + "/AccessRules/list",
  INVOICE_TOTAL: API_ENDPOINT_URL + "/Invoices/grandTotal",
  VENDOR_REFUND: API_ENDPOINT_URL + "/Refunds/list/vendor/",
  VENDOR_AGREEMENT: API_ENDPOINT_URL + "/Vendors/agreementList/",
  CREATE_INVOICE: API_ENDPOINT_URL + "/Invoices/add",
  LOGOUT: API_ENDPOINT_URL + "/Users/logout",
  LEADS_LIST: API_ENDPOINT_URL + "/Leads/list/",
  LEADS_ROWCOUNT: API_ENDPOINT_URL + "/Leads/rowCount",
  LEAD_DETAILS: API_ENDPOINT_URL + "/Leads/details/",
  LEADS_MEMBERLIST: API_ENDPOINT_URL + "/Globals/memberList/leads/",
  CLIENT_PARENT_DROPDOWN: API_ENDPOINT_URL + "/Clients/clientParent",
  CLIENT_ADD: API_ENDPOINT_URL + "/Clients/add",
  TICKETS_OPEN_DAYS: API_ENDPOINT_URL + "/Tickets/openDays",
  TICKETS_REPLIES_COUNT: API_ENDPOINT_URL + "/Tickets/ticketsRepliesCount",
  TICKETS_PARTICIPENTS_LIST: API_ENDPOINT_URL + "/Tickets/getTicketsUsersById",
  TICKETS_LAST_REPLIES_IN_DAYS:
    API_ENDPOINT_URL + "/Tickets/ticketsLastReplyInDays",
  ACCOUNT_LIST: API_ENDPOINT_URL + "/Accounts/list",
  PAYMENT_FOR_REFUND: API_ENDPOINT_URL + "/Invoices/paymentForRefund",
  UPDATE_LEAD: API_ENDPOINT_URL + "/Leads/modify/",
  LEAD_DELETE: API_ENDPOINT_URL + "/Leads/delete/",
  UPDATE_LEAD_STATUS: API_ENDPOINT_URL + "/Leads/status/",
  TICKETS_CATEGORY_LIST: API_ENDPOINT_URL + "/TicketCategories/list",
  TICKETS_PRIORITY_LIST: API_ENDPOINT_URL + "/Priorities",
  CLIENT_CONTACT_LIST: API_ENDPOINT_URL + "/Contacts/list",
  GET_TICKET_CODE: API_ENDPOINT_URL + "/Tickets/generateTicketCode",
  CREATE_TICKET: API_ENDPOINT_URL + "/Tickets/add",
  CREATE_PROJECT: API_ENDPOINT_URL + "/Projects/add",
  PROJECT_DETAILS: API_ENDPOINT_URL + "/Projects/listById/",
  UPDATE_TICKET: API_ENDPOINT_URL + "/Tickets/modify",
  MENU_QR: API_ENDPOINT_URL + "/MenuQrs/findOne",
  MENU_QR_ACTIVE_INACTIVE: API_ENDPOINT_URL + "/MenuQrs/update",
  CLIENT_MODIFY: API_ENDPOINT_URL + "/Clients/modify/",
  ITEM_DETAILS: API_ENDPOINT_URL + "/savedItems/details/",
  EDIT_ITEM: API_ENDPOINT_URL + "/savedItems/modify/",
  IMPORT_ITEMS: API_ENDPOINT_URL + "/savedItems/import",
  INVOICE_CHANGE_STATUS: API_ENDPOINT_URL + "/Invoices/status/",
  INV_DELETE_REFUND: API_ENDPOINT_URL + "/Invoices/saveRefund",
  INV_REQ_DELETE_REFUND: API_ENDPOINT_URL + "/Invoices/saveRefundRequest",
  IMPORT_LEADS: API_ENDPOINT_URL + "/Leads/import/",
  ADD_SUBSCRIPTION: API_ENDPOINT_URL + "/TenantCompanies/subscription/",
  TICKETS_DROPDOWN_BY_CLIENTID:
    API_ENDPOINT_URL + "/Tickets/dropdownListByClientID",
  PROJECT_LIST: API_ENDPOINT_URL + "/Projects/list",
  PROJECT_DELETE: API_ENDPOINT_URL + "/Projects/delete/",
  PROJECTS_DROPDOWN_BY_CLIENTID:
    API_ENDPOINT_URL + "/Projects/dropdownListByClientID",
  PROJECT_DEPARTMENT_ID:
    API_ENDPOINT_URL + "/Projects/departmentListByParentId/",
  COMM_LIST: API_ENDPOINT_URL + "/commissionCalculations/dropdownList?",
  PRIMARY_CONTACT_UPDATE: API_ENDPOINT_URL + "/Contacts/status/",
  ADD_VENDOR_CONTACT: API_ENDPOINT_URL + "/Contacts/add",
  ACCOUNT_DETAILS: API_ENDPOINT_URL + "/Contacts/details/",
  UPDATE_CONTACT: API_ENDPOINT_URL + "/Contacts/modify/",
  CONTACT_DELETE: API_ENDPOINT_URL + "/Contacts/delete/",
  CLIENT_DETAIL_CONTACT_LIST: API_ENDPOINT_URL + "/Clients/contactList/",
  ADD_SEATS: API_ENDPOINT_URL + "/TenantCompanies/addSeats/",
  REMOVE_SEATS: API_ENDPOINT_URL + "/TenantCompanies/removeSeats/",
  VISIBILITY_GET_LIST: API_ENDPOINT_URL + "/Visibilities/listById",
  VISIBILITY_UPDATE: API_ENDPOINT_URL + "/Visibilities/addModify",
  PROJECT_DROPDOWN_LIST: API_ENDPOINT_URL + "/Projects/dropdownList",
  OPPORTUNITY_DROPDOWN_LIST: API_ENDPOINT_URL + "/Opportunities/dropdownList",
  ADD_BUG: API_ENDPOINT_URL + "/Bugs/add",
  UPDATE_BUG: API_ENDPOINT_URL + "/Bugs/modify/",
  BUG_DETAILS: API_ENDPOINT_URL + "/Bugs/details/",
  RESERVED_SEATS: API_ENDPOINT_URL + "/TenantCompanies/getSeats/",
  DELETE_TICKETS: API_ENDPOINT_URL + "/Tickets/delete/",
  VENDOR_PURCHASEORDER: API_ENDPOINT_URL + "/Refunds/purchaseOrderList/",
  ACCOUNT_TYPE: API_ENDPOINT_URL + "/ChartOfAccounts/list",
  DELETE_REFUND_BY_VENDORID: API_ENDPOINT_URL + "/Refunds/refunddelete/",
  TICKET_CLIENT_LOCATION_DD_LIST:
    API_ENDPOINT_URL + "/Tickets/clientLocationsList/",
  ADD_VENDOR_REFUND: API_ENDPOINT_URL + "/Bills/refund",
  CLIENT_DELETE_MODULE_COUNT: API_ENDPOINT_URL + "/Clients/deleteModuleCount",
  CLIENT_DETAIL_COUNT: API_ENDPOINT_URL + "/Clients/detailCount",
  CLIENT_DELETE: API_ENDPOINT_URL + "/Clients/delete/",
  LEADS_CONTACTS_LIST: API_ENDPOINT_URL + "/leadContacts/list/",
  LEAD_CONTACT_DETAILS: API_ENDPOINT_URL + "/leadContacts/details/",
  UPDATE_LEAD_CONTACT: API_ENDPOINT_URL + "/leadContacts/modify/",
  ADD_LEAD_CONTACT: API_ENDPOINT_URL + "/leadContacts/add/",
  DELETE_LEAD_CONTACT: API_ENDPOINT_URL + "/leadContacts/delete/",
  LEADS_PRIMARY_CONTACT_UPDATE: API_ENDPOINT_URL + "/leadContacts/makePrimary/",
  TENANT_LIST: API_ENDPOINT_URL + "/Tenants/list/",
  BATCH_EMAIL: API_ENDPOINT_URL + "/Invoices/batchEmail",
  PAYMENT_LIST_BY_CLIENT: API_ENDPOINT_URL + "/paymentInfos/listByClientID",
  CALLS_LIST: API_ENDPOINT_URL + "/Calls/list/",
  CALL_DETAILS: API_ENDPOINT_URL + "/Calls/details/",
  LEADS_CONTACTS_LIST_DD: API_ENDPOINT_URL + "/leadContacts/DDlist/",
  ADD_LEAD_CALL: API_ENDPOINT_URL + "/Calls/add/",
  UPDATE_LEAD_CALL: API_ENDPOINT_URL + "/Calls/modify/",
  DELETE_LEAD_CALL: API_ENDPOINT_URL + "/Calls/delete/",
  MEETINGS_LIST: API_ENDPOINT_URL + "/Meetings/list/",
  ADD_LEAD_MEETING: API_ENDPOINT_URL + "/Meetings/add/",
  MEETING_DETAILS: API_ENDPOINT_URL + "/Meetings/details/",
  UPDATE_LEAD_MEETING: API_ENDPOINT_URL + "/Meetings/modify/",
  UPDATE_TIMESHEET: API_ENDPOINT_URL + "/Timesheets/modify/",
  DELETE_LEAD_MEETING: API_ENDPOINT_URL + "/Meetings/delete/",
  BUG_LIST: API_ENDPOINT_URL + "/Bugs/list",
  UPDATE_BUG_STATUS: API_ENDPOINT_URL + "/Bugs/status/",
  DELETE_BUG: API_ENDPOINT_URL + "/Bugs/delete/",

  ATTACHMENTS_LIST: API_ENDPOINT_URL + "/Attachments/list",
  OPPORTUNITY_DETAILS: API_ENDPOINT_URL + "/Opportunities/listById/",
  OPPORTUNITY_DELETE: API_ENDPOINT_URL + "/Opportunities/delete/",
  UPDATE_OPPORTUNITY: API_ENDPOINT_URL + "/Opportunities/modify/",
  USERS_LIST: API_ENDPOINT_URL + "/Users/list/",
  USER_BAN: API_ENDPOINT_URL + "/Users/ban/",
  USER_STATUS: API_ENDPOINT_URL + "/Users/status/",
  USER_DELETE: API_ENDPOINT_URL + "/Users/delete/",
  ADD_BUG_ATTACHMENTS: API_ENDPOINT_URL + "/Attachments/add",
  DELETE_BUG_ATTACHMENTS: API_ENDPOINT_URL + "/Attachments/delete/",
  OPPORTUNITY_PIPELINE_STAGES_INFO:
    API_ENDPOINT_URL + "/OpportunityStages/list",
  PIPELINE_OPPORTUNITIES_LIST:
    API_ENDPOINT_URL + "/Opportunities/pipelineOpportunities/",
  OPPORTUNITY_CHANGE_STAGE: API_ENDPOINT_URL + "/Opportunities/updateStage/",
  ADD_CC_INFO: API_ENDPOINT_URL + "/paymentInfos/add",
  PAYMENTINFO_DETAIL: API_ENDPOINT_URL + "/paymentInfos/details",
  CONFIG_GET_ALL_DETAIL: API_ENDPOINT_URL + "/Configs/listAll",
  OPPORTUNITY_CHANGE_FINAL_STATUS:
    API_ENDPOINT_URL + "/Opportunities/updateFinalStatus/",
  DECRYPT_INVOICEID: API_ENDPOINT_URL + "/Invoices/decryptInvoiceId",
  ADD_PIN_ACTION: API_ENDPOINT_URL + "/Pinactions/add/",
  DELETE_PIN_ACTION: API_ENDPOINT_URL + "/Pinactions/delete/",
  CONVERT_LEAD_TO_CLIENT: API_ENDPOINT_URL + "/Leads/convertToClient/",
  GET_PAYMENTS: API_ENDPOINT_URL + "/Clients/getAllPayments/",
  COA_TREEDATA: API_ENDPOINT_URL + "/ChartOfAccounts/treeData/",
  COA_DELETE: API_ENDPOINT_URL + "/ChartOfAccounts/delete/",
  COA_LIST: API_ENDPOINT_URL + "/ChartOfAccounts/list/",
  COA_ADD: API_ENDPOINT_URL + "/ChartOfAccounts/add/",
  COA_MODIFY: API_ENDPOINT_URL + "/ChartOfAccounts/modify/",
  COA_DETAILS: API_ENDPOINT_URL + "/ChartOfAccounts/details/",
  TYPES_OF_COA_LIST: API_ENDPOINT_URL + "/TypesOfCoas/list",
  PIPELINE_PROJECTS_LIST: API_ENDPOINT_URL + "/Projects/projectsByPipeline/",
  PROJECT_CATEGORY_DD_LIST: API_ENDPOINT_URL + "/ProjectCategories/list",
  PROJECT_STAGE_LIST_BY_PIPE:
    API_ENDPOINT_URL + "/ProjectStateReasons/stagesByPipeId/",
  TRANSACTIONS_REPORT_LIST:
    API_ENDPOINT_URL + "/Transactions/transactionsReport/list",
  ACTIVATE_TENANT: API_ENDPOINT_URL + "/Tenants/activate/",
  ESTIMATE_LIST: API_ENDPOINT_URL + "/Estimates/list",
  ESTIMATE_DELETE: API_ENDPOINT_URL + "/Estimates/delete/",
  ESTIMATE_CHANGE_STATUS: API_ENDPOINT_URL + "/Estimates/status/",
  CLIENT_AGREEMENT: API_ENDPOINT_URL + "/Agreements/listById?",
  AGREEMENT_DELETE: API_ENDPOINT_URL + "/Agreements/delete?",
  CLIENT_TRANSACTION_LIST: API_ENDPOINT_URL + "/Transactions/listById",
  UPDATE_COMPANY: API_ENDPOINT_URL + "/Configs/company",
  CHECK_TENANT_DOMAIN: API_ENDPOINT_URL + "/TenantCompanies/domainExists/",
  UPDATE_OPPORTUNITY_STATUS: API_ENDPOINT_URL + "/Opportunities/status/",
  TICKETS_CHANGE_BILL_STATUS_BY_ID:
    API_ENDPOINT_URL + "/Tickets/changeBillStatus/",
  TICKETS_CHANGE_LOCK_UNLOCK_STATUS_BY_ID:
    API_ENDPOINT_URL + "/Tickets/lockTicketStatus/",
  DEFAULT_WORKING_DAYS: API_ENDPOINT_URL + "/WorkingDays",
  DEFAULT_LANGUAGE: API_ENDPOINT_URL + "/Languages/list",
  DEFAULT_LOCALE: API_ENDPOINT_URL + "/Locales/list",
  DEFAULT_CURRENCY: API_ENDPOINT_URL + "/Currencies/list",
  PROJECT_PIPELINE_LIST: API_ENDPOINT_URL + "/ProjectStatePiplines/list",
  SALES_INVOICE_LIST_LIST:
    API_ENDPOINT_URL + "/SalesTaxInvoice/listBySalesTax/",
  TRANSACTIONS_LIST: API_ENDPOINT_URL + "/Transactions/list",
  ESTIMATE_DETAILS: API_ENDPOINT_URL + "/Estimates/details/",
  ESTIMATE_SEND_EMAIL: API_ENDPOINT_URL + "/Estimates/email",

  ADD_ACOUNT: API_ENDPOINT_URL + "/Accounts/add",
  DELETE_ACOUNT: API_ENDPOINT_URL + "/Accounts/delete/",
  GET_CHECKLIST: API_ENDPOINT_URL + "/WriteChecks/list",
  DELETE_CHECK: API_ENDPOINT_URL + "/WriteChecks/delete/",
  CLIENT_REFUND_LIST: API_ENDPOINT_URL + "/Refunds/listByClient",
  CLIENT_REFUND_DELETE: API_ENDPOINT_URL + "/Refunds/refundDelete",
  CLIENT_REFUND_INVOICE_LIST:
    API_ENDPOINT_URL + "/Refunds/addClientRefundDetails",
  DEFAULT_ACCOUNTS: API_ENDPOINT_URL + "/Accounts/list",
  UPDATE_SYSTEM: API_ENDPOINT_URL + "/Configs/system",
  PAYMENT_SETTINGS: API_ENDPOINT_URL + "/Configs/paymentSettings",
  PURCHASEORDER_SETTINGS_DETAILS:
    API_ENDPOINT_URL + "/Configs/purchaseOrderSettings",
  INVOICE_SETTINGS_DETAILS: API_ENDPOINT_URL + "/Configs/invoiceSettings",
  UPDATE_PAYMENTS_SETTING: API_ENDPOINT_URL + "/Configs/updatePaymentSettings",
  ESTIMATE_SETTINGS_DETAILS: API_ENDPOINT_URL + "/Configs/estimateSettings",
  ESTIMATE_CONVERT_TO_INVOICE: API_ENDPOINT_URL + "/Estimates/convert?",
  CREATE_ESTIMATE: API_ENDPOINT_URL + "/Estimates/add",
  PURCHASEORDER_DETAILS: API_ENDPOINT_URL + "/Configs/purchaseOrderSettings",
  GLOBAL_ADDMEMBER: API_ENDPOINT_URL + "/Globals/addMember/",
  OPPO_INACTIVE_DAYS: API_ENDPOINT_URL + "/Opportunities/inactiveDays/",
  TIMEBAR_NO_OF_DAYS_LIST: API_ENDPOINT_URL + "/Timebars/getTimebarDetails",
  CLIENT_AGREEMENT_EMAIL_TEMPLATE: API_ENDPOINT_URL + "/Agreements/new?",
  CLIENT_NEW_AGREEMENT: API_ENDPOINT_URL + "/Agreements/ClientNewAgreement",
  USERS_EMAIL_EXISTS: API_ENDPOINT_URL + "/Employees/userEmailExists",
  RESET_PASSWORD: API_ENDPOINT_URL + "/Employees/restorePassword",
  EMAIL_TEMPLATE_DELETE: API_ENDPOINT_URL + "/EmailTemplates/delete",
  EMAIL_TEMPLATE_ADD: API_ENDPOINT_URL + "/EmailTemplates/add?",
  GET_BALANCE_BY_ACC_ID: API_ENDPOINT_URL + "/Accounts/getBalance/",
  GET_PAYTO_LIST: API_ENDPOINT_URL + "/WriteChecks/getAllUsers",
  CREATE_CHECK: API_ENDPOINT_URL + "/WriteChecks/add",
  GET_CHECK_DETAILS: API_ENDPOINT_URL + "/WriteChecks/listById/",
  DEPARTMENT_DD_LIST: API_ENDPOINT_URL + "/Departments/dropdownList/",
  PAYMENT_METHOD_LIST: API_ENDPOINT_URL + "/PaymentMethods/list",
  PAYMENT_METHOD_DELETE: API_ENDPOINT_URL + "/PaymentMethods/delete/",
  PAYMENT_METHOD_ADD: API_ENDPOINT_URL + "/PaymentMethods/add",
  PAYMENT_METHOD_UPDATE: API_ENDPOINT_URL + "/PaymentMethods/modify/",
  PAYMENT_METHOD_BY_ID: API_ENDPOINT_URL + "/PaymentMethods/list/",
  UPDATE_LEAD_SETTING: API_ENDPOINT_URL + "/Configs/leadSettings",
  LEAD_STATUS_DELETE: API_ENDPOINT_URL + "/LeadStatuses/delete/",
  UPDATE_ESTIMATE: API_ENDPOINT_URL + "/Estimates/modify/",
  OPPORTUNITY_LIST_BY_CLIENT:
    API_ENDPOINT_URL + "/Opportunities/listByClientLead",
  LEAD_STATUS_ADD: API_ENDPOINT_URL + "/LeadStatuses/add",
  LEAD_STATUS_BY_ID: API_ENDPOINT_URL + "/LeadStatuses/list/",
  LEAD_STATUS_UPDATE: API_ENDPOINT_URL + "/LeadStatuses/modify/",
  COMPANY_LIST: API_ENDPOINT_URL + "/TenantCompanies/list",
  GET_COMPANY_DETAILS: API_ENDPOINT_URL + "/TenantCompanies/details",
  DEADLINE_LIST: API_ENDPOINT_URL + "/Deadlines/list",
  TICKETS_REPLIES_LIST: API_ENDPOINT_URL + "/TicketsReplies/list",
  TICKETS_REPLIES_DELETE: API_ENDPOINT_URL + "/TicketsReplies/delete/",
  OPPORTUNITY_KANBABN_POPUP: API_ENDPOINT_URL + "/Opportunities/kanbanPopUp/",

  LOGGED_USER_INITIAL_DATA: API_ENDPOINT_URL + "/Users/userInitialDetails",
  PROPOSAL_TEMPLATE_LIST:
    API_ENDPOINT_URL + "/EmailTemplates/listAllProposalTemplates",
  PROPOSAL_TEMPLATE_LIST_BY_ID:
    API_ENDPOINT_URL + "/EmailTemplates/listByEmailTemplateId",
  UPDATE_PROPOSAL_TEMPLATE:
    API_ENDPOINT_URL + "/Configs/updateProposalTemplate",
  CREATE_NEW_PROPOSAL_TEMPLATE: API_ENDPOINT_URL + "/Configs/createNewProposal",
  DELETE_PROPOSAL_TEMPLATE:
    API_ENDPOINT_URL + "/EmailTemplates/deleteProposalTemplateById",

  LEAD_SOURCE_DELETE: API_ENDPOINT_URL + "/LeadSources/delete/",
  LEAD_SOURCE_ADD: API_ENDPOINT_URL + "/LeadSources/add",
  LEAD_SOURCE_BY_ID: API_ENDPOINT_URL + "/LeadSources/list/",
  LEAD_SOURCE_UPDATE: API_ENDPOINT_URL + "/LeadSources/modify/",
  TICKETS_PRIORITY_ADD: API_ENDPOINT_URL + "/TicketCategories/add",
  TICKETS_TIME_WORKED_SPENT: API_ENDPOINT_URL + "/Tickets/ticketsTimeWorked/"
};

export const StorageKey = {
  ACCESSTOKEN: "accessToken",
  VENDOR_REMOVE_COLUMN: "VendorRemovedColumns",
  CLIENT_REMOVE_COLUMN: "ClientRemovedColumns",
  VENDOR_CONTACT_REMOVE_COLUMN: "VendorContactRemovedColumns",
  VENDOR_PURCAHSE_ORDER_REMOVED_COLUMN: "VendorPurchaseOrderRemovedColumns",
  VENDOR_TRANSACTION_REMOVED_COLUMN: "VendorTransactionRemovedColumns",
  ITEM_REMOVE_COLUMN: "ItemRemovedColumns",
  VENDOR_REFUND_REMOVED_COLUMN: "VendorRefundRemovedColumns",
  VENDOR_AGREEMENT_REMOVED_COLUMN: "VendorAgreementRemovedColumns",
  INVOICE_LIST_REMOVE_COLUMN: "InvoiceListtRemovedColumns",
  INVOICE_PAID_REMOVE_COLUMN: "InvoicePaidRemovedColumns",
  INVOICE_RECUR_REMOVE_COLUMN: "InvoiceRecurRemovedColumns",
  LEADS_REMOVE_COLUMN: "LeadsRemovedColumns",
  OPPORTUNITIES_REMOVE_COLUMN: "OpportunitesRemovedColumns",
  TASK_REMOVE_COLUMN: "TaskRemovedColumns",
  TICKETS_REMOVE_COLUMN: "TicketsRemovedColumns",
  CLIENT_AGREEMENT_REMOVED_COLUMN: "ClientAgreementRemovedColumns",
  CLIENT_TRANSACTION_REMOVED_COLUMN: "ClientTransactionRemovedColumns",
  CLIENT_REFUND_REMOVED_COLUMN: "ClientRefundRemovedColumns",
  TRANSACTIONS_LIST: API_ENDPOINT_URL + "/Transactions/list"
};

export const ErrorMessage = {
  SERVER_DEFULT_MESSAGE: "Oops! Something went wrong! Please retry later"
};

export class ThemeColor {
  static themeColor: ThemeColorInterface[] = [
    { id: "red-light", value: "Red light" },
    { id: "red-dark", value: "Red dark" },
    { id: "green-light", value: "green light" },
    { id: "green-dark", value: "green dark" },
    { id: "blue-light", value: "Blue light" },
    { id: "blue-dark", value: "Blue dark" },
    { id: "purple-light", value: "Purple light" },
    { id: "purple-dark", value: "Purple dark" },
    { id: "indigo-light", value: "Indigo light" },
    { id: "indigo-dark", value: "Indigo dark" }
  ];
}

export class Common {
  static matDialogConfig: MatDialogConfig = {
    position: {
      top: "0px",
      right: "0px",
      bottom: "0px"
    },
    maxWidth: "95vw",
    height: "100%",
    width: "100vw",
    disableClose: true,
    panelClass: [
      "animated",
      "slideInRight",
      "full-screen-modal",
      'green-light'
    ]
  };
}

export class Refunds {
  public static refundType = [
    { value: "refund", label: "Refund account" },
    { value: "credit", label: "Credit" }
  ];
}
export class RefundTo {
  public static refundTo = [
    {
      value: "account",
      label: "Accounts"
    },
    {
      value: "credit_card",
      label: "Credit Card"
    }
  ];
}

export class ClientRefunds {
  public static refundType = [
    { value: "", label: "Select User Type" },
    { value: "refund", label: "Refund by check" },
    { value: "credit", label: "Refund for Credit" }
  ];
}
export const VISIBILITYMODELNAME = {
  CLIENT: "client",
  CLIENT_DETAIL_CONTACT: "client_detail_contact",
  VENDOR: "vendor",
  VENDOR_REFUND: "vendor_refund",
  VENDOR_AGREEMENT: "vendor_agreement",
  VENDOR_CONTACTS: "vendor_contact",
  VENDOR_PURCHASE_ORDER: "vendor_purchase_order",
  VENDOR_TRANSACTION: "vendor_transaction",
  LEADS: "leads",
  LEADS_LIST: "leads_list",
  LEADS_DETAIL_CONTACT: "leads_detail_contact",
  TICKET: "ticket",
  TENANTS_LIST: "tenants_list",
  LEADS_CALLS_LIST: "leads_calls_list",
  CLIENT_DETAIL_OPPORTUNITIES: "client_detail_opportunities",
  LEAD_DETAIL_TASKS: "lead_detail_tasks",
  ALLINVOICE: "invoice",
  LEADS_MEETINGS_LIST: "leads-meetings-list",
  RECINVOICE: "recurring_invoice",
  BUG: "bug",
  CLIENT_DETAIL_TICKET: "client_detail_ticket",
  OPPORTUNITIES_LIST: "opportunities_list",
  TASK_LIST: "task",
  LEAD_DETAIL_OPPORTUNITIES: "lead_detail_opportunities",
  PAYMENTS_LIST: "payments_list",
  CLIENT_DETAIL_TASKS: "client_detail_tasks",
  ESTIMATE_LIST: "estimate_list",
  CLIENT_AGREEMENT: "client_detail_agreement",
  CLIENT_DETAIL_INVOICE: "client_detail_invoice",
  TICKET_DETAIL_INVOICE: "ticket_detail_invoice",
  CLIENT_TRANSACTION: "client_detail_transaction",
  TRANSACTION_REPORT: "tranaction_report",
  ITEM: "item",
  CLIENT_REFUND: "client_detail_refund",
  CHECKS: "write_checks",
  TICKET_SETTING_CATEGORY: "Ticket_setting_category"
};

export const AVOIDSPINNERAPI = [
  "api/Clients/contactList/",
  "api/Clients/list",
  "api/Tickets/ticketsInfoInCountDays",
  "api/Opportunities/kanbanPopUp/",
  "api/Users/updateTheme",
  "api/TenantCompanies/list",
  "clients/access-logs",
  "clients/search",
];

export const ADDITIONALROUTERPATH = [
  "/admin",
  "/subscription",
  "/wizard",
  "/page-not-found",
];
