import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {API, StorageKey} from './constant';
import { Observable, Subject } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/toPromise';

@Injectable({
    providedIn: 'root'
})
export class MenuqrService {

    constructor(private http: HttpClient) {
    }

    activeMenu() {
        return this.http.get(API.MENU_QR + '?filter=' + JSON.stringify({"where" : {"menuQrId":"1"}}));
    }

      inactivateMenu() {
        return this.http.post(API.MENU_QR_ACTIVE_INACTIVE + '?where=' + JSON.stringify({"isActive":1}),{"isActive":0});
    }

    activateMenu(url) {
        return this.http.post(API.MENU_QR_ACTIVE_INACTIVE + '?where=' + JSON.stringify({"menuQrId":1}),{"url":''+url});
    }
}
