import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class BreadcrumbService {
  initialBreadcrumbArray: any = [
    {
      name: "Provisioner",
      url: "/admin/provisioner"
    }
  ];

  editedBreadcrumbArray: any = [];

  constructor() {}

  build(arr: any) {
    this.editedBreadcrumbArray = [];
    this.editedBreadcrumbArray = arr;
    return true;
  }

  getBreadcrumb() {
    return this.initialBreadcrumbArray.concat(this.editedBreadcrumbArray);
  }
}
