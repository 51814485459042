import {
    ActionReducerMap,
    MetaReducer
  } from '@ngrx/store';
  import { environment } from '../../../../environments/environment';
import { ClientsState, clientsReducer, CompanySettingState, companySettingReducer, DeviceState, deviceReducer } from './reducers';
  
  
  export interface State {
    clients: ClientsState;
    companySettings: CompanySettingState;
    devices: DeviceState;
  }
  
  export const reducers: ActionReducerMap<State> = {
    clients: clientsReducer,
    companySettings: companySettingReducer,
    devices: deviceReducer
  };
  
  
  export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];

  export * from './actions';
  export * from './effects';
  export * from './reducers';
  export * from './selectors';
  