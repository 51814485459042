import { TenantCompaniesService } from './../../service/tenant-companies.service';
import { DialogComponent } from './dialog/dialog.component';
import { ItemsService } from './../../service/items.service';
import { MenuService } from './../../service/menu.service';
import { ModuleListService } from './../../service/module-list.service';
import { EmployeeService } from './../../service/employee.service';
import { Component, OnInit } from '@angular/core';
import * as toastr from 'toastr';
import { Router } from "@angular/router";
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';


@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent implements OnInit {
  isYearly: boolean = false;
  isYearlySelected = 0;
  isMonthly: boolean = true;
  activeUsers: number;
  deactiveUsers: number;
  subscribedModuleList: any;
  defaultModulePrice = 2;
  modulePrice = 0;
  totalPrice = 0;
  list: any;
  tableItems: any;
  dataArr = [];
  displayedColumns: string[] = ['item_name', 'active_users', 'cost_pupm', 'cost_pm', 'row_cost'];
  dataSource = new MatTableDataSource<any>();
  pucost: number= 0;
  displaypucost: any= 0;
  mcost: number= 0;
  displaymcost: any= 0;
  total_cost: number = 0;
  displaytotal_cost: any = 0;
  subTotal: number = 0;
  sTotal: any = 0;
  tax: number = 0;
  displaytax: any = 0;
  displaydiscount: any = 0;
  discount: number = 0;
  total: number = 0;
  totalSeats: number = 0;
  displaytotal: any = 0;
  constructor(private employeeService: EmployeeService, private moduleListService: ModuleListService, private menuService: MenuService, private itemsService: ItemsService, private dialog: MatDialog, private router: Router, private tenantCompaniesService: TenantCompaniesService) { }
  
  ngOnInit() {
    var subscription = localStorage.getItem('subscription');
    if (subscription == '1') {
      this.router.navigate(["/admin"], { replaceUrl: true });
    }
    this.getUserCount();
    this.getSubscribedModules();
    this.getReservedSeats();
    this.getDefaultItemsList();
  }
  
  getUserCount() {
    this.employeeService.activeDeactiveUserCount().subscribe((response: any) => {
      if (response.info.code == 200) {
        this.activeUsers = response.data.activeUsers;
        this.deactiveUsers = response.data.deActiveUsers;
      }
    });
  }

  getSubscribedModules() {
    this.menuService.getSubscribedModules().subscribe((response: any) => {
      if (response.info.code == 200) {
        this.subscribedModuleList = response.data;
        var dataLength = Object.keys(response.data).length
        for (let index = 0; index < dataLength; index++) {
          const element = response.data[index];
          if (element.label != 'dashboard' && element.label != 'calendar' && element.label != 'client' && element.label != 'vendor' && element.label != 'tasks' && element.label != 'hr' && element.label != 'report' && element.label != 'utilities') {
            this.modulePrice += element.module_price;
          }
        }
        this.totalPrice = this.modulePrice + this.defaultModulePrice;
      }
    });
  }

  getReservedSeats() {
    this.tenantCompaniesService.getReservedSeats().subscribe((response: any) => {
      if (response.info.code == 200) {
        this.totalSeats = response.data[0].noOfUsers;
      }
    });
  }

  getDefaultItemsList() {
    this.dataArr = [];
    this.subTotal = 0;
    this.itemsService.getDefaultItemsList().subscribe((response: any) => {
      // console.log(response)
      if (response.info.code == 200) {
        if (this.isMonthly) {
          var numberOfMonths = 1;
        } else {
          var numberOfMonths = 12;
        }
        var activeUser = {
          name: 'Seats Reserved',
          class: 'active_users',
          numberOfUsers: this.totalSeats,
          cost_per_u_p_m: Number(2.00).toFixed(2),
          cost_per_month: Number(0.00).toFixed(2),
          total_row_cost: Number(this.totalSeats * 2 * numberOfMonths).toFixed(2)
        }
        this.subTotal = (this.totalSeats * 2 * numberOfMonths)
        this.dataArr.push(activeUser);
        var moduleArray = [];
        this.subscribedModuleList.forEach(subscribed_module => {
          moduleArray.push(subscribed_module.label);
        });
        response.data.list.forEach(element => {
          // console.log(moduleArray)
          this.pucost = 0;
          this.mcost = 0;
          this.discount = 0;
          if (moduleArray.includes(element.itemName.toLowerCase())) {
            // console.log(element.itemName)
            if (element.pricingType == 0 && (element.itemName != 'Dashboard' && element.itemName != 'Calendar' && element.itemName != 'Client' && element.itemName != 'Vendor' && element.itemName != 'Tasks' && element.itemName != 'HR' && element.itemName != 'Report' && element.itemName != 'Utilities')) {
              this.pucost = element.unitCost;
              this.displaypucost = Number(this.pucost).toFixed(2);
              this.total_cost = this.pucost * this.totalSeats * numberOfMonths;
              this.displaytotal_cost = Number(this.total_cost).toFixed(2);
              this.subTotal += this.total_cost;
            } else if (element.pricingType == 1 && element.itemName != 'Dashboard' && element.itemName != 'Calendar' && element.itemName != 'Client' && element.itemName != 'Vendor' && element.itemName != 'Tasks' && element.itemName != 'HR' && element.itemName != 'Report' && element.itemName != 'Utilities') {
              this.mcost = element.unitCost
              this.total_cost = this.mcost * numberOfMonths;
              this.displaymcost = Number(element.unitCost).toFixed(2);
              this.displaytotal_cost = Number(this.total_cost).toFixed(2);
              this.subTotal += this.total_cost;
            } else {
              this.displaymcost = Number(0.00).toFixed(2);
              this.displaypucost = Number(0.00).toFixed(2);
              this.displaytotal_cost = Number(0.00).toFixed(2);
            }
            this.tableItems = {
              name: element.itemName,
              numberOfUsers: 0,
              class: element.itemName.replace(/ /g, "_"),
              cost_per_u_p_m: this.displaypucost,
              cost_per_month: this.displaymcost,
              total_row_cost: this.displaytotal_cost
            }
            this.dataArr.push(this.tableItems);
          }
        });
        
        var subtotalRow = {
          name: '',
          class: 'subtotal_row',
          numberOfUsers: '',
          cost_per_u_p_m: '',
          cost_per_month: 'Subtotal',
          total_row_cost: Math.round((this.subTotal)*100)/100
        }
        this.sTotal = Number(Math.round((this.subTotal) * 100) / 100).toFixed(2);
        this.displaytax = Number(Math.round((this.subTotal * (8.25/100))*100)/100).toFixed(2); 
        this.tax = Math.round((this.subTotal * (8.25/100))*100)/100; 
        var salesTaxRow = {
          name: '',
          class: 'sales_tax_row',
          numberOfUsers: '',
          cost_per_u_p_m: '',
          cost_per_month: 'Sales Tax (8.25%)',
          total_row_cost: this.tax
        }
        if (this.isYearlySelected) {
          this.displaydiscount = Number(Math.round((this.subTotal * (10 / 100)) * 100) / 100).toFixed(2) ; 
          this.discount = Math.round((this.subTotal * (10 / 100)) * 100) / 100 ; 
        }else{
          this.displaydiscount = Number(0.00).toFixed(2) ; 

        }
        var discountRow = {
          name: '',
          class: 'discount_row',
          numberOfUsers: '',
          cost_per_u_p_m: '',
          cost_per_month: 'Discount',
          total_row_cost: this.discount
        }
        var totalRow = {
          name: '',
          class: 'total_row',
          numberOfUsers: '',
          cost_per_u_p_m: '',
          cost_per_month: 'Total',
          total_row_cost: ((this.subTotal)+this.tax)-this.discount
        }
        this.total = ((this.subTotal) + this.tax) - this.discount;
        this.displaytotal = Number(((this.subTotal) + this.tax) - this.discount).toFixed(2);
        // this.dataArr.push(subtotalRow)
        // this.dataArr.push(salesTaxRow)
        // this.dataArr.push(discountRow)
        // this.dataArr.push(totalRow)
        this.dataSource.data = this.dataArr
      }
    });
  }

  radioChange(event) {
    if (event == 1) {
      this.isMonthly = true;
      this.isYearly = false;
      this.isYearlySelected = 0;
      this.getDefaultItemsList();
    } else if (event == 2) {
      this.isMonthly = false;
      this.isYearly = true;
      this.isYearlySelected = 1;
      this.getDefaultItemsList();
    }
  }

  subscriptionDialog(){
    if (this.isMonthly) {
      var plan = 'monthly';
    } else if (this.isYearly) {
      var plan = 'yearly';
    }
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '50%',
      height: 'auto',
      disableClose: true,
      data: { total: this.displaytotal, plan: plan }
    });
    dialogRef.afterClosed().subscribe(result => {
      
    });
  }

}
