import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import * as toastr from "toastr";

@Component({
  selector: 'app-weblauncher',
  templateUrl: './weblauncher.component.html',
  styleUrls: ['./weblauncher.component.scss']
})
export class WeblauncherComponent implements OnInit {
  weblauncherForm: FormGroup;
  submitted = false;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.weblauncherForm = this.formBuilder.group({
      displayTitle: [''],
      webUrl: [''],
      phoneNumber: [''],
      launchUrl: [''],
    });
  }

  get f() { return this.weblauncherForm.controls; }

  onSubmit() {
      this.submitted = true;

      if (this.weblauncherForm.invalid) {
        toastr.error("Invalid Details !");
        return;
      }
      console.log(this.weblauncherForm.value);

      toastr.success("Form Submitted Successfully !");
      this.weblauncherForm.reset();
  }

}
