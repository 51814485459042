import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTabGroup, MatIcon, MatTableDataSource} from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';


@Component({
  selector: 'app-email-templates',
  templateUrl: './email-templates.component.html',
  styleUrls: ['./email-templates.component.scss']
})
export class EmailTemplatesComponent implements OnInit {
  ckeConfig: any;
  @ViewChild("myckeditor") ckeditor: any;

  // public Editor = ClassicEditor;

  

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
  }

  getCkEditorConfig(){
    this.ckeConfig = {
      allowedContent: false,
      extraPlugins: 'divarea',
      forcePasteAsPlainText: false
    };
  }

}
