import { PinactionService } from './../../service/pinaction.service';
import { GlobalService } from './../../service/global.service';
import { MeetingsService } from './../../service/meeting.service';
import { CallsService } from './../../service/calls.service';
import {Injector} from '@angular/core';
import {ClientService} from '../../service/client.service';
import {LeadsService} from '../../service/leads.service';
import {AbstractControl, FormBuilder, ValidatorFn} from '@angular/forms';
import {VendorService} from '../../service/vendor.service';
import {SelectModel} from '../../service/models/SelectModel';
import {Client} from '../../service/models/Client';
import {MatDialog} from '@angular/material';

import {InvoiceServiceService} from "../../service/invoice-service.service";
import {OpportunitiesService} from "../../service/opportunities.service";
import {ItemsService} from "../../service/items.service";
import {ProjectService} from "../../service/project.service";
import {TicketsService} from "../../service/tickets.service";
import {BugsService} from "../../service/bugs.service";
import {EmployeeService} from "../../service/employee.service";

import {VisibilityService} from '../../service/visibility.service';
import {EStatusCode, IResponse} from '../../service/Utils/Interfaces.class';
import {VISIBILITYMODELNAME} from '../../service/constant';
import {PDFGenerator} from '../../service/Utils/PDFGenerator';
import {ExcelGenerator} from '../../service/Utils/ExcelGenerator';
import { TenantExistsService } from "../../service/tenant-exists.service";
import {TaskService} from "../../service/task.service";
import {EstimateService} from "../../service/estimate.service";
import {TransactionsService} from '../../service/transactions.service';
import {BankCashService} from '../../service/bank-cash.service';
import {ConfigService} from "../../service/config.service";
import {SettingsService} from "../../service/settings.service";
import {AgreementService} from "../../service/agreement.service";
import {DepartmentService} from "../../service/department.service";
import { DeadlineService } from '../../service/deadline.service';

export abstract class AppComponentBase {

  public fb: FormBuilder;
  public bank_cashService: BankCashService;
  public vendorService: VendorService;
  public clientService: ClientService;
  public leadsService: LeadsService;
  public taskService: TaskService;
  public globalService: GlobalService;
  public callsService: CallsService;
  public meetingsService: MeetingsService;
  public InvoiceService: InvoiceServiceService;
  public opportunitiesService: OpportunitiesService;
  public itemsService: ItemsService;
  public projectService: ProjectService;
  public ticketsService: TicketsService;
  public  transactionService: TransactionsService;
  public usersList: SelectModel[];
  public dialog: MatDialog;
  public visibilityModuleName: string;

  public bugsService: BugsService;
  public employeeService: EmployeeService;
  public pinactionService: PinactionService;

  public visibilityService: VisibilityService;
  public visibilityColumns: string[];
  public displayedColumns: string[];
  public visibilitySelected: string[];
  readonly VISIBILITYMODELNAME = VISIBILITYMODELNAME;
  pdfGenerator= new PDFGenerator();
  excelGenerator = new ExcelGenerator();
  public tenantExistsService: TenantExistsService;
  public estimateService: EstimateService;
  public configService: ConfigService;
  public settingService: SettingsService;
  public agreementService: AgreementService;
  public departmentService: DepartmentService;
  public deadlineService: DeadlineService;

  constructor(injector: Injector) {
    this.fb = injector.get(FormBuilder);
    this.vendorService = injector.get(VendorService);
    this.clientService = injector.get(ClientService);
    this.leadsService = injector.get(LeadsService);
    this.taskService = injector.get(TaskService);
    this.globalService = injector.get(GlobalService);
    this.callsService = injector.get(CallsService);
    this.meetingsService = injector.get(MeetingsService);
    this.InvoiceService = injector.get(InvoiceServiceService);
    this.opportunitiesService = injector.get(OpportunitiesService);
    this.itemsService = injector.get(ItemsService);
    this.projectService = injector.get(ProjectService);
    this.ticketsService = injector.get(TicketsService);
    this.tenantExistsService = injector.get(TenantExistsService);
    this.estimateService = injector.get(EstimateService)
    this.transactionService = injector.get(TransactionsService);
    this.bank_cashService = injector.get(BankCashService);
    this.dialog = injector.get(MatDialog);
    this.usersList = new Array<SelectModel>();
    this.bugsService = injector.get(BugsService);
    this.employeeService = injector.get(EmployeeService);
    this.pinactionService = injector.get(PinactionService);
    this.visibilityService = injector.get(VisibilityService);
    this.visibilityColumns = new Array();
    this.displayedColumns = new Array();
    this.visibilitySelected = new Array();
    this.configService = injector.get(ConfigService);
    this.settingService = injector.get(SettingsService);
    this.agreementService = injector.get(AgreementService);
    this.departmentService = injector.get(DepartmentService);
    this.deadlineService = injector.get(DeadlineService);
  }

  employeeDDList() {
    this.leadsService.EmployesDDList().subscribe((SalesPersonRes: any) => {
      if (SalesPersonRes.info.code === 200) {
        SalesPersonRes.data.forEach(userObj => {
          const selectModel: SelectModel = new SelectModel();
          selectModel.setValue(userObj.user_id);
          selectModel.setLabel(userObj.fullname);
          this.usersList.push(selectModel);
        });
      }
    });
  }

  updateVisibility(modelName: string, columnNames: string[]): void {
    if (modelName && columnNames) {
      const data = {
        moduleName: modelName,
        columnName: columnNames.toString()
      };
      this.visibilityService.updateVisibilityForUser(data).subscribe((res: IResponse) => {

      });
    }
  }

  getVisibleColumn(modelName): void {
    this.visibilityService.getVisibilitiesForUser(modelName).subscribe((res: IResponse) => {
      if (res.info.code === EStatusCode.OK) {
        if (res.data.columnName) {
          let data = res.data.columnName.split(',');
          data = data.map(s => s.trim());
          if (data.length > 0) {
            this.displayedColumns = data;
            this.visibilitySelected = data;
          }
        }
      } else {
        console.log(res);
      }
    });
  }

  updateVisibilityColumns(moduleName, values) {
    this.updateVisibility(moduleName, values);
    this.displayedColumns = values;
  }
}
