import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Rx';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
  HttpEvent,
  HttpClient,
  HttpHeaderResponse, HttpResponse
} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/delay';
import {Router} from '@angular/router';
import {environment} from '../environments/environment';
import {LoginService} from './service/login.service';
import {API, AVOIDSPINNERAPI, StorageKey} from './service/constant';
import {SpinnerComponent} from './admin/shared/spinner.component';
import {LoaderService} from './service/loader-service.service';
import {throwError} from 'rxjs';


@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {


  isRefreshingToken: boolean = false;
  private requests: HttpRequest<any>[] = [];
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private router: Router, private loginService: LoginService, private loaderService: LoaderService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let request: HttpRequest<any>;
    //console.log("test");

    this.pushRequestToList(req);
    if (req.url.indexOf('api/Users/login') > 0) {
      request = req;
    } else {
      request = req;
      const token = localStorage.getItem(StorageKey.ACCESSTOKEN);
      if (token) {
        request = req.clone({headers: req.headers.set('Authorization', token)});
      }
    }
    return next.handle(request)
      .catch(error => {
        if (error instanceof HttpErrorResponse) {
          if ((<HttpErrorResponse>error).status === 419) {
            return this.handle401Error(request, next);
          } else if ((<HttpErrorResponse>error).status === 401) {
            this.removeRequest(req);
            this.logoutUser();
          } else {
            this.removeRequest(req);
            return Observable.throw(error);
          }
        } else {
          this.removeRequest(req);
          return Observable.throw(error);
        }
      }).finally(() => {
        this.removeRequest(req);
      });
    /* return Observable.create(observer => {
       // And subscribe to the original observable to ensure the HttpRequest is made
       const subscription = next.handle(request)
         .subscribe(
           event => {
             if (event instanceof HttpResponse) {

               observer.next(event);
             }
           },
           error => {
             if (error instanceof HttpErrorResponse) {
               if ((<HttpErrorResponse>error).status === 419) {
                 return this.handle401Error(request, next);
               } else if ((<HttpErrorResponse>error).status === 401) {
                 this.removeRequest(req);
                 this.logoutUser();
               } else {
                 this.removeRequest(req);
                 return Observable.throw(error);
               }
             } else {
               this.removeRequest(req);
               return Observable.throw(error);
             }

           },
           () => {
             this.removeRequest(req);
             observer.complete();
           });
       // return teardown logic in case of cancelled requests
       return () => {
         this.removeRequest(req);
         subscription.unsubscribe();
       };
     });*/
  }

  handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    this.isRefreshingToken = true;
    return this.refreshToken(localStorage.getItem(StorageKey.ACCESSTOKEN))
      .switchMap((success: any) => {
        if (success.data.accessToken) {
          localStorage.setItem(StorageKey.ACCESSTOKEN, success.data.accessToken);
          this.isRefreshingToken = false;
          return next.handle(this.addToken(request, success.data.accessToken));
        } else {
          this.isRefreshingToken = false;
          this.logoutUser();
        }
      })
      .catch(error => {
        return this.logoutUser();
      })
      .finally(() => {
        this.isRefreshingToken = false;
      });
  }

  refreshToken(refreshtoken: string = null): Observable<any> {
    return this.loginService.refreshToken({
      'headers': {
        'Authorization': refreshtoken,
      }
    }).delay(200);
  }

  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({setHeaders: {Authorization: token}});

  }

  removeRequest(req: HttpRequest<any>) {
    if (this.checkSpinnerAvoidReq(req.url)) {
      const i = this.requests.indexOf(req);
      if (i >= 0) {
        this.requests.splice(i, 1);
      }
      this.loaderService.isLoading.next(this.requests.length > 0);
    }
  }

  logoutUser() {
    localStorage.clear();
    return Observable.throw('');
  }

  checkSpinnerAvoidReq(url: string) {
    const request = AVOIDSPINNERAPI;
    let isNotAvoidReq = true;
    request.forEach(data => {
      if (url.indexOf(data) > 0) {
        isNotAvoidReq = false;
      }
    });
    return isNotAvoidReq;
  }

  pushRequestToList(req: HttpRequest<any>) {
    if (this.checkSpinnerAvoidReq(req.url)) {
      this.requests.push(req);
      this.loaderService.isLoading.next(true);
    } else {
      // console.log('Not add to cons = ' + req.url);
    }
  }

}
