import { NgModule} from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import {TranslateModule} from '@ngx-translate/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule, MatInputModule, MatButtonModule, MatAutocompleteModule, MatIconModule,MatTabsModule,MatTableModule, MatFormFieldModule, MatSelectModule, MatSlideToggleModule, MatCardModule, MatPaginatorModule,MatSortModule,MatCheckboxModule } from '@angular/material';
import { NightModeControlComponent } from './night-mode-control.component'
import { AddCallFlowToggleComponent } from './add-call-flow-toggle/add-call-flow-toggle.component'
import { CallFlowToggleControlComponent } from './call-flow-toggle-control/call-flow-toggle-control.component'
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonComponentModule } from "../../../common/commonComponent.module";

@NgModule({
    declarations: [
      NightModeControlComponent,
      AddCallFlowToggleComponent,
      CallFlowToggleControlComponent

    ],
    imports: [
        BrowserModule,
        TranslateModule.forRoot(),
        FormsModule,
        MatStepperModule,
        MatInputModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatIconModule,
        MatTabsModule,
        MatTableModule,
        MatFormFieldModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatCardModule,
        MatPaginatorModule,
        MatSortModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        UiSwitchModule,
        NgSelectModule,
        CommonComponentModule

        
    ],
    providers: [],
    bootstrap: [NightModeControlComponent],
    exports:[],
})

export class NightModeControlModule { }