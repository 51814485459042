import { NgModule} from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import {TranslateModule} from '@ngx-translate/core';
import { ApplicationsComponent } from './applications.component';
import { ApplicationsRoutingModule } from './application-routing.module';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule, MatInputModule, MatButtonModule, MatAutocompleteModule, MatIconModule,MatTabsModule,MatTableModule, MatFormFieldModule, MatSelectModule, MatSlideToggleModule, MatCardModule, MatPaginatorModule,MatSortModule,MatCheckboxModule } from '@angular/material';
import { ExtensionandusersModule } from './extensionandusers/extensionandusers.module';
import { RingGroupsModule } from './ringgroups/ringgroups.module';
import { from } from "rxjs";
import { PagingparkingModule } from './pagingparking/pagingparking.module';
import { TimeconditionsModule } from "./timeconditions/timeconditions.module";
import { CallqueuesModule } from './callqueues/callqueues.module';
import { UiSwitchModule } from 'ngx-ui-switch';
import { Select2Module } from "ng-select2-component";
import { ConferenceBridgesModule } from './conference-bridges/conference-bridges.module';
import { AnnouncementsModule } from './announcements/announcements.module';
import { NightModeControlModule } from './night-mode-control/night-mode-control.module';
import { MiscDestinationModule } from './misc-destination/misc-destination.module';
import { MusicOnHoldModule } from './music-on-hold/music-on-hold.module';
import { TimegroupsModule } from './timegroups/timegroups.module';
import { IvrModule } from './ivr/ivr.module';
import { SystemrecordingsModule } from "./systema-recordings/systemrecordings.module";



@NgModule({
    declarations: [
        ApplicationsComponent,
    ],
    imports: [
        BrowserModule,
        ApplicationsRoutingModule,
        TranslateModule.forRoot(),
        FormsModule,
        MatStepperModule,
        MatInputModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatIconModule,
        MatTabsModule,
        MatTableModule,
        MatFormFieldModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatCardModule,
        MatPaginatorModule,
        MatSortModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        ExtensionandusersModule,
        RingGroupsModule,
        PagingparkingModule,
        TimeconditionsModule,
        CallqueuesModule,
        UiSwitchModule,
        Select2Module,
        ConferenceBridgesModule,
        AnnouncementsModule,
        NightModeControlModule,
        MiscDestinationModule,
        MusicOnHoldModule,
        TimegroupsModule,
        IvrModule,
        SystemrecordingsModule
        
    ],
    providers: [],
    bootstrap: [ApplicationsComponent],
    exports:[],
})

export class ApplicationsModule { }