import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  MatDialog,
  MatPaginator,
  MatTableDataSource,
  MatSort,
} from "@angular/material";
import { MoboAlert } from "../notification";
import { AddCompanySettingComponent } from "./add-company-setting/add-company-setting.component";
import { ClientService, readCookie } from "../services";
import Swal from "sweetalert2";
import { BreadcrumbService } from "../services/breadcrumb.service";
import { Router } from "@angular/router";

@Component({
  selector: "company-settings",
  templateUrl: "./company-settings.component.html",
  styleUrls: ["./company-settings.component.scss"],
})
export class CompanySettingsComponent implements OnInit {
  dataSource: any;
  displayedColumns: string[] = ["name", "model_name", "actions"];

  @ViewChild(MatPaginator, {}) paginator: MatPaginator;
  @ViewChild(MatSort, {}) sort: MatSort;

  comapnySettings: any;

  constructor(
    private alert: MoboAlert,
    private dialog: MatDialog,
    private service: ClientService,
    private router: Router
  ) {}

  ngOnInit() {
    if (!readCookie("authenticate")) {
      this.router.navigate(["/login"], {
        queryParams: { return: location.href },
      });
      return false;
    }
    this.service.comapnySettings().subscribe((data: any) => {
      this.comapnySettings = data;
      this.dataSource = new MatTableDataSource(this.comapnySettings);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  openAddCompanySettingDialog() {
    const addCompanySettingDialog = this.dialog.open(
      AddCompanySettingComponent
    );
    addCompanySettingDialog.afterClosed().subscribe((data: any) => {
      if (data) {
        if (data.added) {
          Swal.fire("Added!", "Company setting has been added.", "success");
          this.service.comapnySettings().subscribe((data: any) => {
            this.comapnySettings = data;
            this.dataSource = new MatTableDataSource(this.comapnySettings);
            this.dataSource.paginator = this.paginator;
          });
        }
      }
    });
  }

  removeCompanySetting(id: string, rev: string) {
    this.alert
      .confirm({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
      })
      .then((result) => {
        if (result.value) {
          this.service
            .remove_company_setting(id, rev)
            .subscribe((data: any) => {
              if (data) {
                if (data.ok) {
                  Swal.fire("Deleted!", "Company has been deleted.", "success");
                  this.service.comapnySettings().subscribe((data: any) => {
                    this.comapnySettings = data;
                    this.dataSource = new MatTableDataSource(
                      this.comapnySettings
                    );
                    this.dataSource.paginator = this.paginator;
                  });
                } else {
                  Swal.fire("Oopsss...", "Something went worng", "error");
                }
              }
            });
        }
      });
  }

  editCompanySettingName(id: string, name: string) {
    Swal.fire({
      title: "Edit company setting name",
      input: "text",
      inputValue: name,
      cancelButtonColor: "#EC4849",
      reverseButtons: true,
      confirmButtonColor: "#45CE30",
      showCancelButton: true,
      confirmButtonText: "SAVE",
      cancelButtonText: "CANCEL",
      focusCancel: true,
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.value) {
        this.service
          .editcompanySettingName(id, result.value)
          .subscribe((data: any) => {
            if (data) {
              if (data.ok) {
                Swal.fire(
                  "Edited!",
                  "Company setting name has been edited.",
                  "success"
                );
                this.service.comapnySettings().subscribe((data: any) => {
                  this.comapnySettings = data;
                  this.dataSource = new MatTableDataSource(
                    this.comapnySettings
                  );
                  this.dataSource.paginator = this.paginator;
                });
              } else {
                Swal.fire("Oopsss...", "Something went worng", "error");
              }
            }
          });
      }
    });
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  clone(id: string, name: string) {
    this.alert
      .confirmClone({
        title: "Are you sure?",
        text: "You want to clone this company setting?",
      })
      .then((result) => {
        if (result.value) {
          Swal.fire({
            title: "Provide a name",
            input: "text",
            inputValue: `${name} - Copy`,
            cancelButtonColor: "#EC4849",
            reverseButtons: true,
            confirmButtonColor: "#45CE30",
            showCancelButton: true,
            confirmButtonText: "SAVE",
            cancelButtonText: "CANCEL",
            focusCancel: true,
            allowOutsideClick: false,
          }).then((result: any) => {
            if (result.value) {
            }
          });
        }
      });
  }
}
