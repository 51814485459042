import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-timeconditions',
  templateUrl: './timeconditions.component.html',
  styleUrls: ['./timeconditions.component.scss']
})
export class TimeconditionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
