import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SettingsRoutingModule } from "./settings-routing.module";
import { MatTabsModule, MatButtonModule, MatSortModule, MatTableModule,MatCardModule, MatFormFieldModule, MatFormFieldControl } from "@angular/material";
import { PermissionsUsersModule } from './permissions-users/permissions-users.module'
import { EmailSettingsModule } from './email-settings/email-settings.module';
import { SharedModule } from "../../admin/shared/shared.module";

@NgModule({
    imports: [
      CommonModule,
      SettingsRoutingModule,
      MatTabsModule,
      PermissionsUsersModule,
      EmailSettingsModule,
      MatTableModule,
      MatCardModule,
      MatFormFieldModule,
      MatButtonModule,
      MatSortModule,
      SharedModule
    ],
    declarations: [
      
    ]
  })
  export class SettingsModule {}