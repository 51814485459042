import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  HostListener,
} from "@angular/core";
import { DialogComponent } from "../../../dialog/dialog.component";
import { DialogService } from "../../../dialog/dialog.service";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { ClientService, readCookie } from "../../../services";
import Swal from "sweetalert2";
import { EditFormComponent } from "../../../edit-form/edit-form.component";
import { isShowPhoneApplicable } from "../../../helpers/showPhoneHelper";
import { element } from "protractor";

interface IOptions {
  item_id: string;
  item_text: string;
  item_desc: string;
  // image: string;
  // isDisabled?: boolean;
}

@Component({
  selector: "app-device-settings",
  templateUrl: "./device-settings.component.html",
  styleUrls: ["./device-settings.component.scss"],
})
export class DeviceSettingsComponent implements OnInit {
  client_id: string;
  model_id: string;
  device_id: number;
  group_setting_id: number;
  device: any;
  config: any;
  name: string;
  clientName: string;
  configuredVariable: any = [];
  clientSettingsConfig: any;
  clientSettingsConfiguredIds: any = [];
  companySettingConfig: any;
  companySettingConfiguredIds: any = [];
  groupSettingConfig: any;
  groupSettingConfiguredIds: any = [];
  filteredFlag: string = "default";
  originalData: any = [];
  selectedItem: Array<string> = [];
  dropDownList = [];
  device_manuf: string;
  device_model: string;

  dropdownList = [
    {
      item_id: "changedSetting",
      item_text: "Changed Setting",
      item_desc:
        "Changed Setting (This is a setting that YOU changed, not default)",
    },
    {
      item_id: "inheritedSetting",
      item_text: "Inherited Company Setting",
      item_desc:
        "Inherited Setting (This is a setting that inherited from company setting)",
    },
    {
      item_id: "inheritedClientSetting",
      item_text: "Inherited Client Setting",
      item_desc:
        "Inherited Setting (This is a setting that inherited from client setting)",
    },
    {
      item_id: "inheritedGroupSetting",
      item_text: "Inherited Group Setting",
      item_desc:
        "Inherited Setting (This is a setting that inherited from group setting)",
    },
    {
      item_id: "normalSetting",
      item_text: "Normal Setting",
      item_desc: "Normal Setting (Unchanged, Default)",
    },
  ];

  options = [
    {
      item_id: "changedSetting",
      item_text: "Changed Setting",
      item_desc: "(This is a setting that YOU changed, not default)",
    },
    {
      item_id: "inheritedSetting",
      item_text: "Inherited Setting",
      item_desc: "(This is a setting that inherited from company setting)",
    },
    {
      item_id: "inheritedClientSetting",
      item_text: "Inherited Setting",
      item_desc: "(This is a setting that inherited from client setting)",
    },
    {
      item_id: "inheritedGroupSetting",
      item_text: "Inherited Setting",
      item_desc: "(This is a setting that inherited from group setting)",
    },
    {
      item_id: "normalSetting",
      item_text: "Normal Setting",
      item_desc: "(Unchanged, Default)",
    },
  ];

  selectedOptions = [
    {
      item_id: "changedSetting",
      item_text: "Changed Setting",
      item_desc: "(This is a setting that YOU changed, not default)",
    },
    {
      item_id: "inheritedSetting",
      item_text: "Inherited Setting",
      item_desc: "(This is a setting that inherited from company setting)",
    },
    {
      item_id: "inheritedClientSetting",
      item_text: "Inherited Setting",
      item_desc: "(This is a setting that inherited from client setting)",
    },
    {
      item_id: "inheritedGroupSetting",
      item_text: "Inherited Setting",
      item_desc: "(This is a setting that inherited from group setting)",
    },
    {
      item_id: "normalSetting",
      item_text: "Normal Setting",
      item_desc: "(Unchanged, Default)",
    },
  ];

  selectedItems = [
    {
      item_id: "changedSetting",
      item_text: "Changed Setting",
      item_desc:
        "Changed Setting (This is a setting that YOU changed, not default)",
    },
    {
      item_id: "inheritedSetting",
      item_text: "Inherited Company Setting",
      item_desc:
        "Inherited Setting (This is a setting that inherited from company setting)",
    },
    {
      item_id: "inheritedClientSetting",
      item_text: "Inherited Client Setting",
      item_desc:
        "Inherited Setting (This is a setting that inherited from client setting)",
    },
    {
      item_id: "inheritedGroupSetting",
      item_text: "Inherited Group Setting",
      item_desc:
        "Inherited Setting (This is a setting that inherited from group setting)",
    },
    {
      item_id: "normalSetting",
      item_text: "Normal Setting",
      item_desc: "Normal Setting (Unchanged, Default)",
    },
  ];

  phone: MatDialogRef<any>;

  displayPhone: boolean = false;

  constructor(
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private service: ClientService,
    private dialog: MatDialog,
    private router: Router,
    private el: ElementRef
  ) {}

  ngOnInit() {
    if (!readCookie("authenticate")) {
      this.router.navigate(["/login"], {
        queryParams: { return: location.href },
      });
      return false;
    }
    this.route.params.subscribe((data: any) => {
      this.client_id = data.client_id;
      this.model_id = data.model_id;
      this.device_id = data.device_id;
      this.group_setting_id = data.group_setting_id;
      this.service
        .clientDeviceAddEdit(
          this.device_id,
          this.model_id,
          this.client_id,
          this.group_setting_id
        )
        .subscribe((data: any) => {
          this.displayPhone = isShowPhoneApplicable(data.device.device_model);
          this.originalData = JSON.parse(JSON.stringify(data.device));
          this.device = data.device;

          this.device_manuf = this.device.device_manuf;
          this.device_model = this.device.device_model;
          this.config = data.config;
          this.name = data.name;
          this.clientName = data.client_name;
          this.config.forEach((val) => {
            this.configuredVariable.push(val.setting_variable);
          });
          this.clientSettingsConfig = data.client_setting_config;
          this.clientSettingsConfig.forEach((val) => {
            this.clientSettingsConfiguredIds.push(val.setting_variable);
          });
          this.companySettingConfig = data.company_setting_config;
          this.companySettingConfig.forEach((val) => {
            this.companySettingConfiguredIds.push(val.variable);
          });
          this.groupSettingConfig = data.group_setting_config;
          this.groupSettingConfig.forEach((val) => {
            this.groupSettingConfiguredIds.push(val.setting_variable_name);
          });

          this.clientSettingsConfiguredIds =
            this.clientSettingsConfiguredIds.filter((val) => {
              for (var k in this.configuredVariable) {
                if (this.configuredVariable[k] == val) {
                  return false;
                }
              }
              return true;
            });
          this.companySettingConfiguredIds =
            this.companySettingConfiguredIds.filter((val) => {
              for (var k in this.configuredVariable) {
                if (this.configuredVariable[k] == val) {
                  return false;
                }
              }
              return true;
            });
          this.groupSettingConfiguredIds =
            this.groupSettingConfiguredIds.filter((val) => {
              for (var k in this.configuredVariable) {
                if (this.configuredVariable[k] == val) {
                  return false;
                }
              }
              return true;
            });
        });
    });

    //
  }

  showDescription(name: string, description: string) {
    Swal.fire(name, description);
  }

  edit(settings: any) {
    if (this.filteredFlag !== "defaults") {
      const { defaults } = JSON.parse(JSON.stringify(this.originalData));
      settings = defaults.find((data) => data.name === settings.name);
    }

    const editFormDialog = this.dialog.open(EditFormComponent, {
      width: "50%",
      disableClose: true,
      data: {
        for: "deviceSetting",
        settings: settings,
        clientSettingsConfig: this.clientSettingsConfig,
        companySettingConfig: this.companySettingConfig,
        groupSettingConfig: this.groupSettingConfig,
        config: this.config,
        client_id: this.client_id,
        device_id: this.device_id,
      },
    });
    editFormDialog.afterClosed().subscribe((data: any) => {
      if (data) {
        if (data.edited) {
          this.configuredVariable = [];
          this.clientSettingsConfiguredIds = [];
          this.companySettingConfiguredIds = [];
          this.groupSettingConfiguredIds = [];
          Swal.fire("Edited!", "Device setting has been edited.", "success");
          this.service
            .clientDeviceAddEdit(
              this.device_id,
              this.model_id,
              this.client_id,
              this.group_setting_id
            )
            .subscribe((data: any) => {
              this.device = data.device;
              this.config = data.config;
              this.config.forEach((val) => {
                this.configuredVariable.push(val.setting_variable);
              });
              this.clientSettingsConfig = data.client_setting_config;
              this.clientSettingsConfig.forEach((val) => {
                this.clientSettingsConfiguredIds.push(val.setting_variable);
              });
              this.companySettingConfig = data.company_setting_config;
              this.companySettingConfig.forEach((val) => {
                this.companySettingConfiguredIds.push(val.variable);
              });
              this.groupSettingConfig = data.group_setting_config;
              this.groupSettingConfig.forEach((val) => {
                this.groupSettingConfiguredIds.push(val.setting_variable_name);
              });
              this.clientSettingsConfiguredIds =
                this.clientSettingsConfiguredIds.filter((val) => {
                  for (var k in this.configuredVariable) {
                    if (this.configuredVariable[k] == val) {
                      return false;
                    }
                  }
                  return true;
                });
              this.companySettingConfiguredIds =
                this.companySettingConfiguredIds.filter((val) => {
                  for (var k in this.configuredVariable) {
                    if (this.configuredVariable[k] == val) {
                      return false;
                    }
                  }
                  return true;
                });
              this.groupSettingConfiguredIds =
                this.groupSettingConfiguredIds.filter((val) => {
                  for (var k in this.configuredVariable) {
                    if (this.configuredVariable[k] == val) {
                      return false;
                    }
                  }
                  return true;
                });
            });
        }
      }
    });
  }

  public filteredDataArray = [];

  onChangeFilterData() {
    this.filteredDataArray = [];

    if (this.selectedOptions.length === this.options.length) {
      return (this.device = JSON.parse(JSON.stringify(this.originalData)));
    } else if (this.selectedOptions.length === 0) {
      return (this.device = []);
    }
    let dataArray = [];
    for (let index = 0; index < this.selectedOptions.length; index++) {
      const element = this.selectedOptions[index];
      let filteredData = this.filteredData(element.item_id);

      if (
        this.filteredDataArray.length > 0 &&
        this.selectedOptions.length !== this.options.length
      ) {
        this.filteredDataArray = this.mergeValuesWithDuplicateNames([
          ...this.filteredDataArray,
          ...filteredData,
        ]);
      } else {
        this.filteredDataArray.push(...filteredData);
      }
    }
    this.device.defaults = this.filteredDataArray;
  }
  // Function to merge the 'values' arrays for objects with the same 'id'
  mergeValuesWithDuplicateNames(arr) {
    const mergedArray = arr.reduce((result, obj, i) => {
      const existingObj = result.find((item) => item.name === obj.name);
      if (existingObj) {
        existingObj.values.push(...obj.values);
      } else {
        result.push({ ...obj });
      }
      return result;
    }, []);

    return mergedArray.sort((a: any, b: any) => {
      let nA = a.name.toLowerCase();
      let nB = b.name.toLowerCase();

      if (nA < nB) return -1;
      else if (nA > nB) return 1;
      return 0;
    });
  }

  // drop down  filtered  data
  filteredData(str: string) {
    const matchedElementsWithParent = [];
    const { defaults } = JSON.parse(JSON.stringify(this.originalData));

    for (const group of defaults) {
      const matchedValues = [];
      for (const setting of group.values) {
        if (
          this.configuredVariable.includes(setting.setting_variable_name) &&
          str === "changedSetting"
        ) {
          matchedValues.push(setting);
        }
        if (
          this.clientSettingsConfiguredIds.includes(
            setting.setting_variable_name
          ) &&
          str === "inheritedClientSetting"
        ) {
          matchedValues.push(setting);
        }
        if (
          this.groupSettingConfiguredIds.includes(
            setting.setting_variable_name
          ) &&
          str === "inheritedGroupSetting"
        ) {
          matchedValues.push(setting);
        }
        if (
          this.companySettingConfiguredIds.includes(
            setting.setting_variable_name
          ) &&
          !this.clientSettingsConfiguredIds.includes(
            setting.setting_variable_name
          ) &&
          str === "inheritedSetting"
        ) {
          matchedValues.push(setting);
        }
        if (
          !this.companySettingConfiguredIds.includes(
            setting.setting_variable_name
          ) &&
          !this.clientSettingsConfiguredIds.includes(
            setting.setting_variable_name
          ) &&
          !this.configuredVariable.includes(setting.setting_variable_name) &&
          !this.groupSettingConfiguredIds.includes(
            setting.setting_variable_name
          ) &&
          str === "normalSetting"
        ) {
          matchedValues.push(setting);
        }
      }
      if (matchedValues.length > 0) {
        matchedElementsWithParent.push({ ...group, values: matchedValues });
      }
    }
    return matchedElementsWithParent;
  }

  back() {
    history.back();
  }

  showPhone() {
    this.phone = this.dialogService.open(
      DialogComponent,
      {
        width: "80%",
      },
      {
        for: "deviceSetting",
        companySettingConfig: this.companySettingConfig,
        clientSettingsConfig: this.clientSettingsConfig,
        groupSettingConfig: this.groupSettingConfig,
        config: this.config,
        client_id: this.client_id,
        device_id: this.device_id,
        device: this.displayPhone,
      }
    );
    this.phone.afterClosed().subscribe((data: any) => {
      if (data) {
        if (data.edited) {
          this.configuredVariable = [];
          this.clientSettingsConfiguredIds = [];
          this.companySettingConfiguredIds = [];
          this.groupSettingConfiguredIds = [];
          this.service
            .clientDeviceAddEdit(
              this.device_id,
              this.model_id,
              this.client_id,
              this.group_setting_id
            )
            .subscribe((data: any) => {
              this.device = data.device;
              this.config = data.config;
              this.config.forEach((val) => {
                this.configuredVariable.push(val.setting_variable);
              });
              this.clientSettingsConfig = data.client_setting_config;
              this.clientSettingsConfig.forEach((val) => {
                this.clientSettingsConfiguredIds.push(val.setting_variable);
              });
              this.companySettingConfig = data.company_setting_config;
              this.companySettingConfig.forEach((val) => {
                this.companySettingConfiguredIds.push(val.variable);
              });
              this.groupSettingConfig = data.group_setting_config;
              this.groupSettingConfig.forEach((val) => {
                this.groupSettingConfiguredIds.push(val.setting_variable_name);
              });
              this.clientSettingsConfiguredIds =
                this.clientSettingsConfiguredIds.filter((val) => {
                  for (var k in this.configuredVariable) {
                    if (this.configuredVariable[k] == val) {
                      return false;
                    }
                  }
                  return true;
                });
              this.companySettingConfiguredIds =
                this.companySettingConfiguredIds.filter((val) => {
                  for (var k in this.configuredVariable) {
                    if (this.configuredVariable[k] == val) {
                      return false;
                    }
                  }
                  return true;
                });
              this.groupSettingConfiguredIds =
                this.groupSettingConfiguredIds.filter((val) => {
                  for (var k in this.configuredVariable) {
                    if (this.configuredVariable[k] == val) {
                      return false;
                    }
                  }
                  return true;
                });
              this.showPhone();
            });
        }
      }
    });
  }

  showFilter() {
    var x = document.getElementById("filter-options");
    if (x.style.display == "none" || x.style.display == "") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  }

  firstTime: boolean = false;

  allUnchecked() {
    // @ts-ignore
    if (
      // @ts-ignore
      !document.getElementById("group").checked &&
      // @ts-ignore
      !document.getElementById("client").checked &&
      // @ts-ignore
      !document.getElementById("company").checked &&
      // @ts-ignore
      !document.getElementById("changed").checked
    ) {
      return true;
    }
    return false;
  }

  toggleDisplay(input, Ids, name) {
    if (input.checked) {
      for (var i in Ids) {
        const item = document.getElementById(Ids[i]);
        item.style.display = "block";
        const card = item.parentElement.parentElement.parentElement;
        card.style.display = "block";
        card.classList.add(name);
      }
    } else {
      for (var i in Ids) {
        const item = document.getElementById(Ids[i]);
        item.style.display = "none";
        const card = item.parentElement.parentElement.parentElement;
        card.classList.remove(name);
        if (card.classList.length <= 4) {
          card.style.display = "none";
        }
      }
    }
  }

  settingFilter(name: string) {
    const input = document.getElementById(name);
    if (!this.firstTime) {
      this.firstTime = true;
      const cards = document.getElementsByClassName("setting-card");
      const items = document.getElementsByClassName("setting-item");
      for (var i in cards) {
        // @ts-ignore
        if (cards[i].style) {
          // @ts-ignore
          cards[i].style.display = "none";
        }
      }
      for (var i in items) {
        // @ts-ignore
        if (items[i].style) {
          // @ts-ignore
          items[i].style.display = "none";
        }
      }
    }

    if (!this.allUnchecked()) {
      switch (name) {
        case "group":
          this.toggleDisplay(input, this.groupSettingConfiguredIds, name);
          break;

        case "client":
          this.toggleDisplay(input, this.clientSettingsConfiguredIds, name);
          break;

        case "company":
          this.toggleDisplay(input, this.companySettingConfiguredIds, name);
          break;

        case "changed":
          this.toggleDisplay(input, this.configuredVariable, name);
          break;

        default:
          break;
      }
    } else {
      this.firstTime = false;
      const cards = document.getElementsByClassName("setting-card");
      const items = document.getElementsByClassName("setting-item");
      for (var i in cards) {
        // @ts-ignore
        if (cards[i].style) {
          // @ts-ignore
          cards[i].style.display = "block";
        }
      }
      for (var i in items) {
        // @ts-ignore
        if (items[i].style) {
          // @ts-ignore
          items[i].style.display = "block";
        }
      }
    }
  }

  search(value: any) {
    const pPattern = /^p[0-9]+/i;
    if (pPattern.test(value)) {
      const element = document.getElementById(value);
      if (element) {
      }
    }
  }

  showOptions = false;
  selectAll: boolean = true;

  toggleOption(option: any): void {
    if (this.selectedOptions.some((item) => item.item_id === option.item_id)) {
      this.selectedOptions = this.selectedOptions.filter(
        (o) => o.item_id !== option.item_id
      );
    } else {
      this.selectedOptions.push(option);
    }
    if (this.options.length === this.selectedOptions.length)
      this.selectAll = true;
    else {
      this.selectAll = false;
    }
    this.onChangeFilterData();
  }

  toggleDropdown(): void {
    this.showOptions = !this.showOptions;
  }

  checkSelectedOption(option: IOptions) {
    return this.selectedOptions.some((item) => item.item_id === option.item_id);
  }

  //  on select all and de select all
  onSelectAll(selectall: boolean) {
    this.selectAll = selectall;
    if (selectall) {
      this.device = JSON.parse(JSON.stringify(this.originalData));
      this.selectedOptions = this.options;
    } else {
      this.selectedOptions = [];
      this.device = [];
    }
  }

  // html class for drop down options
  getClass(option: IOptions): string {
    switch (option.item_id) {
      case "changedSetting":
        return "text-danger";
      case "inheritedClientSetting":
        return "text-pink";
      case "inheritedSetting":
        return "text-warning";
      case "inheritedGroupSetting":
        return "text-purple";
      default:
        return "text-muted";
    }
  }
}
