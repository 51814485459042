import { Component, OnInit, Inject, Optional } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ClientService } from "../services";
import Swal from "sweetalert2";
import { MoboSnackBar } from "../notification";

@Component({
  selector: "device-config-dialog",
  templateUrl: "./device-config-dialog.component.html",
  styleUrls: ["./device-config-dialog.component.scss"],
})
export class DeviceConfigDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<DeviceConfigDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder,
    private service: ClientService,
    private snackbar: MoboSnackBar
  ) {}

  form: FormGroup;
  formFields: {} = new Object();
  ngOnInit() {
    for (var i in this.data.values) {
      var settedValue = null;
      switch (this.data.for) {
        case "companySetting":
          for (var k in this.data.companyConfig) {
            if (
              this.data.companyConfig[k].variable ==
              this.data.values[i].setting_variable_name
            ) {
              settedValue = this.data.companyConfig[k].value;
              break;
            }
          }
          break;
        case "clientSetting":
          for (var c in this.data.config) {
            if (
              this.data.config[c].setting_variable ==
              this.data.values[i].setting_variable_name
            ) {
              settedValue = this.data.config[c].setting_value;
              break;
            }
          }
          if (!settedValue) {
            for (var c in this.data.companySettingConfig) {
              if (
                this.data.companySettingConfig[c].variable ==
                this.data.values[i].setting_variable_name
              ) {
                settedValue = this.data.companySettingConfig[c].value;
                break;
              }
            }
          }
          break;

        case "groupSetting":
          for (var c in this.data.config) {
            if (
              this.data.config[c].setting_variable_name ==
              this.data.values[i].setting_variable_name
            ) {
              settedValue = this.data.config[c].setting_variable_value;
              break;
            }
          }
          if (!settedValue) {
            for (var c in this.data.client_setting_config) {
              if (
                this.data.client_setting_config[c].setting_variable ==
                this.data.values[i].setting_variable_name
              ) {
                settedValue = this.data.client_setting_config[c].setting_value;
                break;
              }
            }
          }
          if (!settedValue) {
            for (var c in this.data.company_setting_config) {
              if (
                this.data.company_setting_config[c].variable ==
                this.data.values[i].setting_variable_name
              ) {
                settedValue = this.data.company_setting_config[c].value;
                break;
              }
            }
          }
          break;
        case "deviceSetting":
          for (var c in this.data.config) {
            if (
              this.data.config[c].setting_variable ==
              this.data.values[i].setting_variable_name
            ) {
              settedValue = this.data.config[c].setting_value;
              break;
            }
          }
          if (!settedValue) {
            for (var c in this.data.groupSettingConfig) {
              if (
                this.data.groupSettingConfig[c].setting_variable_name ==
                this.data.values[i].setting_variable_name
              ) {
                settedValue = this.data.groupSettingConfig[c].setting_variable_value;
                break;
              }
            }
          }
          if (!settedValue) {
            for (var c in this.data.clientSettingsConfig) {
              if (
                this.data.clientSettingsConfig[c].setting_variable ==
                this.data.values[i].setting_variable_name
              ) {
                settedValue = this.data.clientSettingsConfig[c].setting_value;
                break;
              }
            }
          }
          if (!settedValue) {
            for (var c in this.data.companySettingConfig) {
              if (
                this.data.companySettingConfig[c].variable ==
                this.data.values[i].setting_variable_name
              ) {
                settedValue = this.data.companySettingConfig[c].value;
                break;
              }
            }
          }
          break;

        default:
          break;
      }
      this.formFields[this.data.values[i].setting_variable_name] = [
        settedValue,
      ];
    }
    this.form = this.fb.group(this.formFields);
  }

  close() {
    this.dialogRef.close();
  }

  reset() {
    this.form.reset();
  }

  save() {
    switch (this.data.for) {
      case "companySetting":
        for (var k in this.form.value) {
          if (!this.form.value[k] && this.form.value[k] !== 0) {
            delete this.form.value[k];
          }
        }

        var fields = [];
        for (var k in this.form.controls) {
          fields.push(k);
        }
        for (var i in this.formFields) {
          if (fields.includes(i)) {
            if (this.formFields[i][0] == this.form.value[i]) {
              var have = false;
              for (var e in this.data.companyConfig) {
                if (this.data.companyConfig[e].variable == i) {
                  have = true;
                }
              }
              if (!have) {
                delete this.form.value[i];
              }
            }
          }
        }

        this.service
          .companySettingModify(
            this.data.companySettingId,
            this.form.value,
            fields
          )
          .subscribe((data: any) => {
            if (data) {
              if (data.ok) {
                this.dialogRef.close({
                  edited: true,
                });
                this.snackbar.success({
                  msg:
                    "Setting Edited Successfully. Please wait, Opening phone dialog...",
                });
              } else {
                Swal.fire("Oopsss...", "Something went worng", "error");
              }
            }
          });
        break;

        case "clientSetting":
        for (var k in this.form.value) {
          if (!this.form.value[k] && this.form.value[k] !== 0) {
            delete this.form.value[k];
          }
        }
        var fields = [];
        for (var k in this.form.controls) {
          fields.push(k);
        }
        for (var i in this.formFields) {
          if (fields.includes(i)) {
            if (this.formFields[i][0] == this.form.value[i]) {
              var have = false;
              for(var e in this.data.config){
                if(this.data.config[e].setting_variable == i){
                  have = true;
                }
              }
              if(!have){
                delete this.form.value[i];
              }
            }
          }
        }
        this.service
          .clientSettingModify(
            this.data.client_id,
            this.data.id,
            this.form.value,
            fields
          )
          .subscribe((data: any) => {
            if (data) {
              if (data.ok) {
                this.dialogRef.close({
                  edited: true
                });
                this.snackbar.success({
                  msg:
                    "Setting Edited Successfully. Please wait, Opening phone dialog...",
                });
              } else {
                Swal.fire("Oopsss...", "Something went worng", "error");
              }
            }
          });
        break;

        case "groupSetting":
        for (var k in this.form.value) {
          if (!this.form.value[k] && this.form.value[k] !== 0) {
            delete this.form.value[k];
          }
        }
        var fields = [];
        for (var k in this.form.controls) {
          fields.push(k);
        }

        for (var i in this.formFields) {
          if (fields.includes(i)) {
            if (this.formFields[i][0] == this.form.value[i]) {
              var have = false;
              for(var e in this.data.config){
                if(this.data.config[e].setting_variable_name == i){
                  have = true;
                }
              }
              if(!have){
                delete this.form.value[i];
              }
            }
          }
        }

        this.service
          .modifyGroupSetting(
            this.data.client_id,
            this.data.id,
            this.form.value,
            fields
          )
          .subscribe((data: any) => {
            if (data) {
              if (data.ok) {
                this.dialogRef.close({
                  edited: true
                });
                this.snackbar.success({
                  msg:
                    "Setting Edited Successfully. Please wait, Opening phone dialog...",
                });
              } else {
                Swal.fire("Oopsss...", "Something went worng", "error");
              }
            }
          });
        break;

        case "deviceSetting":
        for (var k in this.form.value) {
          if (!this.form.value[k] && this.form.value[k] !== 0) {
            delete this.form.value[k];
          }
        }
        var fields = [];
        for (var k in this.form.controls) {
          fields.push(k);
        }

        for (var i in this.formFields) {
          if (fields.includes(i)) {
            if (this.formFields[i][0] == this.form.value[i]) {
              var have = false;
              for(var e in this.data.config){
                if(this.data.config[e].setting_variable == i){
                  have = true;
                }
              }
              if(!have){
                delete this.form.value[i];
              }
            }
          }
        }

        this.service
          .modifyDeviceSetting(
            this.data.client_id,
            this.data.device_id,
            this.form.value,
            fields
          )
          .subscribe((data: any) => {
            if (data) {
              if (data.ok) {
                this.dialogRef.close({
                  edited: true
                });
                this.snackbar.success({
                  msg:
                    "Setting Edited Successfully. Please wait, Opening phone dialog...",
                });
              } else {
                Swal.fire("Oopsss...", "Something went worng", "error");
              }
            }
          });
        break;
    }
  }
}
