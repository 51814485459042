import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ClientDashboardComponent} from "./client-dashboard.component";
import { ClientComponent } from '../client.component';


const routes: Routes = [
  {
    path: 'client',
    component: ClientComponent,
       
    children: [
      {
          path: 'dashboard',
          component: ClientDashboardComponent
      },
    ]
  }
];

// @NgModule({
//     imports: [RouterModule.forChild([
//       {
//           path: 'client',
//           component: ClientComponent,
             
//       children: [
//           {
//               path: 'dashboard',
//               component: ClientDashboardComponent
//           },
//         ]
//       }
//     ]),
//   ],
//     exports: [RouterModule]
//   })
  
  @NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
export class ClientDashboardRoutingModule { }
