import { Component, OnInit, Optional, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormBuilder, Validators, FormControl } from "@angular/forms";
import { Store } from "@ngrx/store";
import { State } from "../../../../../reducers";
import { selectAllCompanySettings, CompanySettingLoad } from "../../../store";
import { Observable } from "rxjs";
import { MoboSnackBar } from "../../../notification";

@Component({
  selector: "edit-dialog",
  templateUrl: "./edit-dialog.component.html",
  styleUrls: ["./edit-dialog.component.scss"],
})
export class EditDialogComponent implements OnInit {
  companySettings$: Observable<any[]>;
  companySettings: any[];

  constructor(
    private dialogRef: MatDialogRef<EditDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
    private _formBuilder: FormBuilder,
    private store: Store<State>,
    private snackBar: MoboSnackBar
  ) {
    // select company settings from state
    this.companySettings$ = this.store.select(selectAllCompanySettings);
  }

  globalForm = this._formBuilder.group({
    name: [this.data.setting.name, [Validators.required, this.noWhitespaceValidator]],
    company_setting: [
      this.data.setting.inherit_from_company_settings,
      [Validators.required, this.noWhitespaceValidator],
    ],
  });

  groupForm = this._formBuilder.group({
    name: [this.data.setting.name, [Validators.required, this.noWhitespaceValidator]],
    group_id: [this.data.setting.group_id, [Validators.required, this.noWhitespaceValidator]],
    client_setting: [
      this.data.setting.inherit_from_client_settings,
      [Validators.required, this.noWhitespaceValidator],
    ],
  });

  public noWhitespaceValidator(control: FormControl) {
    console.log(control.value);
    
    const isWhitespace = (control.value || "").toString().trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { required: true };
  }

  ngOnInit() {
    switch (this.data.from) {
      case "global":
        this.store.dispatch(new CompanySettingLoad());
        this.companySettings$.subscribe((data: any[]) => {
          this.companySettings = data;
        });
        break;

      case "group":
        console.log(this.data);
        break;

      default:
        break;
    }
  }

  save() {
    switch (this.data.from) {
      case "global":
        if (this.globalForm.valid) {
          this.dialogRef.close({
            modified: true,
            index: this.data.index,
            value: this.globalForm.value,
          });
        } else {
          this.snackBar.error({ msg: "All fields are required" });
        }
        break;

      case "group":
        if (this.groupForm.valid) {
          this.dialogRef.close({
            modified: true,
            index: this.data.index,
            value: this.groupForm.value,
          });
        } else {
          this.snackBar.error({ msg: "All fields are required" });
        }
        break;

      default:
        break;
    }
  }
}
