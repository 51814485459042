import { Component, OnInit, Inject, Optional } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ClientService } from "../services";
import Swal from "sweetalert2";

@Component({
  selector: "edit-form",
  templateUrl: "./edit-form.component.html",
  styleUrls: ["./edit-form.component.scss"],
})
export class EditFormComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<EditFormComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
    private _formBuilder: FormBuilder,
    private service: ClientService
  ) {}

  editForm: FormGroup;
  formFields: {} = new Object();
  initialValues: any;
  allVariables: any = [];

  ngOnInit() {
    this.data.settings.values.forEach((val, key) => {
      switch (this.data.for) {
        case "companySetting":
          if (this.data.companySetting.config.length > 0) {
            this.data.companySetting.config.forEach((val1, key1) => {
              if (val1.variable == val.setting_variable_name) {
                return (this.formFields[val.setting_variable_name] = [
                  val1.value,
                ]);
              } else {
                if (!this.formFields[val.setting_variable_name]) {
                  this.formFields[val.setting_variable_name] = [];
                }
              }
            });
          } else {
            this.formFields[val.setting_variable_name] = [];
          }
          break;

        case "groupSetting":
          if (this.data.config.length > 0) {
            var i = true;
            this.data.config.forEach((val1, key1) => {
              if (val1.setting_variable_name == val.setting_variable_name) {
                i = false;
                return (this.formFields[val.setting_variable_name] = [
                  val1.setting_variable_value,
                ]);
              }
            });
            this.data.client_setting_config.forEach((val1) => {
              if (val1.setting_variable == val.setting_variable_name) {
                i = false;
                if (this.formFields[val.setting_variable_name] === undefined) {
                  return (this.formFields[val.setting_variable_name] = [
                    val1.setting_value,
                  ]);
                }
              }
            });
            if (i) {
              this.data.company_setting_config.forEach((val1) => {
                if (val1.variable == val.setting_variable_name) {
                  i = false;
                  return (this.formFields[val.setting_variable_name] = [
                    val1.value,
                  ]);
                }
              });
            }
            if (i) {
              this.formFields[val.setting_variable_name] = [];
            }
          } else {
            var i = true;
            this.data.client_setting_config.forEach((val1) => {
              if (val1.setting_variable == val.setting_variable_name) {
                i = false;
                return (this.formFields[val.setting_variable_name] = [
                  val1.setting_value,
                ]);
              }
            });
            if (i) {
              this.data.company_setting_config.forEach((val1) => {
                if (val1.variable == val.setting_variable_name) {
                  i = false;
                  return (this.formFields[val.setting_variable_name] = [
                    val1.value,
                  ]);
                }
              });
            }
            if (i) {
              this.formFields[val.setting_variable_name] = [];
            }
          }
          if (!this.formFields[val.setting_variable_name]) {
            this.formFields[val.setting_variable_name] = [];
          }

          break;

        case "deviceSetting":
          if (this.data.config.length > 0) {
            var i = true;

            this.data.config.forEach((val1, key1) => {
              if (val1.setting_variable == val.setting_variable_name) {
                i = false;
                return (this.formFields[val.setting_variable_name] = [
                  val1.setting_value,
                ]);
              }
            });
            this.data.groupSettingConfig.forEach((val1) => {
              if (val1.setting_variable_name == val.setting_variable_name) {
                i = false;
                if (this.formFields[val.setting_variable_name] === undefined) {
                  return (this.formFields[val.setting_variable_name] = [
                    val1.setting_variable_value,
                  ]);
                }
              }
            });
            if (i) {
              this.data.clientSettingsConfig.forEach((val1) => {
                if (val1.setting_variable == val.setting_variable_name) {
                  i = false;
                  return (this.formFields[val.setting_variable_name] = [
                    val1.setting_value,
                  ]);
                }
              });
            }
            if (i) {
              this.data.companySettingConfig.forEach((val1) => {
                if (val1.variable == val.setting_variable_name) {
                  i = false;
                  return (this.formFields[val.setting_variable_name] = [
                    val1.value,
                  ]);
                }
              });
            }
            if (i) {
              this.formFields[val.setting_variable_name] = [];
            }
          } else {
            var i = true;
            this.data.groupSettingConfig.forEach((val1) => {
              if (val1.setting_variable_name == val.setting_variable_name) {
                i = false;
                return (this.formFields[val.setting_variable_name] = [
                  val1.setting_variable_value,
                ]);
              }
            });
            if (i) {
              this.data.clientSettingsConfig.forEach((val1) => {
                if (val1.setting_variable == val.setting_variable_name) {
                  i = false;
                  return (this.formFields[val.setting_variable_name] = [
                    val1.setting_value,
                  ]);
                }
              });
            }
            if (i) {
              this.data.companySettingConfig.forEach((val1) => {
                if (val1.variable == val.setting_variable_name) {
                  i = false;
                  return (this.formFields[val.setting_variable_name] = [
                    val1.value,
                  ]);
                }
              });
            }
            if (i) {
              this.formFields[val.setting_variable_name] = [];
            }
          }
          if (!this.formFields[val.setting_variable_name]) {
            this.formFields[val.setting_variable_name] = [];
          }

          break;

        case "clientSetting":
          if (this.data.config.length > 0) {
            this.data.config.forEach((val1, key1) => {
              if (val1.setting_variable == val.setting_variable_name) {
                return (this.formFields[val.setting_variable_name] = [
                  val1.setting_value,
                ]);
              }
            });
          }
          this.data.companySettingConfig.forEach((val1) => {
            if (!this.formFields[val.setting_variable_name]) {
              if (val1.variable == val.setting_variable_name) {
                i = false;
                if (this.formFields[val.setting_variable_name] === undefined) {
                  return (this.formFields[val.setting_variable_name] = [
                    val1.value,
                  ]);
                }
              }
            }
          });

          if (!this.formFields[val.setting_variable_name]) {
            this.formFields[val.setting_variable_name] = [];
          }

          break;

        default:
          break;
      }
    });
    this.editForm = this._formBuilder.group(this.formFields);
  }

  edit() {
    switch (this.data.for) {
      case "companySetting":
        for (var k in this.editForm.value) {
          if (!this.editForm.value[k] && this.editForm.value[k] !== 0) {
            delete this.editForm.value[k];
          }
        }

        var fields = [];
        for (var k in this.editForm.controls) {
          fields.push(k);
        }
        for (var i in this.formFields) {
          if (fields.includes(i)) {
            if (this.formFields[i][0] == this.editForm.value[i]) {
              var have = false;
              for (var e in this.data.companySetting.config) {
                if (this.data.companySetting.config[e].variable == i) {
                  have = true;
                }
              }
              if (!have) {
                delete this.editForm.value[i];
              }
            }
          }
        }

        this.service
          .companySettingModify(
            this.data.companySetting._id,
            this.editForm.value,
            fields
          )
          .subscribe((data: any) => {
            if (data) {
              if (data.ok) {
                this.dialogRef.close({
                  edited: true,
                });
              } else {
                Swal.fire("Oopsss...", "Something went worng", "error");
              }
            }
          });
        break;

      case "groupSetting":
        for (var k in this.editForm.value) {
          if (!this.editForm.value[k] && this.editForm.value[k] !== 0) {
            delete this.editForm.value[k];
          }
        }
        var fields = [];
        for (var k in this.editForm.controls) {
          fields.push(k);
        }

        for (var i in this.formFields) {
          if (fields.includes(i)) {
            if (this.formFields[i][0] == this.editForm.value[i]) {
              var have = false;
              for (var e in this.data.config) {
                if (this.data.config[e].setting_variable_name == i) {
                  have = true;
                }
              }
              if (!have) {
                delete this.editForm.value[i];
              }
            }
          }
        }

        this.service
          .modifyGroupSetting(
            this.data.client_id,
            this.data.id,
            this.editForm.value,
            fields
          )
          .subscribe((data: any) => {
            if (data) {
              if (data.ok) {
                this.dialogRef.close({
                  edited: true,
                });
              } else {
                Swal.fire("Oopsss...", "Something went worng", "error");
              }
            }
          });
        break;

      case "deviceSetting":
        for (var k in this.editForm.value) {
          if (!this.editForm.value[k] && this.editForm.value[k] !== 0) {
            delete this.editForm.value[k];
          }
        }
        var fields = [];
        for (var k in this.editForm.controls) {
          fields.push(k);
        }

        for (var i in this.formFields) {
          if (fields.includes(i)) {
            if (this.formFields[i][0] == this.editForm.value[i]) {
              var have = false;
              for (var e in this.data.config) {
                if (this.data.config[e].setting_variable == i) {
                  have = true;
                }
              }
              if (!have) {
                delete this.editForm.value[i];
              }
            }
          }
        }

        this.service
          .modifyDeviceSetting(
            this.data.client_id,
            this.data.device_id,
            this.editForm.value,
            fields
          )
          .subscribe((data: any) => {
            if (data) {
              if (data.ok) {
                this.dialogRef.close({
                  edited: true,
                });
              } else {
                Swal.fire("Oopsss...", "Something went worng", "error");
              }
            }
          });
        break;

      case "clientSetting":
        for (var k in this.editForm.value) {
          if (!this.editForm.value[k] && this.editForm.value[k] !== 0) {
            delete this.editForm.value[k];
          }
        }
        var fields = [];
        for (var k in this.editForm.controls) {
          fields.push(k);
        }
        for (var i in this.formFields) {
          if (fields.includes(i)) {
            if (this.formFields[i][0] == this.editForm.value[i]) {
              var have = false;
              for (var e in this.data.config) {
                if (this.data.config[e].setting_variable == i) {
                  have = true;
                }
              }
              if (!have) {
                delete this.editForm.value[i];
              }
            }
          }
        }
        this.service
          .clientSettingModify(
            this.data.client_id,
            this.data.id,
            this.editForm.value,
            fields
          )
          .subscribe((data: any) => {
            if (data) {
              if (data.ok) {
                this.dialogRef.close({
                  edited: true,
                });
              } else {
                Swal.fire("Oopsss...", "Something went worng", "error");
              }
            }
          });
        break;

      default:
        Swal.fire("Oopsss...", "Something went worng", "error");
        break;
    }
  }

  reset() {
    switch (this.data.for) {
      case "companySetting":
        this.editForm.reset();
        break;

      case "groupSetting":
        this.editForm.reset();
        break;

      case "deviceSetting":
        this.editForm.reset();
        break;

      case "clientSetting":
        this.editForm.reset();
        break;

      default:
        break;
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
