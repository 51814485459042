import { NgModule} from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import {TranslateModule} from '@ngx-translate/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule, MatInputModule, MatButtonModule, MatAutocompleteModule, MatIconModule,MatTabsModule,MatTableModule, MatFormFieldModule, MatSelectModule, MatSlideToggleModule, MatCardModule, MatPaginatorModule,MatSortModule,MatCheckboxModule } from '@angular/material';
import { ProvisionerComponent } from "./provisioner.component";
import { AllCompanyListComponent } from './all-company-list/all-company-list.component';
import { CommonComponentModule } from "../../../common/commonComponent.module";

@NgModule({
    declarations: [
        ProvisionerComponent,
        AllCompanyListComponent
    ],

    imports: [
    BrowserModule,
        TranslateModule.forRoot(),
        FormsModule,
        MatStepperModule,
        MatInputModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatIconModule,
        MatTabsModule,
        MatTableModule,
        MatFormFieldModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatCardModule,
        MatPaginatorModule,
        MatSortModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        CommonComponentModule
    ],
    providers: [],
    bootstrap: [ProvisionerComponent],
    exports:[],
})

export class ProvisionerModule { }