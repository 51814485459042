import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator, MatTableDataSource, MatSort} from '@angular/material';

export interface pcapData {
  callDate: string;
  recording: string;
  system: string;
  callerId: string;
  outboundId: string;
  did: string;
  app: string;
  destination: string;
  disposition: string;
  duration: string;
  userfield: string;
  account: string;

}

const ELEMENT_DATA: pcapData[] = [
  {callDate:'22-12-2018',recording:'',system:'1545509077.163664',callerId:'1001',outboundId:'9729548001',did:'9729548001',app:'Congestion',destination:'9729548001',disposition:'Answered',duration:'0:12',userfield:'',account:''},
  {callDate:'14-04-2018',recording:'',system:'1545409855.164662',callerId:'97227330056',outboundId:'9729548001',did:'9729548002',app:'Dial',destination:'750',disposition:'No Answer',duration:'0:24',userfield:'',account:''},
  {callDate:'17-11-2018',recording:'',system:'1545109069.163559',callerId:'97217340145',outboundId:'9729548012',did:'9729548003',app:'Dial',destination:'9729548001',disposition:'Answered',duration:'0:12',userfield:'',account:''},
  {callDate:'17-11-2018',recording:'',system:'1545109069.163559',callerId:'97217340145',outboundId:'9729548012',did:'9729548003',app:'Dial',destination:'9729548001',disposition:'Answered',duration:'0:12',userfield:'',account:''},
  {callDate:'17-11-2018',recording:'',system:'1545109069.163559',callerId:'97217340145',outboundId:'9729548012',did:'9729548003',app:'Dial',destination:'9729548001',disposition:'Answered',duration:'0:12',userfield:'',account:''},
  {callDate:'14-04-2018',recording:'',system:'1545409855.164662',callerId:'97227330056',outboundId:'9729548001',did:'9729548002',app:'Dial',destination:'750',disposition:'No Answer',duration:'0:24',userfield:'',account:''},
  {callDate:'14-04-2018',recording:'',system:'1545409855.164662',callerId:'97227330056',outboundId:'9729548001',did:'9729548002',app:'Dial',destination:'750',disposition:'No Answer',duration:'0:24',userfield:'',account:''},
  {callDate:'22-12-2018',recording:'',system:'1545509077.163664',callerId:'1001',outboundId:'9729548001',did:'9729548001',app:'Congestion',destination:'9729548001',disposition:'Answered',duration:'0:12',userfield:'',account:''},
  {callDate:'14-04-2018',recording:'',system:'1545409855.164662',callerId:'97227330056',outboundId:'9729548001',did:'9729548002',app:'Dial',destination:'750',disposition:'No Answer',duration:'0:24',userfield:'',account:''},
  {callDate:'17-11-2018',recording:'',system:'1545109069.163559',callerId:'97217340145',outboundId:'9729548012',did:'9729548003',app:'Dial',destination:'9729548001',disposition:'Answered',duration:'0:12',userfield:'',account:''},
  {callDate:'22-12-2018',recording:'',system:'1545509077.163664',callerId:'1001',outboundId:'9729548001',did:'9729548001',app:'Congestion',destination:'9729548001',disposition:'Answered',duration:'0:12',userfield:'',account:''},
];
@Component({
  selector: 'app-pcap',
  templateUrl: './pcap.component.html',
  styleUrls: ['./pcap.component.scss']
})
export class PcapComponent implements OnInit {
 
  displayedColumns: string[] = ['call_date','recording','system','caller_id','outbound_id','did','app','destination','disposition','duration','userfield','account','action'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor() { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

}
