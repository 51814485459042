import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl  } from '@angular/forms';
import { Select2Option, Select2Data } from 'ng-select2-component';
import * as toastr from "toastr";

@Component({
  selector: 'app-trunk-setting-form',
  templateUrl: './trunk-setting-form.component.html',
  styleUrls: ['./trunk-setting-form.component.scss']
})
export class TrunkSettingFormComponent implements OnInit {
  hideShow: boolean = false;

  trunkSettingForm: FormGroup;
  submitted = false;


  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.trunkSettingForm = this.formBuilder.group({
      trunkName: ['',Validators.required],
      outboundCallerId: ['',Validators.required],
      maximumChannels: ['',Validators.required],
      asteriskOption: ['',Validators.required],
      outgoingTrunkName: ['', Validators.required],
      peerDetails: ['',Validators.required],
      userContext: ['',Validators.required],
      userDetails: ['',Validators.required],
      registerString: ['',Validators.required]
    })
  }

  get f() { return this.trunkSettingForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.trunkSettingForm.invalid) {
      toastr.error("Invalid Details !");
      return;
    }
    console.log(this.trunkSettingForm.value);

    toastr.success("Form Submitted Successfully !")
  }

}
