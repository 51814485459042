import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollTopButtonComponent } from './scroll-top-button/scroll-top-button.component';
import { MatIconModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    ScrollTopButtonComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    BrowserAnimationsModule
  ],
  exports: [
    ScrollTopButtonComponent
  ]
})
export class CommonComponentModule { }
