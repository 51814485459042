import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthService } from "../services/auth.service";
import { MoboSnackBar } from "../notification";
import { Router, ActivatedRoute } from "@angular/router";
import { readCookie } from "../services";

@Component({
  selector: "login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private snackBar: MoboSnackBar,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  loginForm: FormGroup;
  submitted = false;

  ngOnInit() {

    if (readCookie("authenticate")) {
      this.snackBar.warn({msg:"Already logged in!! Redirecting..."})
      this.route.queryParams.subscribe((params) => {
        if (params["return"]) {
          location.href = params["return"];
        } else {
          this.router.navigate(["/admin/provisioner/clients"]);
        }
      });
      return;
    }

    this.snackBar.error({
      msg: "Please login",
    });
    this.loginForm = this.fb.group({
      username: ["", [Validators.required]],
      password: ["", [Validators.required]],
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  login() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return this.loginForm.controls;
    }

    this.authService.login(this.loginForm.value).subscribe((data: any) => {
      if (data) {
        if (data.success) {
          this.snackBar.success({
            msg: data.msg,
          });
          localStorage.setItem('token', data.token);
          this.createCookie("authenticate", "true", (100*100));
          this.route.queryParams.subscribe((params) => {
            if (params["return"]) {
              location.href = params["return"];
            } else {
              this.router.navigate(["/admin/provisioner/clients"]);
            }
          });
        }
        if (!data.success) {
          this.snackBar.error({
            msg: data.msg,
          });
        }
      }
    });
  }

  createCookie(name, value, minutes) {
    if (minutes) {
      var date = new Date();
      date.setTime(date.getTime() + minutes * 60 * 1000);
      var expires = "; expires=" + date.toUTCString();
    } else {
      var expires = "";
    }
    document.cookie = name + "=" + value + expires + "; path=/";
    document.cookie = "sessionTime=" + date + expires + "; path=/";
  }
}
