import { Component, OnInit } from '@angular/core';
import { Select2Option, Select2Data } from 'ng-select2-component';

@Component({
  selector: 'app-general-stats',
  templateUrl: './general-stats.component.html',
  styleUrls: ['./general-stats.component.scss']
})
export class GeneralStatsComponent implements OnInit {
  // title = "";
  // type = 'ColumnChart';
  // data = [
  //   ['SUN',3],
  //   ['MON',23],
  //   ['TUE',12],
  //   ['WED',16],
  //   ['THU',8],
  //   ['FRI',12],
  //   ['SAT',3]
  // ];
  // columnNames = ['Week', 'Calls'];
  // options = {
  //   colors: ['#e77d4b'],
  //   legend: {
  //     position: 'none'
  //   },
  // };

  public barChartOptions = {
    layout: {
      padding: {
          left: 30,
          right: 30,
          top: 30,
          bottom: 30
      }
    },
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      xAxes: [{
        gridLines: {
          display: false,
          drawBorder: true,
        },
        ticks: {
          beginAtZero: true,
          display: true,
        },
        barPercentage: 1.0,
        categoryPercentage: 0.5,
      }],
      yAxes: [{
        gridLines: {
          display: false,
          drawBorder: true,
        },
        stacked: false,
        ticks: {
          beginAtZero: true,
          display: true,
        },
      }],
    },
    legend: {
      display: false
    }

  };
  public barChartLabels = [ "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  public barChartType = 'bar';
  public barChartLegend = true;
  public barChartData = [
    {
      data: [3, 23, 12, 16, 8, 12, 3],
      label: 'Calls',
    }
  ];
  public barChartColors:Array<any> = [
    {
      backgroundColor: 'rgba(231, 125, 75, 1)',
      borderColor: 'rgba(231, 125, 75, 1)',
      pointBackgroundColor: 'rgba(231, 125, 75, 1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(231, 125, 75, 1)',
    }
  ];

  public recording

  public data2 : Select2Data = [
    { value: 'Busiest Day', label: 'Busiest Day' },
    { value: 'Busiest Hour', label: 'Busiest Hour',}
  ];

  selectData: Select2Option[] = [];

  constructor() { }

  ngOnInit() {
  }
  update(value: string) {
    this.recording = value;
  }
  open() {
    this.selectData = JSON.parse(JSON.stringify(this.data2));
  }

  search(text: string) {
    this.selectData = text
      ? (JSON.parse(JSON.stringify(this.data2)) as Select2Option[])
        .filter(option => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1)
      : JSON.parse(JSON.stringify(this.data2));
  }


}
