import { Component, OnInit } from '@angular/core';
import { Chart, ChartType, ChartOptions } from 'chart.js';
@Component({
  selector: 'app-user-call-statistics',
  templateUrl: './user-call-statistics.component.html',
  styleUrls: ['./user-call-statistics.component.css','../user-dashboard.component.scss']
})
export class UserCallStatisticsComponent implements OnInit {
  
  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      xAxes: [{
        gridLines: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: true,
        },
        barPercentage: 1.0,
        categoryPercentage: 0.5,
      }],
      yAxes: [{
        gridLines: {
          display: false,
          drawBorder: false,
        },
        stacked: false,
        ticks: {
          beginAtZero: true,
          display: false,
        }
      }],
    },
    legend: {
      labels: {
        usePointStyle: true,
        boxWidth: 8
      },
      position: 'top',
    }

  };
  public barChartLabels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  public barChartType = 'bar';
  public barChartLegend = true;
  public barChartData = [
    {
      data: [5, 2.5, 3, 1, 3, 3.5, 4],
      label: 'Inbound Calls',
    },
    {
      data: [4, 2, 2.5, 2, 2, 4.5, 3],
      label: 'Outbound Calls',
    },
  ];
  public barChartColors:Array<any> = [
    {
      backgroundColor: 'rgba(100, 88, 174,1)',
      borderColor: 'rgba(100, 88, 174,1)',
      pointBackgroundColor: 'rgba(100, 88, 174,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(100, 88, 174,1)',
    },
    {
      backgroundColor: 'rgba(230, 124, 76,1)',
      borderColor: 'rgba(230, 124, 76,1)',
      pointBackgroundColor: 'rgba(230, 124, 76,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(230, 124, 76,1)',
    },
  ];

  constructor() { }

  ngOnInit() {
  }

}
