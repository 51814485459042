import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AdminComponent } from "../admin.component";
import { DashboardComponent } from "./dashboard.component";

const routes: Routes = [
  {
    path: 'admin',
    component: AdminComponent,
    data: {
      title: 'dashboard',
      status: false
    },
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent
      }
    ]
  }
];

// @NgModule({
//   imports: [RouterModule.forChild([
//     {
//       path: 'admin',
//       component: AdminComponent,
//       data: {
//         title: 'dashboard',
//         status: false
//       },
//       children: [
//         {
//           path: 'dashboard',
//           component: DashboardComponent
//         }
//       ]
//     }
//   ]),
// ],
//   exports: [RouterModule]
// })

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule {}
