import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl  } from '@angular/forms';
import { Select2Option, Select2Data } from 'ng-select2-component';
import * as toastr from "toastr";

@Component({
  selector: 'app-newpagegroup',
  templateUrl: './newpagegroup.component.html',
  styleUrls: ['./newpagegroup.component.scss']
})
export class NewpagegroupComponent implements OnInit {
  
  pagingForm: FormGroup;
  submitted = false;

  devListData = [
    {id:1, name:'Default'},
    {id:2, name:'Cisco 504G '},
    {id:3, name:'Polcyom 301x'},
    {id:4, name:'yealink 921'},
    {id:5, name:'ActXPhone'},
    {id:6, name:'Coccinella'},
    {id:3, name:'Ekiga'},
    {id:4, name:'IAXComm'},
    {id:5, name:'IMSDroid'},
    {id:6, name:'JsPhone'},
    {id:3, name:'KPhone'},
    {id:4, name:'Linphone'},
  ];

  announcementData = [
    {id:1, name:'Default'},
    {id:2, name:'Announcement 1'},
    {id:3, name:'Announcement 2'},
    {id:4, name:'Announcement 3'},
    {id:5, name:'Announcement 4'},
    {id:6, name:'Announcement 5'},
  ];

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.pagingForm = this.formBuilder.group({
      pageExtension: ['', [Validators.required, Validators.min(1)]],
      groupDescription: ['', [Validators.required, Validators.maxLength(30)]],
      deviceList: ['', Validators.required],
      announceMent: ['', Validators.required],
      pageAnnouncement: ['', Validators.required]
    });

    this.pagingForm.reset();
  }

  get f() { return this.pagingForm.controls; }

  onSubmit() {
      this.submitted = true;

      if (this.pagingForm.invalid) {
        toastr.error("Invalid Details !");
        return;
      }
      console.log(this.pagingForm.value);

      toastr.success("Form Submitted Successfully !")
  }
  
  
}
