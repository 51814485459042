import { Component, OnInit ,ViewChild} from '@angular/core';
import { Select2Option, Select2Data } from 'ng-select2-component';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { FormGroup } from '@angular/forms';
import {MatPaginator, MatTableDataSource} from '@angular/material';

const URL = 'https://evening-anchorage-3159.herokuapp.com/api/';

export interface PeriodicElement {
  file : string;
  format : string;

}

const ELEMENT_DATA: PeriodicElement[] = [
  {file: 'Wav-dontstopw', format : 'WAV'}
  
];
@Component({
  selector: 'app-add-music-on-hold',
  templateUrl: './add-music-on-hold.component.html',
  styleUrls: ['./add-music-on-hold.component.scss']
})
export class AddMusicOnHoldComponent implements OnInit {

  displayedColumns: string[] = [ 'file','format','play','action'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  musicOnHoldFormgroup : FormGroup

  public recording
  fileName: any;
  fullFileName: string;

  public data2 : Select2Data = [
    { value: 'AK', label: 'Alaska' },
    { value: 'HI', label: 'Hawaii',},
    { value: 'CA', label: 'California' },
    { value: 'NV', label: 'Nevada' },
    { value: 'OR', label: 'Oregon' },
    { value: 'WA', label: 'Washington' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'CO', label: 'Colorado' },
    { value: 'ID', label: 'Idaho' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'ND', label: 'North Dakota' }
  ]
  data: Select2Option[] = [];

  constructor() { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
  }

  update(value: string) {
    this.recording = value;
  }
  open() {
    this.data = JSON.parse(JSON.stringify(this.data2));
  }

  search(text: string) {
    this.data = text
      ? (JSON.parse(JSON.stringify(this.data2)) as Select2Option[])
        .filter(option => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1)
      : JSON.parse(JSON.stringify(this.data2));
  }

  uploadFile(files: File[]) {
    console.log(this.uploader.queue)
    // console.log('called')
    // const formData: FormData = new FormData();
    // if (files.length > 0) {
    //   const file: File = files[0];
    //   this.fullFileName = file.name;
    //   formData.append('file', file, file.name);
    // }
  }

  musicOnHoldForm(){
    console.log()
  }

  

  public uploader:FileUploader = new FileUploader({url: URL});

  public hasBaseDropZoneOver:boolean = false;
  public hasAnotherDropZoneOver:boolean = false;
 
  public fileOverBase(e:any):void {
    this.hasBaseDropZoneOver = e;
    console.log(this.uploader.queue)
  }
 
  public fileOverAnother(e:any):void {
    this.hasAnotherDropZoneOver = e;
    console.log(this.uploader.queue)
  }

  

}
