import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API } from "./constant";

@Injectable({
    providedIn: 'root'
})
export class ModuleListService {
    constructor(private http: HttpClient) { }
    getModules() {
        return this.http.get(API.GET_PARENT_MODULES + '?filter[where][parent]=0');
    }
}
