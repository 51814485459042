import { Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, MatTableDataSource} from '@angular/material';
import {Router} from "@angular/router";
export interface PeriodicElement {
  checked: boolean;
  extension: number;
  name: string;
  cw: boolean;
  dnd: boolean;
  fm: boolean;
  cf: boolean;
  cfb: boolean;
  cfu: boolean;
  type: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {checked: false ,extension:100, name: 'Virtual Assistant', cw: true, dnd: false, fm:false, cf:false, cfb:false,cfu:false,type:'sip'},
  {checked: false ,extension:1001, name: 'Prime Source', cw: true, dnd: false, fm:false, cf:false, cfb:false,cfu:false,type:'sip'},
  {checked: false ,extension:1002, name: 'Golden Chick', cw: true, dnd: false, fm:false, cf:false, cfb:false,cfu:false,type:'sip'},
  {checked: false ,extension:101, name: 'Hamed Cell Phone', cw: true, dnd: false, fm:false, cf:false, cfb:false,cfu:false,type:'sip'},
  {checked: false ,extension:103, name: 'Hamed AVS', cw: true, dnd: false, fm:false, cf:false, cfb:false,cfu:false,type:'sip'},
  {checked: false ,extension:101, name: 'Support Sam Home', cw: true, dnd: false, fm:false, cf:false, cfb:false,cfu:false,type:'sip'},
  {checked: false ,extension:105, name: 'Fanvile X6', cw: true, dnd: false, fm:false, cf:false, cfb:false,cfu:false,type:'sip'},
  {checked: false ,extension:106, name: 'Sam Fanvile', cw: true, dnd: false, fm:false, cf:false, cfb:false,cfu:false,type:'sip'},
  {checked: false ,extension:107, name: 'GXV-3275', cw: true, dnd: false, fm:false, cf:false, cfb:false,cfu:false,type:'sip'},
  {checked: false ,extension:108, name: 'GXV-3240', cw: true, dnd: false, fm:false, cf:false, cfb:false,cfu:false,type:'sip'},
];

@Component({
  selector: 'app-extensionusers',
  templateUrl: './extensionusers.component.html',
  styleUrls: ['./extensionusers.component.scss']
})
export class ExtensionusersComponent implements OnInit {

  displayedColumns: string[] = ['checked','extension', 'name', 'cw', 'dnd','action'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  constructor(public router: Router) { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
  }
  
  GoToUser(){
    this.router.navigateByUrl('/user')

  }

}
