import { NgModule} from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import {TranslateModule} from '@ngx-translate/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule, MatInputModule, MatButtonModule, MatAutocompleteModule, MatIconModule,MatTabsModule,MatTableModule, MatFormFieldModule, MatSelectModule, MatSlideToggleModule, MatCardModule, MatPaginatorModule,MatSortModule,MatCheckboxModule } from '@angular/material';
import { MiscDestinationComponent } from './misc-destination.component'
import { UiSwitchModule } from 'ngx-ui-switch';
import { Select2Module } from "ng-select2-component";
import { AllMiscDestinationComponent } from './all-misc-destination/all-misc-destination.component';
import { AddMiscDestinationComponent } from './add-misc-destination/add-misc-destination.component';
import { CommonComponentModule } from "../../../common/commonComponent.module";


@NgModule({
    declarations: [
      MiscDestinationComponent,
      AllMiscDestinationComponent,
      AddMiscDestinationComponent

    ],
    imports: [
        BrowserModule,
        TranslateModule.forRoot(),
        FormsModule,
        MatStepperModule,
        MatInputModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatIconModule,
        MatTabsModule,
        MatTableModule,
        MatFormFieldModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatCardModule,
        MatPaginatorModule,
        MatSortModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        UiSwitchModule,
        Select2Module,
        CommonComponentModule

        
    ],
    providers: [],
    bootstrap: [MiscDestinationComponent],
    exports:[],
})

export class MiscDestinationModule { }