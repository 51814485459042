import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API} from './constant';
import {BehaviorSubject, Observable, Subject} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ClientService {

  public updateClientEventListener = new BehaviorSubject(0);

  constructor(private http: HttpClient) {
  }

  getClientList(filter?) {
    return this.http.get(API.CLIENT_LIST + '?filter=' + JSON.stringify(filter));
  }

  getClientListOnMap() {
    return this.http.get(API.CLIENT_LIST_ON_MAP);
  }

  getParentClientListDropDown() {
    return this.http.get(API.CLIENT_PARENT_DROPDOWN);
  }

  createClient(request) {
    return this.http.post(API.CLIENT_ADD, request);
  }

  getClientDetail(clientId) {
    return this.http.get(API.CLIENT_DETAILS + clientId);
  }

  updateClient(clientId: any, clientDate: any) {
    return this.http.post(API.CLIENT_MODIFY + clientId, clientDate);
  }


  getClientDetailContactList(id, filter): Observable<any> {
    return this.http.get(API.CLIENT_DETAIL_CONTACT_LIST + id + '?filter=' + JSON.stringify(filter));
  }


  clientDropdown() {
    return this.http.get(API.CLIENT_DROPDOWN_LIST);
  }

  deleteClient(clientId) {
    return this.http.delete(API.CLIENT_DELETE + clientId);
  }


  getClientDetailCount(clientId) {
    return this.http.get(API.CLIENT_DETAIL_COUNT + '?clientId=' + clientId);
  }

  getClientModuleCount(clientId) {
    return this.http.get(API.CLIENT_DELETE_MODULE_COUNT + '?clientId=' + clientId);
  }

  getPaymentsList(clientId, filter?) {
    return this.http.get(API.GET_PAYMENTS + clientId+'?filter=' + JSON.stringify(filter));
  }
  getAgreementList(id, filter): Observable<any> {
    if (filter){
      return this.http.get(API.CLIENT_AGREEMENT + 'moduleId=client&id=' + id +'&filter=' + JSON.stringify(filter));
    } else {
      return this.http.get(API.CLIENT_AGREEMENT + 'moduleId=client&id=' + id);
    }
  }
  agreementDelete(id){
    return this.http.post(API.AGREEMENT_DELETE , {id: id});
  }

  getTransactionList(id, filter) {
    if (filter){
      return this.http.get(API.CLIENT_TRANSACTION_LIST + '?id=' + id +'&filter=' + JSON.stringify(filter));
    } else {
      return this.http.get(API.CLIENT_TRANSACTION_LIST + '?id=' + id);
    }
  }
  getRefundList(id, filter) {
    if (filter){
      return this.http.get(API.CLIENT_REFUND_LIST + '?id=' + id +'&filter=' + JSON.stringify(filter));
    } else {
      return this.http.get(API.CLIENT_REFUND_LIST + '?id=' + id);
    }
  }
  refundDelete(clientId, id) {
    return this.http.delete(API.CLIENT_REFUND_DELETE + '/' + clientId + '/' + id);
  }
  invoiceListByClient(action, clientId, refundId) {
    if (action === 'new'){
      return this.http.get(API.CLIENT_REFUND_INVOICE_LIST + '?action=' + action +'&clientId=' + clientId);
    } else {
      return this.http.get(API.CLIENT_REFUND_INVOICE_LIST + '?action=' + action +'&clientId=' + clientId + '&refundId=' + refundId);
    }
  }
  opportunityListByClientId(type, id) {
    return this.http.get(API.OPPORTUNITY_LIST_BY_CLIENT + '?type=' + type+ '&id=' + id);
  }
}
