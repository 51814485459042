import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator, MatTableDataSource, MatSort} from '@angular/material';

export interface PeriodicElement {
  extension: number;
  slots: String;
  name: String;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {extension:9997, slots:'9998-9999', name:'Default-Do Not Use'},
  {extension:85, slots:'86-89', name:'Farmers Ontario-85'},
  {extension:30, slots:'31-34', name:'Steward Financial-30'},
  {extension:35, slots:'36-39', name:'Early Law Firm/Steward-35'},
  {extension:25, slots:'26-29', name:'AwesomeAgency-25'},
  {extension:55, slots:'56-59', name:'Alsal-55'},
  {extension:60, slots:'61-64', name:'ADI-Medical-60'},
  {extension:95, slots:'96-99', name:'Daniel Sheehan Law-95'},
  {extension:75, slots:'76-79', name:'Gedco Drilling-775'},
  {extension:40, slots:'41-44', name:'Zolls NY Pizza'},
];

@Component({
  selector: 'app-pagingparkinglist',
  templateUrl: './pagingparkinglist.component.html',
  styleUrls: ['./pagingparkinglist.component.scss']
})
export class PagingparkinglistComponent implements OnInit {

  displayedColumns: string[] = ['extension','slots','name','action'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  constructor() { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

}
