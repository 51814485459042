import { NgModule} from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import {TranslateModule} from '@ngx-translate/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule, MatInputModule, MatButtonModule, MatAutocompleteModule, MatIconModule,MatTabsModule,MatTableModule, MatFormFieldModule, MatSelectModule, MatSlideToggleModule, MatCardModule, MatPaginatorModule,MatSortModule,MatCheckboxModule } from '@angular/material';
import {ReportsRoutingModule} from './reports-routing.module';
import { ReportsComponent } from './reports.component';
import { ClientReportsModule } from './client-reports/client-reports.module';
import { CallDetailsRecordModule } from './call-details-record/call-details-record.module';
import { ExtensionInfoAndSettingModule } from './extension-info-and-setting/extension-info-and-setting.module';
import { ConferenceModule } from './conference/conference.module';
import { QueueReportModule } from './queue-reports/queue-reports.module';
import { LiveCallMonitoringModule } from './live-call-monitoring/live-call-monitoring.module';
import { VoicemailComponent } from './voicemail/voicemail.component'
import { UiSwitchModule } from 'ngx-ui-switch';
@NgModule({
    declarations: [
        ReportsComponent,
        VoicemailComponent,
        
        
    ],
    imports: [
        BrowserModule,
        TranslateModule.forRoot(),
        FormsModule,
        MatStepperModule,
        MatInputModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatIconModule,
        MatTabsModule,
        MatTableModule,
        MatFormFieldModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatCardModule,
        MatPaginatorModule,
        MatSortModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        ReportsRoutingModule,
        ClientReportsModule,
        CallDetailsRecordModule,
        ExtensionInfoAndSettingModule,
        ConferenceModule,
        QueueReportModule,
        LiveCallMonitoringModule,
        UiSwitchModule
        

        
    ],
    providers: [],
    bootstrap: [ReportsComponent],
    exports:[],
})

export class ReportsModule { }