import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator, MatTableDataSource} from '@angular/material';


export interface PeriodicElement {
  featurecode : string;
  description : string;
  state : string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {featurecode :  '*283', description : 'FM', state: 'Day' },
  {featurecode : '*284 ', description : 'Plano', state: 'Day' }
  
];
@Component({
  selector: 'app-call-flow-toggle-control',
  templateUrl: './call-flow-toggle-control.component.html',
  styleUrls: ['./call-flow-toggle-control.component.scss']
})
export class CallFlowToggleControlComponent implements OnInit {

  displayedColumns: string[] = [ 'featurecode','description','state','action'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  constructor() { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
  }
  

}
