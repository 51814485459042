import {Component, Input, OnInit} from '@angular/core';
import {NavItem} from '../../../../service/Utils/Interfaces.class';

@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.css']
})
export class SubHeaderComponent implements OnInit {

  @Input() icon: string;
  @Input() title: string;
  now: number;

  constructor() {
    setInterval(() => {
      this.now = new Date().getTime();
    }, 1);
  }

  ngOnInit() {

  }

}
