import { LoginService } from './../../service/login.service';
import { element } from 'protractor';
import { SetupService } from './../../service/setup.service';
import { ModuleListService } from './../../service/module-list.service';
import { VendorService } from './../../service/vendor.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Select2Data, Select2Option } from 'ng-select2-component';
import * as toastr from 'toastr';
import { Router } from "@angular/router";
import { GooglePlaceModule, GooglePlaceDirective } from "ngx-google-places-autocomplete";
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { AddressComponent } from 'ngx-google-places-autocomplete/objects/addressComponent';
import { ComponentRestrictions } from 'ngx-google-places-autocomplete/objects/options/componentRestrictions';


@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.css']
})
export class WizardComponent implements OnInit {
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  isEditable = false;
  submitted = false;
  public moduleList: any;
  public modules: any;
  checkedListModule: any = [1, 2, 5, 6, 7, 39, 65, 76];
  isDisabledCheck: boolean = false;
  public configData: any;
  submit = false;
  checkCompany = false;
  country;
  city = '';
  state = '';
  zip = '';
  addressLine1 = '';
  address = '';

  get f() {
    return this.firstFormGroup.controls;
  }

  companyTypeList: Select2Data = [
    {
      'value': 'S Corp', 'label': 'S Corp'
    },
    {
      'value': 'C Corp', 'label': 'C Corp'
    },
    {
      'value': 'LLC', 'label': 'LLC'
    }
  ];
  industryList: Select2Data = [
    {
      'value': 'Legal Services', 'label':'Legal Services'
    },
    {
      'value': 'Professional Services', 'label':'Professional Services'
    },
    {
      'value': 'Medical, Dental, Health Services', 'label':'Medical, Dental, Health Services'
    },
    {
      'value': 'Construction - Electrician, Plumbers, HVAC, Security', 'label':'Construction - Electrician, Plumbers, HVAC, Security'
    },
    {
      'value': 'Information Technology (I.T.)', 'label':'Information Technology (I.T.)'
    },
    {
      'value': 'Product Based Company', 'label':'Product Based Company'
    },
    {
      'value': 'Service Based Company', 'label': 'Service Based Company'
    },
    {
      'value': 'Product & Service Based Company', 'label': 'Product & Service Based Company'
    }
  ];

  industryData: Select2Data = JSON.parse(JSON.stringify(this.industryList));
  companyData: Select2Data = JSON.parse(JSON.stringify(this.companyTypeList));


  constructor(private _formBuilder: FormBuilder, private vendorService: VendorService, private moduleListService: ModuleListService, private setupService: SetupService, private router: Router, private loginService: LoginService) { }

  ngOnInit() {
    var setupDone = localStorage.getItem('setup');
    if (setupDone == "1") {
      this.router.navigate(["/admin"], { replaceUrl: true });
    } else if (localStorage.getItem("userId") === null) {
      this.router.navigate(["/login"], { replaceUrl: true });
    }
    this.firstFormGroup = this._formBuilder.group({
      // companyName: ['', Validators.required],
      industry: ['', Validators.required],
      companyType: ['', Validators.required],
      fein: ['']

    });
    this.secondFormGroup = this._formBuilder.group({
      legalName: ['', Validators.required],
      address: ['', Validators.required],
      city: [''],
      // state: [''],
      country: [''],
      // zip: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      // phone: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      email: ['', Validators.email],
      website: ['']
    });
    this.thirdFormGroup = this._formBuilder.group({
      selectModules: []
    });
    this.getModuleList();
    
  }

  getModuleList(){
    this.moduleListService.getModules().subscribe(data => {
      var dataLength = Object.keys(data).length
      for (let index = 0; index < dataLength; index++) {
        const element = data[index];
        if (element.label == 'dashboard' || element.label == 'calendar' || element.label == 'client' || element.label == 'vendor' || element.label == 'tasks' || element.label == 'hr' || element.label == 'report' || element.label == 'utilities') {
          element.ischecked = 1;
          element.isDisabledCheck = true;
        } else {
          element.ischecked = 0;
          element.isDisabledCheck = false;
        }
      }
      this.moduleList = data;
    });
  }

  // Add new modules to subscribe module array
  onCheckboxChange(option, event) {
    if (event.checked) {
      this.checkedListModule.push(option.menuId);
    }
    else {
      for (var i = 0; i < this.moduleList.length; i++) {
        if (this.checkedListModule[i] == option.menuId) {
          this.checkedListModule.splice(i, 1);
        }
      }
    }
  }

  
  updateIndustry(){
    this.submit=false;
  }
  updateCompany(){
    this.checkCompany=false;
  }
  

  form1() {
    if (!this.firstFormGroup.value.industry){
      this.submit=true;
    }else{
      this.submit=false;
    }
    if (!this.firstFormGroup.value.companyType){
      this.checkCompany=true;
    } else {
      this.checkCompany=false;
    }
  }
  form2() {

  }
  form3() {
    if (this.firstFormGroup.invalid || this.secondFormGroup.invalid || this.thirdFormGroup.invalid) {
      return;
    }else{
      if (this.zip == '') {
        this.zip = '0';
      }
      this.configData = {
        industry: this.firstFormGroup.value.industry,
        companyType: this.firstFormGroup.value.companyType,
        taxId: this.firstFormGroup.value.fein,
        leaglName: this.secondFormGroup.value.legalName,
        address: this.secondFormGroup.value.address,
        city: this.city,
        country:this.secondFormGroup.value.country,
        state: this.state,
        zip: this.zip,
        email: this.secondFormGroup.value.email,
        website: this.secondFormGroup.value.website,
        modules: this.checkedListModule.toString()
      };
      this.setupService.setup(this.configData).subscribe(
        (res: any) => {
          if (res.result.info.code == 200) {
            localStorage.setItem('setup', '1');
            toastr.options.positionClass = "toast-bottom-right";
            toastr.success("Setup successful");
            this.router.navigate(["/admin"], { replaceUrl: true });
          }else{
            localStorage.setItem('setup', '0');
            toastr.options.positionClass = "toast-bottom-right";
            toastr.error("Something went wrong. Please try again.");

          }
        }
      );
    }
  }

  filterIndustry(text: string) {
    this.industryData = text
      ? (JSON.parse(JSON.stringify(this.industryList)) as Select2Option[])
        .filter(option => option.label.toLowerCase().indexOf
          (text.toLowerCase()) > -1)
      : JSON.parse(JSON.stringify(this.industryList));
  }
  filterCompany(text: string) {
    this.companyData = text
      ? (JSON.parse(JSON.stringify(this.companyTypeList)) as Select2Option[])
        .filter(option => option.label.toLowerCase().indexOf
          (text.toLowerCase()) > -1)
      : JSON.parse(JSON.stringify(this.companyTypeList));
  }

  @ViewChild("placesRef") placesRef: GooglePlaceDirective;

  public handleAddressChange(address: Address) {
    /* if (address.photos && address.photos.length > 0) {
      console.dir(address.photos[0].getUrl({ maxHeight: 500, maxWidth: 500 }));
    } */
    let x = this.getComponentByType(address, "street_number");
    let lng = address.geometry.location.lng();
    let lat = address.geometry.location.lat();
    this.city = '';
    this.state = '';
    this.zip = '';
    this.address = address.name;
    this.addressLine1 = ''

    address.address_components.forEach(element => {
      if(element.types[0]=='country'){
        this.country = element.long_name;
      }else if(element.types[0]=='locality'){
        this.city = element.long_name;
      } else if (element.types[0] =='administrative_area_level_1'){
        this.state = element.long_name;
      } else if (element.types[0] =='postal_code'){
        this.zip = element.long_name;
      } else{
        if (this.address == '') {
          this.address = element.long_name;
        }else{
          this.address = this.address+", "+element.long_name;
        }
      }
    });
    if (this.city) {
      this.addressLine1 = this.city;
      if (this.state) {
        this.addressLine1 = this.addressLine1 + ", " + this.state;
        if (this.zip) {
          this.addressLine1 = this.addressLine1 + ", " + this.zip;
        }
      }
    } else if(this.state) {
      this.addressLine1 = this.state;
      if (this.zip) {
        this.addressLine1 = this.addressLine1 + ", " + this.zip;
      }
    } else if (this.zip) {
      this.addressLine1 = this.zip;
    }
    this.secondFormGroup.patchValue({
      country: this.country,
      city: this.addressLine1,
      address: this.address
    });
  }

  public getComponentByType(address: Address, type: string): AddressComponent {
    if (!type)
      return null;

    if (!address || !address.address_components || address.address_components.length == 0)
      return null;

    type = type.toLowerCase();

    for (let comp of address.address_components) {
      if (!comp.types || comp.types.length == 0)
        continue;

      if (comp.types.findIndex(x => x.toLowerCase() == type) > -1)
        return comp;
    }

    return null;
  }

  logout() {
    this.loginService.logout().subscribe((res) => {
      localStorage.clear();
      window.location.reload();
    });
  }

}
