import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API} from './constant';
import {IResponse, LoggedUserInitialDetails} from './Utils/Interfaces.class';
import {Config} from './models/Config';

@Injectable({
  providedIn: 'root'
})

export class ConfigService {

  public checkoutPrivateKey2: string;
  public checkoutPublishableKey2: string;
  public checkoutSellerId2: string;
  public checkoutStatus2: string;
  public absentColor: string;
  public absentOnCalendar: string;
  public accountingSnapshot: string;
  public activeCronjob: string;
  public advanceSalaryEmail: string;
  public allowedFiles: string;
  public allowClientProject: string;
  public allowClientRegistration: string;
  public announcementsEmail: string;
  public asideCollapsed: string;
  public asideFloat: string;
  public assignedProjectEmail: string;
  public attendanceReport: string;
  public authorize: string;
  public authorizeStatus: string;
  public authorizeTransactionKey: string;
  public automaticDatabaseBackup: string;
  public automaticEmailOnRecur: string;
  public autoCloseTicket: string;
  public awardEmail: string;
  public bankCash: string;
  public bitcoinAddress: string;
  public bitcoinStatus: string;
  public braintreeDefaultAccount: string;
  public braintreeLiveOrSandbox: string;
  public braintreeMerchantId: string;
  public braintreePrivateKey: string;
  public braintreePublicKey: string;
  public braintreeStatus: string;
  public bugsColor: string;
  public bugsOnCalendar: string;
  public bugAssignedEmail: string;
  public bugAttachmentEmail: string;
  public bugCommentsEmail: string;
  public bugReportedEmail: string;
  public bugUpdatedEmail: string;
  public build: string;
  public ccavenueKey: string;
  public ccavenueMerchantId: string;
  public ccavenueStatus: string;
  public companyAddress: string;
  public companyCity: string;
  public companyCountry: string;
  public companyDomain: string;
  public companyEmail: string;
  public companyLegalName: string;
  public companyLogo: string;
  public companyName: string;
  public companyPhone: string;
  public companyPhone2: string;
  public companyVat: string;
  public companyZipCode: string;
  public completeProjectsEmail: string;
  public configAdditionalFlag: string;
  public configHost: string;
  public configImap: string;
  public configImapOrPop: string;
  public configMailbox: string;
  public configPassword: string;
  public configPop3: string;
  public configPort: string;
  public configSsl: string;
  public configUsername: string;
  public contactPerson: string;
  public contractExpirationReminder: string;
  public country: string;
  public cronKey: string;
  public currencyPosition: string;
  public dateFormat: string;
  public datePhpFormat: string;
  public datePickerFormat: string;
  public decimalSeparator: string;
  public defaultAccount: string;
  public defaultCurrency: string;
  public defaultCurrencySymbol: string;
  public defaultDepartment: string;
  public defaultLanguage: string;
  public defaultLeadsSource: string;
  public defaultLeadPermission: string;
  public defaultLeadStatus: string;
  public defaultPriority: string;
  public defaultStatus: string;
  public defaultTax: string;
  public defaultTerms: string;
  public deleteMailAfterImport: string;
  public demoMode: string;
  public developer: string;
  public displayEstimateBadge: string;
  public displayInvoiceBadge: string;
  public emailAccountDetails: string;
  public emailEstimateMessage: string;
  public emailInvoiceMessage: string;
  public emailStaffTickets: string;
  public enableLanguages: string;
  public estimateColor: string;
  public estimateEmail: string;
  public estimateFooter: string;
  public estimateLanguage: string;
  public estimateOnCalendar: string;
  public estimatePrefix: string;
  public estimateStartNo: string;
  public estimateTerms: string;
  public fileMaxSize: string;
  public forInvoice: string;
  public forLeads: string;
  public forTickets: string;
  public gcalApiKey: string;
  public gcalId: string;
  public goalAchieveEmail: string;
  public goalNotAchieveEmail: string;
  public goalTrackingColor: string;
  public goalTrackingOnCalendar: string;
  public holidayOnCalendar: string;
  public imapSearch: string;
  public imapSearchForLeads: string;
  public imapSearchForTickets: string;
  public incrementEstimateNumber: string;
  public incrementInvoiceNumber: string;
  public installed: string;
  public invoicesDueAfter: string;
  public invoiceCollectionEmail: string;
  public invoiceColor: string;
  public invoiceFooter: string;
  public invoiceFriendlyReminderEmail: string;
  public invoiceLanguage: string;
  public invoiceLogo: string;
  public invoiceMessageEmail: string;
  public invoiceOnCalendar: string;
  public invoiceOverdueEmail: string;
  public invoicePaymentEmail: string;
  public invoicePrefix: string;
  public invoiceRefundConfirmationEmail: string;
  public invoiceReminderEmail: string;
  public invoiceSecondReminderEmail: string;
  public invoiceStartNo: string;
  public jobCircularEmail: string;
  public language: string;
  public languages: string;
  public lastAutobackup: string;
  public lastCheck: string;
  public lastCronjobRun: string;
  public lastPostmasterRun: string;
  public lastSeenActivities: string;
  public layoutBoxed: string;
  public layoutFixed: string;
  public leadsKeyword: string;
  public leaveEmail: string;
  public locale: string;
  public loginBg: string;
  public logofile: string;
  public logoOrIcon: string;
  public messageReceivedEmail: string;
  public milestoneColor: string;
  public milestoneOnCalendar: string;
  public moneyFormat: string;
  public notifiedUser: string;
  public notifyBugAssignment: string;
  public notifyBugComments: string;
  public notifyBugStatus: string;
  public notifyMessageReceived: string;
  public notifyProjectAssignments: string;
  public notifyProjectComments: string;
  public notifyProjectFiles: string;
  public notifyTaskAssignments: string;
  public officeTime: string;
  public onLeaveColor: string;
  public onLeaveOnCalendar: string;
  public opportunitiesColor: string;
  public opportunitiesOnCalendar: string;
  public overtimeEmail: string;
  public paymentsColor: string;
  public paymentsOnCalendar: string;
  public paypalCancelUrl: string;
  public paypalEmail: string;
  public paypalIpnUrl: string;
  public paypalLive: string;
  public paypalStatus: string;
  public paypalSuccessUrl: string;
  public payslipEmail: string;
  public pdfEngine: string;
  public performanceEmail: string;
  public phoneFormat: string;
  public postmarkApiKey: string;
  public postmarkFromAddress: string;
  public projectAttachmentEmail: string;
  public projectClientNotificationEmail: string;
  public projectColor: string;
  public projectCommentsEmail: string;
  public projectOnCalendar: string;
  public projectPrefix: string;
  public projectResponsibleMilestoneEmail: string;
  public protocol: string;
  public purchaseCode: string;
  public quotationsFormEmail: string;
  public recurringInvoice: string;
  public reminderMessage: string;
  public resetKey: string;
  public rowsPerTable: string;
  public securityToken: string;
  public sendEmailWhenRecur: string;
  public settings: string;
  public setAfterReplyToTicket: string;
  public setAutoassignTicket: string;
  public setCustomerPriorityForTicket: string;
  public setCustomerResolveTicket: string;
  public setReopenTickets: string;
  public setTicketReplyForm: string;
  public setTicketReplyOrder: string;
  public setTicketReplyRatings: string;
  public showEstimateTax: string;
  public showInvoiceTax: string;
  public showItemTax: string;
  public showLoginImage: string;
  public showOnlyLogo: string;
  public sidebarTheme: string;
  public siteAppleicon: string;
  public siteAuthor: string;
  public siteDesc: string;
  public siteFavicon: string;
  public siteIcon: string;
  public smtpHost: string;
  public smtpPass: string;
  public smtpPort: string;
  public smtpUser: string;
  public stripePrivateKey: string;
  public stripePublicKey: string;
  public stripeStatus: string;
  public systemFont: string;
  public tasksAttachmentEmail: string;
  public tasksColor: string;
  public tasksCommentsEmail: string;
  public tasksOnCalendar: string;
  public tasksUpdatedEmail: string;
  public taskAssignedEmail: string;
  public thousandSeparator: string;
  public ticketsKeyword: string;
  public ticketClientEmail: string;
  public ticketClosedEmail: string;
  public ticketDefaultsNotifyCustomer: string;
  public ticketNotifyCustomerWhen: string;
  public ticketReplyEmail: string;
  public ticketSetting: string;
  public ticketStaffEmail: string;
  public timezone: string;
  public trainingEmail: string;
  public useGravatar: string;
  public usePostmark: string;
  public validLicense: string;
  public webmasterEmail: string;
  public websiteName: string;
  public poPrefix: string;
  public showPoTax: string;
  public poTerms: string;
  public companyTemplate: string;
  public googleMapKey: string;
  public pbxAddress: string;
  public actonAddress: string;
  public proposalPrefix: string;
  public proposalFirstReminder: string;
  public proposalSecondReminder: string;
  public invoiceFirstReminder: string;
  public invoiceSecondReminder: string;
  public invoiceThirdReminder: string;
  public proposalThirdReminder: string;
  public callsOnCalendar: string;
  public meetingsOnCalendar: string;
  public deadlineOnCalendar: string;
  public callsColor: string;
  public meetingsColor: string;
  public deadlineColor: string;
  public actonUsername: string;
  public actonPassword: string;
  public actonClientId: string;
  public actonClientSecretKey: string;
  public actonListType: string;
  public testEmailId: string;
  public actonClientListName: string;
  public actonLeadListName: string;
  public smtpEncription: string;
  public actonLeadListId: string;
  public actonClientListId: string;
  public estimatesDueAfter: string;
  public timeFormat: string;
  public tsysStatus: string;
  public tsysMid: string;
  public tsysUsername: string;
  public tsysPassword: string;

  public menuPathlist: string[];

  private configList: Config[];

  userAvatar: string;
  userDesignationsId: number;
  userDomain: string;
  userFullname: string;
  userIsOwner: number;
  userIsSubscriptionOver: number;
  userIsUBM: number;
  userIssetupdone: number;
  userLastLogin: string;
  userTheme: string;
  userType: number;
  userId: number;

  constructor(private http: HttpClient) {
    this.configList = new Array<Config>();
    this.menuPathlist = new Array<string>();
    this.initialValues();
  }

  updateUserDetails(data: any) {
    if (data.theme) {
      this.userTheme = data.theme;
    }
    if (data.isOwner) {
      this.userIsOwner = data.isOwner;
    }
    if (data.issetupdone) {
      this.userIssetupdone = data.issetupdone;
    }
    if (data.isUBM) {
      this.userIsUBM = data.isUBM;
    }
    if (data.domain) {
      this.userDomain = data.domain;
    }
    if (data.designationsId) {
      this.userDesignationsId = data.designationsId;
    }
    if (data.fullname) {
      this.userFullname = data.fullname;
    }
    if (data.isSubscriptionOver) {
      this.userIsSubscriptionOver = data.isSubscriptionOver;
    }
    if (data.lastLogin) {
      this.userLastLogin = data.lastLogin;
    }
    if (data.userType) {
      this.userType = data.userType;
    }
    if (data.userId) {
      this.userId = data.userId;
    }

  }


  updateConfigDetails(data: Config, self: any) {

    const convertedWord = this.camelize(data.configKey.replace(/_|-/g, ' '));

    if (typeof this[convertedWord] === 'undefined') {
      if (convertedWord === '2checkoutPrivateKey') {
        this.checkoutPrivateKey2 = data.value;
      } else if (convertedWord === '2checkoutPublishableKey') {
        this.checkoutPublishableKey2 = data.value;
      } else if (convertedWord === '2checkoutSellerId') {
        this.checkoutSellerId2 = data.value;
      } else if (convertedWord === '2checkoutStatus') {
        this.checkoutStatus2 = data.value;
      } else {
        // console.log(convertedWord);
        // console.log('false');
        this[convertedWord] = data.value;
      }
    } else {
      this[convertedWord] = data.value;
    }
  }


  camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (letter, index) {
      return index === 0 ? letter.toLowerCase() : letter.toUpperCase();
    }).replace(/\s+/g, '');
  }

  getAllConfigDetails() {
    this.http.get(API.LOGGED_USER_INITIAL_DATA).subscribe((result: any) => {
      // console.log(result);
      const res: IResponse = result.result;
      const self = this;
      const userDetails: LoggedUserInitialDetails = res.data;
      this.menuPathlist = userDetails.menuLinkList;
      userDetails.config.map((config: Config) => {
        const configT: Config = new Config().deserialize(config);
        this.configList.push(configT);
        this.updateConfigDetails(configT, self);
      });
      this.updateUserDetails(userDetails.userDetails);
      // console.log(this);
    });
  }

  clearValues() {
    this.configList = new Array<Config>();
    this.initialValues();
  }

  initialValues() {
    this.checkoutPrivateKey2 = '';
    this.checkoutPublishableKey2 = '';
    this.checkoutSellerId2 = '';
    this.checkoutStatus2 = '';
    this.absentColor = '';
    this.absentOnCalendar = '';
    this.accountingSnapshot = '';
    this.activeCronjob = '';
    this.advanceSalaryEmail = '';
    this.allowedFiles = '';
    this.allowClientProject = '';
    this.allowClientRegistration = '';
    this.announcementsEmail = '';
    this.asideCollapsed = '';
    this.asideFloat = '';
    this.assignedProjectEmail = '';
    this.attendanceReport = '';
    this.authorize = '';
    this.authorizeStatus = '';
    this.authorizeTransactionKey = '';
    this.automaticDatabaseBackup = '';
    this.automaticEmailOnRecur = '';
    this.autoCloseTicket = '';
    this.awardEmail = '';
    this.bankCash = '';
    this.bitcoinAddress = '';
    this.bitcoinStatus = '';
    this.braintreeDefaultAccount = '';
    this.braintreeLiveOrSandbox = '';
    this.braintreeMerchantId = '';
    this.braintreePrivateKey = '';
    this.braintreePublicKey = '';
    this.braintreeStatus = '';
    this.bugsColor = '';
    this.bugsOnCalendar = '';
    this.bugAssignedEmail = '';
    this.bugAttachmentEmail = '';
    this.bugCommentsEmail = '';
    this.bugReportedEmail = '';
    this.bugUpdatedEmail = '';
    this.build = '';
    this.ccavenueKey = '';
    this.ccavenueMerchantId = '';
    this.ccavenueStatus = '';
    this.companyAddress = '';
    this.companyCity = '';
    this.companyCountry = '';
    this.companyDomain = '';
    this.companyEmail = '';
    this.companyLegalName = '';
    this.companyLogo = '';
    this.companyName = '';
    this.companyPhone = '';
    this.companyPhone2 = '';
    this.companyVat = '';
    this.companyZipCode = '';
    this.completeProjectsEmail = '';
    this.configAdditionalFlag = '';
    this.configHost = '';
    this.configImap = '';
    this.configImapOrPop = '';
    this.configMailbox = '';
    this.configPassword = '';
    this.configPop3 = '';
    this.configPort = '';
    this.configSsl = '';
    this.configUsername = '';
    this.contactPerson = '';
    this.contractExpirationReminder = '';
    this.country = '';
    this.cronKey = '';
    this.currencyPosition = '';
    this.dateFormat = '';
    this.datePhpFormat = '';
    this.datePickerFormat = '';
    this.decimalSeparator = '';
    this.defaultAccount = '';
    this.defaultCurrency = '';
    this.defaultCurrencySymbol = '';
    this.defaultDepartment = '';
    this.defaultLanguage = '';
    this.defaultLeadsSource = '';
    this.defaultLeadPermission = '';
    this.defaultLeadStatus = '';
    this.defaultPriority = '';
    this.defaultStatus = '';
    this.defaultTax = '';
    this.defaultTerms = '';
    this.deleteMailAfterImport = '';
    this.demoMode = '';
    this.developer = '';
    this.displayEstimateBadge = '';
    this.displayInvoiceBadge = '';
    this.emailAccountDetails = '';
    this.emailEstimateMessage = '';
    this.emailInvoiceMessage = '';
    this.emailStaffTickets = '';
    this.enableLanguages = '';
    this.estimateColor = '';
    this.estimateEmail = '';
    this.estimateFooter = '';
    this.estimateLanguage = '';
    this.estimateOnCalendar = '';
    this.estimatePrefix = '';
    this.estimateStartNo = '';
    this.estimateTerms = '';
    this.fileMaxSize = '';
    this.forInvoice = '';
    this.forLeads = '';
    this.forTickets = '';
    this.gcalApiKey = '';
    this.gcalId = '';
    this.goalAchieveEmail = '';
    this.goalNotAchieveEmail = '';
    this.goalTrackingColor = '';
    this.goalTrackingOnCalendar = '';
    this.holidayOnCalendar = '';
    this.imapSearch = '';
    this.imapSearchForLeads = '';
    this.imapSearchForTickets = '';
    this.incrementEstimateNumber = '';
    this.incrementInvoiceNumber = '';
    this.installed = '';
    this.invoicesDueAfter = '';
    this.invoiceCollectionEmail = '';
    this.invoiceColor = '';
    this.invoiceFooter = '';
    this.invoiceFriendlyReminderEmail = '';
    this.invoiceLanguage = '';
    this.invoiceLogo = '';
    this.invoiceMessageEmail = '';
    this.invoiceOnCalendar = '';
    this.invoiceOverdueEmail = '';
    this.invoicePaymentEmail = '';
    this.invoicePrefix = '';
    this.invoiceRefundConfirmationEmail = '';
    this.invoiceReminderEmail = '';
    this.invoiceSecondReminderEmail = '';
    this.invoiceStartNo = '';
    this.jobCircularEmail = '';
    this.language = '';
    this.languages = '';
    this.lastAutobackup = '';
    this.lastCheck = '';
    this.lastCronjobRun = '';
    this.lastPostmasterRun = '';
    this.lastSeenActivities = '';
    this.layoutBoxed = '';
    this.layoutFixed = '';
    this.leadsKeyword = '';
    this.leaveEmail = '';
    this.locale = '';
    this.loginBg = '';
    this.logofile = '';
    this.logoOrIcon = '';
    this.messageReceivedEmail = '';
    this.milestoneColor = '';
    this.milestoneOnCalendar = '';
    this.moneyFormat = '';
    this.notifiedUser = '';
    this.notifyBugAssignment = '';
    this.notifyBugComments = '';
    this.notifyBugStatus = '';
    this.notifyMessageReceived = '';
    this.notifyProjectAssignments = '';
    this.notifyProjectComments = '';
    this.notifyProjectFiles = '';
    this.notifyTaskAssignments = '';
    this.officeTime = '';
    this.onLeaveColor = '';
    this.onLeaveOnCalendar = '';
    this.opportunitiesColor = '';
    this.opportunitiesOnCalendar = '';
    this.overtimeEmail = '';
    this.paymentsColor = '';
    this.paymentsOnCalendar = '';
    this.paypalCancelUrl = '';
    this.paypalEmail = '';
    this.paypalIpnUrl = '';
    this.paypalLive = '';
    this.paypalStatus = '';
    this.paypalSuccessUrl = '';
    this.payslipEmail = '';
    this.pdfEngine = '';
    this.performanceEmail = '';
    this.phoneFormat = '';
    this.postmarkApiKey = '';
    this.postmarkFromAddress = '';
    this.projectAttachmentEmail = '';
    this.projectClientNotificationEmail = '';
    this.projectColor = '';
    this.projectCommentsEmail = '';
    this.projectOnCalendar = '';
    this.projectPrefix = '';
    this.projectResponsibleMilestoneEmail = '';
    this.protocol = '';
    this.purchaseCode = '';
    this.quotationsFormEmail = '';
    this.recurringInvoice = '';
    this.reminderMessage = '';
    this.resetKey = '';
    this.rowsPerTable = '';
    this.securityToken = '';
    this.sendEmailWhenRecur = '';
    this.settings = '';
    this.setAfterReplyToTicket = '';
    this.setAutoassignTicket = '';
    this.setCustomerPriorityForTicket = '';
    this.setCustomerResolveTicket = '';
    this.setReopenTickets = '';
    this.setTicketReplyForm = '';
    this.setTicketReplyOrder = '';
    this.setTicketReplyRatings = '';
    this.showEstimateTax = '';
    this.showInvoiceTax = '';
    this.showItemTax = '';
    this.showLoginImage = '';
    this.showOnlyLogo = '';
    this.sidebarTheme = '';
    this.siteAppleicon = '';
    this.siteAuthor = '';
    this.siteDesc = '';
    this.siteFavicon = '';
    this.siteIcon = '';
    this.smtpHost = '';
    this.smtpPass = '';
    this.smtpPort = '';
    this.smtpUser = '';
    this.stripePrivateKey = '';
    this.stripePublicKey = '';
    this.stripeStatus = '';
    this.systemFont = '';
    this.tasksAttachmentEmail = '';
    this.tasksColor = '';
    this.tasksCommentsEmail = '';
    this.tasksOnCalendar = '';
    this.tasksUpdatedEmail = '';
    this.taskAssignedEmail = '';
    this.thousandSeparator = '';
    this.ticketsKeyword = '';
    this.ticketClientEmail = '';
    this.ticketClosedEmail = '';
    this.ticketDefaultsNotifyCustomer = '';
    this.ticketNotifyCustomerWhen = '';
    this.ticketReplyEmail = '';
    this.ticketSetting = '';
    this.ticketStaffEmail = '';
    this.timezone = '';
    this.trainingEmail = '';
    this.useGravatar = '';
    this.usePostmark = '';
    this.validLicense = '';
    this.webmasterEmail = '';
    this.websiteName = '';
    this.poPrefix = '';
    this.showPoTax = '';
    this.poTerms = '';
    this.companyTemplate = '';
    this.googleMapKey = '';
    this.pbxAddress = '';
    this.actonAddress = '';
    this.proposalPrefix = '';
    this.proposalFirstReminder = '';
    this.proposalSecondReminder = '';
    this.invoiceFirstReminder = '';
    this.invoiceSecondReminder = '';
    this.invoiceThirdReminder = '';
    this.proposalThirdReminder = '';
    this.callsOnCalendar = '';
    this.meetingsOnCalendar = '';
    this.deadlineOnCalendar = '';
    this.callsColor = '';
    this.meetingsColor = '';
    this.deadlineColor = '';
    this.actonUsername = '';
    this.actonPassword = '';
    this.actonClientId = '';
    this.actonClientSecretKey = '';
    this.actonListType = '';
    this.testEmailId = '';
    this.actonClientListName = '';
    this.actonLeadListName = '';
    this.smtpEncription = '';
    this.actonLeadListId = '';
    this.actonClientListId = '';
    this.estimatesDueAfter = '';
    this.timeFormat = '';
    this.tsysStatus = '';
    this.tsysUsername = '';
    this.tsysMid = '';
    this.tsysPassword = '';

    this.menuPathlist = new Array<string>();

    this.userAvatar = '';
    this.userDesignationsId = 0;
    this.userDomain = '';
    this.userFullname = '';
    this.userIsOwner = 0;
    this.userIsSubscriptionOver = 0;
    this.userIsUBM = 0;
    this.userIssetupdone = 0;
    this.userLastLogin = '';
    this.userTheme = '';
    this.userType = 0;
    this.userId = 0;
  }

}
