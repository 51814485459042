import { Component, OnInit, ViewChild } from '@angular/core';
import { RestService } from '../../../rest.service';
import { ActivatedRoute, Router } from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {State} from '../../../service/models/state';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { Paginator } from 'primeng/primeng';
import {AddressComponent} from 'ngx-google-places-autocomplete/objects/addressComponent';
// import {Address} from 'ngx-google-places-autocomplete/objects/address';
 import {GooglePlaceDirective} from 'ngx-google-places-autocomplete';
 import { Select2Option, Select2Data } from 'ng-select2-component';
import { ClientGlobalSetting, ClientGroupSetting, ExtensionInfo } from './addclienttabledata';



 export interface Food {
  value: string;
  viewValue: string;
 }


const Clientglobaldata: ClientGlobalSetting[] = [{name: '2130', devicemodel: 'Grandstream GXP2130', baseconfig: 'Vivant 2130', },
                                                  {name: '2160', devicemodel: 'Grandstream GXP2160', baseconfig: 'Vivant 2160', }];


const clientgroupdata : ClientGroupSetting[] = [{name: 'Support 2130', devicemodel: 'Grandstream GXP2130', baseconfig: '2130' },
                                                {name: 'Accounting 2130', devicemodel: 'Grandstream GXP2130', baseconfig: '2130' },
                                                {name: 'Salse 2160', devicemodel: 'Grandstream GXP2160', baseconfig: '2160'},
                                                {name: 'Accounting 2160', devicemodel: 'Grandstream GXP2160', baseconfig: '2160'}
                                              ] 


@Component({
  selector: 'app-addclient',
  templateUrl: './addclient.component.html',
  styleUrls: ['./addclient.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {showError: true}
  }]
})
export class AddclientComponent implements OnInit {


  myModel = false;
  myModel1 = false;

  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  fifthFormGroup: FormGroup;
  sixthFormGroup: FormGroup;

  groupData = [
    {id:1, name:'Default'},
    {id:2, name:'Group 1'},
    {id:3, name:'Group 2'},
    {id:4, name:'Group 3'},
    {id:5, name:'Group 4'},
    {id:6, name:'Group 5'},
  ];

  groupSettingData = [
    {id:1, name:'Default'},
    {id:2, name:'Setting 1'},
    {id:3, name:'Setting 2'},
    {id:4, name:'Setting 3'},
    {id:5, name:'Setting 4'},
    {id:6, name:'Setting 5'},
  ];
  companySettingData = [
    {id:1, name:'Default'},
    {id:2, name:'Setting 1'},
    {id:3, name:'Setting 2'},
    {id:4, name:'Setting 3'},
    {id:5, name:'Setting 4'},
    {id:6, name:'Setting 5'},
  ];

  clientSettingData = [
    {id:1, name:'Default'},
    {id:2, name:'Setting 1'},
    {id:3, name:'Setting 2'},
    {id:4, name:'Setting 3'},
    {id:5, name:'Setting 4'},
    {id:6, name:'Setting 5'},
  ];

  ringGroupData = [
    {id:1, name:'Default'},
    {id:2, name:'300'},
    {id:3, name:'401'},
    {id:4, name:'402'},
    {id:5, name:'403'},
  ];

  extListData = [
    {id:1, name:'Default'},
    {id:2, name:'300'},
    {id:3, name:'401'},
    {id:4, name:'402'},
    {id:5, name:'403'},
  ];

  destinationData = [
    {id:1, name:'Default'},
    {id:2, name:'Destination 1'},
    {id:3, name:'Destination 2'},
    {id:4, name:'Destination 3'},
  ]

  @ViewChild('myStep1') myStep1;
  @ViewChild('myStep2') myStep2;
  @ViewChild('myStep3') myStep3;
  @ViewChild('myStep4') myStep4;
  @ViewChild('myStep5') myStep5;
  @ViewChild('myStep6') myStep6;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatPaginator) paginator1: MatPaginator;
  @ViewChild(MatSort) sort1: MatSort;

  @ViewChild('placesRef') placesRef: GooglePlaceDirective;
  country;
  city = '';
  state = '';
  zip = '';
  addressLine1 = '';
  address = '';
  billingZip = '';
  clientcountry = ''
  clientcity = ''
  clientstate = ''
  clientzip = ''
  clientaddressLine = ''
  clientaddress = ''
  contactpersoncountry = ''
  contactpersoncity = ''
  contactpersonstate = ''
  contactpersonzip = ''
  contactpersonaddressLine = ''
  contactpersonaddress = ''
  sameAsClientaddress : boolean = true
  recording1 : any
  voicemaitomail : boolean = false
  vaoicemailpassword : boolean = false

  public extensionlist : ExtensionInfo[]

  displayedColumns: string[] = ['name','username','email','id','action'];
  data:any = [];
  listdata: MatTableDataSource<any>;



  foods: Food[] = [
    {value: 'steak-0', viewValue: 'Steak'},
    {value: 'pizza-1', viewValue: 'Pizza'},
    {value: 'tacos-2', viewValue: 'Tacos'}
  ];
  
  applyFilter(filterValue: string) {
    this.listdata.filter = filterValue.trim().toLowerCase();
  }

  applyFilterglobalsetting(filterValue: string) {
    this.ClientGlobalSettingdataSource.filter = filterValue.trim().toLowerCase();
  }
  applyFiltergroupsetting(filterValue: string) {
    this.clientgroupsettingdataSource.filter = filterValue.trim().toLowerCase();
  }
  ColumnsClientGlobalSetting: string[] = ['Name', 'Device Model', 'Base Config'];
  ClientGlobalSettingdataSource = new MatTableDataSource<ClientGlobalSetting>(Clientglobaldata);

  clientgroupsettingdataSource = new MatTableDataSource<ClientGroupSetting>(clientgroupdata);
  Columnsclientgroupsetting : string[] = ['Name', 'Device Model', 'Base Config'];

  constructor(public rest:RestService, private route: ActivatedRoute, private router: Router, private _formBuilder: FormBuilder) { }

  ngOnInit() {

    this.extensionlist = [{extensionNumber : 101,extensionName : 'Vivant', voicmailEnable : false,
                          voicemailToEmail : false, voicemailaddress : 'abc@gmail.com',voicemailPassword : 'abc',
                          authenticationpassword : 'abc',
                          macAddress : 'abcd sad'
    }]
 
    this.ClientGlobalSettingdataSource.paginator = this.paginator;
    this.ClientGlobalSettingdataSource.sort = this.sort;

    this.clientgroupsettingdataSource.paginator = this.paginator1;
    this.clientgroupsettingdataSource.sort = this.sort1;
    

    this.firstFormGroup = this._formBuilder.group({
      clientname : ['', Validators.required],
      clientemail : ['', Validators.email],
      clientphoneno : ['', Validators.pattern('^[0-9\-\(\)]*$')],
      clientaddress : ['', Validators.required],
      clientcityStatezip : ['', Validators.required],
      clientCountry : ['', Validators.required],
      contactpersonname : ['', Validators.required],
      contactpersonemail : ['', Validators.email,],
      contactpersonphone : ['', Validators.pattern('^[0-9\-\(\)]*$')],
      contactpersonaddress : ['', Validators.required],
      contactpersoncityStatezip : ['', Validators.required],
      contactpersonCountry : ['', Validators.required],
      billingaddress : ['',],
      billingcountry : ['',],
      billingcityStateZip : ['',],
      billingzip : ['',],
      sameAsClientaddress : ['']
    });
    
    this.secondFormGroup = this._formBuilder.group({
      contactpersonnumber : ['', Validators.required],
      contactpersonquantity : ['', Validators.required],
      settingname : ['', Validators.required],
      settingdevicemodel : ['', Validators.required],
      settingdevicetype : ['', Validators.required]
    });
    
    // this.thirdFormGroup=this._formBuilder.group({
    //   extensionnumber : ['', Validators.required],
    //   extensionquantity : ['', Validators.required],
    //   extensionemail : ['', Validators.email],
    //   authenticationpassword : [''],
    //   macaddress : [''],
    //   voicemailpassword : ['']
    // });
    this.fourthFormGroup=this._formBuilder.group({
      ringgroupnumber : ['', Validators.required],
      ringgroupname : ['', Validators.required],
      ringgroupdescription : ['', Validators.required],
      ringgroupextension : [''],
      ringtime : ['', Validators.required],
      ringgroupdestination : ['']

    });
    this.fifthFormGroup=this._formBuilder.group({
      parkinglotextension : ['', Validators.required],
      parkingname : ['', Validators.required],
      parkingdescription : ['', Validators.required],
      parkingtimeout : ['', Validators.required],
      parkingcomeback : ['', Validators.required],
      parkingextensionlist : [''],
    });
    this.sixthFormGroup=this._formBuilder.group({
      routedidnumber : ['', Validators.required],
      routename : ['', Validators.required],
      routediscription : ['', Validators.required],
      routedestination : ['']
    })
    this.firstFormGroup.reset();
    this.secondFormGroup.reset();
    this.fourthFormGroup.reset();
    this.fifthFormGroup.reset();
    this.sixthFormGroup.reset();
  }
  
  public handleAddressChange(address: Address) {
    let lng = address.geometry.location.lng();
    let lat = address.geometry.location.lat();
    this.city = '';
    this.state = '';
    this.billingZip = '';
    this.address = address.name;
    this.addressLine1 = '';

    address.address_components.forEach(element => {
      if (element.types[0] == 'country') {
        this.country = element.long_name;
      } else if (element.types[0] == 'locality') {
        this.city = element.long_name;
      } else if (element.types[0] == 'administrative_area_level_1') {
        this.state = element.long_name;
      } else if (element.types[0] == 'postal_code') {
        this.billingZip = element.long_name;
      } else {
        if (this.address == '') {
          this.address = element.long_name + this.zip;
        } else {
          this.address = this.address + ', ' + element.long_name + this.zip;
        }
      }
    });
    if (this.city) {
      this.addressLine1 = this.city;
      if (this.state) {
        this.addressLine1 = this.addressLine1 + ', ' + this.state;
        if (this.billingZip) {
          this.addressLine1 = this.addressLine1 + ', ' + this.billingZip;
        }
      }
    } else if (this.state) {
      this.addressLine1 = this.state;
      if (this.billingZip) {
        this.addressLine1 = this.addressLine1 + ', ' + this.billingZip;
      }
    } else if (this.billingZip) {
      this.addressLine1 = this.billingZip;
    }
    console.log(this.city,this.state,this.billingZip,this.addressLine1)
    this.firstFormGroup.patchValue({
      billingcountry: this.country,
      billingCity: this.city,
      billingaddress: this.address,
      billingzip: this.billingZip,
      billingcityStateZip: this.addressLine1,
      billingstate: this.state,
    });
  }

  public handleAddressChange2(address: Address) {
    let lng = address.geometry.location.lng();
    let lat = address.geometry.location.lat();
    this.clientcity = '';
    this.clientstate = '';
    this.clientzip = '';
    this.clientaddress = address.name;
    this.clientaddressLine = '';

    address.address_components.forEach(element => {
      if (element.types[0] == 'country') {
        this.clientcountry = element.long_name;
      } else if (element.types[0] == 'locality') {
        this.clientcity = element.long_name;
      } else if (element.types[0] == 'administrative_area_level_1') {
        this.clientstate = element.long_name;
      } else if (element.types[0] == 'postal_code') {
        this.clientzip = element.long_name;
      } else {
        if (this.clientaddress == '') {
          this.clientaddress = element.long_name + this.clientzip;
        } else {
          this.clientaddress = this.clientaddress + ', ' + element.long_name + this.clientzip;
        }
      }
    });
    if (this.clientcity) {
      this.clientaddressLine = this.clientcity;
      if (this.clientstate) {
        this.clientaddressLine = this.clientaddressLine + ', ' + this.clientstate;
        if (this.clientzip) {
          this.clientaddressLine = this.clientaddressLine + ', ' + this.clientzip;
        }
      }
    } else if (this.clientstate) {
      this.clientaddressLine = this.clientstate;
      if (this.clientzip) {
        this.clientaddressLine = this.clientaddressLine + ', ' + this.clientzip;
      }
    } else if (this.clientzip) {
      this.clientaddressLine = this.clientzip;
    }
    console.log(this.clientcity,this.clientstate,this.clientzip,this.clientaddressLine)
    this.firstFormGroup.patchValue({
      clientaddress: this.clientaddress,
      clientcityStatezip : this.clientaddressLine,
      clientCountry : this.clientcountry
    });
  }

  public handleAddressChange1(address: Address) {
    let lng = address.geometry.location.lng();
    let lat = address.geometry.location.lat();
    this.contactpersoncity = '';
    this.contactpersonstate = '';
    this.contactpersonzip = '';
    this.contactpersonaddress = address.name;
    this.contactpersonaddressLine = '';

    address.address_components.forEach(element => {
      if (element.types[0] == 'country') {
        this.contactpersoncountry = element.long_name;
      } else if (element.types[0] == 'locality') {
        this.contactpersoncity = element.long_name;
      } else if (element.types[0] == 'administrative_area_level_1') {
        this.contactpersonstate = element.long_name;
      } else if (element.types[0] == 'postal_code') {
        this.contactpersonzip = element.long_name;
      } else {
        if (this.contactpersonaddress == '') {
          this.contactpersonaddress = element.long_name + this.contactpersonzip;
        } else {
          this.contactpersonaddress = this.contactpersonaddress + ', ' + element.long_name + this.contactpersonzip;
        }
      }
    });
    if (this.contactpersoncity) {
      this.contactpersonaddressLine = this.contactpersoncity;
      if (this.contactpersonstate) {
        this.contactpersonaddressLine = this.contactpersonaddressLine + ', ' + this.contactpersonstate;
        if (this.contactpersonzip) {
          this.contactpersonaddressLine = this.contactpersonaddressLine + ', ' + this.contactpersonzip;
        }
      }
    } else if (this.contactpersonstate) {
      this.contactpersonaddressLine = this.contactpersonstate;
      if (this.contactpersonzip) {
        this.contactpersonaddressLine = this.contactpersonaddressLine + ', ' + this.contactpersonzip;
      }
    } else if (this.contactpersonzip) {
      this.contactpersonaddressLine = this.contactpersonzip;
    }
    console.log(this.contactpersoncity,this.contactpersonstate,this.contactpersonzip,this.contactpersonaddressLine)
    this.firstFormGroup.patchValue({
      contactpersonaddress: this.contactpersonaddress,
      contactpersoncityStatezip : this.contactpersonaddressLine,
      contactpersonCountry : this.contactpersoncountry
    });
  }

  

    form1(){
      console.log(this.firstFormGroup.value);
    }
    form2(){
      console.log(this.secondFormGroup.value);
    }
    form3(){
      console.log(this.thirdFormGroup.value);
    }
    form4(){
      console.log(this.fourthFormGroup.value);
    }
    form5(){
      console.log(this.fifthFormGroup.value);
    }
    form6(){
      console.log(this.sixthFormGroup.value);
    }

    addExtensionForm(){
      var array = {extensionNumber : 0,extensionName : '', voicmailEnable : false,
      voicemailToEmail : false, voicemailaddress : '',voicemailPassword : '',
      authenticationpassword : '',
      macAddress : ''
                }

      this.extensionlist.push(array)
    }
   
    delete(extension: ExtensionInfo, i): void {
      
      this.extensionlist.splice(i,1)
    }

}
