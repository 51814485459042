import { NgModule} from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import {TranslateModule} from '@ngx-translate/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule, MatInputModule, MatButtonModule, MatAutocompleteModule, MatIconModule,MatTabsModule,MatTableModule, MatFormFieldModule, MatSelectModule, MatSlideToggleModule, MatCardModule, MatPaginatorModule,MatSortModule,MatCheckboxModule } from '@angular/material';
import { TimeconditionsComponent } from "./timeconditions.component";
import { ConditionlistComponent } from "./conditionlist/conditionlist.component";
import { AddtimeconditionComponent } from "./addtimecondition/addtimecondition.component";
import { UiSwitchModule } from 'ngx-ui-switch';
import { Select2Module } from "ng-select2-component";
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonComponentModule } from "../../../common/commonComponent.module";

@NgModule({
    declarations: [
        TimeconditionsComponent,
        ConditionlistComponent,
        AddtimeconditionComponent
    ],

    imports: [
        BrowserModule,
        TranslateModule.forRoot(),
        FormsModule,
        MatStepperModule,
        MatInputModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatIconModule,
        MatTabsModule,
        MatTableModule,
        MatFormFieldModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatCardModule,
        MatPaginatorModule,
        MatSortModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        UiSwitchModule,
        Select2Module,
        NgSelectModule,
        CommonComponentModule
    ],
    providers: [],
    bootstrap: [TimeconditionsComponent],
    exports:[ConditionlistComponent],
})

export class TimeconditionsModule { }