import { NgModule} from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import {TranslateModule} from '@ngx-translate/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule, MatInputModule, MatButtonModule, MatAutocompleteModule, MatIconModule,MatTabsModule,MatTableModule, MatFormFieldModule, MatSelectModule, MatSlideToggleModule, MatCardModule, MatPaginatorModule,MatSortModule,MatCheckboxModule } from '@angular/material';
import { RinggroupsComponent } from './ringgroups.component';
import { AddringgroupComponent } from './addringgroup/addringgroup.component';
import { RinggrouplistComponent } from './ringgrouplist/ringgrouplist.component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { Select2Module } from "ng-select2-component";
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonComponentModule } from "../../../common/commonComponent.module";

@NgModule({
    declarations: [
        RinggroupsComponent,
        AddringgroupComponent,
        RinggrouplistComponent
    ],
    imports: [
        BrowserModule,
        TranslateModule.forRoot(),
        FormsModule,
        MatStepperModule,
        MatInputModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatIconModule,
        MatTabsModule,
        MatTableModule,
        MatFormFieldModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatCardModule,
        MatPaginatorModule,
        MatSortModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        UiSwitchModule,
        Select2Module,
        NgSelectModule,
        CommonComponentModule
        
    ],
    providers: [],
    bootstrap: [RinggroupsComponent],
    exports:[RinggrouplistComponent],
})

export class RingGroupsModule { }