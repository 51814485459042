import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-night-mode-control',
  templateUrl: './night-mode-control.component.html',
  styleUrls: ['./night-mode-control.component.css']
})
export class NightModeControlComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
