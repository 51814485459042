import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { WizardComponent } from './wizard/wizard.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {RegisterComponent} from './register/register.component';
import {MenuComponent} from './menu/menu.component';
// import { LoginComponent } from './login/login.component';
import { LoginComponent } from '../admin/provisioner/login/login.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  // { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'wizard', component: WizardComponent },
  { path: 'menu', component: MenuComponent },
  { path: 'subscription', component: SubscriptionComponent },
  { path: 'thank-you', component: ThankYouComponent },
  { path: 'page-not-found', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoreRoutingModule { }
