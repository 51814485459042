import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';


export interface Voicemails {
  date: String;
  time: String;
  call_status: String;
  number: String;
  duration: String;
}

const ELEMENT_DATA: Voicemails[] = [
  {date:'June 20, 2018',time:'02:37 PM',call_status:'Voicemail',number:'(469) 879-2040',duration:'20 sec'},
];

@Component({
  selector: 'app-voicemails',
  templateUrl: './voicemails.component.html',
  styleUrls: ['./voicemails.component.scss']
})
export class VoicemailsComponent implements OnInit {

  displayedColumns: string[] = ['date','time','call_status','number','duration'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  public voicemailsList = [
    {
      date: 'Tuesday',
      list:[
        { name:'', callNumber:'(469) 879-2040', company:'VIVANT CORP', callTime:'20 Sec',  time: '02:37 PM' },
        { name:'', callNumber:'(972) 331-1065', company:'ADVANCED VIRTUA', callTime:'07 Sec', time: '09:27 PM' },
        { name:'', callNumber:'(187) 656-2244', company:'Anonymous', callTime:'1.25 min', time: '09:21 PM' },
      ],
    },
  ]


  constructor() { }

  ngOnInit() {
  }


  applyFilter(filterValue: any) {
    this.voicemailsList.filter = filterValue.trim().toLowerCase();
  }

}
