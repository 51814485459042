import { NgModule ,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { applicationsRoutingModule } from './applications-routing.module';
import { CommonModule } from '@angular/common';
import {ApplicationsComponent} from './applications.component';
import { IvrComponent } from './ivr/ivr.component';
import {MatStepperModule} from '@angular/material/stepper';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule,MatSlideToggleModule,MatInputModule, MatPaginatorModule, MatTableModule, MatTabsModule} from '@angular/material';
import {MatCardModule} from '@angular/material/card';
import { Select2Module } from "ng-select2-component";
import { BrowserModule } from '@angular/platform-browser';
import { IvrlistComponent } from './ivr/ivrlist/ivrlist.component';
import { AddivrComponent } from './ivr/addivr/addivr.component';

@NgModule({
    imports: [
      CommonModule,
      applicationsRoutingModule,
      MatTabsModule,
      MatTableModule,
      MatStepperModule,
      FormsModule,
      ReactiveFormsModule,
      MatFormFieldModule,
      MatInputModule,
      MatPaginatorModule,
      BrowserModule,
      MatSlideToggleModule,
      MatCardModule,
      MatSelectModule,
      Select2Module,
     ],
     
    declarations: [
      ApplicationsComponent

    ]
  })
  export class applicationsModule { }