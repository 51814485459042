import { Component, OnInit, Inject, Optional } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatTableDataSource,
  MatPaginator
} from "@angular/material";
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { ClientService } from "../../../services";
@Component({
  selector: "add-group-settings",
  templateUrl: "./add-group-settings.component.html",
  styleUrls: ["./add-group-settings.component.scss"]
})
export class AddGroupSettingsComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<AddGroupSettingsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data,
    private _formBuilder: FormBuilder,
    private _toastr: ToastrService,
    private clientService: ClientService
  ) {}

  addSettingForm: FormGroup;
  clientSettings: Array<any> = [];

  ngOnInit() {
    this.addSettingForm = this._formBuilder.group({
      name: ["", Validators.required],
      config: ["", Validators.required]
    });
    this.clientService.getClientSettings(this.data.client_id).subscribe(res => {
      if(res){
        for(var i in res){
          this.clientSettings.push({
            id: res[i]._id,
            name: res[i].name
          })
        }
      }
    });
  }

  addSetting() {
    if(this.addSettingForm.valid){
      var data = {
        client_id: this.data.client_id,
        group_id: this.data.group_id,
        inherit_from_client_settings: this.addSettingForm.value.config,
        name: this.addSettingForm.value.name,
        created_at: new Date()
      };
      this.clientService.addGroupSetting(data).subscribe((data: any) => {
        if(data.ok){
          this._toastr.success("Group setting added successfully", "", {
            positionClass: "toast-top-center"
          });
          this.dialogRef.close();
        }else{
          this._toastr.error("Opps! Try again", "", {
            positionClass: "toast-top-center"
          });
        }
      });
    }
  }
}
