import { NgModule } from '@angular/core';
import {ClientComponent } from '../client.component';
import { Routes,RouterModule } from '@angular/router'
import { ApplicationsComponent } from './applications.component';
import { AddextensionComponent } from './extensionandusers/addextension/addextension.component';
import { ExtensionusersComponent } from './extensionandusers/extensionusers/extensionusers.component';
import { RinggroupsComponent } from './ringgroups/ringgroups.component';
import { PagingparkingComponent } from './pagingparking/pagingparking.component';
import { TimeconditionsComponent } from './timeconditions/timeconditions.component';
import { CallqueuesComponent } from "./callqueues/callqueues.component";
import { ExtensionandusersComponent } from './extensionandusers/extensionandusers.component';
import { ConferenceBridgesComponent } from './conference-bridges/conference-bridges.component'
import { AnnouncementsComponent } from './announcements/announcements.component';
import { NightModeControlComponent } from './night-mode-control/night-mode-control.component'
import { MiscDestinationComponent } from './misc-destination/misc-destination.component'
import { MusicOnHoldComponent } from './music-on-hold/music-on-hold.component';
import { TimegroupsComponent } from './timegroups/timegroups.component';
import { IvrComponent } from './ivr/ivr.component';
import { SystemaRecordingsComponent } from './systema-recordings/systema-recordings.component';

const routes: Routes=[
    {
    path: 'client',
    component: ClientComponent,
    data: {
      title: 'client',
      status: false
    },
    children: [
      {
        path: 'applications',
        children: [
          {
            path : 'extensionandusers',
            component: ExtensionandusersComponent,
          },
          {
            path : 'ringgroups',
            component: RinggroupsComponent,
          },
          {
            path : 'ivr',
            component: IvrComponent,
          },
          {
            path : 'pagingparking',
            component: PagingparkingComponent,
          },
          {
            path : 'timeconditions',
            component: TimeconditionsComponent,
          },
          {
            path : 'callqueues',
            component: CallqueuesComponent,
          },
          {
            path : 'conferencebridges',
            component : ConferenceBridgesComponent
          },
          {
            path : 'allannouncements',
            component : AnnouncementsComponent
          },
          {
            path : 'nightmodecontrol',
            component : NightModeControlComponent
          },
          {
            path : 'miscdestination',
            component : MiscDestinationComponent
          },
          {
            path : 'musiconhold',
            component : MusicOnHoldComponent
          },
          {
            path : 'timegroups',
            component : TimegroupsComponent
          },
          {
            path : 'systemrecordings',
            component : SystemaRecordingsComponent
          },
        ]
      }
    ]
    }
];

@NgModule({
    imports: [ RouterModule.forChild(routes)],
    exports: [ RouterModule ] 
})

export class ApplicationsRoutingModule {}
