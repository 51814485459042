import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ExtensionInfoAndSettingComponent } from './extension-info-and-setting.component'
import { MatStepperModule, MatInputModule, MatButtonModule, MatAutocompleteModule, MatIconModule,MatTabsModule,MatTableModule, MatFormFieldModule, MatSelectModule, MatSlideToggleModule, MatCardModule, MatPaginatorModule,MatSortModule,MatCheckboxModule} from '@angular/material';
import { Select2Module } from "ng-select2-component";
import {MatDatepickerModule} from '@angular/material/datepicker';
import { CommonComponentModule } from "../../../common/commonComponent.module";

@NgModule({
    declarations: [
       ExtensionInfoAndSettingComponent

    ],
    imports: [
        BrowserModule,
        CommonModule,
        FlexLayoutModule,
        MatCardModule,
        Select2Module,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatTabsModule,
        MatCheckboxModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        CommonComponentModule
        
    ],
    providers: [],
    bootstrap: [ExtensionInfoAndSettingComponent],
    exports:[],
})

export class ExtensionInfoAndSettingModule { }