import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators,NgForm} from '@angular/forms';

@Component({
  selector: 'app-add-misc-destination',
  templateUrl: './add-misc-destination.component.html',
  styleUrls: ['./add-misc-destination.component.scss']
})
export class AddMiscDestinationComponent implements OnInit {

  miscdestinationFormGroup : FormGroup
  constructor(private _formBuilder: FormBuilder) { }


  ngOnInit() {
    this.miscdestinationFormGroup = this._formBuilder.group({
      description : ['', Validators.required],
      dial        : ['', Validators.required]
    })
  }

  miscdestinationForm(){
    console.log(this.miscdestinationFormGroup.value)
  }

}
