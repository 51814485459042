import { NgModule} from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import {TranslateModule} from '@ngx-translate/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule, MatInputModule, MatButtonModule, MatAutocompleteModule, MatIconModule,MatTabsModule,MatTableModule, MatFormFieldModule, MatSelectModule, MatSlideToggleModule, MatCardModule, MatPaginatorModule,MatSortModule,MatCheckboxModule, MatMenuModule } from '@angular/material';
import { AddextensionComponent } from './addextension/addextension.component';
import {ExtensionusersComponent } from './extensionusers/extensionusers.component';
import { ExtensionandusersComponent } from './extensionandusers.component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { Select2Module } from "ng-select2-component";
import { NgSelectModule } from '@ng-select/ng-select';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { CommonComponentModule } from "../../../common/commonComponent.module";


@NgModule({
    declarations: [
        AddextensionComponent,
        ExtensionusersComponent,
        ExtensionandusersComponent

    ],
    imports: [
        BrowserModule,
        TranslateModule.forRoot(),
        FormsModule,
        MatStepperModule,
        MatInputModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatIconModule,
        MatTabsModule,
        MatTableModule,
        MatFormFieldModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatCardModule,
        MatPaginatorModule,
        MatSortModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        UiSwitchModule,
        Select2Module,
        NgSelectModule,
        MatMenuModule,
        NgbModule,
        CommonComponentModule

        
    ],
    providers: [],
    bootstrap: [ExtensionandusersComponent],
    exports:[ExtensionusersComponent],
})

export class ExtensionandusersModule { }