import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {API, StorageKey} from './constant';
import { Observable, Subject } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/toPromise';

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    tenantCompanyKey:string;
    pass:string;
    constructor(private http: HttpClient) {
    }

    userLogin(userData) {
        let httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        };

        let body = JSON.stringify(userData);
        return this.http.post(API.USER_LOGIN, body, httpOptions);

    }

    refreshToken(header: any) {
        return this.http.get(API.REFRESHTOKEN_API, header);
    }

    themeUpdate(themeData) {
      return this.http.post(API.THEME_API, themeData);
    }
    companyDetails(companyId, tenantKey) {
        return this.http.get(API.GET_COMPANY_DETAILS + "?companyId=" + companyId + "&tenantKey=" + tenantKey);
    }

    logout(){
      return this.http.post(API.LOGOUT, {});
    }
    getList(){
        return this.http.get(API.USERS_LIST);
    }
    ban(data){
        return this.http.post(API.USER_BAN,data);
    }
    status(status,userId){
        return this.http.post(API.USER_STATUS+status+'/'+userId,{});
    }
    delete(id){
        return this.http.delete(API.USER_DELETE+id);
    }
    userEmailExists(email){
        return this.http.get(API.USERS_EMAIL_EXISTS+"?email="+email);
    }
    getCompanies(tenantKey){
        return this.http.get(API.COMPANY_LIST+"?tenantKey="+tenantKey);
    }
    resetPassword(data){
        return this.http.post(API.RESET_PASSWORD, data);
    }
    setShareValue(password) {
        this.pass = password;
    }
    setTenantCompanyKey(tenantCompanyKey) {
        this.tenantCompanyKey = tenantCompanyKey;
    }
    getPass() {
        return this.pass;
    }
    getTenantCompanyKey() {
        return this.tenantCompanyKey;
    }
}
