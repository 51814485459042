import { Routes } from '@angular/router';

export const AppRoutes: Routes = [
  { path: '', redirectTo: '/admin', pathMatch: 'full' },
  {path: '', redirectTo: '/client', pathMatch: 'full'},
  {path: '', redirectTo: '/user', pathMatch: 'full'},
  // {
  //   // path: 'accounting/viewInvoice/:id',
  //   // component: InvoiceViewComponent
  // },
  // {
  //   path: 'sales/viewEstimate/:id',
  //   component: EstimateViewComponent
  // },
  // {
  //   // path: 'viewInvoice',
  //   // component: InvoiceViewComponent,
  // },
];
