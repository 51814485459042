import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TenantCompaniesService } from '../../../service/tenant-companies.service';
import * as toastr from 'toastr';
import { Router } from "@angular/router";

export interface DialogData {
  type: string;
  name: string;
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {
  request: any;
  constructor(public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private tenantCompaniesService: TenantCompaniesService,
    private router: Router
    ) { 
    this.request = this.data;
    console.log(this.request);
    }

  ngOnInit() {
  }
  close() {
    this.dialogRef.close();
  }
  subscribeNow(){
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var y = today.getFullYear();

    var todayD = y+'-'+mm+'-'+dd;
    if (this.request.plan == 'monthly') {
      var todaysDate = new Date();
      var numberOfDaysToAdd = 30;
      todaysDate.setDate(todaysDate.getDate() + numberOfDaysToAdd);
      var dd = todaysDate.getDate();
      var mm = todaysDate.getMonth() + 1;
      var y = todaysDate.getFullYear();
      var endDate = y+'-'+mm+'-'+dd;
    }else if (this.request.plan == 'yearly') {
      var todaysDate = new Date();
      var numberOfDaysToAdd = 365;
      todaysDate.setDate(todaysDate.getDate() + numberOfDaysToAdd);
      var dd = todaysDate.getDate();
      var mm = todaysDate.getMonth() + 1;
      var y = todaysDate.getFullYear();
      var endDate = y+'-'+mm+'-'+dd;
    }
    var subscriptionData = {
      subscriptionStartDate: todayD,
      subscriptionEndDate: endDate
    }
    // console.log(subscriptionData)
    this.tenantCompaniesService.subscription(subscriptionData).subscribe((response: any) => {
      // console.log(response)
      if (response.info.code == 200) {
        this.close();
        localStorage.setItem('subscription', '1');
        toastr.options.positionClass = "toast-bottom-right";
        toastr.success("Thanks for subscribing");
        this.router.navigate(["/admin"], { replaceUrl: true });
      }else{
        this.close();
        toastr.options.positionClass = "toast-bottom-right";
        toastr.error("Subscription failed. Please try again");
      }
    });
  }

}
