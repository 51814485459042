import { NgModule} from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { IvrComponent } from './ivr.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule, MatInputModule, MatButtonModule, MatAutocompleteModule, MatIconModule,MatTabsModule,MatTableModule, MatFormFieldModule, MatSelectModule, MatSlideToggleModule, MatCardModule, MatPaginatorModule,MatSortModule,MatCheckboxModule } from '@angular/material';
import { UiSwitchModule } from "ngx-ui-switch";
import { Select2Module } from "ng-select2-component";
import { IvrlistComponent } from './ivrlist/ivrlist.component';
import { AddivrComponent } from './addivr/addivr.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonComponentModule } from "../../../common/commonComponent.module";

@NgModule({
    declarations: [
        IvrComponent,
        IvrlistComponent,
        AddivrComponent
    ],

    imports: [
        BrowserModule,
        TranslateModule.forRoot(),
        FormsModule,
        MatStepperModule,
        MatInputModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatIconModule,
        MatTabsModule,
        MatTableModule,
        MatFormFieldModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatCardModule,
        MatPaginatorModule,
        MatSortModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        UiSwitchModule,
        Select2Module,
        NgSelectModule,
        CommonComponentModule
        
    ],
    providers: [],
    bootstrap: [IvrComponent],
    exports:[IvrlistComponent],
})

export class IvrModule { }