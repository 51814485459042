import * as XLSX from 'xlsx';
import {Injectable} from '@angular/core';


@Injectable()
export class ExcelGenerator {

  constructor() {

  }

  generatExcel(title, data, fileName) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, fileName + '.xlsx');
  }

}
