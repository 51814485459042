import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormControl  } from '@angular/forms';
import * as toastr from "toastr";

export interface TimeConditions {
  address: String;
  netmask: String;
  protocol: String;
}

const ELEMENT_DATA: TimeConditions[] = [
  { address:"185.53.91.40", netmask:"32", protocol:"SIP" },
  { address:"185.53.91.40", netmask:"32", protocol:"SIP" },
  { address:"185.53.91.40", netmask:"32", protocol:"SIP" },
  { address:"185.53.91.40", netmask:"32", protocol:"SIP" },
  { address:"185.53.91.40", netmask:"32", protocol:"SIP" },
];

@Component({
  selector: 'app-intrusion-detection',
  templateUrl: './intrusion-detection.component.html',
  styleUrls: ['./intrusion-detection.component.scss']
})
export class IntrusionDetectionComponent implements OnInit {
  
  displayedColumns: string[] = ['address','netmask','protocol'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  
  intrusionForm: FormGroup;
  submitted = false;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.intrusionForm = this.formBuilder.group({
      findTime: ['',Validators.required],
      banTime: ['', Validators.required],
      maxRetry: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
    })
  }


  get f() { return this.intrusionForm.controls; }

  onSubmit() {
      this.submitted = true;

      if (this.intrusionForm.invalid) {
        toastr.error("Invalid Details !");
        return;
      }
      console.log(this.intrusionForm.value);

      toastr.success("Form Submitted Successfully !")
  }


}
