import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {API} from "./constant";

@Injectable({
  providedIn: 'root'
})
export class EstimateService {
  public updateEstimateEventListener = new BehaviorSubject(0);

  constructor(private http: HttpClient) {}

  estimateList(type, filter): Observable<any> {
    if (type === '' && filter !== ''){
        return this.http.get(API.ESTIMATE_LIST + '?filter=' + JSON.stringify(filter));
    } else {
      if (filter !== '') {
        return this.http.get(API.ESTIMATE_LIST + '?type=' + type + '&filter=' + JSON.stringify(filter));
      } else {
        return this.http.get(API.ESTIMATE_LIST + '?');
      }
    }
  }
  deleteEstimate(estimateId: number): Observable<any>{
    return this.http.delete(API.ESTIMATE_DELETE + estimateId);
  }
   updateEstimateStatus(status: string, estimateId: number): Observable<any>{
      return this.http.post(API.ESTIMATE_CHANGE_STATUS + estimateId, {status: status});
   }
  sendEstimateEmail(estimateEmail: any): Observable<any>{
    return this.http.post(API.ESTIMATE_SEND_EMAIL, estimateEmail);
}
  getEmailTemplate(eGroup) {
    return this.http.get(API.EMAIL_TEMPLATE + '?eGroup=' + eGroup);
  }
  estimateDetailList(estimateId: number): Observable<any>{
    return this.http.get(API.ESTIMATE_DETAILS + estimateId);
  }
  convertToEstimate(id: number): Observable<any>{
    return this.http.post(API.ESTIMATE_CONVERT_TO_INVOICE, {id: id});
  }
  changeStatus(estimateId: number, status: string): Observable<any>{
    return this.http.post(API.ESTIMATE_CHANGE_STATUS + estimateId, {status: status});
}
  estimateActivity(moduleId, type) {
    return this.http.get(API.ACTIVITY_LIST + '?moduleId=' + moduleId + '&type=' + type);
  }
  addEstimate(body){
    return this.http.post(API.CREATE_ESTIMATE, body);
  }
  updateEstimate(id, body) {
    return this.http.post(API.UPDATE_ESTIMATE + id, body);
  }
}

