import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ClientService, readCookie } from "../../../services";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { AddGroupSettingsComponent } from "../add-group-settings/add-group-settings.component";
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";
import { MatSort } from "@angular/material/sort";
import { MoboAlert } from "../../../notification";

@Component({
  selector: "group-settings",
  templateUrl: "./group-settings.component.html",
  styleUrls: ["./group-settings.component.scss"],
})
// @ts-ignore
export class GroupSettingsComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private clientService: ClientService,
    private dialog: MatDialog,
    private _toastr: ToastrService,
    private router: Router,
    private alert: MoboAlert
  ) {}

  client_id: string;
  client_name: string;
  group_id: number;
  group_name: string;
  dataSource: any;
  displayedColumns: string[] = [
    "name",
    "device_model",
    "base_config",
    "actions",
  ];
  // @ts-ignore
  @ViewChild(MatPaginator, {}) paginator: MatPaginator;
  @ViewChild(MatSort, {}) sort: MatSort;

  ngOnInit() {
    if (!readCookie("authenticate")) {
      this.router.navigate(["/login"], {
        queryParams: { return: location.href },
      });
      return false;
    }
    this.route.params.subscribe((data) => {
      console.log(data);
      this.client_id = data.client_id;
      this.group_id = data.id;
    });
    this.clientService
      .getGroupSettings(this.client_id, this.group_id)
      .subscribe((data: any) => {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
    this.clientService
      .getClientName(this.client_id)
      .subscribe((res: string) => {
        this.client_name = res;
      });
    this.clientService
      .getGroupName(this.client_id, this.group_id)
      .subscribe((res: string) => {
        this.group_name = res;
      });
  }

  openAddSettingDialog() {
    const addSettingDialog = this.dialog.open(AddGroupSettingsComponent, {
      data: {
        client_id: this.client_id,
        group_id: this.group_id,
      },
    });
    addSettingDialog.afterClosed().subscribe((data: any) => {
      this.clientService
        .getGroupSettings(this.client_id, this.group_id)
        .subscribe((data: any) => {
          this.dataSource = new MatTableDataSource(data);
          this.dataSource.paginator = this.paginator;
        });
    });
  }

  removeSetting(id: number) {
    if (confirm("Are you sure?")) {
      this.clientService
        .remove_group_setting(this.client_id, this.group_id, id)
        .subscribe((data: any) => {
          if (data.ok) {
            this._toastr.success("Group setting removed successfully", "", {
              positionClass: "toast-top-center",
            });
            this.clientService
              .getGroupSettings(this.client_id, this.group_id)
              .subscribe((data: any) => {
                this.dataSource = new MatTableDataSource(data);
                this.dataSource.paginator = this.paginator;
              });
          } else {
            this._toastr.error("Opps! Try again", "", {
              positionClass: "toast-top-center",
            });
          }
        });
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  editGroupSettingName(id: number, name: string) {
    Swal.fire({
      title: "Edit group setting name",
      input: "text",
      inputValue: name,
      cancelButtonColor: "#EC4849",
      reverseButtons: true,
      confirmButtonColor: "#45CE30",
      showCancelButton: true,
      confirmButtonText: "SAVE",
      cancelButtonText: "CANCEL",
      focusCancel: true,
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.value) {
        this.clientService
          .editGroupSettingName(this.client_id, id, result.value)
          .subscribe((data: any) => {
            if (data) {
              if (data.ok) {
                Swal.fire("Edited!", "Group name has been edited.", "success");
                this.clientService
                  .getGroupSettings(this.client_id, this.group_id)
                  .subscribe((data: any) => {
                    this.dataSource = new MatTableDataSource(data);
                    this.dataSource.paginator = this.paginator;
                  });
              } else {
                Swal.fire("Oopsss...", "Something went worng", "error");
              }
            }
          });
      }
    });
  }

  clone(id: string, name: string) {
    this.alert
      .confirmClone({
        title: "Are you sure?",
        text: "You want to clone this group setting?",
      })
      .then((result) => {
        if (result.value) {
          this.clientService
            .getGroups(this.client_id)
            .subscribe((data: any) => {
              if (data) {
                let options = {};
                data.forEach((val) => {
                  options[val._id] = val.name;
                });
                Swal.fire({
                  title: "Choose a group",
                  input: "select",
                  inputOptions: {
                    ...options,
                  },
                  inputPlaceholder: "Select a group",
                  cancelButtonColor: "#EC4849",
                  reverseButtons: true,
                  confirmButtonColor: "#45CE30",
                  showCancelButton: true,
                  confirmButtonText: "SAVE",
                  cancelButtonText: "CANCEL",
                  focusCancel: true,
                  allowOutsideClick: false,
                }).then((result: any) => {
                  if (result.value) {
                  }
                });
              }
            });
        }
      });
  }
}
