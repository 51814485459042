import { Injectable } from "@angular/core";
import Swal from "sweetalert2";

interface args {
  title: string;
  text: string;
}

@Injectable({
  providedIn: "root"
})
export class MoboAlert {
  public confirm({title,text}:args) {
    return Swal.fire({
      title: title,
      text: text,
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#EC4849",
      reverseButtons: true,
      confirmButtonColor: "#45CE30",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "CANCEL",
      focusCancel: true
    });
  }
  public confirmClone({title,text}:args) {
    return Swal.fire({
      title: title,
      text: text,
      type: "info",
      showCancelButton: true,
      cancelButtonColor: "#EC4849",
      reverseButtons: true,
      confirmButtonColor: "#45CE30",
      confirmButtonText: "Yes, clone it!",
      cancelButtonText: "CANCEL",
      focusCancel: true
    });
  }
}
