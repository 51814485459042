import { Opportunity } from './../../../service/models/Opportunity';
import { Component, Injector, Input, OnInit, Inject } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Select2Data, Select2Option } from 'ng-select2-component';
import { AppComponentBase } from '../AppComponentBase';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatTabGroup, MatTableDataSource } from '@angular/material';
import * as toastr from 'toastr';
//import { GlobalService } from '../../../service/global.service';
//import { ConfigService } from '../../../service/config.service';
import { Router } from '@angular/router'

@Component({
  selector: 'app-permission-dialog',
  templateUrl: './permission-dialog.component.html',
  styleUrls: ['./permission-dialog.component.css']
})
export class PermissionDialogComponent extends AppComponentBase implements OnInit {

  participantsForm: FormGroup;
  showUsers: boolean;
  type: string = 'open';
  showResultLabel: boolean = false;
  LeadsLength: number;
  leads = [];
  permission: number = 3;
  participantsId = '';
  customUsersData: Select2Option[] = [];
  usersArr: any = [];
  radioValue: any;
  radioGroup: any;
  showMessage: boolean = false;
  body = {};
  @Input() tabGroup: MatTabGroup;
  dataSource = new MatTableDataSource<any>();
  constructor(
    public router: Router,
    public formBuilder: FormBuilder,
    private injector: Injector,
    //public globalService: GlobalService,  
    public dialogRef: MatDialogRef<PermissionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog) {
    super(injector);
  }

  ngOnInit() {

    this.formValidations();
    this.employeeDDList();
    if (this.data.type == "leads") {
      setTimeout(() => {
        this.setParticipants();
      }, 300);
    }
    else if (this.data.type == "opportunities") {
      setTimeout(() => {
        this.setParticipants();
      }, 300);
    } else if (this.data.type == "tasks") {
      setTimeout(() => {
        this.setParticipants();
      }, 300);
    }
  }


  /***
   * @description Returns form controls
   */
  get formData() {
    return this.participantsForm.controls;
  }

  navigateMainPage() {
    this.dialogRef.close();
  }

  formValidations() {
    this.participantsForm = this.formBuilder.group({
      permission: ["", Validators.required],
      participants: [""]
    })
  }

  radioChange(event) {
    this.permission = event.value;
    if (event.value === 3) {
      this.showUsers = true;
    }
    else {
      this.showUsers = false;
    }
  }

  employeeDDList() {
    this.leadsService.EmployesDDList().subscribe((SalesPersonRes: any) => {
      if (SalesPersonRes.info.code == 200) {
        var myUsersObj = {}
        SalesPersonRes.data.forEach(userObj => {
          myUsersObj = { "value": userObj.user_id, "label": userObj.fullname };
          this.usersArr.push(myUsersObj);
        });
        this.customUsersData = this.usersArr;
      }
    });
  }

  setParticipants() {
    let customParticipantsId = [];
    let customUsers;
    let assignmentType, myselfFlag;
    // if(this.data.type == "leads"){
    //   customUsers=this.data.details.custom_users;
    // }
    // else{
    customUsers = this.data.details.custom_users;
    // }

    this.configService.getAllConfigDetails();
    let loggedUser = this.configService.userId;
    if (customUsers !== null) {

      customUsers = JSON.parse(customUsers);
      if (customUsers.length > 0) {
        for (let i = 0; i < customUsers.length; i++) {
          customParticipantsId.push(customUsers[i].id);
        }
        if (customParticipantsId.length === 1 && loggedUser === customParticipantsId[0]) {
          customParticipantsId.push(loggedUser);
          myselfFlag = 1;
        } else {

        }
      }
    }

    if (this.data.details.permission_type) {
      if (this.data.details.permission_type == 3) {
        if (myselfFlag) {

          assignmentType = 1;
          this.radioValue = 1;
        } else {
          assignmentType = 3;
          this.radioValue = 3;
          this.showUsers = true;
        }
        assignmentType = 3;
      } else if (this.data.details.permission_type == 1) {
        if (myselfFlag) {

          assignmentType = 1;
          this.radioValue = 1;
          this.showUsers = false;
        } else {
          assignmentType = 3;
          this.radioValue = 3;
        }
      } else if (this.data.details.permission_type == 2) {
        this.showUsers = false;
        assignmentType = 2;
        this.radioValue = 2;
      }
    }
    this.participantsForm.patchValue({
      participants: customParticipantsId,
      permission: assignmentType
    })
  }

  onSubmit() {
    this.updateParticipantsData();
  }

  updateParticipantsData() {
    let type, id, name, departmentId, assignedUser;
    this.configService.getAllConfigDetails();
    //let loggedUser = 
    //participantType = this.participantsForm.value.permission;
    if (this.participantsForm.value.permission === 1) {
      assignedUser = this.configService.userId;
    } else {
      assignedUser = this.participantsForm.value.participants;
    }
    if (this.data.type == "leads") {
      id = this.data.details.leads_id;
      type = this.data.type;
      name = this.data.details.lead_name;
      departmentId = this.data.details.dept_id;
    } else if (this.data.type == "opportunities") {
      id = this.data.details.opportunities_id;
      type = this.data.type;
      name = this.data.details.opportunity_name;
      departmentId = this.data.details.dept_id;
    } else if (this.data.type == "tasks") {
      id = this.data.details.task_id;
      type = this.data.type;
      name = this.data.details.task_name;
      departmentId = this.data.details.dept_id;
    }


    let data = {
      id: Number(id),
      name: String(name),
      type: String(type),
      departmentId: Number(departmentId),
      assignmentType: Number(this.participantsForm.value.permission),
      assignedTo: String(assignedUser)
    }
    this.globalService.addMember(type, id, data).subscribe((addMemRes: any) => {
      if (addMemRes.result.info.code == 200) {
        toastr.options.positionClass = "toast-bottom-right";
        toastr.success("Members added successfully");
        this.dialogRef.close({ data: "true" });
      }
      else {
        toastr.error("Members not updated!");
        this.dialogRef.close({ data: false });
      }
    })
  }

}