import { NgModule} from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import {TranslateModule} from '@ngx-translate/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule, MatInputModule, MatButtonModule, MatAutocompleteModule, MatIconModule,MatTabsModule,MatTableModule, MatFormFieldModule, MatSelectModule, MatSlideToggleModule, MatCardModule, MatPaginatorModule,MatSortModule,MatCheckboxModule } from '@angular/material';
import { CallqueuesComponent } from './callqueues.component';
import { CallqueuelistComponent } from "./callqueuelist/callqueuelist.component";
import { CreatequeueComponent } from "./createqueue/createqueue.component";
import { UiSwitchModule } from "ngx-ui-switch";
import { Select2Module } from "ng-select2-component";
import { CommonComponentModule } from "../../../common/commonComponent.module";


@NgModule({
    declarations: [
        CallqueuesComponent,
        CallqueuelistComponent,
        CreatequeueComponent
    ],

    imports: [
        BrowserModule,
        TranslateModule.forRoot(),
        FormsModule,
        MatStepperModule,
        MatInputModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatIconModule,
        MatTabsModule,
        MatTableModule,
        MatFormFieldModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatCardModule,
        MatPaginatorModule,
        MatSortModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        UiSwitchModule,
        Select2Module,
        CommonComponentModule
    ],
    providers: [],
    bootstrap: [CallqueuesComponent],
    exports:[],
})

export class CallqueuesModule { }