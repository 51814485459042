import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { NotificationEmailService } from '../../../service/notification-email.service';
// import { MatPaginator, MatTabGroup, MatIcon, MatTableDataSource} from '@angular/material';
// import { ActivatedRoute, Router } from '@angular/router';
// import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
// import {ErrorStateMatcher} from '@angular/material/core';


// export class MyErrorStateMatcher implements ErrorStateMatcher {
//   isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
//     const isSubmitted = form && form.submitted;
//     return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
//   }
// }

// export interface Protocol {
//   key: string;
//   value: string;
// }


@Component({
  selector: 'app-email-settings',
  templateUrl: './email-settings.component.html',
  styleUrls: ['./email-settings.component.css']
})

export class EmailSettingsComponent implements OnInit {
  // protocols: Protocol[] = [
  //   {key:'1',value:'SMTP'},
  //   {key:'2',value:'FTP'},
  //   {key:'3',value:'TFTP'},
  //   {key:'4',value:'POP3'}
  // ];
  
  // emailFormControl = new FormControl('', [
  //   Validators.required,
  //   Validators.email,
  // ]);

  // passwordFormControl = new FormControl ('', [
  //   Validators.required,
  // ]);

  // portFormControl = new FormControl ('', [
  //   Validators.required,
  // ]);
  // matcher = new MyErrorStateMatcher();
  // constructor(private route: ActivatedRoute, private router: Router) { }
  subscript: Subscription;  
  selectTabindex : number = 0;
  constructor( public notificationEmailService: NotificationEmailService,) {
    this.subscript = this.notificationEmailService.messageSource.subscribe((val)=> {
      if(val != 0) {
        this.selectTabindex = val;
      }
      else{
        this.selectTabindex = 0;
      }
    })
  }
  ngOnInit() {
  
  }

  ngOnDestroy(){
    this.subscript.unsubscribe();
    this.notificationEmailService.messageSource.next(0);
  }

}
