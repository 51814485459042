import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserRoutingModule} from './user-routing.module'
import { UserComponent } from './user.component'
import { FullComponent } from './layouts/full/full.component'
import { AppSidebarComponent } from "./layouts/full/sidebar/sidebar.component";
import { AppHeaderComponent } from "./layouts/full/header/header.component";
import { FooterComponent } from "./layouts/full/footer/footer.component";
import { MenuListItemComponent } from "./layouts/full/menu-list-item/menu-list-item.component";
import { MaterialModule } from "../material-module";
import { UserDashboardModule } from './user-dashboard/user-dashboard.module';
import { MatTableModule, MatSortModule, MatPaginatorModule } from "@angular/material";
import { MessagesModule } from './messages/messages.module';
import { CallsModule } from './calls/calls.module';
import { VoicemailsModule } from './voicemails/voicemails.module';
import { ContactsModule } from './contacts/contacts.module';
import { ReportsModule } from "./reports/reports.module";
import { SettingsModule } from "./settings/settings.module";
import { SharedModule } from "./shared/shared.module";

@NgModule({
  declarations: [
    UserComponent,
    FullComponent,
    AppSidebarComponent,
    AppHeaderComponent,
    FooterComponent,
    MenuListItemComponent,
  ],
  imports: [
    CommonModule,
    UserRoutingModule,
    MaterialModule,
    UserDashboardModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MessagesModule,
    CallsModule,
    VoicemailsModule,
    ContactsModule,
    ReportsModule,
    SettingsModule,
    SharedModule,
  ]
})
export class UserModule { }
