import { Component, OnInit } from '@angular/core';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { FormBuilder, FormGroup, Validators, FormArray, AbstractControl } from '@angular/forms';
import { Select2Option, Select2Data } from 'ng-select2-component';
import * as toastr from "toastr";
@Component({
  selector: 'app-outbound-routes-form',
  templateUrl: './outbound-routes-form.component.html',
  styleUrls: ['./outbound-routes-form.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true }
  }]
})
export class OutboundRoutesFormComponent implements OnInit {
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  submitted1: boolean = false;
  submitted2: boolean = false;

  routeData = [
    {id:1, name:'Default'},
    {id:2, name:'Route 1'},
    {id:3, name:'Route 2'},
    {id:4, name:'Route 3'},
  ];

  trunkData = [
    {id:1, name:'Default'},
    {id:2, name:'Trunk 1'},
    {id:3, name:'Trunk 2'},
    {id:4, name:'Trunk 3'},
  ];

  destinationData = [
    {id:1, name:'Default'},
    {id:2, name:'Destination 1'},
    {id:3, name:'Destination 2'},
    {id:4, name:'Destination 3'},
  ];

  arr: FormArray;

  constructor(private _formBuilder: FormBuilder) { }

  checked = false;
  disabled = false;

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      routeName: ['', Validators.required],
      routeCid: ['', Validators.required],
      routePosition: ['', Validators.required],
      trunkSequence1: ['', Validators.required],
      trunkSequence2: ['', Validators.required],
      destination: ['', Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      // secondCtrl: ['', Validators.required],
      arr: this._formBuilder.array([this.createItem()]),
    });
    this.firstFormGroup.reset();
    this.secondFormGroup.reset();
  }

  addItem() {
    this.arr = this.secondFormGroup.get('arr') as FormArray;
    this.arr.push(this.createItem());
  }

  removeItem(index) {
    const form = this.secondFormGroup.get('arr') as FormArray
    form.removeAt(index);
  }
  createItem() {
    return this._formBuilder.group({
      prepend: [''],
      prefix: [''],
      matchPattern: [''],
      callerId: [''],
    })
  }

  get f1() { return this.firstFormGroup.controls; }
  get f2() { return this.firstFormGroup.controls; }

  onFirstSubmit() {
      this.submitted1 = true;

      if (this.firstFormGroup.invalid) {
        toastr.error("Invalid Details !");
        return;
      }
      console.log(this.firstFormGroup.value);

      toastr.success("Form Submitted Successfully !")
  }

  onSecondSubmit() {
    this.submitted2 = true;

    if (this.secondFormGroup.invalid) {
      toastr.error("Invalid Details !");
      return;
    }
    console.log(this.secondFormGroup.value);

    toastr.success("Form Submitted Successfully !")
  }

}
