import { Component, OnInit } from "@angular/core";
import { DialogComponent } from "../dialog/dialog.component";
import { DialogService } from "../dialog/dialog.service";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Router } from "@angular/router";
import { readCookie } from "../services";

@Component({
  selector: "app-globalsetting",
  templateUrl: "./globalsetting.component.html",
  styleUrls: ["./globalsetting.component.scss"],
})
export class GlobalsettingComponent implements OnInit {
  public hello() {
    console.log("hii");
  }
  constructor(private dialogService: DialogService, private router: Router) {}

  ngOnInit() {
    if (!readCookie("authenticate")) {
      this.router.navigate(["/login"], {
        queryParams: { return: location.href },
      });
      return false;
    }
  }
  showLogin() {
    this.dialogService.open(DialogComponent, {
      width: "80%",
    });
  }
}
