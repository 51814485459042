import { Component, OnInit,ViewChild } from '@angular/core';
import {MatPaginator, MatTableDataSource} from '@angular/material';

export interface PeriodicElement {
  settingname : string;
  devicemodel : string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {settingname : 'DP750', devicemodel : 'Grandstream DP750' },
  {settingname : 'FanvilX4', devicemodel : 'FanvilX4' }
  
];
@Component({
  selector: 'app-allcompanysetting',
  templateUrl: './allcompanysetting.component.html',
  styleUrls: ['./allcompanysetting.component.scss']
})
export class AllcompanysettingComponent implements OnInit {

  displayedColumns: string[] = [ 'settingname','devicemodel','action'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  constructor() { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
  }
}
