import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators,NgForm} from '@angular/forms';
import { Select2Option, Select2Data } from 'ng-select2-component';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import * as toastr from "toastr";

const URL = 'https://evening-anchorage-3159.herokuapp.com/api/';

@Component({
  selector: 'app-add-system',
  templateUrl: './add-system.component.html',
  styleUrls: ['./add-system.component.scss']
})

export class AddSystemComponent implements OnInit {
  miscdestinationFormGroup : FormGroup
  submitted = false;
  fileName: any;
  fullFileName: string;

  recordingData = [
    {id:1, name:'None'},
    {id:2, name:'Recording 1'},
    {id:3, name:'Recording 2'},
    {id:4, name:'Recording 3'},
    {id:5, name:'Recording 4'},
    {id:6, name:'Recording 5'},
  ];

  constructor(private _formBuilder: FormBuilder) { }

  ngOnInit() {

    this.miscdestinationFormGroup = this._formBuilder.group({
      description : ['', Validators.required],
      Name        : ['', Validators.required],
      recordOver : ['', Validators.required],
      systemRecord : ['', Validators.required],
      featureCode : ['', Validators.required],
    });
    this.miscdestinationFormGroup.reset();
  }

  uploadFile(files: File[]) {
    const formData: FormData = new FormData();
    if (files.length > 0) {
      const file: File = files[0];
      this.fullFileName = file.name;
      formData.append('file', file, file.name);
    }
  }

  musicOnHoldForm(){
    console.log()
  }

  public uploader:FileUploader = new FileUploader({url: URL});
  public hasBaseDropZoneOver:boolean = false;
  public hasAnotherDropZoneOver:boolean = false;
 
  public fileOverBase(e:any):void {
    this.hasBaseDropZoneOver = e;
  }
 
  public fileOverAnother(e:any):void {
    this.hasAnotherDropZoneOver = e;
  }

  get f() { return this.miscdestinationFormGroup.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.miscdestinationFormGroup.invalid) {
      toastr.error("Invalid Details !");
      return;
    }
    console.log(this.miscdestinationFormGroup.value);

    toastr.success("Form Submitted Successfully !");
    this.miscdestinationFormGroup.reset();
  }
}
