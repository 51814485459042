import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API } from "./constant";
import { Observable, Subject } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/toPromise';

@Injectable({
    providedIn: 'root'
})
export class MeetingsService {
    constructor(private http: HttpClient) { }

    getMeetingsList(moduleId, type, filter?){
        return this.http.get(API.MEETINGS_LIST + moduleId + '/' + type + '?filter=' + JSON.stringify(filter));
    }
    addMeeting(meetingsData){
        return this.http.post(API.ADD_LEAD_MEETING, meetingsData);
    }
    meetingDetails(meetingsId) {
        return this.http.get(API.MEETING_DETAILS + meetingsId);
    }
    updateMeeting(meetingsId, meetingsData) {
        return this.http.post(API.UPDATE_LEAD_MEETING + meetingsId, meetingsData);
    }
    deleteMeeting(meetingsId, type) {
        return this.http.delete(API.DELETE_LEAD_MEETING + meetingsId + '/' + type);
    }
}