import { NgModule ,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { connectivityRoutingModule } from './connectivity-routing.module';
import { CommonModule } from '@angular/common';
import {MatStepperModule} from '@angular/material/stepper';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule,MatSlideToggleModule,MatInputModule, MatPaginatorModule, MatTableModule, MatTabsModule} from '@angular/material';
import {MatCardModule} from '@angular/material/card';
import { Select2Module } from "ng-select2-component";
import { UiSwitchModule } from 'ngx-ui-switch';
import { BrowserModule } from '@angular/platform-browser';
import {ConnectivityComponent} from './connectivity.component';
import { OutboundRoutesComponent } from './outbound-routes/outbound-routes.component';
import { TrunkSettingComponent } from './trunk-setting/trunk-setting.component';
import { OutboundRoutesListComponent } from './outbound-routes/outbound-routes-list/outbound-routes-list.component';
import { OutboundRoutesFormComponent } from './outbound-routes/outbound-routes-form/outbound-routes-form.component';
import { TrunkSettingListComponent } from './trunk-setting/trunk-setting-list/trunk-setting-list.component';
import { TrunkSettingFormComponent } from './trunk-setting/trunk-setting-form/trunk-setting-form.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonComponentModule } from '../../common/commonComponent.module';

@NgModule({
    imports: [
      CommonModule,
      connectivityRoutingModule,
      MatTabsModule,
      MatTableModule,
      MatStepperModule,
      FormsModule,
      ReactiveFormsModule,
      MatFormFieldModule,
      MatInputModule,
      MatPaginatorModule,
      BrowserModule,
      MatSlideToggleModule,
      MatCardModule,
      MatSelectModule,
      Select2Module,
      UiSwitchModule,
      NgSelectModule,
      CommonComponentModule
     ],
     
    declarations: [
      ConnectivityComponent,
      OutboundRoutesComponent,
      TrunkSettingComponent,
      OutboundRoutesListComponent,
      OutboundRoutesFormComponent,
      TrunkSettingListComponent,
      TrunkSettingFormComponent,
    ],

    exports: [OutboundRoutesFormComponent]
  })
  export class connectivityModule { }
  