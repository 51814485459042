import { Component, OnInit, Inject, Optional } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import { ClientService } from "../../services";
import Swal from "sweetalert2";
import { MoboSnackBar } from "../../notification";

@Component({
  selector: "edit-setting",
  templateUrl: "./edit-device-setting.component.html",
  styleUrls: ["./edit-device-setting.component.scss"],
})
export class EditDeviceSettingComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<EditDeviceSettingComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data,
    private _formBuilder: FormBuilder,
    private service: ClientService,
    private snackBar: MoboSnackBar
  ) {}

  deviceSetting: FormGroup;
  index: any;
  options: any;
  found: boolean = false;
  searchValue: any = null;
  load: boolean = false;

  ngOnInit() {
    this.deviceSetting = this._formBuilder.group({
      setting_name: [null, Validators.required],
      setting_description: [null, Validators.required],
      setting_variable_name: [null, Validators.required],
      setting_variable_type: [null, Validators.required],
      old_options: this._formBuilder.array([]),
      new_options: this._formBuilder.array([]),
    });
  }

  get newOptions() {
    return this.deviceSetting.get("new_options") as FormArray;
  }

  get oldOptions() {
    return this.deviceSetting.get("old_options") as FormArray;
  }

  addSettingOption() {
    return this.newOptions.push(this.addOption());
  }
  addOldSettingOption(value, name) {
    return this.oldOptions.push(this.addOption(value, name));
  }

  addOption(value = "", name = ""): FormGroup {
    return this._formBuilder.group({
      value: [value, Validators.required],
      name: [name, Validators.required],
    });
  }

  removeSettingOption(index: any) {
    return this.newOptions.removeAt(index);
  }
  removeOldSettingOption(index: any) {
    return this.oldOptions.removeAt(index);
  }

  search() {
    this.index = null;
    this.options = null;
    this.deviceSetting.reset();
    this.deviceSetting.setControl("new_options", this._formBuilder.array([]));
    this.found = false;
    for (var i in this.data.defaults) {
      for (var j in this.data.defaults[i].values) {
        if (
          this.data.defaults[i].values[j].setting_variable_name ==
          this.searchValue
        ) {
          this.index = {
            i: i,
            j: j,
          };
          this.deviceSetting.controls.setting_name.setValue(
            this.data.defaults[i].values[j].setting_name
          );
          this.deviceSetting.controls.setting_description.setValue(
            this.data.defaults[i].values[j].setting_description
          );
          this.deviceSetting.controls.setting_variable_name.setValue(
            this.data.defaults[i].values[j].setting_variable_name
          );
          this.deviceSetting.controls.setting_variable_type.setValue(
            this.data.defaults[i].values[j].setting_variable_type
          );
          if (this.data.defaults[i].values[j].setting_options) {
            this.options = this.data.defaults[i].values[j].setting_options;
            for (var op of this.options) {
              this.addOldSettingOption(op.value, op.name);
            }
          }
          this.found = true;
          break;
        }
      }
      if (this.found) {
        break;
      }
    }
    if (!this.found) {
      this.snackBar.error({
        msg: "Not found",
        action: "Close",
      });
    }
  }

  editDeviceSetting() {
    if (this.deviceSetting.valid) {
      this.load = true;
      this.data.defaults[this.index.i].values[
        this.index.j
      ].setting_name = this.deviceSetting.value.setting_name;
      this.data.defaults[this.index.i].values[
        this.index.j
      ].setting_description = this.deviceSetting.value.setting_description;

      // check for duplicate values
      var all_options = [
        ...this.deviceSetting.value.old_options,
        ...this.deviceSetting.value.new_options,
      ];
      var V_values = [];
      for (var option of all_options) {
        if (V_values.includes(option.value)) {
          console.log("DUPLICATE")
          Swal.fire(
            "Oopsss...",
            "All the values of setting options should be unique.",
            "error"
          );
          return;
        }
        V_values.push(option.value);
      }

      // FOR OLD OPTIONS
      this.data.defaults[this.index.i].values[
        this.index.j
      ].setting_options = [];
      for (var i in this.deviceSetting.value.old_options) {
        if (
          this.data.defaults[this.index.i].values[this.index.j].setting_options
        ) {
          this.data.defaults[this.index.i].values[
            this.index.j
          ].setting_options.push(this.deviceSetting.value.old_options[i]);
        }
      }

      // FOR NEW OPTIONS
      for (var i in this.deviceSetting.value.new_options) {
        if (
          this.data.defaults[this.index.i].values[this.index.j].setting_options
        ) {
          this.data.defaults[this.index.i].values[
            this.index.j
          ].setting_options.push(this.deviceSetting.value.new_options[i]);
        } else {
          this.data.defaults[this.index.i].values[this.index.j][
            "setting_options"
          ] = [];
          this.data.defaults[this.index.i].values[
            this.index.j
          ].setting_options.push(this.deviceSetting.value.new_options[i]);
        }
      }
      this.service
        .editDeviceSetting(this.data._id, this.data.defaults)
        .subscribe((data: any) => {
          if (data) {
            if (data.ok) {
              this.dialogRef.close({
                added: true,
              });
            } else {
              this.load = false;
              Swal.fire("Oopsss...", "Something went worng", "error");
            }
          }
        });
    } else  {
      console.log("INVALID", this.deviceSetting);
      
    }
  }
}
