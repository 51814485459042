import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator, MatTableDataSource, MatSort} from '@angular/material';


export interface CallQueues {
  ivr_name: String;

}

const ELEMENT_DATA: CallQueues[] = [
  {ivr_name:'Billing Welcome IVR'},
  {ivr_name:'Queue Breakout'},
];

@Component({
  selector: 'app-ivrlist',
  templateUrl: './ivrlist.component.html',
  styleUrls: ['./ivrlist.component.scss']
})
export class IvrlistComponent implements OnInit {
  displayedColumns: string[] = ['ivr_name','action'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  constructor() { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

}
