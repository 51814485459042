import { Component, OnInit, AfterViewChecked, ViewChild, ElementRef } from '@angular/core';
import { Select2Option, Select2Data } from 'ng-select2-component';
import { FormBuilder, FormGroup, Validators, FormControl  } from '@angular/forms';
import * as toastr from "toastr";


@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit,AfterViewChecked {
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  public dataSource: any;
  messagesForm: FormGroup;
  submitted = false;

  public recording

  public data2 : Select2Data = [
    { value: '1234567890', label: '1234567890' },
    { value: '2345678901', label: '2345678901',},
    { value: '3456789012', label: '3456789012' },
    { value: '4567890123', label: '4567890123' },
    { value: '5678901234', label: '5678901234' },
    { value: '6789012345', label: '6789012345' },
    { value: '7890123456', label: '7890123456' },
    { value: '8901234567', label: '8901234567' },
    { value: '9012345678', label: '9012345678' },
    { value: '1122334455', label: '1122334455' },
    { value: '6677889900', label: '6677889900' },
    { value: '1526374890', label: '1526374890' },
  ]
  data: Select2Option[] = [];
  
  public usersList = [
    {
      date:'July 10, 2018',
      list: [
        { name:'(214) 499-7628', message:'Hello Techspawn', time:'6:23 AM' },
        { name:'Bruce Wayne', message:'That is Perfect', time:'10:30 AM' },
        { name:'Barry Allen', message:'Yeah Will Do it !', time:'1:45 AM' }
      ]
    },
    {
      date:'July 11, 2018',
      list: [
        { name:'Clark Kent', message:'Floormap is done', time:'9:30 AM' },
        { name:'Diana prince', message:'Nice design', time:'2:12 PM' },
        { name:'Hal Jordan', message:'Ok then, will meet you', time:'5:00 PM' }
      ]
    },
    {
      date:'July 12, 2018',
      list: [
        { name:'Tony Stark', message:'Its Ok', time:'3:44 AM' },
        { name:'Steve Rogers', message:'That will work', time:'2:55 PM' },
        { name:'Thor Odinson', message:'Awesome !', time:'4:21 PM' }
      ]
    },
    {
      date:'July 13, 2018',
      list: [
        { name:'Bruce Banner', message:'Its Ok', time:'3:44 AM' },
        { name:'Natasha Romanoff', message:'That will work', time:'2:55 PM' },
        { name:'Clint Barton', message:'Awesome !', time:'4:21 PM' }
      ]
    },
  ];

  public chatList = [
    {
      date: '13 July 2018',
      list: [
        {id: 2, name:'Bruce Wayne', message:'This is the point i was talking about.', time:'8:11 AM'},
        {id: 1, name:'Tony Stark', message:'Yeah Sure. That is a complex piece of Machinery.', time:'8:13 AM'},
        {id: 1, name:'Tony Stark', message:'It may Take a large team to build', time:'8:14 AM'},
        {id: 2, name:'Bruce Wayne', message:'Yes. According to me we need approximately 3000 engineers to build it.', time:'8:20 AM'},
        {id: 1, name:'Tony Stark', message:'Yeah. You are right', time:'8:29 AM'},
        {id: 2, name:'Bruce Wayne', message:'This is the point i was talking about.', time:'8:30 AM'},
        {id: 1, name:'Tony Stark', message:'Yeah Sure. That is a complex piece of Machinery.', time:'8:32 AM'},
        {id: 1, name:'Tony Stark', message:'It may Take a large team to build', time:'8:40 AM'},
        {id: 2, name:'Bruce Wayne', message:'Yes. According to me we need approximately 3000 engineers to build it.', time:'8:55 AM'},
        {id: 1, name:'Tony Stark', message:'Yeah. You are right', time:'9:09 AM'},
      ]
    },
  ];

  
  constructor(private formBuilder: FormBuilder) { }

  applyFilter(filterValue: any) {
    this.usersList.filter = filterValue.trim().toLowerCase();
  }

  ngOnInit() {
    this.scrollToBottom();
    this.messagesForm = this.formBuilder.group({
      textMessage: [''],
    })
  }

  ngAfterViewChecked() {
    this.scrollToBottom();        
  }

  scrollToBottom(): void {
    try {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch(err) { }                 
}

  get f() { return this.messagesForm.controls; }
  
  onSubmit() {
    this.submitted = true;

    if (this.messagesForm.invalid) {
      return;
    }
    console.log(this.messagesForm.value);
    this.messagesForm.reset();
}

  update(value: string) {
    this.recording = value;
  }
  open() {
    this.data = JSON.parse(JSON.stringify(this.data2));
  }

  search(text: string) {
    this.data = text
      ? (JSON.parse(JSON.stringify(this.data2)) as Select2Option[])
        .filter(option => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1)
      : JSON.parse(JSON.stringify(this.data2));
  }

}
