import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { EmailSettingsComponent } from './email-settings.component';
import { MatTabsModule, MatSelectModule, MatDividerModule, MatListModule, MatButtonModule, MatFormFieldModule, MatSlideToggleModule, MatCardModule, MatIconModule,MatTableModule, MatPaginatorModule, } from "@angular/material";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { EmailTemplatesComponent } from './email-templates/email-templates.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { UiSwitchModule } from 'ngx-ui-switch';
import { Select2Module } from "ng-select2-component";
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotificationEmailComponent } from './notification-email/notification-email.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonComponentModule } from "../../../common/commonComponent.module";

@NgModule({
    imports: [
      CommonModule,
      BrowserAnimationsModule,
      MatFormFieldModule,
      MatPaginatorModule,
      MatTabsModule,
      MatTableModule,
      HttpClientModule,
      MatInputModule,
      MatIconModule,
      MatCardModule,
      MatSlideToggleModule,
      MatSelectModule,
      MatButtonModule,
      MatListModule,
      MatDividerModule,
      CKEditorModule,
      FormsModule,
      ReactiveFormsModule,
      UiSwitchModule,
      Select2Module,
      NgSelectModule,
      CommonComponentModule
    ],
    providers: [],
    declarations: [
        EmailSettingsComponent,
        EmailTemplatesComponent,
        NotificationEmailComponent
    ]
  })
  export class EmailSettingsModule { }