import {Injectable} from '@angular/core';


declare var jsPDF: any;


@Injectable()
export class PDFGenerator {

  constructor() {

  }

  generatPDFInTable(title, heading, data, fileName, module? : string) {

    let doc = new jsPDF('l');
    doc.text(7, 15, title);
    if(module){
      doc.autoTable(heading, data, {
        startY: 30,
        theme: 'grid',
        margin: {horizontal: 7},
        bodyStyles: {valign: 'top'},
        styles: {overflow: 'linebreak'},
        columnStyles: {
          itemDesc:{columnWidth:100}
        }
      });
    }else{
      doc.autoTable(heading, data, {
        startY: 30,
        theme: 'grid',
        margin: {horizontal: 7},
        bodyStyles: {valign: 'top'},
        styles: {overflow: 'linebreak', columnWidth: 'auto'},
        columnStyles: {text: {columnWidth: 'auto'}}
      });
    }

    doc.save(fileName + '.pdf');

  }

}
