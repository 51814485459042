import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "removeUnderscore" })
export class RemoveUnderscorePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      let str = value.replace(/_/g, " ");
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  }
}
