import { Device } from "../../models";
import { DeviceAction, DeviceActionType } from "../actions";

export interface DeviceState {
  loading: boolean;
  loaded: boolean;
  data: Device[] | null;
}

export const deviceState: DeviceState = {
  loading: false,
  loaded: false,
  data: null
};

export function deviceReducer(
  state = deviceState,
  action: DeviceAction
): DeviceState {
  switch (action.type) {
    case DeviceActionType.DEVICE_LOAD: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case DeviceActionType.DEVICE_LOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload
      };
    }

    case DeviceActionType.DEVICE_LOAD_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null
      };
    }
  }
  return state;
}
