import { TenantExistsService } from './../../service/tenant-exists.service';
import { Component, OnInit, Output, EventEmitter, ViewEncapsulation } from "@angular/core";
import {ActivatedRoute, Params, Router} from '@angular/router';
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { LoginService } from "../../service/login.service";
import * as toastr from 'toastr';
import {API, StorageKey} from '../../service/constant';
import {ConfigService} from '../../service/config.service';

@Component({
  selector: 'app-login',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public data: any;
  loginForm: FormGroup;
  submitted = false;
  public userId: any;
  hostname: string;
  companyName: string = "UTILIKO";
  companyKey: string;


  constructor(private fb: FormBuilder, private loginService: LoginService, private tenantExistsService: TenantExistsService, private router: Router, private activatedRoute: ActivatedRoute,
              private configService : ConfigService) {
  }


  ngOnInit() {
    this.hostname = window.location.hostname;
    if (this.hostname != 'localhost') {
      this.checkDomain(this.hostname);
    }
    document.querySelector("body").setAttribute("themebg-pattern", "theme1");
    this.loginForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required]]
    });
    this.activatedRoute.queryParams.subscribe((params: Params) => {
    });
  }
  checkDomain(domain) {
    if (domain) {
      domain = domain.replace(/ /g, "").toLowerCase();
      this.tenantExistsService.domainExists(domain).subscribe((res: any) => {
        if (!res.data.isDomainExist) {
          this.router.navigate(["/page-not-found"], { replaceUrl: true });
        } else{
          this.loginService.setTenantCompanyKey(res.data.tenantCompanyKey);
          this.companyName = res.data.companyName;
          this.companyKey = res.data.tenantCompanyKey;
        }
      });
    }
  }

  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return this.loginForm.controls;
    }

    this.data = {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password,
      domain: this.hostname,
      tenantCompanyKey: this.companyKey
    };

    this.loginService.userLogin(this.data).subscribe(
      (res: any) => {
        toastr.options.positionClass = "toast-bottom-right";
        if ((res.statusCode == 200 && res.domain == this.hostname) || (res.statusCode == 200 && this.hostname == 'localhost')) {
          toastr.success("Login successful");
          console.log("userdata",res)
          this.loginService.setShareValue(this.loginForm.value.password);
          localStorage.setItem(StorageKey.ACCESSTOKEN, res.accessToken);
          localStorage.setItem('userId', res.userId);
          localStorage.setItem('theme', res.theme);
          localStorage.setItem('owner', res.isOwner);
          localStorage.setItem('setup', res.issetupdone);
          localStorage.setItem('isUbm', res.isUBM);
          localStorage.setItem('domain', res.domain);
          localStorage.setItem('tk', res.tkey);
          this.configService.getAllConfigDetails();
          if (res.issetupdone == 1 && res.isSubscriptionOver == 0){
            localStorage.setItem('subscription', '1');
            this.router.navigate(["/admin"], {replaceUrl: true});
          } else if (res.issetupdone == 1 && res.isSubscriptionOver == 1){
            localStorage.setItem('subscription', '0');
            toastr.options.positionClass = "toast-bottom-right";
            toastr.warning("Please renew subscription.");
            this.router.navigate(['/subscription'], {replaceUrl: true})
          } else{
            localStorage.setItem('subscription', '1');
            toastr.options.positionClass = "toast-bottom-right";
            toastr.success("Complete setup to get started.");
            this.router.navigate(["/wizard"], {replaceUrl: true});
          }
        } else if (res.statusCode == 200 && res.domain != this.hostname) {
          toastr.error("Login credentials do not match with " + res.domain +" access");
          this.router.navigate(["/page-not-found"], { replaceUrl: true });
        } else {
          toastr.error("Login credentials do not match the system. Please try again");
        }
      },
      error => {
        toastr.options.positionClass = "toast-bottom-right";
        if(error.error && error.error.error && error.error.error.data && error.error.error.data.responseMsg){
          toastr.error("Login credentials do not match the system. Please try again");
        }else {
          toastr.error("Login credentials do not match the system. Please try again");
        }
        // console.log(error);
      }
    );
  }
}
