import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators,NgForm} from '@angular/forms';
import { Select2Option, Select2Data } from 'ng-select2-component';
@Component({
  selector: 'app-add-conference-bridges',
  templateUrl: './add-conference-bridges.component.html',
  styleUrls: ['./add-conference-bridges.component.scss']
})
export class AddConferenceBridgesComponent implements OnInit {

  conferencebridgeFormgroup : FormGroup
  constructor(private _formBuilder: FormBuilder) { }

  public recording

  public data2 : Select2Data = [
    { value: 'AK', label: 'Alaska' },
    { value: 'HI', label: 'Hawaii',},
    { value: 'CA', label: 'California' },
    { value: 'NV', label: 'Nevada' },
    { value: 'OR', label: 'Oregon' },
    { value: 'WA', label: 'Washington' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'CO', label: 'Colorado' },
    { value: 'ID', label: 'Idaho' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'ND', label: 'North Dakota' }
  ]
  data: Select2Option[] = [];

  ngOnInit() {
    this.conferencebridgeFormgroup = this._formBuilder.group({
      conferencenumber : ['', Validators.required],
      conferencename   : ['', Validators.required],
      userpin          : ['', Validators.required],
      adminpin         : ['', Validators.required],
      joinmessage      : ['', Validators.required],
      musiconholdclass : ['', Validators.required],
      maximumparticipants : ['', Validators.required],
      membertimeout    : ['', Validators.required]
    })
  }
  conferencebridgeForm(){
    console.log(this.conferencebridgeFormgroup.value);
  }
  update(value: string) {
    this.recording = value;
  }
  open() {
    this.data = JSON.parse(JSON.stringify(this.data2));
  }

  search(text: string) {
    this.data = text
      ? (JSON.parse(JSON.stringify(this.data2)) as Select2Option[])
        .filter(option => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1)
      : JSON.parse(JSON.stringify(this.data2));
  }

}
