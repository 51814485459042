import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource} from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';

export interface InboundRoutes {
  did: number;
  cid: string;
  description: string;
  destination: string;
}

const ELEMENT_DATA: InboundRoutes[] = [
  {did:2143907281, cid:'Any', description:'Hunter Kelly', destination:'Extension: This is demo Inbound Routes description here'},
  {did:2146130380, cid:'Any', description:'Dallas Fax', destination:'Misc Destinations: Dallas Fax Temporary Forwarding Number'},
  {did:2146130500, cid:'Any', description:'Dallas', destination:'Time Conditions: This is demo Inbound Routes description here'},
  {did:2146183420, cid:'Any', description:'Frisco Wade', destination:'Time Conditions: This is demo Inbound Routes description here'},
  {did:2146124555, cid:'Any', description:'Piano-Pkwy', destination:'Time Conditions: This is demo Inbound Routes description here'},
];

@Component({
  selector: 'app-inbound-routes-list',
  templateUrl: './inbound-routes-list.component.html',
  styleUrls: ['./inbound-routes-list.component.scss']
})
export class InboundRoutesListComponent implements OnInit {
  displayedColumns: string[] = ['did','cid','description','destination', 'action'];
  dataSource = new MatTableDataSource<InboundRoutes>(ELEMENT_DATA);
  
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
  }

}
