import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { API_BASE } from "../env/provisioner.env";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  constructor(private http: HttpClient) {
    this.header = new HttpHeaders();
    this.header = this.header.append(
      "Access-Control-Allow-Credentials",
      "true"
    );
    this.header = this.header.append("Access-Control-Allow-Origin", "*");
    this.header = this.header.append("Access-Control-Allow-Origin", "*");
  }

  header: HttpHeaders;

  login(data) {
    return this.http.post(API_BASE + "auth/login", {
      headers: this.header,
      username: data.username,
      password: data.password
    });
  }
}
