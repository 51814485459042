import { NgModule} from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import { ClientRoutingModule } from './client-routing.module';
import { ClientComponent } from './client.component';
import { RestService } from '../../rest.service';
import { ClientlistComponent } from './clientlist/clientlist.component';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';
import { MatStepperModule, MatInputModule, MatButtonModule, MatAutocompleteModule, MatIconModule,MatTabsModule,MatTableModule, MatFormFieldModule, MatSelectModule, MatSlideToggleModule, MatCardModule, MatPaginatorModule,MatSortModule, MatCheckboxModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import { AddclientComponent } from './addclient/addclient.component';
import { Select2Module } from "ng-select2-component";
import { UiSwitchModule } from "ngx-ui-switch";
//import { UsersComponent } from './users/users.component'; 
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonComponentModule } from "../../common/commonComponent.module";


@NgModule({
    declarations: [
        ClientComponent,
        ClientlistComponent,
        AddclientComponent,
        
        //routingComponents,
        //UsersComponent
    ],
    imports: [
        BrowserModule,
        ClientRoutingModule,
        MatTableModule,
        MatTabsModule,
        MatStepperModule,
        MatInputModule,
        MatButtonModule,
        MatAutocompleteModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatIconModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatCardModule,
        MatPaginatorModule,
        FormsModule,
        MatSortModule,
        GooglePlaceModule,
        TranslateModule.forRoot(),
        MatCheckboxModule,
        Select2Module,
        UiSwitchModule,
        NgSelectModule,
        CommonComponentModule

        
    ],
    providers: [RestService],
    bootstrap: [ClientComponent],
    exports:[ClientlistComponent],
})

export class ClientModule { }