import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator, MatTableDataSource, MatSort} from '@angular/material';

export interface TimeConditions {
  setting_name: String;
  device_model: String;
  device_type: String;
}

const ELEMENT_DATA: TimeConditions[] = [
  { setting_name:"DP750", device_model:"Grandstream DP750 Fanvil", device_type:"Grandstream DP750 Fanvil" },
  { setting_name:"DP750", device_model:"Grandstream DP750 Fanvil", device_type:"Grandstream DP750 Fanvil" },
  { setting_name:"DP750", device_model:"Grandstream DP750 Fanvil", device_type:"Grandstream DP750 Fanvil" },
  { setting_name:"DP750", device_model:"Grandstream DP750 Fanvil", device_type:"Grandstream DP750 Fanvil" },
  { setting_name:"DP750", device_model:"Grandstream DP750 Fanvil", device_type:"Grandstream DP750 Fanvil" },

];

@Component({
  selector: 'app-all-company-list',
  templateUrl: './all-company-list.component.html',
  styleUrls: ['./all-company-list.component.scss']
})
export class AllCompanyListComponent implements OnInit {

  displayedColumns: string[] = ['setting_name','device_model','device_type','action'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  
  
  constructor() { }

  ngOnInit() {
  }

}
