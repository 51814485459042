import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {API} from './constant';

@Injectable({
  providedIn: 'root'
})
export class VisibilityService {

  constructor(private http: HttpClient) {
  }

  getVisibilitiesForUser( modelName: string): Observable<any> {
    return this.http.get(API.VISIBILITY_GET_LIST + '?moduleName=' + modelName);
  }

  updateVisibilityForUser(data: any): Observable<any> {
    return this.http.post(API.VISIBILITY_UPDATE, data);
  }
}
