import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API} from './constant';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InvoiceServiceService {

  public updateInvoiceEventListener = new BehaviorSubject(0);

  constructor(private http: HttpClient) {}

  invoiceList(type, moduleId, filter): Observable<any> {
    if (type === '' && filter !== ''){
      if (filter !== '') {
        return this.http.get(API.INVOICE_LIST + '?filter=' + JSON.stringify(filter));
      } else {
        return this.http.get(API.INVOICE_LIST + '?');
      }
    } else {
      if (filter !== '') {
        return this.http.get(API.INVOICE_LIST + '?type=' + type + '&moduleId=' + moduleId + '&filter=' + JSON.stringify(filter));
      } else {
        return this.http.get(API.INVOICE_LIST + '?type=' + type + '&moduleId=' + moduleId );
      }
    }
  }
  paidInvList() {
    return this.http.get(API.INVOICE_LIST);
  }
  invoiceDelete(invoiceId) {
    return this.http.delete(API.INVOICE_DLTLIST + '/' + invoiceId);
  }
  invoiceDetailList(invoiceId) {
    return this.http.get(API.INVOICE_DETLIST + '/' + invoiceId);
  }
  invoiceClone(invoiceId, clientId) {
    return this.http.get(API.CLONE_INVOICE + '?invoicesId=' + invoiceId + '&clientId=' + clientId);
  }
  invoiceCost(model, value, invoiceId) {
    return this.http.get(API.INVOICE_CALCULATION + '?modelValue=' + model + '&value=' + value + '&id=' + invoiceId);
  }
  getEmailTemplate(eGroup) {
    return this.http.get(API.EMAIL_TEMPLATE + '?eGroup=' + eGroup);
  }
  sendEmail(data) {
    return this.http.post(API.SEND_INV_MAIL, data);
  }
  invoiceActivity(moduleId, type) {
    return this.http.get(API.ACTIVITY_LIST + '?moduleId=' + moduleId + '&type=' + type);
  }
  profileInfo(userId) {
    return this.http.get(API.PROFILE_LIST + '?userId=' + userId);
  }
  accountList() {
    return this.http.get(API.ACCOUNT_LIST);
  }
  paymentForRefund(invoiceId, clientId){
    return this.http.get(API.PAYMENT_FOR_REFUND + '?invoicesId=' + invoiceId +'&clientId=' + clientId);
  }
  changeStatus(invoiceId, status){
    return this.http.get(API.INVOICE_CHANGE_STATUS + invoiceId + '?status=' + status);
  }
  invoiceRefund(data){
    if (data.action !== 'requestRefundFromInvoiceDelete') {
      if (data.refundType === 'refund') {
        return this.http.get(API.INV_DELETE_REFUND + '?action=' + data.action + '&invoicesId=' + data.invoicesId + '&clientId=' +
          data.clientId + '&amount=' + data.amount + '&accountId' + data.accountId +'&refundType=' + data.refundType);
      } else {
        return this.http.get(API.INV_DELETE_REFUND + '?action=d' + data.action + '&invoicesId=' + data.invoicesId + '&clientId=' +
          data.clientId + '&amount=' + data.amount + '&refundType=' + data.refundType);
      }
    } else {
      return this.http.get(API.INV_REQ_DELETE_REFUND + data);
    }
  }
  listCommision(){
    return this.http.get(API.COMM_LIST);
  }
  addInvoice(body){
    return this.http.post(API.CREATE_INVOICE, body);
  }
  batchEmail(data) {
    return this.http.post(API.BATCH_EMAIL , data);
  }
  paymentInfoListByClient(id) {
    return this.http.get(API.PAYMENT_LIST_BY_CLIENT+ "?clientId=" + id);
  }
  addCreditCardInfo(data){
    return this.http.post(API.ADD_CC_INFO , data);
  }
  paymentInfoDetails(id){
    return this.http.get(API.PAYMENTINFO_DETAIL + '/' + id);
  }
  decryptId(id){
    return this.http.get(API.DECRYPT_INVOICEID + '?invoicesId=' + id);
  }
}
