import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';


export interface CdrData {
  dialogOpt: number;
}

@Component({
  selector: 'app-cdr-dialog',
  templateUrl: './cdr-dialog.component.html',
  styleUrls: ['./cdr-dialog.component.scss']
})

export class CdrDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CdrDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CdrData) { }

  cdrData = this.data; 
  
  ngOnInit() {
    console.log("Dialog Data:- ", this.data);
  }

  navigateToCDR() {
    this.dialogRef.close();
  }

}
