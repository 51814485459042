import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API } from "./constant";
import { Observable, Subject } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/toPromise';

@Injectable({
    providedIn: 'root'
})

export class CallsService {

    constructor(private http: HttpClient) { }

    getCallsList(moduleId, moduleName, filter?){
        return this.http.get(API.CALLS_LIST + moduleId + '/' + moduleName +'?filter=' + JSON.stringify(filter));
    }
    callDetails(callsId){
        return this.http.get(API.CALL_DETAILS + callsId);
    }
    updateCall(callsId, callsData){
        return this.http.post(API.UPDATE_LEAD_CALL+callsId, callsData);
    }
    addCall(callsData){
        return this.http.post(API.ADD_LEAD_CALL, callsData);
    }
    deleteCall(callsId, type) {
        return this.http.delete(API.DELETE_LEAD_CALL + callsId + '/' + type);
    }
}