import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { UserDashboardComponent, Dialerpad } from "./user-dashboard.component";
import { UserDashboardRoutingModule } from "./user-dashboard-routing.module";
import { FormsModule } from '@angular/forms';
import {MatCardModule, MatButtonModule, MatFormFieldModule,MatDialogModule, MatTableModule, MatSortModule, MatPaginatorModule } from '@angular/material';
import { ChartsModule } from 'ng2-charts';
import { from } from "rxjs";
import { NewMessagesComponent } from './new-messages/new-messages.component';
import { NewVoicemailsComponent } from './new-voicemails/new-voicemails.component';
import { UserCallStatisticsComponent } from './user-call-statistics/user-call-statistics.component';
import { UserCallStatusComponent } from './user-call-status/user-call-status.component';
import { UserInboundRoutesComponent } from './user-inbound-routes/user-inbound-routes.component';
import {ScrollDispatchModule} from '@angular/cdk/scrolling';
import { SharedModule } from '../shared/shared.module';
import { CommonComponentModule } from "../../common/commonComponent.module";
@NgModule({
    imports: [  
      CommonModule,
      BrowserModule,
      UserDashboardRoutingModule,
      MatButtonModule,
      MatCardModule,
      MatDialogModule,
      MatFormFieldModule,
      ChartsModule,
      MatTableModule,
      MatSortModule,
      MatPaginatorModule,
      FormsModule,
      ScrollDispatchModule,
      SharedModule,
      CommonComponentModule
    ],
    declarations :[
      UserDashboardComponent, 
      NewMessagesComponent, 
      NewVoicemailsComponent, 
      UserCallStatisticsComponent, 
      UserCallStatusComponent, 
      UserInboundRoutesComponent, 
      Dialerpad,
    ],
    entryComponents: [Dialerpad],
})

export class UserDashboardModule { }