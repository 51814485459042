import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-voicemails',
  templateUrl: './new-voicemails.component.html',
  styleUrls: ['./new-voicemails.component.css','../user-dashboard.component.scss']
})
export class NewVoicemailsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
