import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../service/dashboard.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(public dashboardservice: DashboardService, public router : Router) { }

  ngOnInit() {
  }

  GoToclient(){
    // this.dashboardservice.changeMessage(1)
    this.router.navigateByUrl('admin/client')

  }

}
