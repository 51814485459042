import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AdminComponent } from "./admin.component";
import {AuthGuard} from '../app.authGuard.service';

const routes: Routes = [
  {
    path: "admin",    
    // component: AdminComponent,
    redirectTo: '/admin/provisioner/clients', pathMatch: 'full',
    data: {
      title: "Get Started"
    },
    
    // component: AdminComponent,
    // children: [
    //   {
    //     path: "",
    //     // component: HomeComponent
    //   }
    // ]
  },
  {path: '', redirectTo: '/admin/provisioner/clients', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AdminRoutingModule {}
