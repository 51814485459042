import { Component, OnInit, Optional, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ClientService } from "../../../services";
import { group } from "@angular/animations";
import Swal from "sweetalert2";
import { MacAddressValidator } from '../../../helpers/macAddress-Validator';

@Component({
  selector: "add-device",
  templateUrl: "./add-device.component.html",
  styleUrls: ["./add-device.component.scss"],
})
// @ts-ignore
export class AddDeviceComponent implements OnInit {
  addDeviceForm: FormGroup;
  groups: any;
  groupSettings: any;

  constructor(
    private dialogRef: MatDialogRef<AddDeviceComponent>,
    // @ts-ignore
    @Optional() @Inject(MAT_DIALOG_DATA) private data,
    private _formBuilder: FormBuilder,
    private service: ClientService
  ) {}

  ngOnInit() {
    this.addDeviceForm = this._formBuilder.group({
      extNum: [
        null,
        [Validators.required, Validators.pattern("^[a-zA-Z0-9 _-]*$")],
      ],
      extName: ["", Validators.required],
      authPass: [this.generatePassword(), Validators.required],
      macAddr: ["", [Validators.required, MacAddressValidator.customPatternValidator]],
      group_id: [null, [Validators.required, Validators.pattern("^[0-9]*$")]],
      inherit_from_group_settings: [null, Validators.required],
      created_at: [new Date()],
    });

    this.service.getGroups(this.data.client_id).subscribe((data: any) => {
      this.groups = data;
    });
  }

  generatePassword() {
    var length = 18,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  getGroupSettings(val: number) {
    this.service
      .getGroupSettings(this.data.client_id, val)
      .subscribe((res: any) => {
        this.groupSettings = res;
      });
  }

  addDevice() {
    if (this.addDeviceForm.valid) {
      var val = this.addDeviceForm.value;
      var data = {
        extNum: val.extNum.trim(),
        extName: val.extName.trim(),
        authPass: val.authPass,
        macAddr: val.macAddr.replace(/:|\s|'|"|;|-|\./g, "").toLowerCase(),
        group_id: val.group_id,
        inherit_from_group_settings: val.inherit_from_group_settings,
        created_at: new Date().toString(),
      };
      this.service
        .duplicateMac([
          val.macAddr
            .replace(/:|\s|'|"|;|-|\./g, "")
            .toLowerCase()
            .toString(),
        ])
        .subscribe((dup: any) => {
          if (!dup.duplicate) {
            this.service
              .add_device(this.data.client_id, data)
              .subscribe((data: any) => {
                if (data) {
                  if (data.ok) {
                    this.dialogRef.close({
                      added: true,
                    });
                  } else {
                    Swal.fire("Oopsss...", "Something went worng", "error");
                  }
                }
              });
          } else {
            return Swal.fire(
              "Oopsss...",
              `MAC address ${data.macAddr} have already exists for client ${dup.client}`,
              "error"
            );
          }
        });
    }
  }
}
