import { Component, OnInit, Optional, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ClientService } from "../../../services";
import Swal from "sweetalert2";

@Component({
  selector: "add-setting",
  templateUrl: "./add-setting.component.html",
  styleUrls: ["./add-setting.component.scss"]
})
export class AddSettingComponent implements OnInit {
  addSetting: FormGroup;
  companySettings: any;

  constructor(
    private dialogRef: MatDialogRef<AddSettingComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data,
    private _formBuilder: FormBuilder,
    private service: ClientService
  ) {}

  ngOnInit() {
    this.addSetting = this._formBuilder.group({
      name: ["", [Validators.required]],
      comapny_setting: [null, [Validators.required]]
    });

    this.service.comapnySettings().subscribe((data: any) => {
      this.companySettings = data;
    });
  }

  addCompanySetting() {
    if (this.addSetting.valid) {
      this.service
        .add_client_setting(this.data.client_id, {
          company_setting: this.addSetting.value.comapny_setting,
          name: this.addSetting.value.name,
          created_at: new Date().toString()
        })
        .subscribe((data: any) => {
          if (data) {
            if (data.ok) {
              this.dialogRef.close({
                added: true
              });
            } else {
              Swal.fire("Oopsss...", "Something went worng", "error");
            }
          }
        });
    }
  }
}
