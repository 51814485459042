import { CommonComponentModule } from "./../../common/commonComponent.module";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import {
  MatStepperModule,
  MatInputModule,
  MatButtonModule,
  MatAutocompleteModule,
  MatIconModule,
  MatTabsModule,
  MatTableModule,
  MatFormFieldModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatCardModule,
  MatPaginatorModule,
  MatSortModule,
} from "@angular/material";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { ProvisionerComponent } from "./provisioner.component";
import { AllcompanysettingComponent } from "./allcompanysetting/allcompanysetting.component";
import { ProvisionerRoutingModule } from "./provisioner-routing.module";
import { AddcomapnysettingComponent } from "./addcomapnysetting/addcomapnysetting.component";
import { GlobalsettingComponent } from "./golbalsetting/globalsetting.component";
import { DialogComponent } from "./dialog/dialog.component";
import { HttpClientModule } from "@angular/common/http";
import { DialogService } from "./dialog/dialog.service";
import { PopoverModule } from "ngx-popover";
import { Select2Module } from "ng-select2-component";
import { ClientsComponent } from "./clients/clients.component";
import { MatDialogModule } from "@angular/material/dialog";
import { AddClientComponent } from "./clients/add-client/add-client.component";
import { MatRadioModule } from "@angular/material/radio";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { reducers, metaReducers, effects } from "./store";
import { environment } from "../../../environments/environment";
import { EffectsModule } from "@ngrx/effects";
import { services } from "./services";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatTooltipModule } from "@angular/material/tooltip";
import { GroupsComponent } from "./clients/groups/groups.component";
import { AddGroupComponent } from "./clients/groups/add-group/add-group.component";
import { GroupSettingsComponent } from "./clients/groups/group-settings/group-settings.component";
import { AddGroupSettingsComponent } from "./clients/groups/add-group-settings/add-group-settings.component";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { DevicesComponent } from "./clients/devices/devices.component";
import { AddDeviceComponent } from "./clients/devices/add-device/add-device.component";
import { GlobalSettingsComponent } from "./clients/global-settings/global-settings.component";
import { AddSettingComponent } from "./clients/global-settings/add-setting/add-setting.component";
import { ClientDetailComponent } from "./clients/client-detail/client-detail.component";
import { CompanySettingsComponent } from "./company-settings/company-settings.component";
import { AddCompanySettingComponent } from "./company-settings/add-company-setting/add-company-setting.component";
import { DeviceSettingsComponent } from "./clients/devices/device-settings/device-settings.component";
import { EditCompanySettingsComponent } from "./company-settings/edit-company-settings/edit-company-settings.component";
import { EditFormComponent } from "./edit-form/edit-form.component";
import { BreadcrumbsModule } from "ng6-breadcrumbs";
import { EditGroupSettingComponent } from "./clients/groups/edit-group-setting/edit-group-setting.component";
import { EditSettingsComponent } from "./clients/global-settings/edit-settings/edit-settings.component";
import { DeviceModelsComponent } from "./device-models/device-models.component";
import { AddDeviceModelComponent } from "./device-models/add-device-model/add-device-model.component";
import { EditDeviceSettingComponent } from "./device-models/edit-device-setting/edit-device-setting.component";
import { AddDeviceSettingComponent } from "./device-models/add-device-setting/add-device-setting.component";
import { AddSingleDeviceSettingComponent } from "./device-models/add-single-device-setting/add-single-device-setting.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { LoginComponent } from "./login/login.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { ImageMapComponent } from "./image-map/image-map.component";
import { DeviceConfigDialogComponent } from "./device-config-dialog/device-config-dialog.component";
import { EditDialogComponent } from "./clients/add-client/edit-dialog/edit-dialog.component";

@NgModule({
  declarations: [
    ProvisionerComponent,
    AllcompanysettingComponent,
    AddcomapnysettingComponent,
    GlobalsettingComponent,
    DialogComponent,
    ClientsComponent,
    AddClientComponent,
    GroupsComponent,
    AddGroupComponent,
    GroupSettingsComponent,
    AddGroupSettingsComponent,
    DevicesComponent,
    AddDeviceComponent,
    GlobalSettingsComponent,
    AddSettingComponent,
    ClientDetailComponent,
    CompanySettingsComponent,
    AddCompanySettingComponent,
    DeviceSettingsComponent,
    EditCompanySettingsComponent,
    EditFormComponent,
    EditGroupSettingComponent,
    EditSettingsComponent,
    DeviceModelsComponent,
    AddDeviceModelComponent,
    EditDeviceSettingComponent,
    AddDeviceSettingComponent,
    AddSingleDeviceSettingComponent,
    LoginComponent,
    ImageMapComponent,
    DeviceConfigDialogComponent,
    EditDialogComponent,
  ],
  imports: [
    BrowserModule,
    MatTableModule,
    MatTabsModule,
    MatStepperModule,
    MatInputModule,
    MatButtonModule,
    MatAutocompleteModule,
    BreadcrumbsModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    MatSortModule,
    NgSelectModule,
    MatSlideToggleModule,
    MatCardModule,
    MatPaginatorModule,
    FormsModule,
    MatSortModule,
    MatTooltipModule,
    GooglePlaceModule,
    TranslateModule.forRoot(),
    ProvisionerRoutingModule,
    HttpClientModule,
    PopoverModule,
    MatDialogModule,
    MatRadioModule,
    MatGridListModule,
    MatSnackBarModule,
    MatCheckboxModule,
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot(effects),
    StoreDevtoolsModule.instrument({}),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    CommonComponentModule,
  ],
  providers: [DialogService, ...services],
  entryComponents: [
    DialogComponent,
    AddClientComponent,
    AddGroupComponent,
    AddGroupSettingsComponent,
    AddDeviceComponent,
    AddSettingComponent,
    AddCompanySettingComponent,
    EditFormComponent,
    AddDeviceModelComponent,
    AddDeviceSettingComponent,
    EditDeviceSettingComponent,
    AddSingleDeviceSettingComponent,
    DeviceConfigDialogComponent,
    EditDialogComponent,
  ],
  bootstrap: [ProvisionerComponent],
  exports: [ImageMapComponent],
})
export class ProvisionerModule {}
