import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl  } from '@angular/forms';
import { Select2Option, Select2Data } from 'ng-select2-component';
import * as toastr from "toastr";

@Component({
  selector: 'app-newparkinglot',
  templateUrl: './newparkinglot.component.html',
  styleUrls: ['./newparkinglot.component.scss']
})
export class NewparkinglotComponent implements OnInit {
  
  parkingLotForm: FormGroup;
  submitted = false;

  parkedClassData = [
    {id:1, name:'Default'},
    {id:2, name:'Class 1'},
    {id:3, name:'Class 2'},
    {id:4, name:'Class 3'},
    {id:5, name:'Class 4'},
    {id:6, name:'Class 5'},
  ];

  parkAlertData = [
    {id:1, name:'None'},
    {id:2, name:'Alert 1'},
    {id:3, name:'Alert 2'},
    {id:4, name:'Alert 3'},
    {id:5, name:'Alert 4'},
    {id:6, name:'Alert 5'},
  ];

  announcementData = [
    {id:1, name:'Default'},
    {id:2, name:'Announcement 1'},
    {id:3, name:'Announcement 2'},
    {id:4, name:'Announcement 3'},
    {id:5, name:'Announcement 4'},
    {id:6, name:'Announcement 5'},
  ];

  destinationData = [
    {id:1, name:'Default'},
    {id:2, name:'Destination 1'},
    {id:3, name:'Destination 2'},
    {id:4, name:'Destination 3'},
    {id:5, name:'Destination 4'},
    {id:6, name:'Destination 5'},
  ];

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.parkingLotForm = this.formBuilder.group({
      parkingExtension: ['', Validators.required],
      parkingName: ['', [Validators.required, Validators.maxLength(30)]],
      startPosition: ['', Validators.required],
      noOfSlots: ['', Validators.required],
      slotRange: ['', Validators.required],
      parkingTimeout: ['', Validators.required],
      parkedMusic: ['', Validators.required],
      alertInfo: ['', Validators.required],
      callerId: ['', Validators.required],
      announceMent: ['', Validators.required],
      destination1: ['', Validators.required],
      destination2: ['', Validators.required],
    });
    this.parkingLotForm.reset();
  }

  get f() { return this.parkingLotForm.controls; }

  onSubmit() {
      this.submitted = true;

      if (this.parkingLotForm.invalid) {
        toastr.error("Invalid Details !");
        return;
      }
      console.log(this.parkingLotForm.value);

      toastr.success("Form Submitted Successfully !")
  }

}
