import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddSystemComponent } from './add-system/add-system.component';
import { SystemrecordinglistComponent } from './systemrecordinglist/systemrecordinglist.component';
import { SystemaRecordingsComponent } from './systema-recordings.component';
import { MatStepperModule, MatInputModule, MatButtonModule, MatAutocompleteModule, MatIconModule,MatTabsModule,MatTableModule, MatFormFieldModule, MatSelectModule, MatSlideToggleModule, MatCardModule, MatPaginatorModule,MatSortModule,MatCheckboxModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from 'ng2-file-upload';
import { Select2Module } from 'ng-select2-component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { CommonComponentModule } from '../../../common/commonComponent.module';
import { NgSelectModule } from '@ng-select/ng-select';
@NgModule({
  declarations: [
    AddSystemComponent,
    SystemrecordinglistComponent,
    SystemaRecordingsComponent
  ],
  imports: [
    CommonModule,
    MatTabsModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    FormsModule,
    FormsModule,
    MatStepperModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    FileUploadModule,
    Select2Module,
    UiSwitchModule,
    NgSelectModule,
    CommonComponentModule
    
    
  ]
})
export class SystemrecordingsModule { }
